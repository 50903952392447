<template>
  <v-container fluid>
    <v-card elevation="1">
      <v-tabs v-model="tab">
        <v-tab>
          Contracts with missing invoices
        </v-tab>
        <v-tab>
          Contracts with missing documents
        </v-tab>
      </v-tabs>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0 mt-10"
        item-key="id"
        :loading="isLoading"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:item.name="{ item }">
          <router-link :to="{ name: 'contracts-EditContract', query: { id: item.id } }">
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.created="{ item }">
          {{ formatDate(item.created) }}
        </template>
        <template v-slot:item.createdBy="{ item }">
          <a :href="`mailto:${item.createdBy}`">{{ item.createdBy }}</a>
        </template>
        <template v-slot:item.expires="{ item }">
          {{ formatDate(item.expires) }}
        </template>
        <template v-slot:item.commences="{ item }">
          {{ formatDate(item.commences) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import currencies from '~src/mixins/currencies';
import { mapState } from 'vuex';

export default {
  name: 'Reports',
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.reports',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      tab: 0,
      headers: [{
        text: 'Contract',
        value: 'name',
      },
      {
        text: 'Created By',
        value: 'createdBy',
        width: '250px',
      },
      {
        text: 'Created',
        value: 'created',
        width: '130px',
      },
      {
        text: 'Commences',
        value: 'commences',
        width: '130px',
      },
      {
        text: 'Expires',
        value: 'expires',
        width: '130px',
      },
      ],
      items: [],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
  },
  watch: {
    tab() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.items = [];
      const { selectedCurrency } = this;
      let url = '';
      switch (this.tab) {
        default:
        case 0:
          url = `api/contracts/reports/missingInvoice/${selectedCurrency}`;
          break;
        case 1:
          url = `api/contracts/reports/missingDocuments/${selectedCurrency}`;
          break;
      }
      const { data } = await this.$http.get(url);
      this.items = data;
      this.isLoading = false;
    },
  },
};
</script>
