<template>
  <v-row class="d-flex align-stretch">
    <v-col class="d-flex flex-column align-stretch" cols="12" md="4">
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          Recurring Deals Profit (MRC)
        </v-card-title>
        <GraphRecurringProfit :target="parseFloat(targetMrp)" />
      </v-card>
      <v-card class="fill-height">
        <v-card-title primary-title>
          Non-Recurring Deals Profit (NRC)
        </v-card-title>
        <GraphRecurringProfit lineType="Non-Recurring Monthly Cost" :target="parseFloat(targetNrp)" />
      </v-card>
    </v-col>
    <v-col cols="12" md="2" class="d-flex flex-column align-stretch text-center">
      <v-card class="fill-height mb-10" :loading="isLoading">
        <v-card-title primary-title>
          MRP ({{ monthName }})
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.mrp) }}
          </div>
          <div class="text-h5">
            <Target :target="targetMrp" :actual="graphData.mrp" />
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height mb-10" :loading="isLoading">
        <v-card-title primary-title>
          MRP YTD
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.mrpYtd) }}
          </div>
          <div class="text-h5">
            <Target :target="targetMrpYtd" :actual="graphData.mrpYtd" />
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height mb-10" :loading="isLoading">
        <v-card-title primary-title>
          NRP ({{ monthName }})
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.nrp) }}
          </div>
          <div class="text-h5">
            <Target :target="targetNrp" :actual="graphData.nrp" />
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height" :loading="isLoading">
        <v-card-title primary-title>
          NRP YTD
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.nrpYtd) }}
          </div>
          <div class="text-h5">
            <Target :target="targetNrpYtd" :actual="graphData.nrpYtd" />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" class="d-flex flex-column align-stretch">
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          MRP Sales Pipeline
        </v-card-title>
        <GraphPipeline />
      </v-card>
      <v-card class="fill-height">
        <v-card-title primary-title>
          NRP Sales Pipeline
        </v-card-title>
        <GraphPipeline lineType="Non-Recurring Monthly Cost" />
      </v-card>
    </v-col>
    <v-col cols="12" md="2" class="d-flex flex-column align-stretch text-center">
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          Items in WIP
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ graphData.itemsInWip }}
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          MRR in WIP
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.mrrInWip) }}
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          MRP in WIP
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.mrpInWip) }}
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height mb-10">
        <v-card-title primary-title>
          NRR in WIP
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.nrrInWip) }}
          </div>
        </v-card-text>
      </v-card>
      <v-card class="fill-height">
        <v-card-title primary-title>
          NRP in WIP
        </v-card-title>
        <v-card-text>
          <div class="text-h4">
            {{ currencySymbol }} {{ formatNumberRounded(graphData.nrpInWip) }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import GraphRecurringProfit from '@/modules/contracts/src/components/GraphRecurringProfit.vue';
import GraphPipeline from '@/modules/contracts/src/components/GraphPipeline.vue';
import companySettings from '@/mixins/companySettings';
import Target from './Target.vue';

export default {
  name: 'DashboardStats',
  components: {
    GraphPipeline,
    GraphRecurringProfit,
    Target,
  },
  mixins: [companySettings],
  data() {
    return {
      deliveredItems: [],
      wipItems: [],
      isLoading: false,
      targetMrp: 0,
      targetMrpYtd: 0,
      targetNrp: 0,
      targetNrpYtd: 0,
      settings: [
        { name: 'targetMrp' },
        { name: 'targetMrpYtd' },
        { name: 'targetNrp' },
        { name: 'targetNrpYtd' },
      ],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    ...mapGetters('currencies', ['currencySymbol']),
    month() {
      return new Date();
    },
    monthName() {
      return dayjs(this.month).format('MMM YYYY');
    },
    graphData() {
      const { deliveredItems } = this;
      const { month } = this;
      const mrp = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isSame(month, 'month'))
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const { targetMrp } = this;
      const mrpComplete = Math.round((mrp / targetMrp) * 100);

      const beginningOfTheYear = new Date();
      beginningOfTheYear.setMonth(0);
      const mrpYtd = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const nrp = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isSame(month, 'month'))
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const nrpYtd = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const itemsInWip = uniq(this.wipItems
        .map((item) => item.agreement.id))
        .length;

      const mrrInWip = this.wipItems
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.lineAmount, 0);

      const mrpInWip = this.wipItems
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const nrrInWip = this.wipItems
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.lineAmount, 0);

      const nrpInWip = this.wipItems
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      return {
        itemsInWip,
        mrrInWip,
        mrpInWip,
        mrp,
        mrpYtd,
        mrpComplete,
        nrp,
        nrpYtd,
        nrrInWip,
        nrpInWip,
      };
    },
  },
  async mounted() {
    await Promise.all([
      (async () => {
        await this.refresh();
      })(),
      (async () => {
        await this.refreshCompanySettings();
      })(),
    ]);
  },
  methods: {
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.deliveredItems = [];
      this.wipItems = [];
      await Promise.all([
        (async () => {
          let url = 'odata/incomeLines?';
          url += '$filter=agreement/Status eq \'Delivered\'';
          // url += ` AND lineType eq '${this.lineType}'`;
          url += '&$expand=agreement($select=deliveredDate)';
          const { data } = await this.$http.get(url);
          this.deliveredItems = data.value;
        })(),
        (async () => {
          let url = 'odata/incomeLines?';
          url += '$filter=agreement/Status eq \'Work In Progress\'';
          // url += ` AND lineType eq '${this.lineType}'`;
          url += '&$expand=agreement($select=acceptedDate,id)';
          const { data } = await this.$http.get(url);
          this.wipItems = data.value;
        })(),
      ]);
      this.isLoading = false;
    },
  },
};
</script>
