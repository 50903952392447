<!-- eslint-disable max-lines -->
<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="agreements"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500, -1]
      }"
      dense
      show-expand
      :search="search"
      sort-by="agreementNumber"
      :sort-desc="true"
    >
    <template v-slot:top>
      <v-row>
        <v-col cols="4">
          <div class="text-h5 ml-2">Manage Agreements</div>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="1">
        </v-col>
        <v-col cols="3">
          <v-text-field
            clearable
            class="mr-2"
            v-model="search"
            :append-icon="mdiMagnify"
            :label="$t('common.search')"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="1">
          <v-btn small color="secondary" @click="refresh" class="mt-5">
            <v-icon>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <ExportToExcelButton
            class="mt-5"
            small
            name="Agreements"
            :data="exportData"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.verified="{ item }">
      <v-icon
        v-if="item.verified"
        color="success"
        class="mr-2"
      >
        {{ mdiSeal }}
      </v-icon>
    </template>
    <template v-slot:item.isDeleted="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isDeleted"
        large
        @save="saveIsDeleted(item)"
      >
        <BoolField class="inline-block" v-model="item.isDeleted" />
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: true }, { text: 'No', value: false}]"
            v-model="item.isDeleted"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.agreementNumber="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isActive"
        large
        @save="saveAgreementNumber(item)"
      >
        {{ item.agreementNumber }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-text-field
            v-model="item.agreementNumber"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isActive"
        large
        @save="saveIsActive(item)"
      >
        <BoolField class="inline-block" :value="!item.isActive" />
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: false }, { text: 'No', value: true}]"
            v-model="item.isActive"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.dates="{ item }">
    <div class="d-flex flex-column gap-2">
      <v-edit-dialog
        :return-value.sync="item.acceptedDate"
        large
        @save="saveAcceptedDate(item)"
      >
        <b>Accepted Date:</b> {{ formatDate(item.acceptedDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="Accepted Date"
            v-model="item.acceptedDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
      <v-edit-dialog
        :return-value.sync="item.deliveredDate"
        large
        @save="saveDeliveredDate(item)"
      >
        <b>Delivered Date:</b> {{ formatDate(item.deliveredDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="Delivered Date"
            v-model="item.deliveredDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
      <v-edit-dialog
        :return-value.sync="item.upgradedDate"
        large
        @save="saveUpgradedDate(item)"
      >
        <b>Upgraded Date:</b> {{ formatDate(item.upgradedDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="Upgraded Date"
            v-model="item.upgradedDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
      <v-edit-dialog
        :return-value.sync="item.acceptedDate"
        large
        @save="saveCancellationDate(item)"
      >
        <b>Cancellation Date:</b> {{ formatDate(item.cancellationDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="Cancellation Date"
            v-model="item.cancellationDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
      <v-edit-dialog
        :return-value.sync="item.endDate"
        large
        @save="saveEndDate(item)"
      >
        <b>End Date:</b> {{ formatDate(item.endDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="End Date"
            v-model="item.endDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
      <v-edit-dialog
        :return-value.sync="item.originalEndDate"
        large
        @save="saveOriginalEndDate(item)"
      >
        <b>Original End Date:</b> {{ formatDate(item.originalEndDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            label="Original End Date"
            v-model="item.originalEndDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
    </div>
    </template>
    <template v-slot:item.invoiceNumber="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceNumber"
        large
        @save="saveInvoiceNumber(item)"
      >
        {{ item.invoiceNumber }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-text-field
            v-model="item.invoiceNumber"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.invoiceStatus="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceStatus"
        large
        @save="saveInvoiceStatus(item)"
      >
        {{ item.invoiceStatus }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-text-field
            v-model="item.invoiceStatus"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.status="{ item }">
      <v-edit-dialog
        :return-value.sync="item.status"
        large
        @save="saveStatus(item)"
      >
        {{ item.status }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="stages"
            v-model="item.status"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.solution.solutionNumber="{ item }">
      <div style="display: block" v-if="item.solution">
        <router-link
          :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }"
          class="black--text"
          >
          {{ item.solution.solutionNumber }}
        </router-link>
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding-top: 10px">
        <AgreementHeader :Agreement="item" :Open="true" @refresh="onHeaderRefresh" />
        <div class="mt-2" />
        <Agreement ref="Agreement" :AgreementId="item.id" @refresh="refresh" @input="debouncedSave" />
      </td>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiPencilBoxOutline, mdiSeal,
} from '@mdi/js';
import { debounce } from 'lodash';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '~src/components/ExportToExcelButton.vue';
import agreementActions from '../mixins/agreementActions';
import Agreement from '../components/Agreement.vue';
import AgreementHeader from '../components/AgreementHeader.vue';

export default {
  name: 'ManageAgreements',
  mixins: [agreementActions, loading],
  components: {
    Agreement,
    AgreementHeader,
    BoolField,
    ExportToExcelButton,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiPencilBoxOutline,
      mdiRefresh,
      mdiSeal,
      agreements: [],
      search: '',
      headers: [
        { text: 'Verified', value: 'verified', width: '60px' },
        { text: 'Agreement Number', value: 'agreementNumber', width: '150px' },
        { text: 'Solution Number', value: 'solution.solutionNumber', width: '120px' },
        { text: 'Agreement Status', value: 'status', width: '180px' },
        { text: 'Dates', value: 'dates', width: '300px' },
        // { text: 'Delivered Date', value: 'deliveredDate', width: '150px' },
        // { text: 'Accepted Date', value: 'acceptedDate', width: '150px' },
        // { text: 'Cancellation Date', value: 'cancellationDate', width: '150px' },
        { text: 'Solution', value: 'solution.name' },
        { text: 'Deleted', value: 'isDeleted', width: '120px' },
        { text: 'Archived', value: 'isActive', width: '120px' },
        { text: 'Project Name', value: 'reference', width: '30%' },
        { text: 'Invoice Number', value: 'invoiceNumber' },
        { text: 'Invoice Status', value: 'invoiceStatus' },
        { text: '', value: 'actions' },
      ],
      xeroInvoiceNumber: '',
      dialog: false,
    };
  },
  computed: {
    ...mapState('agreements', ['statuses', 'stages']),
    ...mapState('solutions', ['solutions']),
    ...mapState('xeroInvoices', ['xeroInvoices']),
    exportData() {
      return this.agreements.map((a) => ({
        'Agreement Number': a.agreementNumber,
        'Solution Number': a.solution?.solutionNumber,
        'Agreement Status': a.status,
        Solution: a.solution?.name,
        Deleted: a.isDeleted,
        Archived: a.isActive,
        'Project Name': a.reference,
        'Invoice Number': a.invoiceNumber,
        'Invoice Status': a.invoiceStatus,
      }));
    },
    sortedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.xeroInvoices
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reverse();
    },
  },
  methods: {
    ...mapActions('agreements', ['LOAD_manageAgreements', 'UPDATE_agreement', 'UNARCHIVE_agreement']),
    ...mapActions('revenueLines', ['LOAD_revenueLines']),
    ...mapActions('solutions', ['LOAD_solutions', 'DELETE_solution', 'UPDATE_solution']),
    ...mapActions('suppliers', ['LOAD_suppliers']),
    async createSolution(item) {
      this.isLoading = true;
      await this.$http.post(`api/agreements/create-solution/${item.id}`);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'Solution created successfully');
    },
    onHeaderRefresh() {
      this.$refs.Agreement.refresh();
      this.refresh();
    },
    async saveIsActive(item) {
      if (item.isActive) {
        await this.UNARCHIVE_agreement({
          id: item.id,
        });
      } else {
        await this.UPDATE_agreement({
          id: item.id,
          isActive: item.isActive,
        });
      }
      await this.refresh();
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (agreement) {
      this.$log.info(`Debounced saving agreement: ${agreement.agreementNumber}`);
      if (this.isLoading) {
        this.$log.info('Skipping save as we are loading');
        return;
      }
      this.save(agreement);
    }, 1000),
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          this.agreements = await this.LOAD_manageAgreements();
        })(),
        (async () => {
          await this.LOAD_solutions();
        })(),
        (async () => {
          await this.LOAD_suppliers({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_revenueLines();
        })(),
      ]);
      this.isLoading = false;
    },
    async save(item) {
      await this.UPDATE_agreement(item);
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>
<style scoped>
.inline-block,
.inline-block > div{
  display: inline-block;
}
</style>
