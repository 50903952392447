import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('auth', ['token_expires']),
    ...mapState('organizations', ['organization']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
  },
  methods: {
    ...mapMutations('currencies', ['SET_selectedCurrency']),
    ...mapActions('xeroOrganisations', ['LOAD_xeroOrganisations']),
  },
  watch: {
    async organization() {
      if (this.$route.meta?.redirectOnRefresh) {
        this.$log.info(`redirecting to ${this.$route.meta.redirectOnRefresh.name}`);
        this.$router.push({ name: this.$route.meta?.redirectOnRefresh.name });
      } else if (this.refresh) {
        await this.$nextTick();
        this.$log.info('organization changed, refreshing', this.name);
        this.$log.info('refreshing');

        await this.LOAD_xeroOrganisations();
        await this.$nextTick();
        const orgs = this.xeroOrganisations;
        if (orgs) {
          const org = orgs[0];
          if (org) {
            this.SET_selectedCurrency(org.baseCurrency);
          }
        }

        await this.refresh();
      }
    },
    async token_expires() {
      if (this.refresh) {
        await this.$nextTick();
        this.$log.info('token changed, refreshing', this.name);
        await this.refresh();
      }
    },
  },
};
