<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <EventLog />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <HangfireListRecurringJob title="Recurring Jobs" />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12">
        <HangfireBusJobs title="Successful Jobs" status="succeeded" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <HangfireBusJobs title="Failed Jobs" status="failed" />
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
// eslint-disable-next-line max-len
import HangfireBusJobs from '@codehq/aurora-app-core/src/components/HangfireBusJobs.vue';
import EventLog from '../components/EventLog.vue';
import HangfireListRecurringJob from '../components/HangfireListRecurringJobs.vue';

export default {
  name: 'Hangfire',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    HangfireListRecurringJob,
    // eslint-disable-next-line vue/no-unused-components
    HangfireBusJobs,
    EventLog,
  },
};
</script>
