<template>
  <v-dialog v-model="filtersDialog" max-width="700">
    <template #activator="{ on }">
      <v-btn class="mt-2" icon title="Filter the data" v-on="on">
        <v-icon>{{ mdiFilter }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title color="accent">
        <span class="grey--text">Filters</span>
      </v-card-title>
      <v-card-text>
        <div>
          <!-- Field Selector -->
          <v-select
            v-model="selectedField"
            :items="fields"
            item-text="displayName"
            item-value="name"
            label="Select a field"
          ></v-select>

          <!-- Search Options Selector -->
          <v-select
            v-if="selectedField"
            v-model="selectedOption"
            :items="searchOptions"
            label="Select a search option"
          ></v-select>

          <!-- Filter Value Input -->
          <v-text-field
            v-if="selectedOption"
            v-model="filterValue"
            label="Enter filter value"
          ></v-text-field>

          <!-- Add Filter Button -->
          <v-btn @click="addFilter">
            Add Filter
          </v-btn>

          <!-- List of Added Filters -->
          <v-list v-if="filters.length">
            <v-list-item-group>
              <v-list-item v-for="(filter, index) in filters" :key="index">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ filter.displayName }} {{ filter.option }} {{ filter.value }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon @click="removeFilter(index)">
                    {{ mdiDelete }}
                  </v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="filtersDialog = false">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiFilter, mdiClose, mdiMenu, mdiDelete,
} from '@mdi/js';

export default {
  name: 'FiltersDialog',
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    metadata: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiClose,
      mdiDelete,
      mdiMenu,
      mdiFilter,
      filtersDialog: false,
      localColumns: this.headers,
      selectedField: null,
      selectedOption: null,
      filterValue: '',
      filters: [],
      icons: {
        delete: mdiDelete,
      },
    };
  },
  computed: {
    fields() {
      return this.metadata.map((m) => ({
        name: m.name,
        displayName: m.displayName,
        type: m.type,
      }));
    },
    searchOptions() {
      const field = this.fields.find((f) => f.name === this.selectedField);
      if (!field) return [];

      let searchOptions = [];
      switch (field.type) {
        case 'System.String':
          searchOptions = ['starts with', 'contains', 'equal to'];
          break;
        case 'System.DateTimeOffset':
        case 'System.Nullable`1[[System.DateTimeOffset, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
          searchOptions = ['before', 'after'];
          break;
        case 'System.Boolean':
          searchOptions = ['is true', 'is false'];
          break;
        case 'System.Double':
          searchOptions = ['greater than', 'equal to', 'less than'];
          break;
        default:
          searchOptions = [];
      }

      return searchOptions;
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.filters = newValue;
      },
    },
  },
  methods: {
    addFilter() {
      if (this.selectedField && this.selectedOption && this.filterValue) {
        const field = this.fields.find((f) => f.name === this.selectedField);
        let query = '';
        let operator = '';

        switch (this.selectedOption) {
          case 'greater than':
            operator = 'gt';
            break;
          case 'equal to':
            operator = 'eq';
            break;
          case 'less than':
            operator = 'lt';
            break;
          case 'is true':
            operator = 'eq';
            this.filterValue = true;
            break;
          case 'is false':
            operator = 'eq';
            this.filterValue = false;
            break;
          default:
            operator = 'eq';
            break;
        }

        switch (field.type) {
          case 'System.DateTimeOffset':
            operator = this.selectedOption === 'before' ? 'lt' : 'gt';
            query = `${this.selectedField} ${operator} ${this.filterValue}`;
            break;
          case 'System.String':
            query = `${this.selectedField} ${operator} '${this.filterValue}'`;
            break;
          default:
          case 'System.Boolean':
          case 'System.Double':
          case 'System.Nullable`1[[System.DateTimeOffset, mscorlib, Version=4.0.0.0, Culture=neutral, PublicKeyToken=b77a5c561934e089]]':
            query = `${this.selectedField} ${operator} ${this.filterValue}`;
            break;
        }
        this.filters.push({
          field: this.selectedField,
          option: this.selectedOption,
          value: this.filterValue,
          type: field.type,
          displayName: field.displayName,
          query,
        });
        this.selectedField = null;
        this.selectedOption = null;
        this.filterValue = '';
        this.$emit('input', this.filters);
      }
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
      this.$emit('input', this.filters);
    },
  },
};
</script>
