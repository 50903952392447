<template>
  <div>
    <v-progress-linear v-if="isLoading" :indeterminate="true" color="primary" />
    <table>
      <tr
        v-for="(line, index) in lineItems"
        :key="`form-line-${index}`"
        :data-cy="`FormContract-FormLine-${index}`"
      >
        <td>
          <FormContractItem
            :value="line"
            :index="index"
            :totalItems="sortedLineItems.length"
            :disabled="disabled"
            @input="debouncedSave"
            @remove="removeLine"
            @refresh="
              refreshContractLineItems();
              $emit('refresh');
            "
            @migrated="refreshContractLineItems"
            @update:up="moveLineUp(index)"
            @update:down="moveLineDown(index)"
            @update:section="changeSection"
          />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormContractItem from './FormContractItem.vue';

export default {
  name: 'SectionLines',
  components: {
    FormContractItem,
  },
  mixins: [loading],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    sectionId() {
      this.refreshContractLineItems();
    },
  },
  data() {
    return {
      mdiPlus,
      lineItems: [],
    };
  },
  computed: {
    ...mapState('quotes', ['quote']),
    ...mapState('quoteSections', ['quoteSections']),
    sortedLineItems() {
      return this.lineItems.filter((item) => !item.isDeleted);
    },
    sectionId() {
      return this.value?.id;
    },
  },
  async mounted() {
    await this.refreshContractLineItems();
  },
  methods: {
    ...mapActions('contractSectionLineItems', [
      'UPDATE_contractSectionLineItem',
      'CREATE_contractSectionLineItem',
      'LOAD_sectionLineItemsBySection',
    ]),
    async refreshContractLineItems() {
      if (this.sectionId) {
        this.isLoading = true;
        this.lineItems = (await this.LOAD_sectionLineItemsBySection(this.sectionId)) ?? [];

        const total = this.lineItems.reduce((prev, curr) => prev + curr.quantity * +curr.amount, 0);

        this.$emit('onCalculateTotal', this.value, total);
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(async function (item) {
      this.$log.debug('debounced saving line', item);
      const updatedItem = await this.UPDATE_contractSectionLineItem(item);
      this.$emit('updated:line', updatedItem);
    }, 500),
    async moveLineUp(sourceIndex) {
      this.$log.debug('moving line up', sourceIndex);
      const targetIndex = sourceIndex - 1;
      const { lineItems } = this;
      const temp = lineItems[targetIndex];
      lineItems[targetIndex] = lineItems[sourceIndex];
      lineItems[targetIndex].lineNumber = targetIndex + 1;
      lineItems[sourceIndex] = temp;
      lineItems[sourceIndex].lineNumber = sourceIndex + 1;
      this.lineItems = lineItems;
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.UPDATE_contractSectionLineItem(this.lineItems[targetIndex]);
        })(),
        (async () => {
          await this.UPDATE_contractSectionLineItem(this.lineItems[sourceIndex]);
        })(),
      ]);
      this.isLoading = false;
      await this.refreshContractLineItems();
      // this.lineItems = sortedLineItems.map(async (l) => {
      //   const result = { ...l };
      //   result.lineNumber = sortedLineItems.indexOf(l) + 1;
      //   await this.UPDATE_sectionLineItem(result);
      //   return result;
      // });
    },
    async moveLineDown(sourceIndex) {
      this.$log.debug('moving line down', sourceIndex);
      const targetIndex = sourceIndex + 1;
      const { lineItems } = this;
      const temp = lineItems[targetIndex];
      lineItems[targetIndex] = lineItems[sourceIndex];
      lineItems[targetIndex].lineNumber = targetIndex + 1;
      lineItems[sourceIndex] = temp;
      lineItems[sourceIndex].lineNumber = sourceIndex + 1;
      this.lineItems = lineItems;
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.UPDATE_contractSectionLineItem(this.lineItems[targetIndex]);
        })(),
        (async () => {
          await this.UPDATE_contractSectionLineItem(this.lineItems[sourceIndex]);
        })(),
      ]);
      this.isLoading = false;
      await this.refreshContractLineItems();
    },
    async changeSection(item) {
      const { sectionId, localContractItem } = item;
      const targetLineItem = this.lineItems.find((l) => l.id === localContractItem.id);
      this.$log.debug(`changing section from ${targetLineItem.sectionId} to ${sectionId}`);
      // eslint-disable-next-line max-len
      const prevSectionLineItems = await this.LOAD_sectionLineItemsBySection(
        localContractItem.contractSectionId,
      );
      targetLineItem.quoteSectionId = sectionId;
      const targetlineItems = await this.LOAD_sectionLineItemsBySection(sectionId);
      localContractItem.lineNumber = targetlineItems.length + 1;
      await this.UPDATE_contractSectionLineItem(targetLineItem);
      prevSectionLineItems.forEach(async (l, i) => {
        const oldLine = { ...l };
        oldLine.lineNumber = i + 1;
        await this.UPDATE_contractSectionLineItem(oldLine);
      });
      await this.$nextTick();
      await this.refreshContractLineItems();
      this.$emit('refresh');
    },
    // moveLineToSection(lineIndex, sectionIndex, $event) {
    //   const newSectionIndex = this.localQuote.sections.findIndex((s) => s.id === $event.id);
    //   const section = this.localQuote.sections[sectionIndex];
    //   const { lineItems } = section;
    //   const lineItem = lineItems[lineIndex];
    //   lineItems.splice(lineIndex, 1);
    //   section.lineItems = lineItems;
    //   this.localQuote.sections[sectionIndex] = section;
    //   const newSection = this.localQuote.sections[newSectionIndex];
    //   newSection.lineItems.push(lineItem);
    //   this.localQuote.sections[newSectionIndex] = newSection;
    // },
  },
};
</script>
