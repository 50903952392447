<template>
  <v-container>
  <v-row class="text-center">
    <v-col cols="6">
      <v-select
        data-cy="GraphGrossProfitPipeline-staff"
        filled
        label="Filter Staff"
        :items="users"
        item-text="email"
        item-value="email"
        v-model="selectedStaff"
        multiple
        clearable
      />
    </v-col>
    <v-col cols="6">
    </v-col>
    <v-col cols="12">
      <v-progress-linear :indeterminate="true" v-if="isLoading" color="primary"></v-progress-linear>
      <GChart
        v-if="items.length > 0"
        type="ComboChart"
        :data="chartData"
        :options="chartOptions"
      />
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { sumBy } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dayjs from 'dayjs';
// import ReportNewBusinessLogData from '../../../../components/ReportNewBusinessLogData.vue';
import ExportToExcelButton from '../../../../components/ExportToExcelButton.vue';

export default {
  name: 'Targets',
  mixins: [loading],
  module: 'Reports',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ExportToExcelButton,
  },
  data() {
    return {
      counterInterval: undefined,
      localData: [],
      selectedStaff: [],
      selectedStage: [],
    };
  },
  watch: {
    selectedFilters: {
      immediate: false,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  computed: {
    ...mapState('users', ['users']),
    ...mapState('userTargets', ['userTargets']),
    ...mapState('quotes', ['stages']),
    chartData() {
      const { chartUsers } = this;

      const result = [['Month', ...chartUsers.map((u) => u.email), 'Target']];
      for (let index = 0; index < 10; index += 1) {
        const projectionMonth = dayjs().add(index, 'month');
        const item = [];
        item.push(projectionMonth.format('MMM YYYY'));
        let { items } = this;
        if (this.selectedStage.length > 0) {
          items = items.filter((i) => this.selectedStage.includes(i.stage));
        }
        const monthItems = items.map((i) => ({ amount: i[`monthTotal${index}`], createdBy: i.createdBy, stage: i.stage }));
        // item.push(sumBy(monthItems.filter((i) => i.stage === 'Prospecting'), (i) => i.amount));
        // item.push(sumBy(monthItems.filter((i) => i.stage === 'Negotiating'), (i) => i.amount));
        // item.push(sumBy(monthItems.filter((i) => i.stage === 'Possibility'), (i) => i.amount));
        // item.push(sumBy(monthItems.filter((i) => i.stage === 'Certain'), (i) => i.amount));
        let targetTotal = 0;
        chartUsers.forEach((u) => {
          // const userTarget = userTargets.find((t) => t.userId === u.userId);
          // item.push(userTarget.target);
          const userItems = monthItems.filter((i) => i.createdBy === u.email);
          const total = sumBy(userItems.filter((i) => i.stage === 'Invoiced'), (i) => i.amount);
          item.push(total);
          targetTotal += u[`target${projectionMonth.format('MMMM')}`];
        });
        item.push(targetTotal);
        result.push(item);
        // item.push(sumBy(this.items, (i) => i[`monthTotal${index}`].)
        // headers.push({
        //   text: projectionMonth.format('MMM YYYY'),
        //   value: `month${index}`,
        //   width: '100px',
        // });
      }
      // const items = this.items.map((f) => {
      //   const item = [];
      //   item.push(f.month);
      //   item.push(f.prospectingAmount);
      //   item.push(f.negotiatingAmount);
      //   item.push(f.possibilityAmount);
      //   item.push(f.certainAmount);
      //   return item;
      // });
      const chartData = [...result];
      return chartData;
    },
    chartOptions() {
      const seriesNum = this.chartUsers.length;
      const result = {
        animation: {
          duration: 1000,
          easing: 'out',
        },
        chart: {
          title: 'Sales Pipeline',
        },
        vAxis: { format: '#,###', title: 'Amount' },
        hAxis: { minorGridlines: { minSpacing: 100 }, title: 'Date' },
        height: 400,
        legend: { position: 'top' },
        isStacked: true,
        seriesType: 'bars',
        series: {},
      };
      result.series[seriesNum] = { type: 'line' };
      return result;
    },
    chartUsers() {
      const { getUserName, userTargets } = this;
      let users = userTargets.map((u) => ({ ...u, email: getUserName(u.userId) }));
      if (this.selectedStaff.length > 0) {
        users = users.filter((i) => this.selectedStaff.includes(i.email));
      }
      return users;
    },
    graphUsers() {
      const result = this.chartUsers.map((user) => ({
        id: user.id,
        email: user.email,
      }));
      result.unshift({
        id: null,
        email: 'All Staff',
      });
      return result;
    },
  },
  async created() {
    this.localTask = this.Task;
    await this.LOAD_users();
    await this.LOAD_userTargets();
  },
  methods: {
    ...mapActions('users', ['LOAD_users']),
    ...mapActions('userTargets', ['LOAD_userTargets']),
    getTotal(row) {
      const item = row.slice(1);
      return sumBy(item, (r) => r);
    },
    getUserName(userId) {
      const user = this.users.find((u) => u.id === userId);
      return user?.email;
    },
  },
  // created() {
  //   this.counterInterval = setInterval(
  //     () => {
  //       this.refresh();
  //     }, 60000,
  //   );
  // },
  // destroyed() {
  //   clearInterval(this.counterInterval);
  // },
};
</script>
