<template>
  <div data-cy="FileUpload-input">
    <v-file-input
      v-model="file"
      :label="label"
      :loading="isLoading"
      @change="fileChanged"
      :accept="accept"
      show-size
      :prepend-icon="icon"
      :error-messages="errors"
    >
      <v-btn
      slot="append"
      color="success"
      @click="upload"
      :loading="isLoading"
      data-cy="FileUpload-buttonUpload"
      small
      :disabled="!file"
    >
      <v-icon class="mr-2">
        mdi-arrow-up-bold-circle-outline
      </v-icon>
      Upload
    </v-btn>
    </v-file-input>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiFile } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'TemplateUpload',
  mixins: [loading],
  props: {
    /** Set the Mimetype accept. Defaults to imaage/*.
    */
    accept: {
      type: String,
      default: '*',
    },
    /** Error message for max file size. Defaults to File is larger than the limit of 2Mb.
    */
    errorFileSize: {
      type: String,
      default: 'File is larger than the limit of 5Mb',
    },
    /** Set the icon. Defaults to mdi-camera.
    */
    icon: {
      type: String,
      default: mdiFile,
    },
    /** Set the label. Defaults to image.
    */
    label: {
      type: String,
      default: 'Current Template: Default.docx',
    },
    /** Max file size. Defaults to 5000000 (5Mb).
    */
    maxFileSize: {
      type: Number,
      default: 5000000,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      errors: [],
      file: null,
    };
  },
  methods: {
    ...mapActions('userFiles', ['CREATE_userFile']),
    fileChanged(file) {
      this.errors = [];
      if (file?.size > this.maxFileSize) {
        this.errors.push(this.errorFileSize);
      }
    },
    async upload() {
      const { file } = this;
      this.isLoading = true;
      const result = await this.CREATE_userFile(file);
      this.$emit('input', `${baseUrl}/auroraApi/files/${result.id}`);
      this.isLoading = false;
    },
  },
};
</script>
