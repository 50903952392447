<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
  >
    <v-card v-if="Agreement">
      <v-card-title>Migrate {{ Agreement.agreementNumber }} to</v-card-title>
      <v-card-text>
        <!-- <v-checkbox label="Include VAT" v-model="includeVat"></v-checkbox> -->
        <!-- <v-checkbox label="Include first and last page" v-model="includeFirstPage">
          </v-checkbox> -->
        <v-autocomplete
          :items="solutions"
          item-value="id"
          :item-text="s => `${s.solutionNumber}: ${s.name}`"
          v-model="solutionId"
          label="Solution"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="isSaving" color="success" @click="migrate">
          Migrate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ModalPrint',
  mixins: [loading],
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      solutionId: undefined,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('solutions', ['solutions']),
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_solutions();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('solutions', ['LOAD_solutions']),
    ...mapActions('agreements', ['MIGRATE_agreement']),
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    async migrate() {
      this.isSaving = true;
      const { Agreement, solutionId } = this;
      await this.MIGRATE_agreement({
        agreement: Agreement,
        solutionId,
      });
      this.isSaving = false;
      this.$router.push({
        name: 'solutions-EditSolution',
        query: {
          id: solutionId,
        },
      });
    },
  },
};
</script>
