import Mutations from '../../odata/mutations';

export default {
  ...new Mutations('quote'),
  ...new Mutations('quotes'),
  ...new Mutations('activeQuotes'),
  ...new Mutations('selectedStatus'),
  ...new Mutations('selectedStage'),
  ...new Mutations('selectedUser'),
  ...new Mutations('search'),
};
