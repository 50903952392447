import { sortBy } from 'lodash';

export default {
  getXeroRepeatingInvoice: (state) => state.xeroRepeatingInvoice,
  sortedRepeatingBill: (state) => [{
    reference: 'New Repeating Invoice',
    repeatingInvoiceID: null,
  }, ...sortBy(state.repeatingInvoices, 'reference')],
  sortedRepeatingInvoices: (state) => [{
    reference: 'New Repeating Invoice',
    repeatingInvoiceID: null,
  }, ...sortBy(state.repeatingInvoices, 'reference')],
};
