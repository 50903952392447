<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="quoteTemplates"
          class="elevation-1"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                color="success"
                data-cy="QuoteTemplate-button-new"
                @click="$router.push({
                  name: 'quotes-EditQuoteTemplate'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn small color="default" :to="{
                name: 'quotes-EditQuoteTemplate',
                query: {id: item.id}
              }"
            >
              Edit
            </v-btn>
            <v-btn small color="primary" class="mr-2" @click="download(item)">Download</v-btn>
            <v-btn small color="error" @click="remove(item)">Delete</v-btn>
          </template>
          <template v-slot:item.created="{ item }">
            {{ formatDate(item.created) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Quote Templates
          </v-card-title>
          <v-card-text>
            <QuoteTemplateContent />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import QuoteTemplateContent from '../components/QuoteTemplateContent.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ListQuoteTemplate',
  module: 'QuoteTemplate',
  mixins: [loading],
  components: {
    QuoteTemplateContent,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.quoteTemplates',
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('quoteTemplates', ['quoteTemplates']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('quoteTemplateType'),
          value: 'quoteTemplateType',
        },
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_quoteTemplates();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates', 'DELETE_quoteTemplate']),
    async download(item) {
      const uri = `${baseUrl}/auroraApi/files/${item.uploadedFileId}`;
      this.dialog = false;
      this.isPrinting = true;

      // Perform the file download here
      // For example, you can use the following code to download a file from a URL:
      const response = await fetch(uri);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${item.name}.docx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      // Set the loading state to false once the file has finished downloading
      this.isPrinting = false;
    },
    /** Delete QuoteTemplate from the collection
     */
    async remove(item) {
      const result = await this.$confirm('Are you sure you want to delete this template?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });
      if (result) {
        this.isLoading = true;
        await this.DELETE_quoteTemplate(item.id);
        this.isLoading = false;
      }
    },
  },
};
</script>

<docs>
# QuoteTemplate example

'''js
<QuoteTemplate />
'''
</docs>
