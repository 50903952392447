<template>
  <v-dialog v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn icon color="primary" dark v-on="on" @click="openDocument">
        <v-icon>
          {{ mdiFileEye }}
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title primary-title>
        <v-btn class="ml-auto" icon color="primary" @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="document-preview">
          <iframe :src="documentUrl" height="100%" width="100%"></iframe>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiFileEye } from '@mdi/js';

export default {
  name: 'DocumentViewer',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiClose,
      mdiFileEye,
      dialog: false,
    };
  },
  computed: {
    documentUrl() {
      const { url } = this;
      const documentUrl = `https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`;
      return documentUrl;
    },
  },
  methods: {
    openDocument() {
      this.dialog = true;
    },
  },
};
</script>
