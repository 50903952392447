export default {
  module: 'RequiredField',
  computed: {
    requiredFieldRules() {
      return [(v) => (!v || v === this.nullId ? this.$t('App.RequiredField.required') : true)];
    },
  },
  setup() {
    return {
      nullId: '00000000-0000-0000-0000-000000000000',
    };
  },
};
