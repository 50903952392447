<template>
  <div>
    Failed
  </div>
</template>

<script>
export default {
  name: 'Failed',
};
</script>
