import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('quote', 'quotes');

const customActions = {
  async LOAD_quote({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`quotes(${id})`)
      .query();
      // .query({ $expand: 'LineItems,ExchangeRates,Attachments' });
    commit('SET_quote', data);
    return data;
  },
  async LOAD_quotes({ commit }, isDeleted) {
    const { data } = await this.$http.get(`api/quotes?isDeleted=${isDeleted}`);
    commit('SET_quotes', data);
    return data;
  },
  async LOAD_archivedQuotes({ commit }, isDeleted) {
    const { data } = await this.$http.get(`api/quotes/archived?isDeleted=${isDeleted}`);
    commit('SET_quotes', data);
    return data;
  },
  async LOAD_clientQuotes({ rootGetters }, clientId) {
    const data = await odata.o(rootGetters)
      .get('quotes')
      .query({
        $filter: `IsDeleted eq false AND ContactId eq ${clientId}`,
        $expand: 'Sections($expand=LineItems)',
        $orderby: 'Created desc',
      });
    return data;
  },
  async LOAD_manageQuotes({ rootGetters, commit }) {
    const data = await odata.o(rootGetters)
      .get('quotes')
      .query({
        $orderby: 'QuoteNumber desc',
      });
    commit('SET_quotes', data);
    return data;
  },
};

export default { ...actions, ...customActions };
