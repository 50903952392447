<template>
  <v-autocomplete
    clearable
    :items="xeroRepeatingInvoices"
    :return-object="true"
    :item-text="i =>
      `${formatDate(i.date)}: ${i.reference} - ${formatNumber(i.total)}`
      "
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    label="Reference"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'XeroInvoicesSelect',
  extends: VAutocomplete,
  computed: {
    ...mapState('xeroRepeatingInvoices', ['xeroRepeatingInvoices']),
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('xeroInvoices', ['LOAD_xeroRepeatingInvoices']),
  },
  async refresh() {
    this.$log.debug('loading repeating xero invoices');
    this.isLoading = true;
    await this.LOAD_xeroRepeatingInvoices();
    this.isLoading = false;
  },
};
</script>
