<template>
  <v-dialog
    v-model="dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        block
        color="secondary"
        v-bind="attrs"
        v-on="on"
        dark
      >
        History
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ quote.reference}} - History</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  Changed By
                </th>
                <th class="text-left">
                  Field
                </th>
                <th class="text-left">
                  Previous Value
                </th>
                <th class="text-left">
                  New Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in sectionLineItemAudits"
                :key="item.id"
              >
                <td>{{ formatDateTime(item.created) }}</td>
                <td>{{ item.createdBy }}</td>
                <td>{{ item.fieldName }}</td>
                <td>{{ item.oldValue }}</td>
                <td>{{ item.newValue }}</td>
              </tr>
            </tbody>
        </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="dialog = false">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ModalQuoteHistory',
  props: {
    quote: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  mixins: [loading],
  computed: {
    sectionLineItemAudits() {
      const { quote } = this;
      let lineItems = [];
      let audits = [];
      if (quote.sections) {
        quote.sections.forEach((s) => {
          lineItems = lineItems.concat(s.lineItems ?? []);
        });
        lineItems.forEach((l) => {
          audits = audits.concat(l.audits ?? []);
        });
      }
      return audits;
    },
  },
};
</script>
