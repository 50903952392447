import axios from 'axios';

export default {
  async LOAD_userFile({ rootState }, id) {
    const { organization } = rootState.organizations;
    const { data } = await axios.get(`/auroraApi/Files/${id}/properties`, {
      headers: {
        OrganizationId: organization.id,
      },
    });
    return data;
  },
  async CREATE_userFile({ rootState }, file) {
    const formData = new FormData();

    formData.append('file', file);

    const { organization } = rootState.organizations;
    const result = await axios.post('/auroraApi/Files', formData, {
      headers: {
        OrganizationId: organization.id,
        'Content-Type': 'multipart/form-data',
      },
    });
    return result.data;
  },
};
