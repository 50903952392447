<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
    <v-card v-if="value" :loading="isLoading">
      <v-card-title>
        <span class="headline">Documents ({{ contractAttachments.length }})</span>
      </v-card-title>
      <v-card-text>
          <v-list three-line>
          <template v-for="(attachment, index) in contractAttachments">
            <v-list-item
              :key="index"
            >
              <v-list-item-avatar>
                <v-icon>{{ mdiFile }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="attachment.fileName"></v-list-item-title>
                <v-list-item-subtitle v-html="attachment.attachmentType"></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  target="_blank"
                  :href="attachment.fileUrl"
                >
                  <v-icon>{{ mdiDownload }}</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn :disabled="disabled" icon color="error" @click="remove(attachment)">
                  <v-icon>{{ mdiDelete }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </template>
          </v-list>
          <FileUpload v-if="!disabled" @input="fileUploaded" />
      </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiFile, mdiDownload, mdiDelete } from '@mdi/js';
import FileUpload from '@/components/FileUpload.vue';

export default {
  name: 'ContractAttachments',
  props: ['value', 'disabled'],
  mixins: [loading],
  components: {
    FileUpload,
  },
  computed: {
    ...mapState('contractAttachments', ['contractAttachments']),
    contractId() {
      return this.value?.id;
    },
  },
  data() {
    return {
      mdiDelete,
      mdiDownload,
      mdiFile,
      dialog: false,
    };
  },
  watch: {
    contractId: {
      immediate: true,
      deep: true,
      handler() {
        this.loadAttachments();
      },
    },
  },
  async mounted() {
    await this.loadAttachments();
  },
  methods: {
    ...mapActions('contractAttachments', [
      'CREATE_contractAttachment',
      'LOAD_contractAttachmentsForContract',
      'DELETE_contractAttachment',
    ]),
    async fileUploaded(item) {
      const { uploadedFile, url } = item;
      await this.CREATE_contractAttachment({
        fileName: uploadedFile.fileName,
        attachmentType: uploadedFile.attachmentType,
        fileUrl: url,
        contractId: this.value.id,
      });
      await this.loadAttachments();
    },
    async loadAttachments() {
      if (!this.value) {
        return;
      }
      this.isLoading = true;
      if (this.contractId) {
        await this.LOAD_contractAttachmentsForContract(this.contractId);
      }
      this.isLoading = false;
    },
    async remove(attachment) {
      this.isLoading = true;
      await this.DELETE_contractAttachment(attachment.id);
      this.isLoading = false;
      await this.loadAttachments();
    },
  },
};
</script>
