export default {
  methods: {
    async organizationChanged(o) {
      this.$log.info('mixin detected organization changed, refreshing', o);
      await this.refresh();
    },
  },
  mounted() {
    this.$root.$on('refresh', (o) => {
      this.$log.info('root refresh detected, refreshing', o);
      this.refresh();
    });
  },
  destroyed() {
    this.$root.$off('refresh');
  },
};
