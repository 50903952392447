<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="-1"
      dense
      :search="search"
      sort-by="contractNumber"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
            <v-btn
              class="ml-2"
              color="success"
              data-cy="Contract-button-new"
              @click="
                $router.push({
                  name: 'contracts-EditContract',
                })
              "
            >
              <v-icon class="mr-2">
                {{ mdiPlus }}
              </v-icon>
              New Contract
            </v-btn>
          </v-col>
          <v-col cols="1">
            <!-- <v-checkbox
              data-cy="Contract-showContractCurrency"
              v-model="showContractCurrency"
              label="Show Contract Currency"
              @change="refresh"
            ></v-checkbox> -->
          </v-col>
          <v-col cols="2">
            <v-select
              :items="contractTypes"
              data-cy="Contract-contractType"
              v-model="contractType"
              label="Filter by contract type"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              clearable
              class="mr-2"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-btn small color="secondary" @click="refresh" class="mt-5">
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
            <ExportToExcel small name="Contracts" class="mt-5" :data="items" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.contractNumber="{ item }">
        <router-link :to="{ name: 'contracts-EditContract', query: { id: item.id } }">
          {{ item.contractNumber }}
        </router-link>
      </template>
      <template v-slot:item.commences="{ item }">
        {{ formatDate(item.commences) }}
      </template>
      <template v-slot:item.contractType="{ item }">
        {{ getContractType(item.contractType) }}
      </template>
      <template v-slot:item.expires="{ item }">
        {{ formatDate(item.expires) }}
      </template>
      <template v-slot:item.monthlyTotal="{ item }">
        <span class="d-block text-right">
          {{ formatCurrency(item.monthlyTotal, item.currency) }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" text v-on="on">
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list class="text-left">
            <v-list-item v-if="isItemEditable(item.status)">
              <v-list-item-content>
                <v-btn
                  text
                  block
                  color="secondary"
                  dark
                  :to="{
                    name: 'contracts-EditContract',
                    query: {
                      id: item.id,
                    },
                  }"
                >
                  Edit
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn text block color="secondary" dark @click="remove(item)"> Delete </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical,
  mdiPlus,
  mdiMagnify,
  mdiRefresh,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import currencies from '~src/mixins/currencies';
import contractTypes from '../components/contractTypes.json';

export default {
  name: 'ListContract',
  module: 'Contract',
  mixins: [currencies, loading],
  meta: {
    breadcrumb: [
      {
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contracts',
        disabled: true,
      },
    ],
  },
  data() {
    return {
      /**
       * Icons
       */
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiRefresh,
      /** Filters
       */
      contractTypes,
      showContractCurrency: false,
      contractType: undefined,
      search: '',
      /** items
       */
      contracts: [],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    /**
     * Get table column headers
     */
    headers() {
      return [
        {
          text: this.$$t('contractNumber'),
          value: 'contractNumber',
          width: '110px',
        },
        {
          text: 'Solution ID',
          value: 'solutionId',
          width: '110px',
        },
        {
          text: this.$$t('status'),
          value: 'status',
        },
        {
          text: this.$$t('contact'),
          value: 'contactName',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('createdBy'),
          value: 'createdBy',
        },
        {
          text: this.$$t('contractType'),
          value: 'contractType',
          width: '110px',
        },
        {
          text: this.$$t('expires'),
          value: 'expires',
          width: '120px',
        },
        {
          text: 'Monthly Total',
          value: 'monthlyTotal',
          width: '110px',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '100px',
        },
      ];
    },
    items() {
      const { contractType, contracts } = this;
      if (contractType) {
        return contracts.filter((c) => c.contractType === contractType);
      }
      return contracts;
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('Contract:updated', this.refresh);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('contracts', ['LOAD_MIN_contracts', 'DELETE_contract']),

    isItemEditable(status) {
      return status !== 'Accepted' && status !== 'Declined' && status !== 'Delivered';
    },

    /** Delete Contract from the collection
     */
    async remove(contract) {
      const result = await this.$confirm('Are you sure you want to delete this contract?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_contract(contract.id);
        this.isLoading = false;
      }
    },
    getContractType(contractType) {
      return contractTypes.find((c) => c.value === contractType)?.text;
      // item.contractType === 'ACCREC' ? 'Money In' : 'Money Out'
    },
    async refresh() {
      this.$log.debug('refreshing contracts');
      this.isLoading = true;
      if (this.showContractCurrency) {
        this.contracts = await this.LOAD_MIN_contracts();
      } else {
        const { data } = await this.$http.get(
          `/api/contracts/reports/byCurrency/${this.selectedCurrency}`,
        );
        this.contracts = data;
      }
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Contract example

'''js
<Contract />
'''
</docs>
