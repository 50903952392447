<template>
  <v-autocomplete
    clearable
    :items="sortedInvoices"
    :item-text="i =>
      `${formatDate(i.date)}: ${i.invoiceNumber} - ${i.reference}`
      "
    item-value="invoiceID"
    label="Invoice Number"
  />
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'XeroInvoicesSelect',
  extends: VAutocomplete,
  computed: {
    ...mapState('xeroInvoices', ['xeroInvoices']),
    sortedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.xeroInvoices
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reverse();
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('xeroInvoices', ['LOAD_MIN_xeroInvoices']),
  },
  async refresh() {
    this.$log.debug('loading minimal xero invoices');
    this.isLoading = true;
    await this.LOAD_MIN_xeroInvoices();
    this.isLoading = false;
  },
};
</script>
