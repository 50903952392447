<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-container>
    <v-card>
      <v-card-title primary-title>
        {{ $$t('title') }}
      </v-card-title>
      <v-card-text v-html="$$t('text')">
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :to="{ name: 'auth-Login' }">
          {{ $$t('login') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="{ name: 'auth-Register' }">
          {{ $$t('register') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'Welcome',
  module: 'App',
};
</script>
