<template>
  <div>
    <v-progress-linear v-if="isLoading" :indeterminate="true" color="primary" />
    <v-btn text color="primary" @click="addSection" :disabled="disabled">
      <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
      Add a new section
    </v-btn>
    <v-card
      class="mb-10"
      color="grey lighten-3"
      v-for="(section, index) in sections"
      :key="section.id"
    >
      <v-card-title primary-title :id="section.id">
        <AutoSelect
          :disabled="disabled"
          v-model="section.title"
          @input="debouncedSave(section)"
        ></AutoSelect>
        <DatePicker
          class="mt-5 ml-5"
          :data-cy="`QuoteSection-deliveredDate`"
          :dense="true"
          :filled="true"
          v-model="section.estimatedDeliveryDate"
          :label="$$t('deliveredDate')"
          :id="`QuoteSection-deliveredDate-${index}-datepicker`"
          @input="debouncedSave(section)"
        />
        <v-btn
          class="ml-5"
          @click="deleteSection(section)"
          color="error"
          small
          :disabled="disabled"
        >
          <v-icon>{{ mdiDelete }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <QuoteSectionLines
          :value="section"
          :index="index"
          :disabled="disabled"
          @refresh="refreshQuoteSections"
          @updated:line="$emit('updated:line', $event)"
        />
      </v-card-text>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-btn text color="primary" @click="addSection" :disabled="disabled">
          <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
          Add a new section
        </v-btn>
      </v-col>
      <v-col cols="6">
        <slot name="bottom" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { debounce, sortBy } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import QuoteSectionLines from './QuoteSectionLines.vue';

export default {
  name: 'QuoteSections',
  module: 'QuoteSection',
  components: {
    QuoteSectionLines,
  },
  mixins: [loading],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState('quoteSections', ['quoteSections']),
    disabled() {
      return (
        this.value?.status === 'Accepted'
        || this.value?.status === 'Declined'
        || this.value?.status === 'Delivered'
        || this.value?.status === 'In Progress'
        || this.value?.status === 'Invoiced'
        || this.value?.status === 'Paid'
        || this.value?.status === 'In Xero'
      );
    },
    sections() {
      return sortBy(this.quoteSections, 'order');
    },
  },
  data() {
    return {
      mdiDelete,
      mdiPlus,
    };
  },
  watch: {
    async value() {
      await this.refreshQuoteSections();
    },
  },
  async mounted() {
    await this.refreshQuoteSections();
  },
  methods: {
    ...mapActions('quoteSections', [
      'UPDATE_quoteSection',
      'CREATE_quoteSection',
      'DELETE_quoteSection',
      'LOAD_quoteSectionsForQuote',
    ]),
    ...mapMutations('quoteSections', ['SET_quoteSections']),
    async addSection() {
      const newSection = await this.CREATE_quoteSection({
        quoteId: this.value.id,
        order: this.sections.length + 1,
        title: 'New section',
        isActive: false,
      });
      await this.refreshQuoteSections();
      await this.$nextTick();
      const element = document.getElementById(newSection.id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        this.$log.warn(`Could not find element to scroll to ${newSection.id}`, newSection);
      }
      this.$emit('inserted:section', newSection);
    },
    async deleteSection(section) {
      const result = await this.$confirm('Are you sure you want to delete this section?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });
      if (result) {
        await this.DELETE_quoteSection(section.id);
      }
    },
    async refreshQuoteSections() {
      if (this.value?.id) {
        this.isLoading = true;
        // this.SET_quoteSections([]);
        await this.LOAD_quoteSectionsForQuote(this.value.id);
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (item) {
      this.UPDATE_quoteSection(item);
    }, 500),
  },
};
</script>
