import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export default {
  /**
  * Install the plugin
  */
  install(Vue) {
    // formatting mixin
    Vue.mixin({
      methods: {
        formatDateTime(dt) {
          const date = dayjs(dt);
          if (date.isValid()) {
            return date.format('YYYY-MM-DD HH:mm');
          }
          return 'No date';
        },
        formatCurrency(money, currencyCode) {
          const userLang = navigator.language || navigator.userLanguage;
          const currency = currencyCode ?? 'ZAR';
          const opts = {
            currency,
            style: 'currency',
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          };
          return new Intl.NumberFormat(userLang, opts).format(money)
            .replace('ZAR', 'R'); // 'CA$ 100.00'
        },
        formatNumber(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return new Intl.NumberFormat(userLang, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }).format(money ?? 0);
        },
        formatNumberRounded(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return new Intl.NumberFormat(userLang, {
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(money ?? 0);
        },
        formatDate(dt) {
          const date = dayjs(dt);
          if (date.isValid()) {
            return date.format('YYYY-MM-DD');
          }
          return 'No date';
        },
        formatMonth(dt) {
          const date = dayjs(dt);
          if (date.isValid()) {
            return date.format('MM');
          }
          return 'No date';
        },
        formatFromNow(dt) {
          const date = dayjs(dt);
          if (date.isValid()) {
            return date.fromNow();
          }
          return 'No date';
        },
      },
    });
  },
};
