<template>
  <div>
    <ProgressLinearTimeout v-if="isLoading" />
    <table v-if="!isSaving">
      <tr
        v-for="(line, index) in incomeLines"
        :key="`agreement-item-${index}`"
        :data-cy="`AgreementItem-${index}`"
      >
        <td>
          <AgreementItem
            :value="line"
            :index="index"
            :totalItems="incomeLines.length"
            :disabled="disabled"
            :supplierDeliveredDisabled="supplierDeliveredDisabled"
            :Agreement="Agreement"
            @remove="removeLine"
            @update:up="moveLineUp"
            @update:down="moveLineDown"
          />
        </td>
      </tr>
    </table>
    <v-row>
      <v-col cols="4">
        <v-btn
          data-cy="AgreementItem-button-addNewLine"
          color="primary"
          text
          @click="addNewLine"
          :disabled="disabled"
        >
          <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
          Add a new line
        </v-btn>
      </v-col>
      <v-col cols="4">
        <span
          v-if="Agreement?.originalAcceptedDate"
          class="text-subtitle text-center d-block"
          :title="formatDateTime(Agreement.originalAcceptedDate)"
        >
          Originally accepted {{ formatFromNow(Agreement.originalAcceptedDate) }}
        </span>
        <span
          v-if="Agreement?.originalDeliveredDate"
          class="text-subtitle text-center d-block"
          :title="formatDateTime(Agreement.originalDeliveredDate)"
        >
          Originally delivered {{ formatFromNow(Agreement.originalDeliveredDate) }}
        </span>
        <span
          v-if="Agreement?.originalEndDate"
          class="text-subtitle text-center d-block"
          :title="formatDateTime(Agreement.originalEndDate)"
        >
          Originally ending on {{ formatDate(Agreement.originalEndDate) }}
        </span>
        <span
          v-if="Agreement?.verified"
          class="text-subtitle text-center d-block"
          :title="formatDateTime(Agreement.verified)"
        >
          Verified {{ formatFromNow(Agreement.verified) }} by {{ Agreement.verifiedBy }}
        </span>
      </v-col>
      <v-col cols="4">
        <div v-if="summary" class="text-h6 text-right mr-1">
          Income Total: {{ formatCurrency(summary.total, Agreement.currencyCode) }}
        </div>
        <div v-if="summary" class="text-h6 text-right mr-1">
          Cost Total: {{ formatCurrency(summary.totalCost, Agreement.currencyCode) }}
        </div>
        <ul class="mt-2 text-right">
          <li v-for="exchangeRate in exchangeRates" :key="exchangeRate.id" class="no-marker">
            Converted from {{ exchangeRate.sourceCurrency }}
            using a rate of {{ exchangeRate.rate.toFixed(3) }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import AgreementItem from './AgreementItem.vue';

export default {
  name: 'AgreementItems',
  components: {
    AgreementItem,
  },
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    IsActive: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [loading],
  data() {
    return {
      mdiPlus,
    };
  },
  computed: {
    ...mapGetters('incomeLines', ['incomeLines']),
    ...mapState('exchangeRates', ['exchangeRates']),
    ...mapState('agreements', ['summary']),
    disabled() {
      return !(this.Agreement?.status === 'New'
        || this.Agreement?.status === 'Draft'
        || this.Agreement?.status === 'Certain'
        || this.Agreement?.status === 'Negotiating'
        || this.Agreement?.status === 'Prospecting');
    },
    supplierDeliveredDisabled() {
      return this.Agreement?.status === 'Accepted'
        || this.Agreement?.status === 'Archived'
        || this.Agreement?.status === 'Delivered'
        || this.Agreement?.status === 'Rejected';
    },
  },
  watch: {
    IsActive() {
      if (this.disabled) return;
      this.refresh();
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('incomeLines', [
      'LOAD_incomeLines',
      'CREATE_incomeLine',
      'DELETE_incomeLine',
      'UPDATE_incomeLine',
    ]),
    async refresh() {
      try {
        if (this.Agreement?.id) {
          const agreementId = this.Agreement.id;
          // eslint-disable-next-line max-len
          const isActive = this.IsActive;
          this.isLoading = true;
          await this.LOAD_incomeLines({ agreementId, isActive });
        }
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async addNewLine() {
      try {
        this.isLoading = true;
        await this.CREATE_incomeLine({
          agreementId: this.Agreement.id,
          term: this.Agreement.term,
          noticeTerm: this.Agreement.noticeTerm,
          renewalTerm: this.Agreement.renewalTerm,
        });
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async moveLineDown(line) {
      const index = this.incomeLines.indexOf(line);
      const lineBelow = this.incomeLines[index + 1];
      const currentLine = { ...line };
      try {
        this.isSaving = true;
        await this.$nextTick();
        await this.UPDATE_incomeLine({
          id: lineBelow.id,
          lineNumber: index + 1,
        });
        await this.UPDATE_incomeLine({
          id: currentLine.id,
          lineNumber: index + 2,
        });
        await this.refresh();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isSaving = false;
      }
    },
    async moveLineUp(line) {
      const index = this.incomeLines.indexOf(line);
      const lineAbove = this.incomeLines[index - 1];
      const currentLine = { ...line };
      lineAbove.lineNumber += 1;
      currentLine.lineNumber -= 1;
      try {
        this.isSaving = true;
        await this.$nextTick();
        await this.UPDATE_incomeLine({
          id: lineAbove.id,
          lineNumber: index + 1,
        });
        await this.UPDATE_incomeLine({
          id: currentLine.id,
          lineNumber: index,
        });
        await this.refresh();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isSaving = false;
      }
    },
    async removeLine(line) {
      const res = await this.$confirm('Are you sure you want to delete this line?');
      if (!res) return;

      try {
        this.isLoading = true;
        await this.DELETE_incomeLine(line.id);
        this.$root.$emit('data:agreementItem-updated', line);
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
