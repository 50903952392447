<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'ContactInfo',
};
</script>
