/* eslint-disable max-len */
export default {
  integrationSettings: [],
  integrationSetting: undefined,
  availableSettings: [
    { displayName: 'Agreement Created', name: 'integration/agreement/created', value: '' },
    { displayName: 'Agreement Updated', name: 'integration/agreement/updated', value: '' },
    { displayName: 'Agreement Status Changed', name: 'integration/agreement/statusUpdated', value: '' },
  ],
};
