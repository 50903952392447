import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contract', 'contracts');

const customActions = {
  async LOAD_contract({ commit, rootGetters }, id) {
    const i = odata.parseId(id);
    const data = await odata.o(rootGetters)
      .get(`contracts(${i})`)
      .query();
    commit('SET_contract', data);
    return data;
  },
  async LOAD_MIN_contracts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({
        // eslint-disable-next-line max-len
        $select: 'Id,ContractNumber,Status,Contact,Name,CreatedBy,Contract,ContractType,Expires,Total,Solution/Name',
        $expand: 'Solution',
        $filter: 'IsDeleted eq false',
      });
    commit('SET_contracts', data);
    return data;
  },
  async LOAD_sectionsWithContract({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contractSections')
      .query({
        $expand: 'Contract,LineItems($filter=IsDeleted eq false)',
        $filter: 'IsDeleted eq false AND Contract/IsDeleted eq false',
      });
    commit('SET_contracts', data);
    return data;
  },
  async LOAD_contractExpanded({ rootGetters }, id) {
    const i = odata.parseId(id);
    const data = await odata.o(rootGetters)
      .get(`contracts(${i})`)
      .query({ $expand: 'Sections($expand=LineItems)' });
    return data;
  },
  async LOAD_contracts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({ $expand: 'ContractItems' });
    commit('SET_contracts', data);
    return data;
  },
  async LOAD_contractsWithMissingInvoices({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({
        $expand: 'ContractItems',
        $filter: 'RepeatingInvoiceId eq null',
      });
    return data;
  },
  async loadContractsBySolution({ commit, rootGetters }, p) {
    const { solutionId } = p;
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({
        $expand: 'Sections($expand=LineItems)',
        $filter: `SolutionId eq ${solutionId}`,
      });
    commit('SET_contracts', data);
    return data;
  },
  async loadContractsWithNoSolution({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({
        $filter: 'IsDeleted eq false AND SolutionId eq null',
        $orderby: 'ContractNumber',
      });
    commit('SET_contracts', data);
    return data;
  },
  async LOAD_contractsWithMissingDocuments({ rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('contracts')
      .query({
        $expand: 'ContractItems',
        $filter: 'Files/any(x: x/IsDeleted ne false)',
      });
    return data;
  },
  async UPDATE_contract({ commit, rootGetters }, contract) {
    const id = odata.parseId(contract.id);
    const data = await odata.o(rootGetters)
      .patch(`contracts(${id})`, contract)
      .query();
    commit('SET_contract', data);
    return data;
  },
};

export default { ...actions, ...customActions };
