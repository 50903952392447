import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contractSectionLineItem', 'contractSectionLineItems');

const customActions = {
  async LOAD_sectionLineItemsBySection({ rootGetters }, contractSectionId) {
    const data = await odata.o(rootGetters)
      .get('contractSectionLineItems')
      .query({
        $filter: `IsDeleted eq false AND contractSectionId eq ${contractSectionId}`,
        $orderby: 'LineNumber',
      });
    return data;
  },
  async LOAD_sectionLineItemsByContract({ rootGetters }, contractId) {
    const data = await odata.o(rootGetters)
      .get('contractSectionLineItems')
      .query({
        $filter: `IsDeleted eq false AND Section/ContractId eq ${contractId}`,
        $orderby: 'LineNumber',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
