<template>
  <v-card>
    <v-card-text>
      <v-btn color="success" @click="create">Add Address</v-btn>
      <div v-for="(address, index) in addresses" :key="index">
        <FormAddress :value="address" @input="debouncedSave" />
        <v-btn color="error" @click="() => remove(address)">Remove</v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormAddress from './FormAddress.vue';

export default {
  name: 'Addresses',
  components: {
    FormAddress,
  },
  mixins: [loading],
  props: {
    supplierId: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ...mapState('addresses', ['addresses']),
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('addresses', [
      'LOAD_clientAddresses',
      'LOAD_supplierAddresses',
      'CREATE_address',
      'DELETE_address',
      'UPDATE_address',
    ]),
    async create() {
      await this.CREATE_address({
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        addressType: '',
        city: '',
        country: '',
        postalCode: '',
        region: '',
        supplierId: this.supplierId,
        clientId: this.clientId,
      });
      await this.refresh();
    },
    async remove(item) {
      const result = await this.$confirm('Are you sure you want to delete this address?');
      if (!result) {
        return;
      }
      await this.DELETE_address(item.id);
      await this.refresh();
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (item) {
      this.save(item);
    }, 1000),
    async save(item) {
      await this.UPDATE_address(item);
    },
    async refresh() {
      this.isLoading = true;
      if (this.clientId) {
        await this.LOAD_clientAddresses(this.clientId);
      }
      if (this.supplierId) {
        await this.LOAD_supplierAddresses(this.supplierId);
      }
      this.isLoading = false;
    },
  },
};
</script>
