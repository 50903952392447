import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contact', 'contacts');

const customActions = {
  async UPDATE_contact({ commit, state, rootGetters }, data) {
    await odata.o(rootGetters)
      .patch(`contacts(${data.contactID})`, data)
      .query();
    const items = state.contacts;
    if (items?.length > 0) {
      const updatedItem = items.find((l) => l.id === data.contactID);
      if (updatedItem) {
        Object.assign(updatedItem, data);
        commit('SET_contacts', items);
      }
    }
    return data;
  },
};

export default { ...actions, ...customActions };
