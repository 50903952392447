import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

export default {
  computed: {
    ...mapState('people', ['people']),
    allPeople() {
      const { people } = this;
      const localPeople = people?.map((p) => ({ ...p, source: 'local' })) ?? [];
      return sortBy([
        ...localPeople,
      ], (c) => c.name);
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('people', ['LOAD_people', 'DELETE_client']),
    async loadAllPeople() {
      await this.LOAD_people();
    },
  },
};
