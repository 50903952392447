<template>
  <div>
    <v-container fluid>
      <ProgressLinearTimeout />
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'SearchRedirect',
  created() {
    const { solutionId, type, agreementId } = this.$route.query;
    if (type === 'agreement') {
      this.$router.push({ name: 'solutions-EditSolution', query: { id: solutionId, agreementId } });
    } else {
      this.$router.push({ name: 'solutions-EditSolution', query: { id: solutionId, agreementId } });
    }
  },
};
</script>
