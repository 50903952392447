<template>
  <!-- Only show button if contract status is not "Accepted" -->
  <v-btn
    v-bind="$props"
    v-on="$listeners"
    :loading="isSaving"
    v-if="canAcceptContract(contract.status)"
    @click="updateContract"
  >
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    {{ $$t('acceptButton') }}
  </v-btn>
</template>

<script>
import { mapActions } from 'vuex';
import { VBtn } from 'vuetify/lib';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import dialogs from '~src/mixins/dialogs';
import { eventBus } from '../../../../main';

export default {
  name: 'ContractAccept',
  module: 'Contract',
  mixins: [loading, dialogs],
  extends: VBtn,
  props: {
    contract: {
      type: Object,
      required: true, // Contract object is required
    },
  },
  computed: {
    contractId() {
      return this.contract.id;
    },
  },
  methods: {
    /** Vuex actions
     */
    ...mapActions('contracts', ['UPDATE_contract']),
    ...mapActions('contractSectionLineItems', ['LOAD_sectionLineItemsByContract']),

    canAcceptContract(status) {
      return (
        status !== 'Accepted' &&
        status !== 'Cancelled' &&
        status !== 'Delivered' &&
        status !== 'In Progress' &&
        status !== 'Upgraded'
      );
    },

    // Method to update the contract status to "Accepted" when the button is clicked
    async updateContract() {
      let canUpdate = true;
      eventBus.$emit('onEventBusValidate', () => {
        canUpdate = false;
      });

      if (!canUpdate) {
        return;
      }

      this.isSaving = true;
      try {
        const result = await this.$confirm(this.$$t('confirmAcceptContract'));
        if (result) {
          await this.UPDATE_contract({
            id: this.contract.id,
            status: 'Accepted',
          });
          this.$emit('update:contract', { ...this.contract, status: 'Accepted' });
        }
      } finally {
        this.isSaving = false;
      }
    },
  },
  setup() {
    return {
      nonRecurringServiceType: 'non-recurring cost',
      nullId: '00000000-0000-0000-0000-000000000000',
    };
  },
};
</script>
