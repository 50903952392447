import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('clientAttachment', 'clientAttachments');

const customActions = {
  async LOAD_clientAttachments({ commit, rootGetters }, clientId) {
    const data = await odata.o(rootGetters)
      .get('clientAttachments')
      .query({ $filter: `IsDeleted eq false AND ClientId eq ${clientId}` });
    commit('SET_clientAttachments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
