<template>
  <v-autocomplete
    v-bind="$props"
    filled
  >
    <template v-slot:item="{ item }">
      <v-list-item-icon>
        <v-img
          v-if="item.source === 'xero'"
          src="../assets/icons8-xero-24.png"
          width="24"
          height="24"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <div v-if="item.addresses !== null">
            <div v-for="(address, index) in item.addresses" :key="index">
              <div>
                <span v-if="address.addressLine1">{{ address.addressLine1 }}, </span>
                <span v-if="address.addressLine2">{{ address.addressLine2 }}, </span>
                <span v-if="address.addressLine3">{{ address.addressLine3 }}, </span>
                <span v-if="address.addressLine4">{{ address.addressLine4 }}, </span>
                <span v-if="address.city">{{ address.city }}, </span>
                <span v-if="address.region">{{ address.region }}, </span>
                <span v-if="address.postalCode">{{ address.postalCode }}, </span>
                <span v-if="address.country">{{ address.country }}</span>
              </div>
            </div>
          </div>
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'SelectClients',
  extends: VAutocomplete,
};

</script>
