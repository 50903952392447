<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Month: {{ month }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="isLoading"
        dense
        :items-per-page="-1"
      ><template v-slot:top>
        <v-row>
          <v-col cols="4">
          </v-col>
          <v-col cols="3">
          </v-col>
          <v-col cols="3">
          </v-col>
          <v-col cols="2" class="text-right">
            <ExportToExcel
              small
              :name="`Monthly GP - ${month}`" class="mt-5 mr-5"
              :data="excelData" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.section.contract.contractNumber="{ item }">
        <router-link :to="{
            name: 'contracts-EditContract', query: { id: item.section.contract.id }
          }">
          {{ item.section.contract.contractNumber }}
        </router-link>
      </template>
        <template v-slot:item.section.contract.contractType="{ item }">
          {{ item.section.contract.contractType === 'ACCREC' ? 'Money In' : 'Money Out' }}
        </template>
        <template v-slot:item.section.contract.commences="{ item }">
          {{ formatDate(item.section.contract.commences) }}
        </template>
        <template v-slot:item.section.contract.expires="{ item }">
          <span v-if="item.section.contract.expires
            && item.section.contract.expires !== '0001-01-01T00:00:00+00:00'">
            {{ formatDate(item.section.contract.expires) }}
          </span>
          <span v-else>
            No Date
          </span>
        </template>
        <template v-slot:item.monthlyTotal="{ item }">
          <span v-if="item.monthlyTotal < 0" class="red--text">
            ({{ formatCurrency(item.monthlyTotalConverted * -1, selectedCurrency) }})
          </span>
          <span v-else>
            {{ formatCurrency(item.monthlyTotalConverted, selectedCurrency) }}
          </span>
        </template>
        <template slot="body.append">
            <tr class="font-weight-bold">
                <td colspan="4" />
                <td>{{ formatCurrency(sumField('monthlyTotalConverted'), selectedCurrency) }}</td>
            </tr>
        </template>
      <template v-slot:item.actions="{ item }">
        <v-btn x-small
          :to="{
            name: 'contracts-EditContract',
            query: {id: item.section.contract.id}
          }">
          Edit
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'TableGpPerMonth',
  mixins: [loading],
  module: 'Contract',
  props: {
    month: {
      type: String,
      default: '',
      required: true,
    },
    index: {
      type: Number,
      default: 0,
      required: true,
    },
    isExpiring: {
      type: Boolean,
      default: false,
      required: true,
    },
    selectedContractType: {
      type: Array,
      default: () => [],
    },
    selectedStatus: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localData: [],
    };
  },
  watch: {
    month() {
      this.refresh();
    },
    index() {
      this.refresh();
    },
    isExpiring() {
      this.refresh();
    },
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    headers() {
      return [
        {
          text: this.$$t('contractNumber'),
          value: 'section.contract.contractNumber',
          width: '120px',
        },
        {
          text: this.$$t('solutionNumber'),
          value: 'section.contract.solutionNumber',
          width: '120px',
        },
        {
          text: this.$$t('section'),
          value: 'section.title',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: this.$$t('contact'),
          value: 'section.contract.contactName',
        },
        {
          text: this.$$t('name'),
          value: 'section.contract.name',
        },
        {
          text: this.$$t('status'),
          value: 'section.contract.status',
        },
        // {
        //   text: this.$$t('contractType'),
        //   value: 'section.contract.contractType',
        //   width: '120px',
        // },
        {
          text: this.$$t('commences'),
          value: 'section.contract.commences',
          width: '130px',
        },
        {
          text: this.$$t('expires'),
          value: 'section.contract.expires',
          width: '120px',
        },
        {
          text: this.$$t('total'),
          value: 'monthlyTotal',
          width: '110px',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    excelData() {
      return this.items.map((i) => ({
        'Contract Number': i.section.contract.contractNumber,
        'Solution Number': i.section.contract.solutionNumber ?? '',
        Section: i.section.title,
        Description: i.description,
        Contact: i.section.contract.contactName,
        Name: i.section.contract.name,
        Status: i.section.contract.status,
        'Contract Type': i.section.contract.contractType,
        Commences: i.section.contract.commences ?? '',
        Expires: i.section.contract.expires ?? '',
        Total: i.monthlyTotal ?? 0,
      }));
    },
    items() {
      return this.localData
        .filter((item) => {
          if (this.selectedContractType.length > 0) {
            return this.selectedContractType.includes(item.lineType);
          }
          return true;
        })
        .filter((item) => {
          if (this.selectedStatus.length > 0) {
            return this.selectedStatus.includes(item.section.contract.status);
          }
          return true;
        })
        .map((item) => {
          const res = { ...item };
          if (res.contractType === 'ACCPAY') {
            res.total *= -1;
          }
          return res;
        });
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const { month, selectedCurrency, isExpiring } = this;
      const url = `/api/contracts/reports/forecast/detail?currencyCode=${selectedCurrency}&month=${month}&isExpiring=${isExpiring}`;
      const { data } = await this.$http.get(url);
      this.localData = data;
      this.isLoading = false;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.items.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
};
</script>
