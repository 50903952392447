import Vue from 'vue';
import VueRouter from 'vue-router';
import { kebabCase } from 'case-anything';

import appViews from '../views';
import guards from './guards';
import modules from '../modules';
import autoRefreshMixin from '../mixins/auto-refresh';

const Init = () => import(/* webpackChunkName: "route-Init" */ '../views/Init.vue');
const Login = () => import(/* webpackChunkName: "route-Login" */ '../views/Login.vue');
const Register = () => import(/* webpackChunkName: "route-Register" */ '../views/Register.vue');

Vue.use(VueRouter);

const mapViews = (prefix, views) => {
  const viewsArr = Object.keys(views)
    .filter((v) => views[v].name !== 'Home')
    .map((v) => {
      const component = views[v];
      if (component.mixins) {
        component.mixins.push(autoRefreshMixin);
      } else {
        component.mixins = [autoRefreshMixin];
      }
      const view = {
        path: `/${prefix}/${kebabCase(v)}`,
        name: `${prefix}-${v}`,
        component,
        meta: views[v].meta,
      };
      return view;
    });
  return viewsArr;
};
Object.keys(appViews).forEach((v) => {
  if (appViews[v].mixins) {
    appViews[v].mixins.push(autoRefreshMixin);
  } else {
    appViews[v].mixins = [autoRefreshMixin];
  }
});

const appRoutes = [
  {
    path: '/auth/login',
    alias: '/login',
    name: 'auth-Login',
    component: Login,
  },
  {
    path: '/auth/register',
    name: 'auth-Register',
    alias: '/register',
    component: Register,
  },
  {
    path: '/auth/password-reset',
    name: 'auth-PasswordReset',
    component: appViews.PasswordReset,
    meta: { requiresAuth: false, requiresOrg: false },
  },
  {
    path: '/profile',
    name: 'app-UserProfile',
    component: appViews.UserProfile,
    meta: { requiresAuth: true },
  },
  {
    path: '/init',
    name: 'app-Init',
    component: Init,
  },
  {
    path: '/search-redirect',
    name: 'app-SearchRedirect',
    component: appViews.SearchRedirect,
  },
  {
    path: '/auth/consent',
    name: 'app-Consent',
    component: appViews.Consent,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/xero/tenants',
    name: 'app-xeroTenants',
    component: appViews.XeroTenants,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/settings/company',
    name: 'app-CompanySettings',
    component: appViews.CompanySettings,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/defaults/company',
    name: 'app-CompanyDefaults',
    component: appViews.CompanyDefaults,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/settings/user',
    name: 'app-UserSettings',
    component: appViews.UserSettings,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/settings/integration',
    name: 'app-IntegrationSettings',
    component: appViews.IntegrationSettings,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/on-board',
    name: 'app-Onboard',
    component: appViews.Onboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/on-board/xero',
    name: 'app-OnboardXero',
    component: appViews.OnboardXero,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/feedback-items',
    name: 'admin-FeedbackItems',
    component: appViews.FeedbackItems,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/manage-contracts',
    name: 'admin-ManageContracts',
    component: appViews.ManageContracts,
    meta: { requiresAuth: true },
  },
  {
    path: '/my/feedback-items',
    name: 'app-MyFeedbackItems',
    component: appViews.MyFeedbackItems,
    meta: { requiresAuth: true },
  },
  {
    path: '/auth/change-password',
    name: 'app-ChangePassword',
    component: appViews.ChangePassword,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/users',
    name: 'admin-Users',
    component: appViews.UserManagementHome,
    meta: { requiresAuth: true },
  },
  {
    path: '/admin/manage',
    name: 'admin-Manage',
    component: appViews.Hangfire,
    meta: { requiresAuth: true },
  },
  {
    path: '/home',
    alias: '/',
    name: 'app-Home',
    component: appViews.Dashboard,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/reports/new-business-log',
    name: 'reports-ListReports',
    component: appViews.ListReports,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/reports/telemetry',
    name: 'reports-Telemetry',
    component: appViews.Telemetry,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/reports/builder',
    name: 'reports-ReportBuilder',
    component: appViews.ReportBuilder,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/contacts',
    name: 'app-Contacts',
    component: appViews.Contacts,
    meta: { requiresAuth: true, requiresOrg: true },
  },
  {
    path: '/release/notes',
    name: 'app-Release',
    component: appViews.Release,
    meta: { requiresAuth: true, requiresOrg: true },
  },
];

const moduleRoutes = modules.map((m) => mapViews(m.config.path, m.views)).flat();
const routes = [...appRoutes, ...moduleRoutes];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach(guards);

export { routes };
export { router };
