<template>
  <Card
    :title="`Quotes (${quotes.length})`"
    :title-right="`Total MRC ${formatNumber(getTotal(quotes))}`"
  >
    <v-list three-line>
      <template v-for="(item) in quotes">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.stage}}: {{ item.reference }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ formatFromNow(item.created) }}
              <span class="text-right d-block">
                {{
                  formatNumber(
                    lineSum(item, "Monthly Recurring Cost"),
                  )
                }} MRC
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <span class="text-h6" v-if="quotes.length === 0">
        No items found
      </span>
    </v-list>
  </Card>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'CardQuotes',
  components: {
    Card,
  },
  props: {
    quotes: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getTotal(item) {
      let total = 0;
      item.forEach((i) => {
        total += this.lineSum(i, 'Monthly Recurring Cost');
      });
      return total;
    },
    lineSum(item, lineType) {
      let lineItems = [];
      if (item.sections) {
        item.sections.forEach((s) => {
          lineItems = lineItems.concat(s.lineItems ?? []);
        });
        const lines = lineItems.filter((l) => l.lineType === lineType);
        const lineAmounts = lines.map((l) => l.lineAmount);
        if (lineAmounts?.length > 0) {
          const result = lineAmounts.reduce((sum, x) => sum + x);
          return result;
        }
      }
      return 0;
    },
  },
};
</script>
