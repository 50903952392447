import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

export default {
  data() {
    return {
      includeArchived: false,
    };
  },
  computed: {
    ...mapState('clients', ['clients']),
    allClients() {
      const { clients } = this;
      const localClients = clients?.map((c) => ({ id: c.id, name: c.name, source: 'local', isActive: c.isActive })) ?? [];
      return sortBy([
        ...localClients,
      ], (c) => c.name);
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('clients', ['LOAD_clients', 'DELETE_client']),
    async loadAllClients() {
      await Promise.all([
        (async () => {
          await this.LOAD_clients({ includeArchived: false });
        })(),
      ]);
    },
  },
};
