<!-- eslint-disable no-unused-vars -->
<template>
  <v-container fluid>
    <v-progress-linear :indeterminate="true" v-if="isLoading"></v-progress-linear>
    <div v-else>
      <div v-if="networkData">
        <VisNetworkBubble :data="networkData" :target="total" @click="nodeClicked" />
      </div>
  </div>
  </v-container>
</template>
<script>
// eslint-disable no-nested-ternary
// eslint-disable no-unused-vars
import { mapActions, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { groupBy, uniq } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import months from './months.json';
import VisNetworkBubble from './VisNetworkBubble.vue';

export default {
  name: 'GraphBubbles',
  components: {
    VisNetworkBubble,
  },
  mixins: [loading],
  data() {
    return {
      localData: [],
      months,
      deliveredItems: [],
      wipItems: [],
      data: undefined,
      selectedMonth: new Date().getMonth(),
      selectedNode: undefined,
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('revenueLines', ['revenueLines']),
    ...mapState('orgTargets', ['orgTargets']),
    graphData() {
      const { deliveredItems } = this;
      const { month } = this;
      const mrp = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isSame(month, 'month'))
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const beginningOfTheYear = new Date();
      beginningOfTheYear.setMonth(0);
      const mrpYtd = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const getRevenueLine = (id) => this.revenueLines.find((r) => r.id === id);
      const mrpRevenueLines = groupBy(deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Monthly Recurring Cost'),
      (i) => {
        const revenueLine = getRevenueLine(i.revenueLineId);
        if (!revenueLine) return 'No revenue line';
        return revenueLine.name;
      });
      const ytdMrpByRevenueLine = Object
        .entries(mrpRevenueLines)
        .map(([k, v]) => ({ name: k, data: v, total: v.reduce((sum, item) => sum + item.profit, 0) }));

      const nrpRevenueLines = groupBy(deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Non-Recurring Cost'),
      (i) => {
        const revenueLine = getRevenueLine(i.revenueLineId);
        if (!revenueLine) return 'No revenue line';
        return revenueLine.name;
      });
      const ytdNrpByRevenueLine = Object
        .entries(nrpRevenueLines)
        .map(([k, v]) => ({ name: k, data: v, total: v.reduce((sum, item) => sum + item.profit, 0) }));

      const nrp = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isSame(month, 'month'))
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const nrpYtd = deliveredItems
        .filter((item) => dayjs(item.agreement.deliveredDate).isAfter(beginningOfTheYear, 'month'))
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const itemsInWip = uniq(this.wipItems
        .map((item) => item.agreement.id))
        .length;

      const mrrInWip = this.wipItems
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.lineAmount, 0);

      const mrpInWip = this.wipItems
        .filter((item) => item.lineType === 'Monthly Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      const nrrInWip = this.wipItems
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.lineAmount, 0);

      const nrpInWip = this.wipItems
        .filter((item) => item.lineType === 'Non-Recurring Cost')
        .reduce((sum, item) => sum + item.profit, 0);

      return {
        itemsInWip,
        mrrInWip,
        mrpInWip,
        ytdMrpByRevenueLine,
        ytdNrpByRevenueLine,
        mrp,
        mrpYtd,
        nrp,
        nrpYtd,
        nrrInWip,
        nrpInWip,
      };
    },
    monthName() {
      return dayjs(this.month).format('MMM YYYY');
    },
    networkData() {
      const { graphData, monthName } = this;
      let nodes = [];
      const edges = [];
      // let index = 1;
      if (graphData) {
        // const color = this.target < 100 ? '#F11A16' : '#1daa89';
        const nrpColor = '#486274';
        const mrpColor = '#1daa89';
        const totalColor = '#951daa';
        const total = this.graphData.mrpYtd + this.graphData.nrpYtd;
        nodes.push({
          id: 0, label: 'Total YTD', value: total, totalColor,
        });
        nodes.push({
          id: 1, label: 'MRP YTD', value: this.graphData.mrpYtd, color: mrpColor,
        });
        nodes.push({
          id: 2, label: 'NRP YTD', value: this.graphData.nrpYtd, color: nrpColor,
        });
        nodes.push({
          id: 3, label: `MRP (${monthName})`, value: this.graphData.mrp, color: mrpColor,
        });
        nodes.push({
          id: 4, label: `NRP (${monthName})`, value: this.graphData.nrp, color: nrpColor,
        });
        nodes.push({
          id: 5, label: `MRP WIP (${monthName})`, value: this.graphData.mrpInWip, color: mrpColor,
        });
        nodes.push({
          id: 6, label: `NRP WIP (${monthName})`, value: this.graphData.nrpInWip, color: nrpColor,
        });

        const maxItems = 10;

        graphData.ytdMrpByRevenueLine
          .slice(0, maxItems)
          .forEach((item, index) => {
            nodes.push({
              id: 7 + index, label: `${item.name} YTD`, value: item.total, color: mrpColor,
            });
            edges.push({
              from: 1, to: 7 + index, value: item.total, color: { opacity: 0.5, highlight: '#1C1C65' },
            });
          });

        if (graphData.ytdMrpByRevenueLine.length > maxItems) {
          const otherTotal = graphData.ytdMrpByRevenueLine
            .slice(maxItems)
            .reduce((sum, item) => sum + item.total, 0);

          nodes.push({
            id: 17, label: 'Other Revenue Lines', value: otherTotal, color: mrpColor,
          });

          edges.push({
            from: 1, to: 17, value: otherTotal, color: { opacity: 0.5, highlight: '#1C1C65' },
          });
        }

        graphData.ytdNrpByRevenueLine
          .slice(0, maxItems)
          .forEach((item, index) => {
            nodes.push({
              id: 7 + graphData.ytdMrpByRevenueLine.length + index, label: `${item.name} YTD`, value: item.total, color: nrpColor,
            });
            edges.push({
              from: 2, to: 7 + graphData.ytdMrpByRevenueLine.length + index, value: item.total, color: { opacity: 0.5, highlight: '#1C1C65' },
            });
          });

        if (graphData.ytdNrpByRevenueLine.length > maxItems) {
          const otherTotal = graphData.ytdNrpByRevenueLine
            .slice(maxItems)
            .reduce((sum, item) => sum + item.total, 0);

          nodes.push({
            id: 7 + graphData.ytdMrpByRevenueLine.length + maxItems, label: 'Other Revenue Lines', value: otherTotal, color: nrpColor,
          });

          edges.push({
            from: 2, to: 7 + graphData.ytdMrpByRevenueLine.length + maxItems, value: otherTotal, color: { opacity: 0.5, highlight: '#1C1C65' },
          });
        }

        edges.push({
          from: 0, to: 1, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });
        edges.push({
          from: 0, to: 2, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });
        edges.push({
          from: 1, to: 3, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });
        edges.push({
          from: 2, to: 4, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });
        edges.push({
          from: 3, to: 5, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });
        edges.push({
          from: 4, to: 6, value: 1, color: { opacity: 0.5, highlight: '#1C1C65' },
        });

        nodes = nodes.map((n) => {
          const node = { ...n };
          node.label = `${node.label}\n(${this.formatNumber(Math.round((node.value)))})`;
          node.font = { face: 'Work Sans', align: 'left' };
          // node.shape = 'circle';
          return node;
        });
      }
      return {
        nodes,
        edges,
      };
    },
    target() {
      return (this.periodProfit / this.periodTarget) * 100;
    },
    total() {
      return this.deliveredItems?.reduce((a, b) => a + b.profit, 0) ?? 0;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orgTargets', ['LOAD_orgTargets']),
    ...mapActions('revenueLines', ['LOAD_revenueLines']),
    ...mapMutations('reports', ['SET_selectedValue', 'SET_period', 'SET_selectedYear']),
    async loadBubbleData() {
      this.deliveredItems = [];
      this.wipItems = [];
      await Promise.all([
        (async () => {
          let url = 'odata/incomeLines?';
          url += '$filter=agreement/Status eq \'Delivered\'';
          // url += ` AND lineType eq '${this.lineType}'`;
          url += '&$expand=agreement($select=deliveredDate)';
          const { data } = await this.$http.get(url);
          this.deliveredItems = data.value;
        })(),
        (async () => {
          let url = 'odata/incomeLines?';
          url += '$filter=agreement/Status eq \'Work In Progress\'';
          // url += ` AND lineType eq '${this.lineType}'`;
          url += '&$expand=agreement($select=acceptedDate,id)';
          const { data } = await this.$http.get(url);
          this.wipItems = data.value;
        })(),
      ]);
    },
    nodeClicked(node) {
      if (this.selectedNode === node) {
        this.selectedNode = undefined;
        return;
      }
      this.selectedNode = node;
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.loadBubbleData();
        })(),
        (async () => {
          await this.LOAD_revenueLines();
        })(),
      ]);
      this.isLoading = false;
    },
  },
};
</script>
