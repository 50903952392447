<template>
  <div>
    <v-textarea
      v-if="edit"
      v-model="localValue"
      :append-icon="mdiClose"
      :append-outer-icon="mdiContentSave"
      @click:append-outer="save"
      @click:append="cancel"
      :label="label"
    />
    <div v-else>
      {{ value }}
      <v-btn icon @click="edit = true">
        <v-icon>{{ mdiPencilBoxOutline }}</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiClose, mdiContentSave, mdiPencilBoxOutline } from '@mdi/js';
import basicModel from '@codehq/aurora-app-core/src/mixins/basicModel';

export default {
  name: 'InlineTextField',
  mixins: [basicModel],
  props: {
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiClose,
      mdiContentSave,
      mdiPencilBoxOutline,
      edit: false,
    };
  },
  methods: {
    cancel() {
      this.edit = false;
      this.localValue = this.value;
    },
    async save() {
      this.$emit('input', this.localValue);
      this.edit = false;
      await this.$nextTick();
      this.$emit('save', this.localValue);
    },
  },
};
</script>
