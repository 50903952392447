<template>
  <div>
    <div v-for="(item, index) in agreementsByStatus" :key="index">
      <AgreementsSummary
        :status="item.name"
        :agreements="item.agreements"
        :hide-status="item.hideStatus"
        :selected-type="selectedType"
        :currency-code="selectedCurrency"
      >
        <div v-if="index === 0" class="pa-5">
          <v-select
            v-model="selectedType"
            :items="incomeTypes"
            label="Type"
          />
        </div>
      </AgreementsSummary>
      <div class="mb-5" />
    </div>
  </div>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapActions, mapState } from 'vuex';
import AgreementsSummary from './AgreementsSummary.vue';

export default {
  name: 'SupplierAgreements',
  mixins: [loading],
  components: {
    AgreementsSummary,
  },
  props: {
    supplierId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showArchived: false,
      incomeTypes: [
        'Recurring Profit',
        'Non-Recurring Profit',
        'Total Profit',
        'Total Income',
      ],
      selectedType: 'Recurring Profit',
      agreements: [],

      /**
       * List of statuses
       */
      statuses: [
        {
          name: 'Presales',
          statuses: [
            'Draft',
            'Negotiating',
            'Prospecting',
          ],
          hideStatus: false,
        },
        {
          name: 'Work in Progress',
          statuses: ['Work In Progress'],
          hideStatus: true,
        },
        {
          name: 'Delivered',
          statuses: ['Delivered'],
          hideStatus: true,
        },
        {
          name: 'Cancelled',
          statuses: ['Cancelled'],
          hideStatus: true,
        },
      ],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    agreementsByStatus() {
      return this.statuses.map((status) => {
        const agreements = this.agreements.filter((agreement) => status.statuses.includes(agreement.status));
        return {
          ...status,
          agreements,
        };
      });
    },
  },
  watch: {
    clientId() {
      this.refresh();
    },
    selectedCurrency() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('agreements', ['LOAD_GetSummaryForSupplier']),
    async refresh() {
      this.isLoading = true;
      const { supplierId, selectedCurrency } = this;
      this.agreements = await this.LOAD_GetSummaryForSupplier({ supplierId, currencyCode: selectedCurrency });
      this.isLoading = false;
    },
  },
};
</script>
