/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */ export const abortController = (axios) => {
  let controller = new AbortController();

  axios.defaults.signal = controller.signal;

  console.log(controller.signal);

  const abort = () => {
    try {
      controller.abort();
    } catch (e) {
      console.error(e);
    }

    controller = new AbortController();

    axios.defaults.signal = controller.signal;
  };

  return abort;
};
