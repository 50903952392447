<template>
  <v-text-field v-bind="$props" v-on="$listeners"
      ref="AutoSelectField"
      @focus="$event.target.select()">
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'AutoSelect',
  extends: VTextField,
};
</script>
