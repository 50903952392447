<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h5 title="Upload general documents for the solution that are not specific to an agreement">
        {{ 'Solution Documents (' + solutionAttachments.length + ')' }}
      </h5>
    </v-card-title>
    <v-card-text>
      <v-list three-line>
        <template v-for="(attachment, index) in solutionAttachments">
          <v-list-item :key="index">
            <v-list-item-avatar>
              <v-icon>{{ mdiFile }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="attachment.fileName"></v-list-item-title>
              <v-list-item-subtitle v-html="attachment.attachmentType"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon target="_blank" :href="attachment.fileUrl">
                <v-icon>{{ mdiDownload }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn :disabled="disabled" icon color="error" @click="remove(attachment)">
                <v-icon>{{ mdiDelete }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <DocumentViewer :url="attachment.fileUrl" />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <FileUpload v-if="!disabled" @input="fileUploaded" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DocumentViewer from '@/components/DocumentViewer.vue';
import { mdiFile, mdiDownload, mdiDelete } from '@mdi/js';
import FileUpload from './FileUpload.vue';

export default {
  name: 'SolutionAttachments',
  mixins: [loading],
  module: 'Solution',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    Solution: {
      type: Object,
      required: true,
    },
  },
  components: {
    DocumentViewer,
    FileUpload,
  },
  computed: {
    ...mapState('solutionAttachments', ['solutionAttachments']),
  },
  data() {
    return {
      mdiDelete,
      mdiDownload,
      mdiFile,
      dialog: false,
    };
  },
  watch: {
    Solution: {
      immediate: true,
      deep: true,
      handler() {
        this.debouncedRefresh();
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('solutionAttachments', [
      'CREATE_solutionAttachment',
      'LOAD_solutionAttachments',
      'DELETE_solutionAttachment',
    ]),
    async fileUploaded(item) {
      const { uploadedFile, url } = item;
      await this.CREATE_solutionAttachment({
        fileName: uploadedFile.fileName,
        attachmentType: uploadedFile.attachmentType,
        fileUrl: url,
        solutionId: this.Solution.id,
      });
      await this.refresh();
    },
    // eslint-disable-next-line func-names
    debouncedRefresh: debounce(function () {
      this.refresh();
    }, 1000),
    async refresh() {
      if (this.Solution.id) {
        this.isLoading = true;
        await this.LOAD_solutionAttachments(this.Solution.id);
        this.isLoading = false;
      }
    },
    async remove(attachment) {
      const result = await this.$confirm('Are you sure you want to delete this attachment?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_solutionAttachment(attachment.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
  },
};
</script>
