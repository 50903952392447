<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title>
        <v-tabs v-model="activeTab">
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-tabs-items v-model="activeTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <v-card>
              <v-card-text>
                <template v-if="tab === 'Notifications'">
                  <v-checkbox
                    v-model="cancellationEmail"
                    label="Email me when agreements are cancelled"
                  ></v-checkbox>

                  <v-checkbox
                    v-model="expirationEmail"
                    label="Email me when agreements are about to expire"
                  ></v-checkbox>

                <v-checkbox
                  v-model="weeklyReminderEmail"
                  label="Weekly emails of agreements that have not been accepted"
                ></v-checkbox>
                  <v-btn
                    color="success"
                    :loading="isSaving"
                    @click="save">Save</v-btn>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UserSettings',
  mixins: [loading],
  data() {
    return {
      activeTab: 'Notifications',
      tabs: ['Notifications'],
      cancellationEmail: null,
      expirationEmail: null,
      weeklyReminderEmail: null,
    };
  },
  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('userSettings', ['userSettings']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('userSettings', [
      'LOAD_userSettings',
      'CREATE_userSetting',
      'UPDATE_userSetting',
    ]),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_userSettings();
      this.setUserSettings(this.userSettings);
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      await this.saveUserSetting('cancellationEmail', this.cancellationEmail);
      await this.saveUserSetting('expirationEmail', this.expirationEmail);
      await this.saveUserSetting('weeklyReminderEmail', this.weeklyReminderEmail);
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
    setUserSettings(userSettings) {
      this.cancellationEmail = userSettings.find(
        (setting) => setting.name === 'cancellationEmail',
      )?.value === 'true';
      this.expirationEmail = userSettings.find(
        (setting) => setting.name === 'expirationEmail',
      )?.value === 'true';
      this.weeklyReminderEmail = userSettings.find(
        (setting) => setting.name === 'weeklyReminderEmail',
      )?.value === 'true';
    },
    async saveUserSetting(name, value) {
      const existingUserSetting = this.userSettings.find(
        (setting) => setting.name === name,
      );
      if (existingUserSetting) {
        await this.UPDATE_userSetting({
          id: existingUserSetting.id,
          name,
          value: value?.toString() ?? 'false',
        });
        return;
      }
      await this.CREATE_userSetting({
        name,
        value: value?.toString() ?? 'false',
      });
    },
  },
};
</script>
