<template>
  <Card :title="`Contacts (${people.length})`">
    <template v-slot:action>
      <v-btn
        :to="{
          name: 'quotes-EditPerson',
          query: {
            clientId,
          }
        }">
        Add
      </v-btn>
    </template>
    <v-list class="list-height-restricted" three-line>
      <template v-for="(item) in people">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.firstName }} {{ item.lastName }}
              <span>
                <a :href="`mailto:${item.emailAddress}`">
                  {{ item.emailAddress }}
                </a>
              </span>
              <v-btn small icon class="float-right"
                :to="{
                  name: 'quotes-EditPerson',
                  query: { id: item.id, clientId }
                }">
                <v-icon>{{ mdiPencil }}</v-icon>
              </v-btn>
            </v-list-item-title>
            <v-list-item-subtitle>
              created {{ formatFromNow(item.created) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <span class="text-h6" v-if="people.length === 0">
        No items found
      </span>
    </v-list>
  </Card>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import Card from './Card.vue';

export default {
  name: 'CardContacts',
  components: {
    Card,
  },
  props: {
    people: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiPencil,
    };
  },
};
</script>
