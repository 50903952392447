<template>
  <v-list dense>
    <template v-for="(solutionExchangeRate, index) in solutionExchangeRates">
      <v-list-item
        :key="index"
      >
        <v-list-item-content>
          <v-list-item-title class="text-right">
            Converted from {{ solutionExchangeRate.sourceCurrency }}
            using a rate of {{ solutionExchangeRate.rate.toFixed(3) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    </v-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiDelete } from '@mdi/js';

export default {
  name: 'SolutionExchangeRate',
  props: ['value', 'disabled', 'allLineItems'],
  mixins: [loading],
  computed: {
    ...mapState('solutionExchangeRates', ['solutionExchangeRates']),
    ...mapState('quotes', ['quote']),
    solutionId() {
      return this.value?.id;
    },
  },
  data() {
    return {
      mdiDelete,
      dialog: false,
    };
  },
  watch: {
    async solutionId() {
      await this.loadSolutionExchangeRate();
    },
  },
  async mounted() {
    await this.loadSolutionExchangeRate();
  },
  methods: {
    ...mapActions('solutionExchangeRates', [
      'CREATE_solutionExchangeRate',
      'LOAD_solutionExchangeRatesForSolution',
      'DELETE_solutionExchangeRate',
    ]),
    async loadSolutionExchangeRate() {
      if (!this.value) {
        return;
      }
      this.isLoading = true;
      if (this.solutionId) {
        await this.LOAD_solutionExchangeRatesForSolution(this.solutionId);
      }
      this.isLoading = false;
    },
    async remove(solutionExchangeRate) {
      this.isLoading = true;
      await this.DELETE_solutionExchangeRate(solutionExchangeRate.id);
      this.isLoading = false;
      await this.loadSolutionExchangeRate();
    },
  },
};
</script>
