<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-text-field
          filled
          data-cy="FormQuoteTemplate-name"
          :label="$$t('name')"
          v-model="localQuoteTemplate.name"
          :hint="$$t('name')"
          required
        />
        <v-select
          :items="['Agreement Template', 'Client Template']"
          v-model="localQuoteTemplate.quoteTemplateType"
          :label="$$t('quoteTemplateType')"
        ></v-select>
        <h3 v-if="file">Template File Name: {{ file.uploadedFile.fileName }}</h3>
        <FileUpload @input="fileUploaded" />
        <div class="d-flex justify-end mt-5">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormQuoteTemplate-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormQuoteTemplate-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FileUpload from '~src/components/FileUpload.vue';

export default {
  name: 'FormQuoteTemplate',
  module: 'QuoteTemplate',
  mixins: [loading],
  components: {
    FileUpload,
  },
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    QuoteTemplate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localQuoteTemplate
      */
      localQuoteTemplate: {},
      file: undefined,
    };
  },
  watch: {
    QuoteTemplate() {
      this.localQuoteTemplate = this.QuoteTemplate;
      this.refresh();
    },
  },
  created() {
    this.localQuoteTemplate = this.QuoteTemplate;
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('userFiles', ['LOAD_userFile']),
    async refresh() {
      if (this.localQuoteTemplate?.uploadedFileId) {
        const userFile = await this.LOAD_userFile(this.localQuoteTemplate.uploadedFileId);
        this.file = {
          uploadedFile: userFile,
        };
      }
    },
    fileUploaded(file) {
      this.file = file;
      this.localQuoteTemplate.uploadedFileId = file.uploadedFile.id;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    async submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localQuoteTemplate);
        this.isSaving = true;
        await new Promise((r) => setTimeout((r), 1000));
        this.isSaving = false;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormQuoteTemplate example

'''js
<FormQuoteTemplate :QuoteTemplate="QuoteTemplate" />
'''
</docs>
