<template>
  <div>
    <v-progress-linear v-if="isLoading" :indeterminate="true" color="primary" />
    <v-btn
      text
      color="primary"
      @click="addSection"
      :disabled="disabled"
      data-cy="Section-button-add"
    >
      <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
      Add a new section
    </v-btn>
    <v-card
      class="mb-10"
      color="grey lighten-3"
      v-for="(section, index) in sections"
      :data-cy="`Section-card-${index}`"
      :key="section.id"
    >
      <v-col class="d-flex justify-end pa-4 pb-0">
        <v-card class="total-header">
          Section ID: {{ section. id}}
          <v-card-title class="text-subtitle-1">
            {{ $$t('total') }} {{ formatCurrency(getTotal(section), value.currency) }}
          </v-card-title>
        </v-card>
      </v-col>
      <v-card-title class="pt-0" primary-title :id="section.id">
        <AutoSelect
          class="mr-5"
          v-model="section.title"
          :disable="disabled"
          @input="debouncedSave(section)"
        ></AutoSelect>
        <SectionImport
          @import="importSection(section, $event)"
          :section="section"
          :disabled="disabled"
        />
        <div class="ml-5" />
        <ContractSectionInvoice
          v-if="hasNrc(section)"
          :invoiceID="section.invoiceId"
          :sectionId="section.id"
          :contractType="value.contractType"
          :contract="value"
          type="single"
        />
        <div class="ml-5" />
        <ContractSectionRepeatingInvoice
          v-if="hasMrc(section)"
          :invoiceID="section.repeatingInvoiceId"
          :sectionId="section.id"
          :contractType="value.contractType"
          :contract="value"
          type="repeating"
        />
        <DatePicker
          class="mt-5 ml-5"
          :data-cy="`Section-estimatedDeliveryDate`"
          :dense="true"
          :filled="true"
          :disabled="disabled"
          v-model="section.estimatedDeliveryDate"
          label="Estimated Delivery"
          :id="`Section-estimatedDeliveryDate-${index}-datepicker`"
          @input="debouncedSave(section)"
        />
      </v-card-title>
      <v-card-text>
        <ContractSectionLines
          v-if="!isImporting"
          :value="section"
          :index="index"
          :disabled="disabled"
          @updated:line="(event) => onUpdateLine(event, section)"
          @onCalculateTotal="onCalculateTotal"
        />
      </v-card-text>
      <v-card-actions>
        <!-- <div class="text-h5 text-left">
          Total:
        </div> -->
        <v-spacer></v-spacer>
        <v-btn
          @click="deleteSection(section)"
          color="error"
          small
          data-cy="Section-delete-button"
          :disabled="disabled"
        >
          <v-icon class="mr-2">{{ mdiDelete }}</v-icon
          >Delete
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-col cols="6">
        <v-btn text color="primary" @click="addSection" :disabled="disabled">
          <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
          Add a new section
        </v-btn>
      </v-col>
      <v-col cols="6">
        <slot name="bottom" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiDelete, mdiPlus } from '@mdi/js';
import { debounce, sortBy } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ContractSectionLines from './SectionLines.vue';
import ContractSectionRepeatingInvoice from './ContractSectionRepeatingInvoice.vue';
import ContractSectionInvoice from './ContractSectionInvoice.vue';
import SectionImport from './SectionImport.vue';

export default {
  name: 'Sections',
  module: 'Section',
  components: {
    ContractSectionRepeatingInvoice,
    ContractSectionInvoice,
    ContractSectionLines,
    SectionImport,
  },
  mixins: [loading],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapState('contractSections', ['contractSections']),
    disabled() {
      return (
        this.value?.status === 'Accepted'
        || this.value?.status === 'Cancelled'
        || this.value?.status === 'Delivered'
        || this.value?.status === 'In Progress'
        || this.value?.status === 'Upgraded'
      );
    },
    sections() {
      return sortBy(this.contractSections, 'order');
    },
    contractId() {
      return this.value?.id;
    },
  },
  data() {
    return {
      mdiDelete,
      mdiPlus,
      lineItems: [],
      isImporting: false,
      totals: [],
    };
  },
  watch: {
    contractId() {
      this.refreshContractSections();
    },
  },
  async mounted() {
    await this.refreshContractSections();
    this.totals = [];
  },
  methods: {
    ...mapActions('contractSections', [
      'UPDATE_contractSection',
      'CREATE_contractSection',
      'DELETE_contractSection',
      'LOAD_contractSectionsByContract',
    ]),
    ...mapMutations('contractSections', ['SET_contractSections']),
    ...mapActions('contractSectionLineItems', [
      'LOAD_sectionLineItemsBySection',
      'CREATE_contractSectionLineItem',
    ]),
    async addSection() {
      const newSection = await this.CREATE_contractSection({
        contractId: this.value.id,
        order: this.sections.length + 1,
        title: 'New section',
        isActive: false,
      });
      await this.refreshContractSections();
      this.onUpdatedSections();
      await this.$nextTick();
      const element = document.getElementById(newSection.id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        this.$log.warn(`Could not find element to scroll to ${newSection.id}`, newSection);
      }
      this.$emit('inserted:section', newSection);
    },
    async hasNrc(section) {
      const lineItems = (await this.LOAD_sectionLineItemsBySection(section.id)) ?? [];
      return lineItems.filter((l) => l.lineType === 'Non-Recurring Cost').length > 0;
    },
    async hasMrc(section) {
      const lineItems = (await this.LOAD_sectionLineItemsBySection(section.id)) ?? [];
      return lineItems.filter((l) => l.lineType === 'Monthly Recurring Cost').length > 0;
    },
    async deleteSection(section) {
      const result = await this.$confirm('Are you sure you want to delete this section?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning',
      });
      if (result) {
        await this.DELETE_contractSection(section.id);
        this.removeTotal(section);
      }
    },

    getTotal(section) {
      if (!this.totals || this.totals.length === 0) {
        return 0;
      }
      const totalIndex = this.totals.findIndex((x) => x.section.id === section.id);

      return totalIndex > -1 ? this.totals[totalIndex].total : 0;
    },

    async refreshContractSections() {
      if (this.value?.id) {
        this.isLoading = true;
        // this.SET_contractSections([]);
        await this.LOAD_contractSectionsByContract(this.value.id);
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(async function (item) {
      await this.UPDATE_contractSection(item);

      const itemTotalIndex = this.totals.findIndex((x) => x.section.id === item.id);
      if (itemTotalIndex > -1) {
        this.totals[itemTotalIndex].section = item;
      }

      this.onUpdatedSections();
    }, 500),
    async importSection(section, item) {
      const lines = item.lineItems.map((l, i) => ({
        contractSectionId: section.id,
        description: l.description,
        amount: l.lineAmount,
        lineType: 'Monthly Recurring Cost',
        quantity: l.quantity,
        lineNumber: i + 1,
      }));
      lines.map(async (l) => {
        this.isImporting = true;
        await this.CREATE_contractSectionLineItem(l);
        this.isImporting = false;
      });
      setTimeout(async () => {
        await this.refreshContractSections();
      }, 1000);
    },

    async onUpdateLine(event, section) {
      this.isLoading = true;
      const lineItems = await this.LOAD_sectionLineItemsBySection(section.id);

      if (lineItems) {
        const total = lineItems.reduce((prev, curr) => prev + curr.quantity * +curr.amount, 0);
        this.addTotal(section, total);
      }
      this.isLoading = false;

      this.$emit('updated:line', event);
    },

    onCalculateTotal(section, total) {
      this.addTotal(section, total);
    },

    addTotal(section, total) {
      const itemTotalIndex = this.totals.findIndex((x) => x.section.id === section.id);
      if (itemTotalIndex > -1) {
        this.totals[itemTotalIndex].total = total;
      } else {
        this.totals.push({
          section,
          total,
        });
      }

      this.onUpdatedSections();
    },

    removeTotal(section) {
      const itemTotalIndex = this.totals.findIndex((x) => x.section.id === section.id);
      if (itemTotalIndex > -1) {
        this.totals.splice(itemTotalIndex, 1);
      }

      this.onUpdatedSections();
    },

    onUpdatedSections() {
      this.$emit(
        'onUpdatedSections',
        this.totals.sort((a, b) => a.section.order - b.section.order),
      );
    },
  },
};
</script>

<style scoped>
.total-header {
  width: fit-content;
}
</style>
