export default {
  agreements: [],
  agreementHeaders: [],
  agreement: undefined,
  stages: [
    'Draft',
    'Prospecting',
    'Negotiating',
    'Work In Progress',
    'Declined',
    'Delivered',
    'Cancelled',
  ],
  summary: undefined,
};
