<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormLineItem
      :LineItem="editLineItem"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormLineItem from '../components/FormLineItem.vue';

export default {
  components: {
    FormLineItem,
  },
  name: 'EditLineItem',
  module: 'LineItem',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing LineItem. Default to {}.
      */
      editLineItem: {},
      /**
       * Query Id for LineItem
       */
      lineItemId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('lineItems', ['lineItems', 'lineItem']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.lineItemId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_lineItem(this.lineItemId);
      this.editLineItem = this.lineItem;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('lineItems', ['LOAD_lineItem', 'CREATE_lineItem', 'UPDATE_lineItem']),
    /** Method used to save the item
     */
    async save(lineItem) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_lineItem(lineItem);
      } else {
        await this.UPDATE_lineItem(lineItem);
      }
      this.isSaving = false;
      this.$router.push({ name: 'line-item-ListLineItem' });
    },
  },
};
</script>

<docs>
# EditLineItem example

'''js
<EditLineItem :LineItem="LineItem" />
'''
</docs>
