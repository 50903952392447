<template>
  <v-container fluid>
    <v-row>
      <v-col cols="3">
        <v-card>
          <v-card-text>
            <v-list-item v-for="item in items" :key="item.status">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.status }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.total }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ item.totalProfit }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>{{ mdiChevronRight }}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card>
          <v-card-title class="text-h5">Agreements</v-card-title>
          <v-list>
            <v-list-item v-for="quote in selectedAgreements" :key="quote.id">
              <v-list-item-content>
                <div>{{ quote.title }}</div>
                <div class="caption">{{ quote.description }}</div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiChevronRight } from '@mdi/js';
import currencies from '~src/mixins/currencies';
import { mapState } from 'vuex';

export default {
  name: 'Reports',
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.reports',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      tab: 0,
      mdiChevronRight,
      headers: [{
        text: 'Contract',
        value: 'name',
      },
      {
        text: 'Created By',
        value: 'createdBy',
        width: '250px',
      },
      {
        text: 'Created',
        value: 'created',
        width: '130px',
      },
      {
        text: 'Commences',
        value: 'commences',
        width: '130px',
      },
      {
        text: 'Expires',
        value: 'expires',
        width: '130px',
      },
      ],
      items: [],
      selectedAgreements: [],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
  },
  watch: {
    tab() {
      this.refresh();
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.items = [];
      const { selectedCurrency } = this;
      const url = `odata/agreements/GetSummaryByState(currencyCode='${selectedCurrency}')`;
      const { data } = await this.$http.get(url);
      this.items = data.value;
      this.isLoading = false;
    },
  },
};
</script>
