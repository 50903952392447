<template>
  <v-container>
    <v-row v-if="client">
      <v-col
        cols="12">
        <v-card>
          <v-card-title>
            <div class="text-h5 ml-2">
              Client: {{ client.name }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="7" class="pl-5">
                <label class="text-h6">Head Office Address</label>
                <InlineTextArea
                  data-cy="ViewClient-headOfficeAddress"
                  v-model="client.headOfficeAddress"
                  @save="UPDATE_client(client)"
                />
                <label class="text-h6">VAT Registration Number</label>
                <InlineTextField
                  data-cy="ViewClient-name"
                  v-model="client.vatNumber"
                  @save="UPDATE_client(client)"
                />
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-col cols="12">
                    <CardQuotes :quotes="quotes" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <CardContracts :contracts="contracts" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <CardContacts :people="people" :clientId="clientId" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <CardActivities :activities="activities" :clientId="clientId" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiPencil } from '@mdi/js';
import CardActivities from '../components/CardActivities.vue';
import CardContacts from '../components/CardContacts.vue';
import CardContracts from '../components/CardContracts.vue';
import CardQuotes from '../components/CardQuotes.vue';
import InlineTextField from '../../../../components/InlineTextField.vue';
import InlineTextArea from '../../../../components/InlineTextArea.vue';

export default {
  name: 'ViewClient',
  mixins: [loading],
  components: {
    CardActivities,
    CardContacts,
    CardContracts,
    CardQuotes,
    InlineTextField,
    InlineTextArea,
  },
  data() {
    return {
      /** icons
       */
      mdiPencil,
      /** data
       */
      clientId: this.$route.query.clientId,
      client: undefined,
      contracts: [],
      people: [],
      quotes: [],
      activities: [],
    };
  },
  computed: {
    items() {
      return this.activites.filter((a) => a.customerId === this.$route.query.clientId);
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('clients', ['LOAD_client', 'DELETE_client', 'UPDATE_client']),
    ...mapActions('activities', ['LOAD_clientActivities']),
    ...mapActions('people', ['LOAD_clientPeople']),
    ...mapActions('quotes', ['LOAD_clientQuotes']),
  },
  async mounted() {
    this.isLoading = true;
    await Promise.all([
      (async () => {
        this.client = await this.LOAD_client(this.clientId);
      })(),
      (async () => {
        this.quotes = await this.LOAD_clientQuotes(this.clientId);
      })(),
      (async () => {
        this.people = await this.LOAD_clientPeople(this.clientId);
      })(),
      (async () => {
        this.activities = await this.LOAD_clientActivities(this.clientId);
      })(),
      (async () => {
        const { data } = await this.$http.get(`/api/contracts/contact/${this.clientId}`);
        this.contracts = data;
      })(),
    ]);
    this.isLoading = false;
  },
};
</script>
