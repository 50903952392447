<template>
  <div>
    <v-dialog v-model="dialog" max-width="500">
      <template v-slot:activator="{ on }">
        <v-btn :disabled="disabled" v-on="on" color="primary">
          Import
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <XeroRepeatingInvoicesSelect
            v-model="selectedInvoice"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="primary" text @click="$emit('import', selectedInvoice); dialog = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import XeroRepeatingInvoicesSelect from './XeroRepeatingInvoicesSelect.vue';

export default {
  name: 'SectionImport',
  props: {
    /**
   * Holds the disabled value, with a default of false
   */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    XeroRepeatingInvoicesSelect,
  },
  data() {
    return {
      dialog: false,
      selectedInvoice: null,
    };
  },
};
</script>
