import { render, staticRenderFns } from "./Decline.vue?vue&type=template&id=71fef9d2&scoped=true&"
import script from "./Decline.vue?vue&type=script&lang=js&"
export * from "./Decline.vue?vue&type=script&lang=js&"
import style0 from "./Decline.vue?vue&type=style&index=0&id=71fef9d2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71fef9d2",
  null
  
)

export default component.exports