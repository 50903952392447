import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('incomeLine', 'incomeLines');

const customActions = {
  async LOAD_incomeLines({ commit, rootGetters }, { agreementId, isActive }) {
    commit('SET_incomeLines', []);
    let $filter = `IsDeleted eq false AND AgreementId eq ${agreementId}`;
    if (isActive) {
      $filter += ` AND IsActive eq ${isActive}`;
    }
    const data = await odata.o(rootGetters)
      .get('incomeLines')
      .query({
        $filter,
        $orderby: 'LineNumber',
      });
    commit('SET_incomeLines', data);
    return data;
  },
  async LOAD_incomeLineProfit({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`incomeLines(${id})/GetProfit`)
      .query();
    return data;
  },
  async LOAD_incomeLinesWithCosts({ rootGetters }, { agreementId, isActive }) {
    const data = await odata.o(rootGetters)
      .get('incomeLines')
      .query({
        $filter: `IsDeleted eq false AND AgreementId eq ${agreementId} AND IsActive eq ${isActive}`,
        $expand: `CostLines($filter=IsDeleted eq false AND IsActive eq ${isActive})`,
        $orderby: 'LineNumber',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
