<template>
  <v-text-field
    v-bind="$props"
    v-model="textFieldValue"
    :data-cy="dataCy"
    :rules="rules"
    @input="onInput"
    @change="onInput"
  >
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'NullableTextField',
  extends: VTextField,
  props: {
    rules: {
      default: () => [],
    },
    dataCy: {
      type: String,
      default: 'NullableTextField',
    },
  },
  watch: {
    value() {
      this.textFieldValue = this.value;
    },
  },
  data() {
    return {
      textFieldValue: null,
    };
  },
  mounted() {
    this.textFieldValue = this.value;
  },
  methods: {
    onInput() {
      if (this.textFieldValue === '') {
        this.$emit('input', null);
      } else {
        this.$emit('input', this.textFieldValue);
      }
    },
  },
};
</script>
