<template>
  <v-dialog
    v-model="dialog"
    scrollable
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        data-cy="ModalEmailQuote-btn-email"
        color="primary"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon class="mr-2">{{ mdiEmail }}</v-icon>
        Email
      </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title>Send Email</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="dialog">
        <FormEmailQuote
          :EmailQuote="editEmailQuote"
          :isSaving="isSaving"
          @save="save"
          @cancel="dialog = false"
        />
        <div class="text-h5">History</div>
        <hr />
        <v-data-table
          :headers="headers"
          :items="emailQuotes"
          item-key="id"
          data-cy="ModalEmailQuote-table-emailQuotes"
        >
          <template v-slot:item.status="{ item }">
            {{ $t(item.status) }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiEmail } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormEmailQuote from './FormEmailQuote.vue';

export default {
  name: 'ModalEmailQuote',
  module: 'EmailQuote',
  components: {
    FormEmailQuote,
  },
  mixins: [loading],
  props: ['editQuote'],
  data() {
    return {
      mdiEmail,
      dialog: false,
      /**
      * Get/Set editing EmailQuote. Default to {}.
      */
      editEmailQuote: {},
    };
  },
  computed: {
    ...mapState('emailQuotes', ['emailQuotes']),
    headers() {
      return [{
        text: this.$$t('recipients'),
        value: 'recipients',
      }, {
        text: this.$$t('subject'),
        value: 'subject',
      }, {
        text: this.$$t('status'),
        value: 'status',
      }];
    },
  },
  watch: {
    editQuote: {
      immediate: true,
      deep: true,
      handler() {
        this.editEmailQuote.quoteId = this.editQuote.id;
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emailQuotes', ['LOAD_emailQuotes', 'LOAD_emailQuote', 'CREATE_emailQuote', 'UPDATE_emailQuote']),
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      if (this.mode === 'edit') {
        await this.LOAD_emailQuotes(this.emailQuote.quoteId);
        this.editEmailQuote = this.emailQuote;
      } else {
        this.editEmailQuote.quoteId = this.editQuote.id;
      }
      this.isLoading = false;
    },
    /** Method used to save the item
     */
    async save(emailQuote) {
      this.isSaving = true;
      if (this.mode === 'edit') {
        await this.UPDATE_emailQuote(emailQuote);
      } else {
        await this.CREATE_emailQuote(emailQuote);
      }
      this.isSaving = false;
      await this.refresh();
      await this.$nextTick();
      this.editEmailQuote.recipients = '';
      this.editEmailQuote.subject = '';
      this.editEmailQuote.body = '';
      this.$emit('refresh');
    },
  },
};
</script>
