<template>
  <div>
    <v-row class="hero-row">
      <v-col cols="12" class="hero-column">
        <v-img max-height="400" src="../assets/SiFutures_background.jpg"></v-img>
      </v-col>
    </v-row>
    <div v-if="isLoading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <div v-else class="display-1 text-center d-block mt-15">
      Thank you, your quote has been declined
    </div>
  </div>
</template>

<script>
export default {
  name: 'Decline',
};
</script>
<style scoped>
.hero-row {
  margin: -20px;
  /* margin-left: -18px;
  margin-right: -18px; */
}
.hero-column {
  padding: 0;
}
</style>
