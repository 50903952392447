<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormEmailQuote
      :EmailQuote="editEmailQuote"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormEmailQuote from '../components/FormEmailQuote.vue';

export default {
  components: {
    FormEmailQuote,
  },
  name: 'EditEmailQuote',
  module: 'EmailQuote',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing EmailQuote. Default to {}.
      */
      editEmailQuote: {},
      /**
       * Query Id for EmailQuote
       */
      emailQuoteId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('emailQuotes', ['emailQuotes', 'emailQuote']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.emailQuoteId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_emailQuote(this.emailQuoteId);
      this.editEmailQuote = this.emailQuote;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('emailQuotes', ['LOAD_emailQuote', 'CREATE_emailQuote', 'UPDATE_emailQuote']),
    /** Method used to save the item
     */
    async save(emailQuote) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_emailQuote(emailQuote);
      } else {
        await this.UPDATE_emailQuote(emailQuote);
      }
      this.isSaving = false;
      this.$router.push({ name: 'email-quote-ListEmailQuote' });
    },
  },
};
</script>

<docs>
# EditEmailQuote example

'''js
<EditEmailQuote :EmailQuote="EmailQuote" />
'''
</docs>
