<template>
  <v-text-field
    v-bind="$props"
    v-model="textFieldValue"
    :data-cy="dataCy"
    :rules="rules"
    @input="onInput"
    @change="onChange"
    @focus="onFocus"
  >
    <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'NullableNumberField',
  extends: VTextField,
  props: {
    rules: {
      default: () => [],
    },
    dataCy: {
      type: String,
      default: 'NullableNumberField',
    },
  },
  watch: {
    value() {
      this.textFieldValue = this.value;
      this.localValue = this.value;
    },
  },
  data() {
    return {
      textFieldValue: null,
      localValue: this.value,
    };
  },
  created() {
    this.textFieldValue = this.formatNumber(this.value);
  },
  mounted() {
    this.textFieldValue = this.formatNumber(this.value);
  },
  methods: {
    onChange() {
      this.textFieldValue = this.formatNumber(this.textFieldValue);
    },
    onFocus() {
      this.textFieldValue = this.localValue;
    },
    onInput() {
      if (this.textFieldValue === '') {
        this.$emit('input', null);
      } else {
        this.localValue = parseFloat(this.textFieldValue);
        this.$emit('input', this.localValue);
      }
    },
  },
};
</script>
