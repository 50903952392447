import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('agreementAttachment', 'agreementAttachments');

const customActions = {
  async LOAD_agreementAttachments({ commit, rootGetters }, agreementId) {
    const data = await odata.o(rootGetters)
      .get('agreementAttachments')
      .query({ $filter: `IsDeleted eq false AND AgreementId eq ${agreementId}` });
    commit('SET_agreementAttachments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
