<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Integration Settings
      </v-card-title>
      <v-card-text>
        <TableIntegrationSettings
          value-label="URL"
        />
      </v-card-text>
    </v-card>`
  </v-container>
</template>

<script>
import TableIntegrationSettings from '../components/TableIntegrationSettings.vue';

export default {
  name: 'IntegrationSettings',
  components: {
    TableIntegrationSettings,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.integrationSettings',
      }],
  },
  data() {
    return {
      activeTab: 'General',
      tabs: ['Accounts', 'Company Targets', 'Revenue Lines', 'Xero Integration'],
      businessType: null,
      businessTypes: ['Sales', 'Retail'],
      incomeType: null,
      incomeTypes: ['Cash', 'Invoices'],
      timeZone: null,
      timeZones: ['UTC', 'GMT', 'EST', 'PST'],
      numEmployees: null,
    };
  },
};
</script>
