<template>
  <v-card>
    <v-card-title primary-title>
      Usage Report
    </v-card-title>
    <v-card-text>
      <ReportFilters @refresh="refresh" />
      <TableTopUsings />
      <GraphTopFeatures />
      <GraphFeedbackItems />
      <GraphTopDestinations />
    </v-card-text>
  </v-card>
</template>

<script>
import ReportFilters from '../components/ReportFilters.vue';
import GraphFeedbackItems from '../components/GraphFeedbackItems.vue';
import GraphTopDestinations from '../components/GraphTopDestinations.vue';
import GraphTopFeatures from '../components/GraphTopFeatures.vue';
import TableTopUsings from '../components/TableTopUsings.vue';

export default {
  name: 'UserNavigation',
  components: {
    ReportFilters,
    GraphTopDestinations,
    GraphTopFeatures,
    GraphFeedbackItems,
    TableTopUsings,
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      this.$store.dispatch('telemetry/LOAD_navigationRoutes');
      this.$store.dispatch('telemetry/LOAD_features');
      this.$store.dispatch('feedbackItems/LOAD_feedbackItems');
    },
  },
};
</script>
