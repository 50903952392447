export default {
  currencySymbol: (state) => {
    const userLang = navigator.language || navigator.userLanguage;
    const currency = state.selectedCurrency ?? 'ZAR';
    if (state.selectedCurrency === 'ZAR') return 'R';
    const res = new Intl.NumberFormat(userLang, { style: 'currency', currency })
      .formatToParts(1)
      .find((x) => x.type === 'currency')
      .value;
    return res;
  },
};
