import { sortBy } from 'lodash';

export default {
  getExchangeRate: (state) => state.exchangeRate,
  currencies: (state) => state.allCurrencies.map((c) => ({
    code: c.code,
    name: c.name,
  })),
  sortedCurrencies: (state, getters) => sortBy(getters.currencies, 'name'),
};
