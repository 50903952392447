<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="-1"
      dense
      :search="search"
      sort-by="contractNumber"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
            <div class="text-h5 ml-2">Manage Contracts</div>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="statuses"
              data-cy="Contract-status"
              v-model="contractStatus"
              label="Filter by status"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :items="contractTypes"
              data-cy="Contract-contractType"
              v-model="contractType"
              label="Filter by contract type"
              clearable
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
              clearable
              class="mr-2"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="1">
            <v-btn small color="secondary" @click="refresh" class="mt-5">
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.contract.contractNumber="{ item }">
        <router-link :to="{ name: 'contracts-EditContract', query: { id: item.contract.id } }">
          {{ item.contract.contractNumber }}
        </router-link>
      </template>
    <template v-slot:item.contract.status="{ item }">
      <v-edit-dialog
        :return-value.sync="item.contract.status"
        large
        @save="saveContract(item.contract)"
      >
        {{ item.contract.status }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="statuses"
            v-model="item.contract.status"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
      <template v-slot:item.contract.contractType="{ item }">
        {{ getContractType(item.contract.contractType) }}
      </template>
      <template v-slot:item.total="{ item }">
        {{ formatCurrency(item.total, item.currency) }}
      </template>
      <template v-slot:item.start="{ item }">
        {{ item.start }}
      </template>
      <template v-slot:item.repeatingInvoiceId="props">
        <v-edit-dialog
          :return-value.sync="props.item.repeatingInvoiceId"
          large
          @save="updateSection(props.item)"
        >
          {{ getRepeatingInvoice(props.item.repeatingInvoiceId) }}
          <template v-slot:input>
            <v-autocomplete
              :item-text="i => `${i.reference}: ${i.contact.name}`"
              item-value="id"
              :items="xeroRepeatingInvoices"
              v-model="props.item.repeatingInvoiceId"
              label="Edit"
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.invoiceID="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceID"
        large
        @save="updateSection(item)"
      >
        {{ getInvoice(item.invoiceId) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-autocomplete
            clearable
            :items="sortedInvoices"
            v-model="item.invoiceId"
            :item-text="i =>
              `${formatDate(i.date)}: ${i.invoiceNumber} - ${i.reference}`
              "
            item-value="invoiceID"
            label="Invoice Number">
          </v-autocomplete>
        </template>
      </v-edit-dialog>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiPencilBoxOutline,
} from '@mdi/js';
import dayjs from 'dayjs';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import currencies from '~src/mixins/currencies';
import contractTypes from '../components/contractTypes.json';

export default {
  name: 'ManageContracts',
  module: 'Contract',
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contracts',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiPencilBoxOutline,
      mdiRefresh,
      /** Filters
       */
      contractStatus: undefined,
      contractType: undefined,
      contractTypes,
      search: '',
      /** items
       */
      contracts: [],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('xeroRepeatingInvoices', ['xeroRepeatingInvoices']),
    ...mapState('xeroInvoices', ['xeroInvoices']),
    sortedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.xeroInvoices
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reverse();
    },
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('contractNumber'),
          value: 'contract.contractNumber',
          width: '110px',
        },
        {
          text: this.$$t('contractType'),
          value: 'contract.contractType',
          width: '110px',
        },
        {
          text: 'Status',
          value: 'contract.status',
        },
        {
          text: 'Section Title',
          value: 'title',
        },
        {
          text: this.$$t('contact'),
          value: 'contract.contactName',
        },
        {
          text: 'Implementation Date',
          value: 'start',
          width: '110px',
        },
        {
          text: 'Repeating Invoice',
          value: 'repeatingInvoiceId',
        },
        {
          text: 'Invoice',
          value: 'invoiceID',
        },
      ];
    },
    statuses() {
      return [{
        text: 'Draft',
        value: 'Draft',
        disabled: false,
      }, {
        text: 'Certain',
        value: 'Certain',
        disabled: false,
      }, {
        text: 'Accepted',
        value: 'Accepted',
        disabled: false,
      }];
    },
    items() {
      const { contractType, contractStatus, contracts } = this;
      let result = contracts;
      if (contractType) {
        result = contracts.filter((c) => c.contract.contractType === contractType);
      }
      if (contractStatus) {
        result = result.filter((c) => c.contract.status === contractStatus);
      }
      result = result.map((c) => {
        const r = { ...c };
        const lineItemsWithStartDate = r.lineItems.filter((l) => l.start !== null);

        if (lineItemsWithStartDate.length === 0) {
          r.start = 'No Date';
        } else {
          const newestStartDate = lineItemsWithStartDate.reduce((a, b) => {
            const aDate = dayjs(a.start);
            const bDate = dayjs(b.start);
            return aDate.isAfter(bDate) ? a : b;
          }).start;

          r.start = dayjs(newestStartDate).format('YYYY-MM-DD');
        }

        return r;
      });

      return result;
    },
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('Contract:updated', this.refresh);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('contracts', ['LOAD_sectionsWithContract', 'UPDATE_contract']),
    ...mapActions('contractSections', ['UPDATE_contractSection']),
    ...mapActions('xeroRepeatingInvoices', ['LOAD_xeroRepeatingInvoices']),
    ...mapActions('xeroInvoices', ['LOAD_MIN_xeroInvoices']),
    getContractType(contractType) {
      return contractTypes.find((c) => c.value === contractType)?.text;
      // item.contractType === 'ACCREC' ? 'Money In' : 'Money Out'
    },
    getRepeatingInvoice(id) {
      const invoice = this.xeroRepeatingInvoices.find((i) => i.id === id);
      if (invoice) {
        return `${invoice.reference}: ${invoice.contact.name}`;
      }
      return '';
    },
    getInvoice(id) {
      const invoice = this.xeroInvoices.find((i) => i.invoiceID === id);
      if (invoice) {
        return `${this.formatDate(invoice.date)}: ${invoice.invoiceNumber} - ${invoice.reference}`;
      }
      return '';
    },
    /** Delete Contract from the collection
     */
    async remove(contract) {
      const result = await this.$confirm('Are you sure you want to delete this contract?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_contract(contract.id);
        this.isLoading = false;
      }
    },
    async refresh() {
      this.$log.debug('refreshing contracts');
      this.isLoading = true;
      await Promise.all([
        (async () => {
          this.contracts = await this.LOAD_sectionsWithContract();
        })(),
        (async () => {
          await this.LOAD_xeroRepeatingInvoices();
        })(),
        (async () => {
          await this.LOAD_MIN_xeroInvoices();
        })(),
      ]);
      this.isLoading = false;
    },
    async saveContract(contract) {
      this.isLoading = true;
      await this.UPDATE_contract(contract);
      this.isLoading = false;
    },
    async updateSection(section) {
      this.isLoading = true;
      const updatedSection = { ...section };
      delete updatedSection.contract;
      delete updatedSection.lineItems;
      delete updatedSection.start;
      await this.UPDATE_contractSection(updatedSection);
      this.isLoading = false;
    },
    getEarliestDate(arrOfDates) {
      let earliestDate = dayjs(arrOfDates[0]);
      arrOfDates.forEach((date) => {
        if (dayjs(date).isBefore(earliestDate)) {
          earliestDate = dayjs(date);
        }
      });
      return earliestDate;
    },
    getImplementationDate(item) {
      const estimatedDeliveryDates = item.lineItems
        .map((li) => li.start);
      const implementationDate = this.getEarliestDate(estimatedDeliveryDates);
      return this.formatDate(implementationDate);
    },
  },
};
</script>
