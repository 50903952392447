<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
  >
    <v-card v-if="Agreement">
      <v-card-title>Print {{ Agreement.AgreementNumber }}</v-card-title>
      <v-card-text>
        <!-- <v-checkbox label="Include VAT" v-model="includeVat"></v-checkbox> -->
        <!-- <v-checkbox label="Include first and last page" v-model="includeFirstPage">
          </v-checkbox> -->
        <v-select
          :items="agreementTemplates"
          item-value="id"
          item-text="name"
          v-model="quoteTemplateId"
          label="Template"
        ></v-select>
        <v-select
          :items="items"
          v-model="pdf"
          label="Format"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="download(url)">
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mdiPrinter } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ModalPrint',
  mixins: [loading],
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    text: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      mdiPrinter,
      isPrinting: false,
      quoteTemplateId: undefined,
      dialog: false,
      pdf: false,
      includeFirstPage: true,
      items: [{
        value: true,
        text: 'PDF',
      },
      {
        value: false,
        text: 'Word',
      },
      ],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapGetters('quoteTemplates', ['agreementTemplates']),
    ...mapState('companySettings', ['companySettings']),
    url() {
      const {
        Agreement, pdf, quoteTemplateId,
      } = this;
      // eslint-disable-next-line max-len
      return `${process.env.VUE_APP_APIURL}/print/agreement/${Agreement.id}?templateId=${quoteTemplateId}&pdf=${pdf}`;
    },
    filename() {
      const { Agreement, pdf } = this;
      // eslint-disable-next-line max-len
      return `Agreement-${Agreement.agreementNumber}-${Agreement.reference}.${pdf ? 'pdf' : 'docx'}`;
    },
    defaultTemplate: {
      get() {
        return this.$store.state.app.defaultTemplate;
      },
      set(value) {
        this.$store.commit('app/SET_defaultTemplate', value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    const templateId = this.companySettings.find(
      (setting) => setting.name === 'defaultTemplateId',
    )?.value;
    if (!this.defaultTemplate) {
      this.quoteTemplateId = templateId;
    }
    this.isLoading = false;
  },
  methods: {
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
    async download(uri) {
      this.dialog = false;
      this.isPrinting = true;

      // Perform the file download here
      // For example, you can use the following code to download a file from a URL:
      const response = await fetch(uri);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = this.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      // Set the loading state to false once the file has finished downloading
      this.isPrinting = false;
    },
  },
};
</script>
