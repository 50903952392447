<!-- eslint-disable max-lines -->
<template>
  <v-form lazy-validation v-model="valid" v-if="localQuote" ref="form">
    <!-- header -->
    <v-row>
      <v-col cols="12" xl="3" lg="3" md="6">
        <v-autocomplete
          auto-select-first
          data-cy="FormQuote-contactId"
          :disabled="disabled"
          filled
          :label="$$t('contactId')"
          :items="customerContacts"
          :rules="requiredFieldRules"
          item-value="id"
          item-text="name"
          @change="onContactChange"
          v-model="localQuote.contactId"
        >
          <template v-slot:item="{ item }">
            <v-list-item-icon>
              <v-img
                v-if="item.source === 'xero'"
                src="../../../../assets/icons8-xero-24.png"
                width="24"
                height="24"
              />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <div v-if="item.addresses !== null">
                  <div v-for="(address, index) in item.addresses" :key="index">
                    <div>
                      <span v-if="address.addressLine1">{{ address.addressLine1 }}, </span>
                      <span v-if="address.addressLine2">{{ address.addressLine2 }}, </span>
                      <span v-if="address.addressLine3">{{ address.addressLine3 }}, </span>
                      <span v-if="address.addressLine4">{{ address.addressLine4 }}, </span>
                      <span v-if="address.city">{{ address.city }}, </span>
                      <span v-if="address.region">{{ address.region }}, </span>
                      <span v-if="address.postalCode">{{ address.postalCode }}, </span>
                      <span v-if="address.country">{{ address.country }}</span>
                    </div>
                  </div>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="12">
        <v-select
          filled
          data-cy="FormQuote-personId"
          :disabled="disabled"
          :label="$$t('person')"
          :rules="requiredFieldRules"
          :items="availablePeople"
          :item-text="(c) => `${c.firstName} ${c.lastName} (${c.emailAddress})`"
          item-value="id"
          v-model="localQuote.personId"
          @change="personUpdated"
        >
          <template v-slot:append-outer v-if="!disabled">
            <ModalContact
              v-if="localQuote.contactId"
              :contactId="localQuote.contactId"
              @refresh="$emit('refresh')"
            />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6">
        <v-autocomplete
          auto-select-first
          data-cy="FormQuote-currencyCode"
          :disabled="disabled"
          filled
          :label="$$t('currencyCode')"
          :items="currencies"
          item-text="name"
          item-value="code"
          v-model="localQuote.currencyCode"
        ></v-autocomplete>
      </v-col>
      <!-- <v-col cols="12" xl="2" lg="2" md="6">
        <v-select
          data-cy="FormQuote-stage"
          filled
          :label="$$t('stage')"
          :items="stages"
          v-model="localQuote.stage"
        ></v-select>
      </v-col> -->
      <v-col cols="12" xl="2" lg="2" md="6">
        <v-select
          :disabled="
            disabled &&
            (localQuote.status === 'Accepted' ||
              localQuote.status === 'Delivered' ||
              localQuote.status === 'In Progress') &&
            !isQuoteAdmin
          "
          data-cy="FormQuote-status"
          filled
          :label="$$t('status')"
          :items="userStatuses"
          v-model="localQuote.status"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="12">
        <v-select
          :disabled="disabled"
          filled
          :items="quoteTemplates"
          v-model="localQuote.templateId"
          item-text="name"
          item-value="id"
          label="Template"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" lg="6" md="12">
        <v-text-field
          filled
          data-cy="FormQuote-reference"
          :disabled="disabled"
          :label="$$t('reference')"
          v-model="localQuote.reference"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="12">
        <v-text-field
          filled
          data-cy="FormQuote-poNumber"
          :disabled="disabled"
          :label="$$t('poNumber')"
          v-model="localQuote.poNumber"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6">
        <DatePicker
          data-cy="FormQuote-date"
          :disabled="disabled"
          label="Quote Date"
          v-model="localQuote.date"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6">
        <DatePicker
          data-cy="FormQuote-expiryDate"
          :label="$$t('expiryDate')"
          v-model="localQuote.expiryDate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3 class="primary--text">Overview</h3>
        <div v-if="disabled" v-html="localQuote.description" />
        <vue-editor
          v-else
          filled
          :editorToolbar="customToolbar"
          data-cy="FormQuote-description"
          :disabled="disabled"
          :label="$$t('description')"
          v-model="localQuote.description"
          rows="4"
        />
      </v-col>
    </v-row>
    <!-- rows -->
    <v-row>
      <v-col cols="12">
        <QuoteSections :value="localQuote" @updated:line="updateTotals">
          <template v-slot:bottom>
            <ExchangeRates v-model="localQuote" :allLineItems="allLineItems" :disabled="disabled" />
          </template>
        </QuoteSections>
      </v-col>
    </v-row>
    <!-- /rows -->
    <!-- exchange rates -->
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <!-- /exchange rates -->
    <!-- totals -->
    <v-row>
      <v-col cols="6">
        <h3 class="primary--text">Notes</h3>
      </v-col>
      <v-col cols="3">
        <h3 class="primary--text">Non-recurring</h3>
      </v-col>
      <v-col cols="3">
        <h3 class="primary--text">Monthly Recurring</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div v-if="disabled" v-html="localQuote.notes" />
        <vue-editor
          v-else
          :editorToolbar="customToolbar"
          :disabled="disabled"
          label="Notes"
          outlined
          v-model="localQuote.notes"
          counter="2000"
        >
        </vue-editor>
      </v-col>
      <v-col cols="6">
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-subTotal"
              :disabled="disabled"
              :label="$$t('subTotal')"
              readonly
              :value="formatNumber(nrcSubTotal)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-subTotal"
              :disabled="disabled"
              :label="$$t('subTotal')"
              readonly
              :value="formatNumber(mrcSubTotal)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-totalNrcTax"
              :disabled="disabled"
              :label="$$t('totalTax')"
              readonly
              :value="formatNumber(totalNrcTax)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-totalMrcTax"
              :disabled="disabled"
              :label="$$t('totalTax')"
              readonly
              :value="formatNumber(totalMrcTax)"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-totalNrc"
              :disabled="disabled"
              :label="$$t('total')"
              readonly
              :value="formatNumber(totalNrc)"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              filled
              data-cy="FormQuote-totalMrc"
              :disabled="disabled"
              :label="$$t('total')"
              readonly
              :value="formatNumber(totalMrc)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- /totals -->
    <!-- buttons -->
    <div class="d-flex justify-end mb-6">
      <!-- <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
      :loading="isSaving"
        data-cy="FormQuote-button-cancel">
        {{ $t('common.buttons.cancel') }}
      </v-btn> -->
      <v-btn
        color="primary"
        type="button"
        class="ml-4"
        :loading="isSaving"
        :disabled="disabled"
        @click="save('save')"
        data-cy="FormQuote-button-save"
      >
        {{ $t('common.buttons.save') }}
      </v-btn>
      <v-btn
        color="primary"
        type="button"
        class="ml-4"
        :loading="isSaving"
        :disabled="disabled"
        @click="save('close')"
        data-cy="FormQuote-button-close"
      >
        {{ $t('common.buttons.saveClose') }}
      </v-btn>
    </div>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
    <!-- /buttons -->
  </v-form>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapState, mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import { mdiPlus, mdiDelete } from '@mdi/js';
import clients from '~src/mixins/contacts';
import requiredField from '~src/mixins/required-field';
import ExchangeRates from './ExchangeRates.vue';
import LineHeading from './LineHeading.vue';
import ModalContact from './ModalContact.vue';
import QuoteSections from './QuoteSections.vue';
import DatePicker from '../../../../components/DatePicker.vue';
import { eventBus } from '../../../../main';

export default {
  components: {
    DatePicker,
    ExchangeRates,
    // eslint-disable-next-line vue/no-unused-components
    LineHeading,
    ModalContact,
    QuoteSections,
    VueEditor,
  },
  name: 'FormQuote',
  module: 'Quote',
  mixins: [clients, requiredField],
  props: {
    /**
     * Set the text box value. Default: undefined,
     */
    Quote: {
      type: Object,
      default: undefined,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiPlus,
      mdiDelete,
      newLineItems: [3, 5, 10, 20],
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      /**
       * Hold form validation state
       */
      valid: false,
      /**
       * Local value used for property localQuote
       */
      localQuote: undefined,
      taxRates: [],
      allLineItems: [],
    };
  },
  computed: {
    ...mapState('people', ['people']),
    ...mapState('quotes', ['statuses', 'stages']),
    ...mapState('quoteSections', ['quoteSections']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
    ...mapState('quoteTemplates', ['quoteTemplates']),
    ...mapGetters('exchangeRates', ['currencies']),
    ...mapGetters('auth', ['isInRole']),
    mrcLineItems() {
      return this.allLineItems.filter(
        (line) => line.lineType === 'Monthly Recurring Cost' && !line.isDeleted,
      );
    },
    nrcLineItems() {
      return this.allLineItems.filter(
        (line) => line.lineType === 'Non-Recurring Cost' && !line.isDeleted,
      );
    },
    availablePeople() {
      return this.people.filter((p) => p.contactId === this.localQuote.contactId) ?? [];
    },
    disabled() {
      return (
        this.localQuote?.status === 'Accepted' ||
        this.localQuote?.status === 'Declined' ||
        this.localQuote?.status === 'Delivered' ||
        this.localQuote?.status === 'In Progress' ||
        this.localQuote?.status === 'Invoiced' ||
        this.localQuote?.status === 'Paid' ||
        this.localQuote?.status === 'In Xero'
      );
    },
    date: {
      get() {
        return this.formatDate(this.localQuote.date);
      },
      set(newValue) {
        this.localQuote.date = dayjs(newValue).toISOString();
      },
    },
    expiryDate: {
      get() {
        return this.formatDate(this.localQuote.expiryDate);
      },
      set(newValue) {
        this.localQuote.expiryDate = dayjs(newValue).toISOString();
      },
    },
    isQuoteAdmin() {
      return this.isInRole('Quotes Admin');
    },
    userStatuses() {
      return this.statuses
        .filter((status) => {
          if (
            (status === 'Accepted' && this.localQuote.status !== 'Accepted') ||
            (status === 'In Progress' && this.localQuote.status !== 'In Progress')
          ) {
            return false;
          }
          if (status === 'Declined' || status === 'Upgraded') {
            return this.isQuoteAdmin;
          }
          return true;
        })
        .map((s) => ({
          text: s,
          value: s,
          disabled:
            (s === 'Invoiced' || s === 'In Xero' || s === 'Paid') &&
            this.xeroOrganisations.length > 0,
        }));
    },
    nrcSubTotal() {
      const { nrcLineItems } = this;
      let subTotal = 0;
      nrcLineItems.forEach((lineItem) => {
        if (lineItem.quantity && lineItem.unitAmount) {
          subTotal += parseFloat((lineItem.quantity * lineItem.unitAmount).toFixed(2), 10);
        }
      });
      return subTotal;
    },
    mrcSubTotal() {
      const { mrcLineItems } = this;
      let subTotal = 0;
      mrcLineItems.forEach((lineItem) => {
        if (lineItem.quantity && lineItem.unitAmount) {
          subTotal += parseFloat((lineItem.quantity * lineItem.unitAmount).toFixed(2), 10);
        }
      });
      return subTotal;
    },
    subTotal() {
      return this.nrcSubTotal + this.mrcSubTotal;
    },
    totalNrcTax() {
      const { nrcLineItems } = this;
      let totalTax = 0;
      nrcLineItems.forEach((lineItem) => {
        if (lineItem.taxAmount) {
          totalTax += parseFloat(lineItem.taxAmount.toFixed(2), 10);
        }
      });
      return totalTax;
    },
    totalMrcTax() {
      const { mrcLineItems } = this;
      let totalTax = 0;
      mrcLineItems.forEach((lineItem) => {
        if (lineItem.taxAmount) {
          totalTax += parseFloat(lineItem.taxAmount.toFixed(2), 10);
        }
      });
      return totalTax;
    },
    totalTax() {
      const { totalMrcTax, totalNrcTax } = this;
      return totalMrcTax + totalNrcTax;
    },
    total() {
      const { subTotal, totalTax } = this;
      return subTotal + totalTax;
    },
    totalMrc() {
      return this.totalMrcTax + this.mrcSubTotal;
    // eslint-disable-next-line max-lines
    },
    totalNrc() {
      return this.totalNrcTax + this.nrcSubTotal;
    },
    /**
     * Returns the taxAmount property
     */
    taxRate() {
      const { taxRates } = this;
      const { orgTaxRate } = this.localQuote;
      if (orgTaxRate && taxRates) {
        const taxTypeItem = taxRates.find((t) => t.taxType === orgTaxRate);
        return taxTypeItem?.effectiveRate ?? 0;
      }
      return 0;
    },
  },
  watch: {
    Quote() {
      this.localQuote = this.Quote;
    },
    localQuote: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localQuote);
      },
    },
  },
  async created() {
    this.localQuote = this.Quote;
  },
  methods: {
    ...mapActions('sectionLineItems', ['LOAD_allQuoteSectionLines']),
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    async save(name) {
      // validate
      if (this.$refs.form.validate()) {
        const { localQuote, total, totalTax, subTotal } = this;
        const quote = { ...localQuote };
        quote.total = total;
        quote.totalTax = totalTax;
        quote.subTotal = subTotal;
        this.$emit(name, quote);
      }
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    close() {
      // validate
      if (this.$refs.form.validate()) {
        const { localQuote, total, totalTax, subTotal } = this;
        const quote = { ...localQuote };
        quote.total = total;
        quote.totalTax = totalTax;
        quote.subTotal = subTotal;
        this.$emit('close', quote);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel', this.localQuote);
    },
    personUpdated(clientId) {
      const { people } = this;
      const person = people.find((c) => c.id === clientId);
      this.localQuote.person = person?.name ?? '';
    },
    onContactChange(contactId) {
      const contact = this.allContacts.find((c) => c.id === contactId);
      this.localQuote.contactSource = contact.source;
      this.localQuote.contact = contact.name;
    },

    onEventBusValidate(callback) {
      if (this.$refs.form && !this.$refs.form.validate()) {
        if (callback) {
          callback();
        }
      }
    },

    async updateTotals() {
      this.$log.debug('loading all quote section lines');
      this.allLineItems = await this.LOAD_allQuoteSectionLines(this.localQuote.id);
    },
  },
  mounted() {
    eventBus.$on('onEventBusValidate', this.onEventBusValidate);
  },
};
</script>

<docs>
# FormQuote example

'''js
<FormQuote :Quote="Quote" />
'''
</docs>
