import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('xeroContact', 'xeroContacts');

const customActions = {
  async LOAD_xeroContact({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroContacts')
      .query({ $filter: 'IsDeleted eq false AND ContactStatus eq \'ACTIVE\'' });
    commit('SET_xeroContacts', data);
    return data;
  },
  async LOAD_xeroClients({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroContacts')
      .query({
        $filter: 'IsDeleted eq false AND IsCustomer eq true AND ContactStatus eq \'ACTIVE\'',
        $expand: 'Addresses($filter=AddressType eq \'POBOX\')',
      });
    commit('SET_xeroClients', data);
    return data;
  },
  async LOAD_xeroContacts({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroContacts')
      .query({
        $filter: 'IsDeleted eq false AND ContactStatus eq \'ACTIVE\'',
        $expand: 'Addresses($filter=AddressType eq \'POBOX\')',
      });
    commit('SET_xeroContacts', data);
    return data;
  },
  async LOAD_xeroSuppliers({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroContacts')
      .query({
        $filter: 'IsDeleted eq false AND IsSupplier eq true AND ContactStatus eq \'ACTIVE\'',
        $expand: 'Addresses($filter=AddressType eq \'POBOX\')',
      });
    commit('SET_xeroSuppliers', data);
    return data;
  },
};

export default { ...actions, ...customActions };
