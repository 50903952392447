<!-- eslint-disable max-len -->
<template>
  <div>
    <p>The bookmarks used in the Word document are as follows:</p>

<ul>
  <li>Quote &laquo;QuoteNumber&raquo;: This bookmark represents the quote number associated with the document.</li>
  <li>&laquo;CustomerName&raquo;: This bookmark is used to input the name of the customer.</li>
  <li>&laquo;Reference&raquo;: This bookmark is used to input a reference or identifier related to the quote.</li>
  <li>Prepared for: &laquo;ContactName&raquo;: This bookmark is used to input the name of the person or entity for whom the quote is prepared.</li>
  <li>&laquo;ContactTelephone&raquo;: This bookmark is used to input the telephone number of the contact person or entity.</li>
  <li>&laquo;ContactEmail&raquo;: This bookmark is used to input the email address of the contact person or entity.</li>
  <li>Prepared By: &laquo;UserName&raquo;: This bookmark is used to input the name of the person who prepared the quote.</li>
  <li>&laquo;UserTelephone&raquo;: This bookmark is used to input the telephone number of the person who prepared the quote.</li>
  <li>&laquo;UserEmail&raquo;: This bookmark is used to input the email address of the person who prepared the quote.</li>
</ul>

<p>The document also includes an overview section with a description field represented by the bookmark &laquo;Description&raquo;.</p>

<p>Lastly, there is a section for notes and terms, represented by the bookmark &laquo;Notes&raquo;. This section is used to provide additional information, terms, or conditions related to the quote.</p>

  </div>
</template>

<script>
export default {
  name: 'QuoteTemplateContent',
};
</script>
