<template>
  <div v-if="localContract">
    <v-data-table
      :headers="headers"
      :items="localContract.contractItems"
      :loading="isLoading"
    >
      <template v-slot:item.matchingContractItemId="{ item }">
        <v-select
          :items="contractLineItems"
          item-text="description"
          item-value="id"
          v-model="item.matchingContractItemId"
        ></v-select>
      </template>
      <template v-slot:item.invoiceFrequency="{ item }">
        <v-select
          data-cy="TableMatchContracts-invoiceFrequency"
          label="Invoice Frequency"
          :items="frequencies"
          v-model="item.invoiceFrequency"
        ></v-select>
      </template>
      <template v-slot:item.accounting="{ item }">
        <v-row>
          <v-col>
            <v-autocomplete
              auto-select-first
              data-cy="TableMatchContracts-accountId"
              v-model="item.accountId"
              :items="incomeAccounts"
              item-text="name"
              item-value="id"
              label="Income Account"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              auto-select-first
              :items="sortedRepeatingInvoices"
              v-model="item.repeatingInvoiceID"
              item-text="reference"
              item-value="repeatingInvoiceID"
              label="Repeating Invoice"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              auto-select-first
              :items="getRepeatingInvoice(item.repeatingInvoiceID).lineItems"
              v-model="item.repeatingInvoiceLineItemID"
              item-text="description"
              item-value="lineItemID"
              label="Repeating Invoice Line"
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="!item.repeatingInvoiceLineItemID"
                  small
                  color="primary"
                  @click="createRepeatingInvoiceLine(item, 'ACCREC')"
                >
                  Create
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              auto-select-first
              data-cy="TableMatchContracts-accoubillAccountIdntId"
              v-model="item.billAccountId"
              :items="expenseAccounts"
              item-text="name"
              item-value="id"
              label="Billing Account"
            />
          </v-col>
          <v-col>
            <v-autocomplete
              auto-select-first
              :items="sortedRepeatingInvoices"
              v-model="item.repeatingBillID"
              item-text="reference"
              item-value="repeatingBillID"
              label="Repeating Bill"
            >
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-autocomplete
              auto-select-first
              :items="getRepeatingInvoice(item.repeatingInvoiceID).lineItems"
              v-model="item.repeatingInvoiceLineItemID"
              item-text="description"
              item-value="lineItemID"
              label="Repeating Bill Line"
            >
              <template v-slot:append-outer>
                <v-btn
                  v-if="!item.repeatingInvoiceLineItemID"
                  small
                  color="primary"
                  @click="createRepeatingInvoiceLine(item, 'ACCPAY')"
                >
                  Create
                </v-btn>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="primary"
          @click="saveLine(item)"
        >
          Save
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sortBy } from 'lodash';
import { mapActions, mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import accounts from '../../../contracts/src/mixins/accounts';
import frequencies from '../../../contracts/src/components/frequencies.json';

export default {
  name: 'TableMatchContracts',
  props: {
    contract: {
      type: Object,
      default: undefined,
    },
    contracts: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [loading, accounts],
  data() {
    return {
      frequencies,
      // options: [
      //   { text: 'Money In', value: 'moneyIn' },
      //   { text: 'Money Out', value: 'moneyOut' },
      // ],
      localContract: undefined,
      // selectedContract: 'moneyIn',
    };
  },
  watch: {
    contract() {
      this.localContract = this.contract;
    },
  },
  computed: {
    ...mapState('xeroRepeatingInvoices', ['xeroRepeatingInvoices']),
    expenseAccounts() {
      return this.allAccounts.filter(
        (account) => account.type === 'EXPENSE',
      );
    },
    incomeAccounts() {
      return this.allAccounts.filter(
        (account) => account.type === 'REVENUE',
      );
    },
    headers() {
      return [
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Quantity',
          value: 'quantity',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Matched Line Item',
          value: 'matchingContractItemId',
        },
        {
          text: 'Accounting',
          value: 'accounting',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    repeatingInvoices() {
      return this.xeroRepeatingInvoices.filter((i) => i.type === this.localContract.contractType);
    },
    sortedRepeatingBill() {
      return [{
        reference: 'New Repeating Invoice',
        repeatingInvoiceID: null,
      }, ...sortBy(this.repeatingInvoices, 'reference')];
    },
    sortedRepeatingInvoices() {
      return [{
        reference: 'New Repeating Invoice',
        repeatingInvoiceID: null,
      }, ...sortBy(this.repeatingInvoices, 'reference')];
    },
    contractLineItems() {
      const arr = this.contracts.map((c) => c.contractItems);
      const result = [].concat(...arr);
      return result;
    },
    repeatingInvoice() {
      return this.repeatingInvoices
        .find((r) => r.repeatingInvoiceID === this.localContract.repeatingInvoiceID)
        ?? { lineItems: [] };
    },
  },
  async mounted() {
    this.localContract = this.contract;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('contractItems', ['UPDATE_contractItem']),
    ...mapActions('xeroRepeatingInvoices', ['CREATE_xeroRepeatingInvoice', 'UPDATE_xeroRepeatingInvoice']),
    ...mapMutations('xeroRepeatingInvoices', ['SET_xeroRepeatingInvoices']),
    getContractLine(matchingContractItemId) {
      return this.contractLineItems.find((c) => c.id === matchingContractItemId) ?? {};
    },
    getRepeatingInvoice(repeatingInvoiceID) {
      const result = this.repeatingInvoices.find((r) => r.repeatingInvoiceID === repeatingInvoiceID)
        ?? {};
      return result;
    },
    getRepeatingInvoiceLine(repeatingInvoiceID, repeatingInvoiceLineItemID) {
      const result = this.repeatingInvoices
        .find((r) => r.repeatingInvoiceID === repeatingInvoiceID)
        ?.lineItems.find((l) => l.lineItemID === repeatingInvoiceLineItemID)
        ?? {};
      return result;
    },
    async saveLine(line) {
      this.isLoading = true;
      const {
        id,
        accountId,
        matchingContractItemId,
        repeatingInvoiceID,
        repeatingInvoiceLineItemID,
        invoiceFrequency,
        billAccountId,
        repeatingBillID,
        repeatingBillLineItemID,
      } = line;
      await this.UPDATE_contractItem({
        id,
        accountId,
        matchingContractItemId,
        repeatingInvoiceID,
        repeatingInvoiceLineItemID,
        invoiceFrequency,
        billAccountId,
        repeatingBillID,
        repeatingBillLineItemID,
      });
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },

    getFrequency(frequency) {
      switch (frequency) {
        default:
        case 2:
          return 1;
        case 3:
          return 3;
        case 4:
          return 12;
      }
    },
    async createRepeatingInvoice(item) {
      this.isLoading = true;
      const data = await this.CREATE_xeroRepeatingInvoice({
        contactID: this.localContract.contactId,
        reference: this.localContract.name,
        currencyCode: this.localContract.currencyCode,
        type: this.localContract.contractType,
        schedule: {
          startDate: item.start,
          unit: 'MONTHLY',
          period: this.getFrequency(item.invoiceFrequency),
          dueDate: 1,
        },
        status: 'DRAFT',
        lineItems: [
          {
            description: item.description,
            quantity: item.quantity,
            unitAmount: item.amount,
            accountCode: this.allAccounts.find((a) => a.id === item.accountId).code,
          },
        ],
      });
      const { xeroRepeatingInvoices } = this;
      xeroRepeatingInvoices.push(data);
      this.SET_xeroRepeatingInvoices(xeroRepeatingInvoices);
      this.localContract.contractItems
        .find((c) => c.id === item.id).repeatingInvoiceID = data.repeatingInvoiceID;
      this.isLoading = false;
      this.$emit('created');
    },
    async createRepeatingInvoiceLine(item, contractType) {
      this.isLoading = true;
      this.saveLine(item);
      if (item.repeatingInvoiceID !== null) {
        const repeatingInvoice = this.getRepeatingInvoice(item.repeatingInvoiceID);
        repeatingInvoice.lineItems.push({
          description: item.description,
          quantity: item.quantity,
          unitAmount: item.amount,
          accountCode: this.allAccounts.find((a) => a.id === item.accountId).code,
        });
        const index = this.repeatingInvoices.indexOf(repeatingInvoice);
        const data = await this.UPDATE_xeroRepeatingInvoice(repeatingInvoice);
        const { xeroRepeatingInvoices } = this;
        xeroRepeatingInvoices[index] = data;
        this.SET_xeroRepeatingInvoices(xeroRepeatingInvoices);
        this.isLoading = false;
        this.localContract.contractItems
          .find((c) => c.id === item.id).repeatingInvoiceLineItemID = data.lineItemID;
      } else {
        const account = this.allAccounts.find((a) => a.id === item.accountId);
        const data = await this.CREATE_xeroRepeatingInvoice({
          contactID: this.localContract.contactId,
          reference: this.localContract.name,
          currencyCode: this.localContract.currencyCode,
          type: contractType,
          schedule: {
            startDate: item.start,
            unit: 'MONTHLY',
            period: this.getFrequency(item.invoiceFrequency),
            dueDate: 1,
          },
          status: 'DRAFT',
          lineItems: [
            {
              description: item.description,
              quantity: item.quantity,
              unitAmount: item.amount,
              accountCode: account.code,
            },
          ],
        });
        const { xeroRepeatingInvoices } = this;
        xeroRepeatingInvoices.push(data);
        this.SET_xeroRepeatingInvoices(xeroRepeatingInvoices);
        this.localContract.contractItems
          .find((c) => c.id === item.id).repeatingInvoiceID = data.repeatingInvoiceID;
      }
      this.isLoading = false;
      this.$emit('created');
    },
  },
};
</script>
