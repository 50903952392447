<template>
  <v-container>
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col lg="8">
        <v-card flat class="rounded-0 ma-3 pa-4">
          <v-container>
            <v-row class="mt-3" justify="center" no-gutters>
              <v-col lg="6">
                <v-card flat>
                  <v-card-title class="text-center justify-center py-">
                    <h1 class="font-weight-bold title mb-3 text-capitalize">
                      Password Reset
                    </h1>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                        v-model="username"
                        outlined
                        filled
                        :rules="[rules.required]"
                        label="Email Address"
                        name="username"
                        required
                      />
                    <v-text-field
                        v-model="password"
                        outlined
                        filled
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.passwordMin]"
                        :type="showPassword ? 'text' : 'password'"
                        name="password"
                        label="New Password"
                        counter
                        @click:append="showPassword = !showPassword"
                      />
                    <v-alert type="error" v-for="error in errors" :key="error">
                      {{ error }}
                    </v-alert>
                    <v-btn
                    color="primary"
                    block
                    @click="reset"
                    :loading="isLoading"
                  >
                    Reset
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authMixins from '@codehq/aurora-app-core/src/mixins/auth';

export default {
  name: 'PasswordReset',
  mixins: [authMixins],
  data() {
    return {
      valid: false,
      isLoading: false,
      showPassword: false,
      email: '',
      username: '',
      password: '',
      code: '',
      state: 'ready',
      message: '',
    };
  },
  methods: {
    reset() {
      const self = this;
      const { username, password, code } = this;
      this.errors = [];
      self.$root.$emit('user.resettingPassword');
      this.isLoading = true;
      this.$http.post('/api/account/resetPassword', {
        username,
        password,
        confirmPassword: this.password,
        code,
      }).then(() => {
        this.isLoading = false;
        self.state = 'success';
        self.$root.$emit('user.passwordReset', {
          username: this.username,
          password: this.password,
        });
        this.$router.push({ name: 'auth-Login' });
      })
        .catch((e) => {
          this.isLoading = false;
          self.message = e.response.data.message;
          self.state = 'fail';
          this.errors = [e.response.data.message];
          self.$root.$emit('user.resettingPasswordFailed', e);
        });
    },
  },
  mounted() {
    this.username = this.$route.query.email;
    this.code = this.$route.query.code;
  },
};
</script>
