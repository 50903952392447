<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormExchangeRate
      :ExchangeRate="editExchangeRate"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormExchangeRate from '../components/FormExchangeRate.vue';

export default {
  components: {
    FormExchangeRate,
  },
  name: 'EditExchangeRate',
  module: 'ExchangeRate',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing ExchangeRate. Default to {}.
      */
      editExchangeRate: {},
      /**
       * Query Id for ExchangeRate
       */
      exchangeRateId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('exchangeRates', ['exchangeRates', 'exchangeRate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.exchangeRateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_exchangeRate(this.exchangeRateId);
      this.editExchangeRate = this.exchangeRate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('exchangeRates', ['LOAD_exchangeRate', 'CREATE_exchangeRate', 'UPDATE_exchangeRate']),
    /** Method used to save the item
     */
    async save(exchangeRate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_exchangeRate(exchangeRate);
      } else {
        await this.UPDATE_exchangeRate(exchangeRate);
      }
      this.isSaving = false;
      this.$router.push({ name: 'exchange-rate-ListExchangeRate' });
    },
  },
};
</script>

<docs>
# EditExchangeRate example

'''js
<EditExchangeRate :ExchangeRate="ExchangeRate" />
'''
</docs>
