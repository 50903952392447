<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title>
        <v-tabs v-model="activeTab">
          <v-tab v-for="(tab, index) in tabs" :key="index">
            {{ tab }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text>
        <v-tabs-items v-model="activeTab">
          <v-tab-item v-for="(tab, index) in tabs" :key="index">
            <v-card>
              <v-card-text>
                <template v-if="tab === 'General'">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="companyName" label="Name of Company"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="businessType" :items="businessTypes" label="Type of Business"></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="registrationNumber" label="Registration Number"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field v-model="vatNumber" label="VAT Number"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select v-model="incomeType" :items="incomeTypes" label="Type of Income"></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-select v-model="currency" :items="currencies" label="Default Currency"></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="postalAddress" label="Postal Address"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="physicalAddress" label="Physical Address"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="adminContact" label="Admin Contact"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="emailAddress" label="Email Address"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="phoneNumber" label="Phone Number"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="communicationsAct" label="Communications Act" hint="eg. 405 of the Communications Act 2003">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="valueAddedTaxAct" label="Value Added Tax Act" hint="eg. Value-Added Tax Act 89 of 1991">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="country" label="Country" hint="eg. the United Kingdom, South Africa">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="nationality" label="Nationality" hint="eg. English, South African">
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn color="success" :loading="isSaving" @click="save">Save</v-btn>
                </template>
                <template v-if="tab === 'Subscription'">
                  <CompanySubscription />
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import companySettings from '@/mixins/companySettings';
import CompanySubscription from '../components/CompanySubscription.vue';

export default {
  name: 'CompanySettings',
  mixins: [companySettings, loading],
  components: {
    CompanySubscription,
  },
  data() {
    return {
      activeTab: 'General',
      tabs: ['General', 'Subscription'],
      businessType: null,
      businessTypes: ['Sales', 'Retail'],
      incomeType: null,
      incomeTypes: ['Cash', 'Invoices'],
      currency: null,
      companyName: null,
      registrationNumber: null,
      vatNumber: null,
      postalAddress: null,
      physicalAddress: null,
      adminContact: null,
      emailAddress: null,
      phoneNumber: null,
      communicationsAct: null,
      valueAddedTaxAct: null,
      country: null,
      nationality: null,
      settings: [
        { name: 'businessType' },
        { name: 'incomeType' },
        { name: 'currency' },
        { name: 'companyName' },
        { name: 'registrationNumber' },
        { name: 'vatNumber' },
        { name: 'postalAddress' },
        { name: 'physicalAddress' },
        { name: 'adminContact' },
        { name: 'emailAddress' },
        { name: 'phoneNumber' },
        { name: 'communicationsAct' },
        { name: 'valueAddedTaxAct' },
        { name: 'country' },
        { name: 'nationality' },
      ],
    };
  },
  computed: {
    ...mapState('currencies', ['currencies']),
  },
  async mounted() {
    await this.refresh();
  },
};
</script>
