<template>
  <v-row dense>
    <v-col class="d-flex" cols="12" xl="2" lg="2" md="6">
      <v-textarea
        v-model="localCostLine.supplierProductName"
        auto-grow
        outlined
        rows="1"
        :disabled="disabled"
        :label="$$t('supplierProductName')"
        dense
        data-cy="FormLine-supplierProductName"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <v-autocomplete
        data-cy="FormLine-supplierId"
        auto-select-first
        clearable
        dense
        :disabled="disabled"
        :items="suppliers"
        item-text="name"
        item-value="id"
        v-model="localCostLine.supplierId"
        :label="$$t('supplier')"
        :rules="requiredFieldRules"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <NullableTextField
        dense
        data-cy="FormLine-quantity"
        :disabled="disabled"
        :label="$$t('quantity')"
        v-model.number="localCostLine.quantity"
        type="Number"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <v-select
        dense
        label="Cost Currency"
        data-cy="FormLine-unitCostCurrencyCode"
        v-model="localCostLine.unitCostCurrencyCode"
        :disabled="disabled"
        @change="updateExchangeRate"
        :items="currencies"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <NullableNumberField
        :precision="2"
        dense
        data-cy="FormLine-unitCostAmount"
        v-model.number="localCostLine.unitCostAmount"
        :label="$$t('unitCostAmount')"
        :disabled="disabled"
        :persistent-hint="isMultiCurrency"
        :hint="currencyHint"
        :rules="requiredFieldRules"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <NullableNumberField
        dense
        data-cy="FormLine-lineAmount"
        v-model="localCostLine.lineAmount"
        :label="$$t('lineAmount')"
        disabled
      />
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="6">
      <DatePicker
        v-model="localCostLine.supplierDeliveredDate"
        :disabled="disabled"
        :filled="false"
        :dense="true"
        data-cy="FormLine-date"
        label="Supplier Delivered"
        type="date"
        class="mt-2"
      />
    </v-col>
    <v-col cols="12" xl="2" lg="2" md="6">
      <DatePicker
        v-model="localCostLine.cancellationDate"
        :filled="false"
        :dense="true"
        data-cy="FormLine-cancellationDate"
        label="Cancellation Date"
        type="date"
        class="mt-2"
      />
    </v-col>
    <v-col cols="12" xl="1" lg="1" md="6">
      <v-btn color="error" icon @click="$emit('remove')" title="Delete cost line">
        <v-icon>{{ mdiDelete }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiCalculator, mdiDelete } from '@mdi/js';
import requiredField from '~src/mixins/required-field';

export default {
  name: 'CostLine',
  module: 'AgreementItem',
  mixins: [requiredField],
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCalculator,
      mdiDelete,
      localCostLine: this.value,
    };
  },
  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('exchangeRates', ['exchangeRates']),
    ...mapState('suppliers', ['suppliers']),
    exchangeRate() {
      if (this.exchangeRates.length > 0 && this.isMultiCurrency) {
        return this.exchangeRates.find(
          (e) => e.sourceCurrency === this.localCostLine.unitCostCurrencyCode,
        )?.rate;
      }
      return 1;
    },
    isMultiCurrency() {
      return this.Agreement.currencyCode !== this.localCostLine.unitCostCurrencyCode;
    },
    lineAmount() {
      const { quantity, unitCostAmount } = this.localCostLine;
      const { exchangeRate } = this;
      return quantity * (unitCostAmount * exchangeRate);
    },
    currencyHint() {
      return `1 ${this.localCostLine.unitCostCurrencyCode} = ${this.exchangeRate} ${this.Agreement.currencyCode}`;
    },
  },
  watch: {
    localCostLine: {
      immediate: true,
      deep: true,
      async handler() {
        await this.$nextTick();
        this.$emit('input', this.localCostLine);
      },
    },
    lineAmount: {
      immediate: true,
      deep: true,
      handler() {
        this.localCostLine.lineAmount = +this.lineAmount.toFixed(2);
      },
    },
    costLine: {
      immediate: true,
      deep: true,
      handler() {
        this.localCostLine = this.value;
      },
    },
    exchangeRate: {
      immediate: true,
      deep: true,
      handler() {
        this.localCostLine.exchangeRate = this.exchangeRate?.rate ?? 1;
      },
    },
  },
  methods: {
    ...mapActions('exchangeRates', ['LOAD_exchangeRatesForAgreement', 'CREATE_exchangeRate']),
    async updateExchangeRate(unitCostCurrencyCode) {
      const existingExchangeRate = this.exchangeRates.find(
        (e) => e.sourceCurrency === unitCostCurrencyCode,
      );
      if (!existingExchangeRate) {
        this.$log.info(`Creating exchange rate: ${unitCostCurrencyCode}`);
        // eslint-disable-next-line max-len
        const filter = `sourceCurrency eq '${unitCostCurrencyCode}' and targetCurrency eq '${this.Agreement.currencyCode}'`;
        const orderBy = 'created desc';
        // eslint-disable-next-line max-len
        const { data } = await this.$http.get(`/odata/currencyItems?$filter=${filter}&$orderby=${orderBy}&$top=1`);
        this.$log.info({
          currencyCode: unitCostCurrencyCode, data, filter, orderBy,
        });
        await this.CREATE_exchangeRate({
          agreementId: this.Agreement.id,
          sourceCurrency: unitCostCurrencyCode,
          targetCurrency: this.Agreement.currencyCode,
          rate: data.value[0].rate,
        });
        await this.LOAD_exchangeRatesForAgreement(this.Agreement.id);
      }
      this.$forceUpdate();
    },
  },
};
</script>
