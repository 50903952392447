<template>
  <div>
    <GChart
      :data="chartData"
      :type="'BarChart'"
      :options="chartOptions"
      :width="600"
      :height="600"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GraphFeedbackItems',
  components: {
    GChart,
  },
  data() {
    return {
      chartOptions: {
        title: 'Feedback Items',
        hAxis: {
          title: 'Total',
          minValue: 0,
        },
        vAxis: {
          title: 'Feedback Type',
          minValue: 0,
        },
      },
    };
  },
  computed: {
    ...mapState('feedbackItems', ['feedbackItems']),
    ...mapGetters('telemetry', ['filters']),
    chartData() {
      const data = [['Feature Request', 'Feedback', 'Bug']];
      const { feedbackItems } = this;
      const { fromDate, toDate } = this.filters;
      const items = feedbackItems
        .filter((item) => item.created >= fromDate && item.created <= toDate);

      const featureRequests = items.filter((item) => item.feedbackType === 'Feature Request');
      const bugs = items.filter((item) => item.feedbackType === 'Bug');
      const feedback = items.filter((item) => item.feedbackType === 'Feedback');

      data.push([featureRequests?.length ?? 0, bugs.length, feedback.length]);

      return data;
    },
  },
};
</script>
