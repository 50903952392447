<template>
  <v-simple-table class="report-summary-table rounded text-center">
    <template v-slot:default>
      <tr>
        <td colspan="8" class="primary white--text text-h5 text-center">
          {{ title }}
        </td>
      </tr>
      <tr>
        <td rowspan="12" class="primary white--text text-h6" style="width: 10%">
          {{ new Date().getFullYear() }}
        </td>
        <td rowspan="12" class="text-right text-h6 text-center" style="width: 20%">
          {{ formatNumber(summary.deliveredThisYear) }}
        </td>
        <td rowspan="6" style="width: 5%">
          H1
        </td>
        <td rowspan="6" style="width: 20%">
          {{ formatNumber(summary.deliveredFirstHalfOfTheYear) }}
        </td>
        <td rowspan="3" style="width: 5%">
          Q1
        </td>
        <td rowspan="3" style="width: 20%">
          {{ formatNumber(summary.deliveredFirstQuarterOfTheYear) }}
        </td>
        <td style="width: 5%">
          Jan
        </td>
        <td style="width: 15%">
          {{ formatNumber(summary.deliveredJanuary) }}
        </td>
      </tr>
      <tr>
        <td>
          Feb
        </td>
        <td>
          {{ formatNumber(summary.deliveredFebruary) }}
        </td>
      </tr>
      <tr>
        <td>
          Mar
        </td>
        <td>
          {{ formatNumber(summary.deliveredMarch) }}
        </td>
      </tr>
      <tr>
        <td rowspan="3">
          Q2
        </td>
        <td rowspan="3">
          {{ formatNumber(summary.deliveredSecondQuarterOfTheYear) }}
        </td>
        <td>
          Apr
        </td>
        <td>
          {{ formatNumber(summary.deliveredApril) }}
        </td>
      </tr>
      <tr>
        <td>
          May
        </td>
        <td>
          {{ formatNumber(summary.deliveredMay) }}
        </td>
      </tr>
      <tr>
        <td>
          Jun
        </td>
        <td>
          {{ formatNumber(summary.deliveredJune) }}
        </td>
      </tr>
      <tr>
        <td rowspan="6">
          H2
        </td>
        <td rowspan="6">
          {{ formatNumber(summary.deliveredSecondHalfOfTheYear) }}
        </td>
        <td rowspan="3">
          Q3
        </td>
        <td rowspan="3">
          {{ formatNumber(summary.deliveredThirdQuarterOfTheYear) }}
        </td>
        <td>
          Jul
        </td>
        <td>
          {{ formatNumber(summary.deliveredJuly) }}
        </td>
      </tr>
      <tr>
        <td>
          Aug
        </td>
        <td>
          {{ formatNumber(summary.deliveredAugust) }}
        </td>
      </tr>
      <tr>
        <td>
          Sep
        </td>
        <td>
          {{ formatNumber(summary.deliveredSeptember) }}
        </td>
      </tr>
      <tr>
        <td rowspan="3">
          Q4
        </td>
        <td rowspan="3">
          {{ formatNumber(summary.deliveredFourthQuarterOfTheYear) }}
        </td>
        <td>
          Oct
        </td>
        <td>
          {{ formatNumber(summary.deliveredOctober) }}
        </td>
      </tr>
      <tr>
        <td>
          Nov
        </td>
        <td>
          {{ formatNumber(summary.deliveredNovember) }}
        </td>
      </tr>
      <tr>
        <td>
          Dec
        </td>
        <td>
          {{ formatNumber(summary.deliveredDecember) }}
        </td>
      </tr>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'TableAnnualBreakdown',
  props: {
    summary: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
