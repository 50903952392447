<template>
  <v-container fluid>
    <UserNavigation />
  </v-container>
</template>

<script>
import UserNavigation from '../modules/telemetry/views/UserNavigation.vue';

export default {
  name: 'Telemetry',
  components: {
    UserNavigation,
  },
};
</script>
