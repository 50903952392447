<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-text-field
          filled
          data-cy="FormContact-name"
          :label="$$t('name')"
          v-model="localContact.name"
        />
        <v-text-field
          filled
          data-cy="FormContact-firstName"
          :label="$$t('firstName')"
          v-model="localContact.firstName"
        />
        <v-text-field
          filled
          data-cy="FormContact-lastName"
          :label="$$t('lastName')"
          v-model="localContact.lastName"
        />
        <v-text-field
          filled
          data-cy="FormContact-emailAddress"
          :label="$$t('emailAddress')"
          v-model="localContact.emailAddress"
        />
        <v-text-field
          filled
          data-cy="FormContact-addressType"
          :label="$$t('addressType')"
          v-model="localContact.addressType"
        />
        <v-text-field
          filled
          data-cy="FormContact-addressLine1"
          :label="$$t('addressLine1')"
          v-model="localContact.addressLine1"
        />
        <v-text-field
          filled
          data-cy="FormContact-addressLine2"
          :label="$$t('addressLine2')"
          v-model="localContact.addressLine2"
        />
        <v-text-field
          filled
          data-cy="FormContact-city"
          :label="$$t('city')"
          v-model="localContact.city"
        />
        <v-text-field
          filled
          data-cy="FormContact-postalCode"
          :label="$$t('postalCode')"
          v-model="localContact.postalCode"
        />
        <v-text-field
          filled
          data-cy="FormContact-taxNumber"
          :label="$$t('taxNumber')"
          v-model="localContact.taxNumber"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormContact-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormContact-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormContact',
  module: 'Contact',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Contact: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localContact
      */
      localContact: {},
    };
  },
  watch: {
    Contact() {
      this.localContact = this.Contact;
    },
  },
  created() {
    this.localContact = this.Contact;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localContact);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormContact example

'''js
<FormContact :Contact="Contact" />
'''
</docs>
