<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Sales Target
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :loading="isLoading"
              transition="expand-transition"
              type="article"
            >
              <FormOrgTarget
                :OrgTarget="editOrgTarget"
                :isSaving="isSaving"
                @save="save"
                @cancel="$router.push({name: from.name })" />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormOrgTarget from '../components/FormOrgTarget.vue';

export default {
  components: {
    FormOrgTarget,
  },
  name: 'EditOrgTarget',
  module: 'Quote',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.targets',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing OrgTarget. Default to {}.
      */
      editOrgTarget: {},
      /**
       * Query Id for OrgTarget
       */
      orgTargetId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('orgTargets', ['orgTargets', 'orgTarget']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.orgTargetId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_orgTarget(this.orgTargetId);
      this.editOrgTarget = this.orgTarget;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orgTargets', ['LOAD_orgTarget', 'CREATE_orgTarget', 'UPDATE_orgTarget']),
    /** Method used to save the item
     */
    async save(orgTarget) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_orgTarget(orgTarget);
      } else {
        await this.UPDATE_orgTarget(orgTarget);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListOrgTarget' });
    },
  },
};
</script>

<docs>
# EditOrgTarget example

'''js
<EditOrgTarget :OrgTarget="OrgTarget" />
'''
</docs>
