export default {
  SET_selectedValue(state, value) {
    state.selectedValue = value;
  },
  SET_period(state, value) {
    state.period = value;
  },
  SET_selectedYear(state, value) {
    state.selectedYear = value;
  },
};
