<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormOrgTaxRate
      :OrgTaxRate="editOrgTaxRate"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormOrgTaxRate from '../components/FormOrgTaxRate.vue';

export default {
  components: {
    FormOrgTaxRate,
  },
  name: 'EditOrgTaxRate',
  module: 'OrgTaxRate',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing OrgTaxRate. Default to {}.
      */
      editOrgTaxRate: {},
      /**
       * Query Id for OrgTaxRate
       */
      orgTaxRateId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('orgTaxRates', ['orgTaxRates', 'orgTaxRate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.orgTaxRateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_orgTaxRate(this.orgTaxRateId);
      this.editOrgTaxRate = this.orgTaxRate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orgTaxRates', ['LOAD_orgTaxRate', 'CREATE_orgTaxRate', 'UPDATE_orgTaxRate']),
    /** Method used to save the item
     */
    async save(orgTaxRate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_orgTaxRate(orgTaxRate);
      } else {
        await this.UPDATE_orgTaxRate(orgTaxRate);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListOrgTaxRate' });
    },
  },
};
</script>

<docs>
# EditOrgTaxRate example

'''js
<EditOrgTaxRate :OrgTaxRate="OrgTaxRate" />
'''
</docs>
