<template>
  <!-- Button to trigger the fetching of audit logs and display in a dialog -->
  <v-btn
    color="secondary"
    :title="$t('common.buttons.history')"
    @click="getAuditLogs"
    v-bind:disabled="isLoading">
    <v-icon>
      {{ mdiClipboardTextClockOutline }}
    </v-icon>
    <v-dialog v-model="dialog" fullscreen>
        <v-card>
          <v-card-title>
            Audit Logs
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="height: 100%;">
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              :items="auditLogs"
              sort-by="updated"
              :sort-desc="true"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-btn>
</template>

<script>
import { mdiClose, mdiClipboardTextClockOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'AuditLog',
  mixins: [loading],
  data: () => ({
    /** Icons
     */
    mdiClose,
    mdiClipboardTextClockOutline,
    dialog: false, // Flag to track if the dialog is open
    loading: false, // Flag to track if logs are currently being fetched
    auditLogs: [], // Array to store the fetched logs
    /** Headers for data table
     */
    headers: [
      { text: 'Name', value: 'propertyName' },
      { text: 'Original Value', value: 'originalValue' },
      { text: 'Current Value', value: 'currentValue' },
      { text: 'Updated', value: 'updated' },
      { text: 'Updated By', value: 'updatedBy' },
    ],
  }),
  props: {
    entityType: {
      type: String,
    },
    entityId: {
      type: String,
      required: true, // Entity ID is required for filtering the logs
    },
  },
  methods: {
    async getAuditLogs() {
      this.dialog = true;
      this.isLoading = true;
      try {
        let url = `/odata/auditLogs?$filter=EntityId eq '${this.entityId}'`;
        if (this.entityType) {
          url += ` and EntityType eq '${this.entityType}'`;
        }
        url += '&$orderby=Updated desc';
        const { data } = await this.$http.get(url);
        this.auditLogs = data.value;
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },

  },

};

</script>
