<template>
  <div id="mynetwork"></div>
</template>
<style lang="scss">
#mynetwork {
  width: 100%;
  height: calc(100vh - 147px);
}
</style>
<script>
import vis from 'vis-network/dist/vis-network';
import 'vis-network/styles/vis-network.css';

export default {
  name: 'VisNetworkBubble',
  props: {
    data: {
      type: Object,
      default: () => undefined,
    },
    target: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      network: undefined,
    };
  },
  watch: {
    data() {
      this.draw();
    },
    target() {
      this.draw();
    },
  },
  mounted() {
    this.draw();
  },
  methods: {
    draw() {
      const { nodes, edges } = this.data;

      // Instantiate our network object.
      const container = document.getElementById('mynetwork');
      const data = {
        nodes,
        edges,
      };
      // eslint-disable-next-line no-unused-vars
      const { target } = this;
      const options = {
        layout: {
          enabled: false,
          levelSeparation: 150,
          nodeSpacing: 500,
          treeSpacing: 250,
          blockShifting: true,
          edgeMinimization: true,
          parentCentralization: true,
          direction: 'UD', // UD, DU, LR, RL
          sortMethod: 'directed', // hubsize, directed
          shakeTowards: 'roots', // roots, leaves
          // hierarchical: {
          //   direction: 'UD',
          //   sortMethod: 'directed',
          // },
          // randomSeed: 10,
        },
        physics: false,
        nodes: {
          shape: 'dot',
          scaling: {
            customScalingFunction(min, max, total, value) {
              // let scale = 0.5;
              // if (target === value) {
              //   scale = 0.2;
              // } else {
              //   // return value / total;
              //   scale = (value * 10) / total;
              // }
              // return scale;
              return (value * 10) / total;
            },
          },
        },
      };
      this.network = new vis.Network(container, data, options);

      // Store the component instance to use in the click event handler
      const componentInstance = this;

      // Add a click event listener to the network
      this.network.on('click', (params) => {
        if (params.nodes.length > 0) {
          const clickedNodeId = params.nodes[0];
          componentInstance.$emit('click', clickedNodeId);
        }
      });
    },
  },
};
</script>
