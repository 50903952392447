<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title>
        Company Targets
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="3">
              <v-text-field v-model="targetMrp" label="MRP Target"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="targetMrpYtd" label="MRP YTD Target"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="targetNrp" label="NRP Target"></v-text-field>
            </v-col>
            <v-col cols="3">
              <v-text-field v-model="targetNrpYtd" label="NRP YTD Target"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn color="success" :loading="isSaving" @click="save">Save</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import companySettings from '@/mixins/companySettings';

export default {
  name: 'CompanyTargets',
  mixins: [companySettings, loading],
  data() {
    return {
      targetMrp: 0,
      targetMrpYtd: 0,
      targetNrp: 0,
      targetNrpYtd: 0,
      settings: [
        { name: 'targetMrp' },
        { name: 'targetMrpYtd' },
        { name: 'targetNrp' },
        { name: 'targetNrpYtd' },
      ],
    };
  },
  mounted() {
    this.refresh();
  },
};
</script>
