<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="suppliers"
          class="elevation-1"
          :loading="isLoading"
          :search="search"
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                color="success"
                data-cy="Supplier-button-new"
                @click="$router.push({
                  name: 'quotes-EditSupplier'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
              <div class="text-h5 ml-2">
                Suppliers
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                label="Search"
                v-model="search"
                clearable
                hide-details
              ></v-text-field>
              <FilterMenu>
                <v-checkbox
                  v-model="includeArchived"
                  label="Include Archived"
                  @change="refresh"
                ></v-checkbox>
              </FilterMenu>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <router-link :to="{
                name: 'quotes-EditSupplier',
                query: {
                  id: item.id
                }
              }"
              class="black--text"
            >
              {{ item.name }}
            </router-link>
            <v-icon v-if="!item.isActive" class="ml-2" @click="unarchive(item)" title="Unarchive" color="error">
              {{ mdiArchive }}
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn-toggle>
              <v-btn color="default" small :to="{
                name: 'quotes-EditSupplier',
                query: {id: item.id}
              }">
                {{ $t('common.buttons.edit') }}
              </v-btn>
              <v-btn color="error" small @click="remove(item)">
                {{ $t('common.buttons.delete') }}
              </v-btn>
              <v-btn v-if="item.isActive" small color="secondary" @click="archive(item)">Archive</v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiPlus, mdiArchive } from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import FilterMenu from '@/components/FilterMenu.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListSupplier',
  module: 'Supplier',
  components: {
    FilterMenu,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.suppliers',
        disabled: true,
      },
      ],
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiArchive,
      mdiPlus,
      includeArchived: false,
    };
  },
  computed: {
    ...mapState('suppliers', ['suppliers']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('email'),
          value: 'email',
        },
        {
          text: this.$$t('telephone'),
          value: 'telephone',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    /**
     * Computed property for the name vuex property search
     */
    search: {
      get() {
        return this.$store.state.suppliers.search;
      },
      set(newValue) {
        this.$store.commit('suppliers/SET_search', newValue);
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('suppliers', [
      'DELETE_supplier',
      'UPDATE_supplier',
      'LOAD_suppliers',
      'DELETE_client',
    ]),
    async unarchive(item) {
      const res = await this.$confirm('Are you sure you want to unarchive this supplier?');
      if (!res) return;
      this.isLoading = true;
      await this.UPDATE_supplier({
        id: item.id,
        isActive: true,
      });
      this.isLoading = false;
      await this.refresh();
    },
    async archive(item) {
      const res = await this.$confirm('Are you sure you want to archive this supplier?');
      if (!res) return;
      this.isLoading = true;
      await this.UPDATE_supplier({
        id: item.id,
        isActive: false,
      });
      this.isLoading = false;
      await this.refresh();
    },
    /** Delete Supplier from the collection
     */
    async remove(supplier) {
      const res = await this.$confirm('Are you sure you want to delete this supplier?');
      if (!res) return;
      this.isLoading = true;
      await this.DELETE_supplier(supplier.id);
      this.isLoading = false;
    },
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      const { includeArchived } = this;
      await this.LOAD_suppliers({ includeArchived });
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Supplier example

'''js
<Supplier />
'''
</docs>
