export default {
  incomeLines: [],
  incomeLine: undefined,
  lineTypes: [{ value: 'Annual Recurring Cost', text: 'Annual Recurring' },
    { value: 'Bi-Annual Recurring Cost', text: 'Bi-Annual Recurring' },
    { value: 'Quarterly Recurring Cost', text: 'Quarterly Recurring' },
    { value: 'Monthly Recurring Cost', text: 'Monthly Recurring' },
    { value: 'Non-Recurring Cost', text: 'Non-Recurring' },
  ],
};
