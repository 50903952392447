<template>
  <v-alert
    v-if="localLineItem"
    border="left"
    colored-border
    color="deep-purple accent-4"
    elevation="2"
  >
    <template v-slot:prepend>
      <span class="ml-2 mr-2">
        {{ localLineItem.lineNumber }}
      </span>
    </template>
    <v-row no-gutters>
      <v-col cols="11">
        <v-row dense>
          <v-col cols="12" xl="4" lg="4" md="6">
            <v-text-field
              dense
              data-cy="FormLine-description"
              :disabled="disabled"
              :label="$$t('description')"
              v-model="localLineItem.description"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <v-select
              clearable
              dense
              data-cy="FormLine-lineType"
              :disabled="disabled"
              :label="$$t('serviceType')"
              v-model="localLineItem.lineType"
              :content-class="`data-cy-lineType-${index}`"
              :items="lineTypes"
              :rules="requiredFieldRules"
            />
          </v-col>
          <v-col
            v-if="localLineItem.lineType === 'Monthly Recurring Cost'"
            cols="12"
            xl="2"
            lg="2"
            md="6"
          >
            <NullableTextField
              clearable
              dense
              data-cy="FormLine-terms"
              :disabled="disabled"
              :label="$$t('term')"
              v-model="localLineItem.terms"
              :rules="requiredFieldRules"
              :content-class="`data-cy-lineType-${index}`"
              hint="months"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <v-autocomplete
              auto-select-first
              clearable
              dense
              data-cy="FormLine-revenueLineId"
              :disabled="disabled"
              :label="$$t('revenueLine')"
              v-model="localLineItem.revenueLineId"
              :items="revenueLines"
              item-text="name"
              item-value="id"
              :rules="requiredFieldRules"
            />
          </v-col>
          <v-col v-if="localLineItem.implementationDate" cols="12" xl="2" lg="2" md="6">
            <DatePicker
              :dense="true"
              :filled="false"
              data-cy="FormLine-implementationDate"
              :label="$$t('deliveredDate')"
              v-model="localLineItem.implementationDate"
              :background-color="getBackgroundColor('ImplementationDate')"
            />
          </v-col>
          <v-col cols="12" xl="3" lg="3" md="6">
            <v-autocomplete
              data-cy="FormLine-supplierId"
              auto-select-first
              clearable
              dense
              :disabled="disabled"
              :items="allSuppliers"
              item-text="name"
              item-value="id"
              v-model="localLineItem.supplierId"
              :label="$$t('supplier')"
              :rules="requiredFieldRules"
            >
              <template v-slot:item="{ item }">
                <v-list-item-icon>
                  <v-img
                    v-if="item.source === 'xero'"
                    src="../../../../assets/icons8-xero-24.png"
                    width="24"
                    height="24"
                  />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              dense
              data-cy="FormLine-quantity"
              :disabled="disabled"
              :label="$$t('quantity')"
              v-model="localLineItem.quantity"
              type="Number"
              :background-color="getBackgroundColor('Quantity')"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <v-select
              dense
              label="Cost Currency"
              data-cy="FormLine-unitCostCurrencyCode"
              v-model="localLineItem.unitCostCurrencyCode"
              :background-color="getBackgroundColor('UnitCostCurrencyCode')"
              :disabled="disabled"
              @change="updateExchangeRate"
              :items="currencies"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              dense
              data-cy="FormLine-unitCostAmount"
              v-model="localLineItem.unitCostAmount"
              :label="$$t('unitCostAmount')"
              :background-color="getBackgroundColor('UnitCostAmount')"
              :disabled="disabled"
              :persistent-hint="isMultiCurrency"
              :hint="currencyHint"
              :rules="requiredFieldRules"
              type="Number"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              dense
              data-cy="FormLine-unitAmount"
              :disabled="disabled"
              :label="$$t('unitAmount')"
              v-model="localLineItem.unitAmount"
              type="Number"
              :rules="requiredFieldRules"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <v-text-field
              dense
              data-cy="FormLine-discountRate"
              :disabled="disabled"
              label="% Discount"
              v-model="localLineItem.discountRate"
              :background-color="getBackgroundColor('DiscountRate')"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <v-text-field
              dense
              readonly
              disabled
              data-cy="lineAmount"
              :label="$$t('lineAmount')"
              v-model="localLineItem.lineAmount"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <v-text-field
              dense
              readonly
              disabled
              data-cy="profit"
              label="Profit"
              v-model="localLineItem.profit"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" class="text-right">
        <v-row no-gutters>
          <v-col cols="6">
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn
                  :disabled="disabled"
                  @click="$emit('update:up', localLineItem)"
                  icon
                  v-if="index > 0"
                >
                  <v-icon>{{ mdiArrowUpBold }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn
                  :disabled="disabled"
                  @click="$emit('remove', localLineItem)"
                  large
                  color="error"
                  icon
                >
                  <v-icon>{{ mdiDelete }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn
                  :disabled="disabled"
                  @click="$emit('update:down')"
                  v-if="index < totalItems - 1"
                  icon
                >
                  <v-icon>{{ mdiArrowDownBold }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-menu offset-y v-if="sections.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :disabled="disabled"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      dark
                      slot="activator"
                    >
                      <v-icon class="ml-2">{{ mdiVectorIntersection }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in sections"
                      :key="item.id"
                      @click="$emit('update:section', { section: item, localLineItem })"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import {
  mdiDelete,
  mdiDragVertical,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiVectorIntersection,
} from '@mdi/js';
import { mapState } from 'vuex';
import suppliers from '~src/mixins/suppliers';
import requiredField from '~src/mixins/required-field';
import DatePicker from '../../../../components/DatePicker.vue';

export default {
  name: 'FormLine',
  module: 'LineItem',
  components: {
    DatePicker,
  },
  mixins: [suppliers, requiredField],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mdiDelete,
      mdiDragVertical,
      mdiArrowUpBold,
      mdiArrowDownBold,
      mdiVectorIntersection,
      localLineItem: {},
    };
  },
  watch: {
    exchangeRate: {
      immediate: true,
      deep: true,
      handler() {
        this.localLineItem.exchangeRate = this.exchangeRate?.rate ?? 1;
      },
    },
    localLineItem: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localLineItem);
      },
    },
    total() {
      this.localLineItem.lineAmount = this.total;
    },
    taxAmount() {
      this.localLineItem.taxAmount = this.taxAmount;
    },
    profit() {
      this.localLineItem.profit = this.profit;
    },
    value() {
      this.localLineItem = this.value;
    },
  },
  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('exchangeRates', ['exchangeRates']),
    ...mapState('orgTaxRates', ['orgTaxRates']),
    ...mapState('quotes', ['terms', 'quote']),
    ...mapState('quoteSections', ['quoteSections']),
    ...mapState('revenueLines', ['revenueLines']),
    ...mapState('sectionLineItemAudits', ['sectionLineItemAudits']),
    exchangeRate() {
      if (this.exchangeRates.length > 0) {
        return this.exchangeRates.find(
          (e) => e.sourceCurrency === this.localLineItem.unitCostCurrencyCode,
        );
      }
      return undefined;
    },
    currencyHint() {
      if (this.isMultiCurrency) {
        const { unitCostAmount, exchangeRate } = this.localLineItem;
        return this.formatCurrency(unitCostAmount * exchangeRate, this.quote.currencyCode);
      }
      return '';
    },
    isMultiCurrency() {
      return this.localLineItem.unitCostCurrencyCode !== this.quote.currencyCode;
    },
    profit() {
      const { discountRate, quantity, exchangeRate } = this.localLineItem ?? {};
      let { unitAmount, unitCostAmount } = this.localLineItem ?? {};
      unitCostAmount *= exchangeRate;
      if (discountRate > 0) {
        const rate = (100 - discountRate) / 100;
        unitAmount *= rate;
      }
      const profit = (unitAmount ?? 0) * quantity - (unitCostAmount ?? 0) * quantity;
      return +profit.toFixed(2);
    },
    lineTypes() {
      return [
        { value: 'Monthly Recurring Cost', text: 'Monthly Recurring' },
        { value: 'Non-Recurring Cost', text: 'Non-Recurring' },
      ];
    },
    sections() {
      const { quoteSections } = this;
      return quoteSections.filter((section) => section.id !== this.localLineItem.quoteSectionId);
    },
    taxRate() {
      const { taxType } = this.localLineItem ?? {};
      const { orgTaxRates } = this;
      const taxRate = orgTaxRates?.find((t) => t.taxType === taxType);
      return taxRate?.rate ?? 0;
    },
    taxAmount() {
      const { taxRate } = this;
      const { discountRate, quantity } = this.localLineItem ?? {};
      let { unitAmount } = this.localLineItem ?? {};
      if (discountRate > 0) {
        const rate = (100 - discountRate) / 100;
        unitAmount *= rate;
      }
      if (taxRate > 0) {
        const rate = taxRate / 100;
        const taxAmount = parseFloat((quantity * unitAmount * rate).toFixed(2), 10);
        return taxAmount;
      }
      return 0;
    },
    total() {
      const { discountRate, quantity } = this.localLineItem ?? {};
      let { unitAmount } = this.localLineItem ?? {};
      const { taxAmount } = this;

      if (discountRate > 0 && unitAmount) {
        const rate = (100 - discountRate) / 100;
        unitAmount *= rate;
      }
      if (quantity && unitAmount) {
        let lineAmount = parseFloat((quantity * unitAmount).toFixed(2), 10);
        if (taxAmount > 0) {
          lineAmount += taxAmount;
        }
        lineAmount = parseFloat(lineAmount.toFixed(2), 10);
        return lineAmount;
      }
      return undefined;
    },
  },
  created() {
    this.localLineItem = this.value;
  },
  mounted() {
    if (this.localLineItem.lineAmount === 0) {
      this.localLineItem.taxAmount = 0;
      this.$emit('save', this.localLineItem);
    }
  },
  methods: {
    getBackgroundColor(fieldName) {
      const { sectionLineItemAudits } = this;
      const audits = sectionLineItemAudits[this.localLineItem.sectionId] ?? [];
      const fieldAudits = audits.filter((a) => a.fieldName === fieldName) ?? [];
      switch (fieldAudits.length) {
        default:
        case 0:
          return '';
        case 1:
          return '#00FFFF';
        case 2:
          return 'warning';
        case 3:
          return 'error';
      }
    },
    updateExchangeRate(item) {
      this.localLineItem.currencyCode = item.currencyCode;
      this.$forceUpdate();
    },
  },
};
</script>
