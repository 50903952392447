<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        Company Defaults
      </v-card-title>
      <v-card-text>
        <v-select
          filled
          :items="quoteTemplates"
          v-model="defaultTemplateId"
          item-text="name"
          item-value="id"
          label="Template"
        />
        <div class="text-h6">Agreement Notes</div>
        <vue-editor v-model="defaultAgreementNotes"></vue-editor>
        <div class="text-h6">Solution Notes</div>
        <vue-editor v-model="defaultSolutionNotes"></vue-editor>
        <v-btn
          color="success"
          class="mt-5"
          :loading="isSaving"
          @click="save">Save</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { VueEditor } from 'vue2-editor';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'CompanyDefaults',
  components: {
    VueEditor,
  },
  mixins: [loading],
  data() {
    return {
      defaultTemplateId: undefined,
      defaultAgreementNotes: '',
      defaultSolutionNotes: '',
    };
  },
  computed: {
    ...mapState('companySettings', ['companySettings']),
    ...mapState('quoteTemplates', ['quoteTemplates']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('companySettings', [
      'LOAD_companySettings',
      'CREATE_companySetting',
      'UPDATE_companySetting',
    ]),
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_quoteTemplates();
      await this.LOAD_companySettings();
      this.setCompanySettings(this.companySettings);
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      await this.saveCompanySetting('defaultTemplateId', this.defaultTemplateId);
      await this.saveCompanySetting('defaultAgreementNotes', this.defaultAgreementNotes);
      await this.saveCompanySetting('defaultSolutionNotes', this.defaultSolutionNotes);
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
    setCompanySettings(companySettings) {
      this.defaultTemplateId = companySettings.find(
        (setting) => setting.name === 'defaultTemplateId',
      )?.value;
      this.defaultAgreementNotes = companySettings.find(
        (setting) => setting.name === 'defaultAgreementNotes',
      )?.value;
      this.defaultSolutionNotes = companySettings.find(
        (setting) => setting.name === 'defaultSolutionNotes',
      )?.value;
    },
    async saveCompanySetting(name, value) {
      const existingCompanySetting = this.companySettings.find(
        (setting) => setting.name === name,
      );
      if (existingCompanySetting) {
        await this.UPDATE_companySetting({
          id: existingCompanySetting.id,
          name,
          value,
        });
        return;
      }
      await this.CREATE_companySetting({
        name,
        value,
      });
    },
  },
};
</script>
