import { mapActions } from 'vuex';

export default {
  ...mapActions('agreements', ['UPDATE_agreement']),
  ...mapActions('solutions', ['UPDATE_solution']),
  methods: {
    async saveSolution(item) {
      await this.UPDATE_agreement({
        id: item.id,
        contractSolutionId: item.contractSolutionId ?? null,
      });
      await this.UPDATE_solution({
        id: item.contractSolutionId,
        solutionNumber: item.agreementNumber.replace('Q', 'S'),
      });
      await this.refresh();
    },
    async saveStatus(item) {
      await this.UPDATE_agreement({
        id: item.id,
        status: item.status,
      });
      await this.refresh();
    },
    async saveIsActive(item) {
      await this.UPDATE_agreement({
        id: item.id,
        isActive: item.isActive,
      });
      await this.refresh();
    },
    async saveIsDeleted(item) {
      await this.UPDATE_agreement({
        id: item.id,
        isDeleted: item.isDeleted,
      });
      await this.refresh();
    },
    async saveDeliveredDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        deliveredDate: item.deliveredDate,
      });
      await this.refresh();
    },
    async saveCancellationDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        cancellationDate: item.cancellationDate,
      });
      await this.refresh();
    },
    async saveAgreementNumber(item) {
      await this.UPDATE_agreement({
        id: item.id,
        agreementNumber: item.agreementNumber,
      });
      await this.refresh();
    },
    async saveAcceptedDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        acceptedDate: item.acceptedDate,
      });
      await this.refresh();
    },
    async saveEndDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        endDate: item.endDate,
      });
      await this.refresh();
    },
    async saveOriginalEndDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        originalEndDate: item.originalEndDate,
      });
      await this.refresh();
    },
    async saveUpgradedDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        upgradedDate: item.upgradedDate,
      });
      await this.refresh();
    },
    async saveInvoiceNumber(item) {
      await this.UPDATE_agreement({
        id: item.id,
        invoiceNumber: item.invoiceNumber,
      });
      await this.refresh();
    },
    async saveInvoiceStatus(item) {
      await this.UPDATE_agreement({
        id: item.id,
        invoiceStatus: item.invoiceStatus,
      });
      await this.refresh();
    },
  },
};
