<template>
  <v-data-table
    :headers="headers"
    :items="contacts"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="Contact-button-new"
          @click="$router.push({
            name: 'contact-EditContact'
          })"
        >
          <v-icon class="mr-2">
            {{ mdiPlus }}
          </v-icon>
          {{ $t('common.buttons.new') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          label="Search"
          v-model="search"
          clearable
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link
        :to="{ name: 'contact-EditContact', query: { id: item.id } }"
        class="black--text"
        >
        {{ item.name }}
      </router-link>
    </template>
    <template v-slot:item.actions="{ item }">
      <TableBasicButtons :editLink="{
          name: 'contact-EditContact',
          query: {id: item.id}
        }"
        @delete="remove(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import list from '~src/mixins/list';

export default {
  name: 'ListContact',
  module: 'Contact',
  components: {
    TableBasicButtons,
  },
  mixins: [list, loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('contacts', ['contacts']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('firstName'),
          value: 'firstName',
        },
        {
          text: this.$$t('lastName'),
          value: 'lastName',
        },
        {
          text: this.$$t('emailAddress'),
          value: 'emailAddress',
        },
        {
          text: this.$$t('addressType'),
          value: 'addressType',
        },
        {
          text: this.$$t('addressLine1'),
          value: 'addressLine1',
        },
        {
          text: this.$$t('addressLine2'),
          value: 'addressLine2',
        },
        {
          text: this.$$t('city'),
          value: 'city',
        },
        {
          text: this.$$t('postalCode'),
          value: 'postalCode',
        },
        {
          text: this.$$t('taxNumber'),
          value: 'taxNumber',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    /**
     * Computed property for the name vuex property search
     */
    search: {
      get() {
        return this.$store.state.clients.search;
      },
      set(newValue) {
        this.$store.commit('clients/SET_search', newValue);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_contacts();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('contacts', ['LOAD_contacts', 'DELETE_contact']),
    /** Delete Contact from the collection
     */
    async remove(Contact) {
      this.isLoading = true;
      await this.DELETE_Contact(Contact);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Contact example

'''js
<Contact />
'''
</docs>
