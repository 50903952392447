<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="agreements"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500, -1]
      }"
      dense
      :search="search"
      sort-by="agreementNumber"
      :sort-desc="true"
      show-expand
    >
    <template v-slot:top>
      <v-row>
        <v-col cols="4">
          <div class="text-h5 ml-2">Exceptions Report</div>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="1">
        </v-col>
        <v-col cols="3">
          <v-text-field
            clearable
            class="mr-2"
            v-model="search"
            :append-icon="mdiMagnify"
            :label="$t('common.search')"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="1">
          <v-btn small color="secondary" @click="refresh" class="mt-5">
            <v-icon>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="1">
          <ExportToExcelButton
            class="mt-5"
            small
            name="Agreements"
            :data="exportData"
          />
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.isDeleted="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isDeleted"
        large
        @save="saveIsDeleted(item)"
      >
        <BoolField class="inline-block" v-model="item.isDeleted" />
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: true }, { text: 'No', value: false}]"
            v-model="item.isDeleted"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
      <template v-slot:item.solutionNumber="{ item }">
        <router-link :to="{ name: 'solutions-EditSolution', query: { id: item.id } }">
          {{ item.solutionNumber }}
        </router-link>
      </template>
    <template v-slot:item.isActive="{ item }">
      <BoolField v-model="item.isActive" />
    </template>
    <template v-slot:item.deliveredDate="{ item }">
      <v-edit-dialog
        :return-value.sync="item.deliveredDate"
        large
        @save="saveDeliveredDate(item)"
      >
        {{ formatDate(item.deliveredDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            v-model="item.deliveredDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.acceptedDate="{ item }">
      <v-edit-dialog
        :return-value.sync="item.acceptedDate"
        large
        @save="saveAcceptedDate(item)"
      >
        {{ formatDate(item.acceptedDate) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <DatePicker
            v-model="item.acceptedDate"
          ></DatePicker>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.invoiceNumber="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceNumber"
        large
        @save="saveInvoiceNumber(item)"
      >
        {{ item.invoiceNumber }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-text-field
            v-model="item.invoiceNumber"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.invoiceStatus="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceStatus"
        large
        @save="saveInvoiceNumber(item)"
      >
        {{ item.invoiceStatus }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-text-field
            v-model="item.invoiceStatus"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.status="{ item }">
      <v-edit-dialog
        :return-value.sync="item.status"
        large
        @save="saveStatus(item)"
      >
        {{ item.status }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="stages"
            v-model="item.status"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.solution.solutionNumber="{ item }">
      <div style="display: block">
        <router-link
          :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }"
          class="black--text"
          >
          {{ item.solution.solutionNumber }}
        </router-link>
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" style="padding-top: 10px">
        <AgreementItems :Agreement="item" @migrated="refresh" />
      </td>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiPencilBoxOutline,
} from '@mdi/js';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ExportToExcelButton from '~src/components/ExportToExcelButton.vue';
import AgreementItems from '../components/AgreementItems.vue';

export default {
  name: 'ReportExceptions',
  mixins: [loading],
  components: {
    BoolField,
    ExportToExcelButton,
    AgreementItems,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiPencilBoxOutline,
      mdiRefresh,
      agreements: [],
      search: '',
      headers: [
        { text: 'Solution Number', value: 'solution.solutionNumber', width: '100px' },
        { text: 'Solution Owner', value: 'solution.owner', width: '120px' },
        { text: 'Person', value: 'solution.person', width: '120px' },
        { text: 'Agreement Number', value: 'agreementNumber', width: '120px' },
        { text: 'Renewal Term', value: 'renewalTerm', width: '80px' },
        { text: 'Status', value: 'status', width: '150px' },
        { text: 'Accepted Date', value: 'acceptedDate', width: '150px' },
        { text: 'Delivered Date', value: 'deliveredDate', width: '150px' },
        { text: 'Agreement Term', value: 'term', width: '120px' },
      ],
      xeroInvoiceNumber: '',
      dialog: false,
    };
  },
  computed: {
    ...mapState('agreements', ['statuses', 'stages']),
    ...mapState('solutions', ['solutions']),
    ...mapState('xeroInvoices', ['xeroInvoices']),
    exportData() {
      return this.agreements.map((a) => ({
        'Agreement Number': a.agreementNumber,
        'Solution Number': a.solution?.solutionNumber ?? '',
        'Agreement Status': a.status,
        'Accepted Date': a.acceptedDate,
        'Delivered Date': a.deliveredDate,
        Solution: a.solution?.name,
        Deleted: a.isDeleted,
        Archived: a.isActive,
        'Project Name': a.reference,
        'Invoice Number': a.invoiceNumber,
        'Invoice Status': a.invoiceStatus,
      }));
    },
    sortedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.xeroInvoices
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reverse();
    },
  },
  methods: {
    ...mapActions('agreements', ['LOAD_manageAgreements', 'UPDATE_agreement']),
    ...mapActions('solutions', ['LOAD_solutions', 'DELETE_solution', 'UPDATE_solution']),
    async createSolution(item) {
      this.isLoading = true;
      await this.$http.post(`api/agreements/create-solution/${item.id}`);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'Solution created successfully');
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          this.agreements = await this.LOAD_manageAgreements();
        })(),
        (async () => {
          await this.LOAD_solutions();
        })(),
      ]);
      this.isLoading = false;
    },
    async save(item) {
      await this.UPDATE_agreement(item);
      await this.refresh();
    },
    async saveSolution(item) {
      await this.UPDATE_agreement({
        id: item.id,
        contractSolutionId: item.contractSolutionId ?? null,
      });
      await this.UPDATE_solution({
        id: item.contractSolutionId,
        solutionNumber: item.agreementNumber.replace('Q', 'S'),
      });
      await this.refresh();
    },
    async saveStatus(item) {
      await this.UPDATE_agreement({
        id: item.id,
        status: item.status,
      });
      await this.refresh();
    },
    async saveIsDeleted(item) {
      await this.UPDATE_agreement({
        id: item.id,
        isDeleted: item.isDeleted,
      });
      await this.refresh();
    },
    async saveDeliveredDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        deliveredDate: item.deliveredDate,
      });
      await this.refresh();
    },
    async saveAcceptedDate(item) {
      await this.UPDATE_agreement({
        id: item.id,
        acceptedDate: item.acceptedDate,
      });
      await this.refresh();
    },
    async saveInvoiceNumber(item) {
      await this.UPDATE_agreement({
        id: item.id,
        invoiceNumber: item.invoiceNumber,
      });
      await this.refresh();
    },
    async saveInvoiceStatus(item) {
      await this.UPDATE_agreement({
        id: item.id,
        invoiceStatus: item.invoiceStatus,
      });
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>
<style scoped>
.inline-block,
.inline-block > div{
  display: inline-block;
}
</style>
