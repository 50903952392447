<template>
  <v-alert
    border="left"
    colored-border
    color="deep-purple accent-4"
    elevation="2"
  >
    <template v-slot:prepend="">
      <v-icon class="sortHandle handle">{{ mdiDragVertical }}</v-icon>
    </template>
    <v-text-field
      v-data="localLineItem.description"
    ></v-text-field>
  </v-alert>
</template>

<script>
import { mdiDelete, mdiDragVertical } from '@mdi/js';

export default {
  name: 'LineHeading',
  props: ['line', 'index'],
  data() {
    return {
      mdiDelete,
      mdiDragVertical,
      localLineItem: {},
    };
  },
  watch: {
    localLineItem: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localLineItem);
        this.calculateTotals();
      },
    },
    line() {
      this.localLineItem = this.line;
    },
  },
  async mounted() {
    this.localLineItem = this.line;
  },
};
</script>
