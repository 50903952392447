<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
          <div class="text-h5 ml-2">
            Activity
          </div>
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :loading="isLoading"
              transition="expand-transition"
              type="article"
            >
              <FormActivity
                :Activity="editActivity"
                :isSaving="isSaving"
                @save="save"
                @cancel="$router.push({name: from.name })" />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormActivity from '../components/FormActivity.vue';

export default {
  components: {
    FormActivity,
  },
  name: 'EditActivity',
  module: 'Activity',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Activity. Default to {}.
      */
      editActivity: {},
      /**
       * Query Id for Activity
       */
      activityId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('activities', ['activities', 'activity']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.activityId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_clients();
    if (this.$route.query.clientId) {
      this.editActivity.clientId = this.$route.query.clientId;
    }
    if (this.mode === 'edit') {
      await this.LOAD_activity(this.activityId);
      this.editActivity = this.activity;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('activities', ['LOAD_activity', 'CREATE_activity', 'UPDATE_activity']),
    ...mapActions('clients', ['LOAD_clients']),
    /** Method used to save the item
     */
    async save(activity) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_activity(activity);
      } else {
        await this.UPDATE_activity(activity);
      }
      this.isSaving = false;
      if (this.$route.query.clientId) {
        this.$router.push({ name: 'quotes-ViewClient', query: { clientId: this.$route.query.clientId } });
      } else {
        this.$router.push({ name: 'quotes-ListActivity' });
      }
    },
  },
};
</script>

<docs>
# EditActivity example

'''js
<EditActivity :Activity="Activity" />
'''
</docs>
