<template>
  <v-container fluid>
    <v-radio-group
      class="select-dashboard"
      v-model="selectedDashboard"
      dense
      row
    >
      <v-radio
        label="Stats"
        value="Stats"
        dense
      ></v-radio>
      <v-radio
        label="Graphs"
        value="Graphs"
        dense
      ></v-radio>
    </v-radio-group>
    <DashboardStats v-if="selectedDashboard === 'Stats'" />
    <GraphBubbles v-else />
  </v-container>
</template>

<script>
import DashboardStats from '../components/DashboardStats.vue';
import GraphBubbles from '../modules/contracts/src/components/GraphBubbles.vue';

export default {
  name: 'Dashboard',
  components: {
    DashboardStats,
    GraphBubbles,
  },
  data() {
    return {
      selectedDashboard: 'Stats',
      deliveredItems: [],
      wipItems: [],
      isLoading: false,
    };
  },
};
</script>
<style scoped>
.select-dashboard {
  width: 200px;
  position: absolute;
  right: 10px;
  top: -5px;
}
</style>
