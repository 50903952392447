import { o } from 'odata';
import { authHub, errorHub } from '@codehq/aurora-app-core/src';

const odata = {
  o(rootGetters) {
    const token = window.localStorage.getItem('token');
    const organization = rootGetters['organizations/getOrganization'];
    return o(`${process.env.VUE_APP_APIURL ?? 'http://localhost'}/odata/`, this.getConf(token, organization));
  },
  getConf: (token, organization) => ({
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      organizationid: `${organization?.id}`,
    }),
    onError: (x, e) => {
      if (e.status === 401) {
        authHub.$emit('network:unauthorized', {
          source: 'odata',
          data: e,
        });
      } else {
        errorHub.$emit('network.error', e);
      }
    },
  }),
  parseId: (id) => {
    let i = id;
    if (Number.isNaN(id)) {
      i = `'${id}'`;
    }
    return i;
  },
};
export { odata };

export default {};
