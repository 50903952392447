<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-card :loading="isLoading">
    <v-card-title>
      <h5 title="Upload all your documents that belong to this agreement">
        {{ 'Agreement Documents (' + agreementAttachments.length + ')' }}
      </h5>
    </v-card-title>
    <v-card-text>
      <v-list three-line>
        <template v-for="(attachment, index) in agreementAttachments">
          <v-list-item :key="index">
            <v-list-item-avatar>
              <v-icon>{{ mdiFile }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="attachment.fileName"></v-list-item-title>
              <v-list-item-subtitle v-html="attachment.attachmentType"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon target="_blank" :href="attachment.fileUrl">
                <v-icon>{{ mdiDownload }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn :disabled="disabled" icon color="error" @click="remove(attachment)">
                <v-icon>{{ mdiDelete }}</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <DocumentViewer :url="attachment.fileUrl" />
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <FileUpload v-if="!disabled" @input="fileUploaded" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DocumentViewer from '@/components/DocumentViewer.vue';
import { mdiFile, mdiDownload, mdiDelete } from '@mdi/js';
import FileUpload from './FileUpload.vue';

export default {
  name: 'AgreementAttachments',
  mixins: [loading],
  module: 'Agreement',
  components: {
    DocumentViewer,
    FileUpload,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    Agreement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('agreementAttachments', ['agreementAttachments']),
  },
  data() {
    return {
      mdiDelete,
      mdiDownload,
      mdiFile,
      dialog: false,
    };
  },
  watch: {
    Agreement: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('agreementAttachments', [
      'CREATE_agreementAttachment',
      'LOAD_agreementAttachments',
      'DELETE_agreementAttachment',
    ]),
    async fileUploaded(item) {
      const { uploadedFile, url } = item;
      await this.CREATE_agreementAttachment({
        fileName: uploadedFile.fileName,
        attachmentType: uploadedFile.attachmentType,
        fileUrl: url,
        agreementId: this.Agreement.id,
      });
      await this.refresh();
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_agreementAttachments(this.Agreement.id);
      this.isLoading = false;
    },
    async remove(attachment) {
      const result = await this.$confirm('Are you sure you want to delete this attachment?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_agreementAttachment(attachment.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
  },
};
</script>
