<template>
      <div>
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="totalHits"
          sort-desc
        >
          <template v-slot:item.totalDuration="{ item }">
            {{ item.totalDuration }}s
          </template>
          <template v-slot:item.averageDuration="{ item }">
            {{ item.averageDuration }}s
          </template>
        </v-data-table>
      </div>
    </template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'TableTopUsings',
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('telemetry', ['routes']),
    headers() {
      return [
        {
          text: 'Destination',
          align: 'left',
          sortable: false,
          value: 'to',
        },
        { text: 'Total Hits', value: 'totalHits' },
        { text: 'Total Duration', value: 'totalDuration' },
        { text: 'Average Duration', value: 'averageDuration' },
      ];
    },
    items() {
      return _.chain(this.routes)
        .groupBy('to')
        .map((value, key) => ({
          to: key,
          totalHits: value.length,
          totalDuration: ((_.sumBy(value, 'duration') || 0) / 1000).toFixed(3),
          averageDuration: ((_.meanBy(value, 'duration') || 0) / 1000).toFixed(3),
        }))
        .value();
    },
  },
};
</script>
