<template>
  <v-card>
    <slot />
    <v-card-title class="d-flex justify-space-between">
      <span>
        {{ status }} ({{ agreements.length }})
      </span>
      <span>
        Total: {{ total(agreements) }}
      </span>
    </v-card-title>
    <v-card-text>
      <ul class="text-left">
        <li v-for="(agreement, index) in agreements" :key="index" class="d-flex justify-space-between">
          <router-link :to="getUrl(agreement)">
            {{ agreement.agreementNumber }}: {{ agreement.reference }} <span v-if="!hideStatus">({{ agreement.status }})</span>
          </router-link>
          <span>
            {{ agreementTotal(agreement) }}
          </span>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AgreementSummary',
  props: {
    currencyCode: {
      type: String,
      default: 'ZAR',
    },
    status: {
      type: String,
      default: '',
    },
    hideStatus: {
      type: Boolean,
      default: false,
    },
    agreements: {
      type: Array,
      required: true,
    },
    selectedType: {
      type: String,
      default: 'Recurring Profit',
    },
  },
  methods: {
    getUrl(agreement) {
      if (agreement.solution) {
        return { name: 'solutions-EditSolution', query: { id: agreement.solution.id } };
      }
      return { name: 'solutions-EditAgreement', query: { id: agreement.id } };
    },
    agreementTotal(agreement) {
      switch (this.selectedType) {
        default:
        case 'Recurring Profit':
          return this.formatCurrency(agreement.totalNonRecurringProfit, this.currencyCode);
        case 'Non-Recurring Profit':
          return this.formatCurrency(agreement.totalNonRecurring, this.currencyCode);
        case 'Total Profit':
          return this.formatCurrency(agreement.totalProfit, this.currencyCode);
        case 'Total Income':
          return this.formatCurrency(agreement.total, this.currencyCode);
      }
    },
    total(agreements) {
      switch (this.selectedType) {
        default:
        case 'Recurring Profit':
          return this.formatCurrency(agreements
            .reduce((acc, agreement) => acc + agreement.totalNonRecurringProfit, 0), this.currencyCode);
        case 'Non-Recurring Profit':
          return this.formatCurrency(agreements
            .reduce((acc, agreement) => acc + agreement.totalNonRecurring, 0), this.currencyCode);
        case 'Total Profit':
          return this.formatCurrency(agreements
            .reduce((acc, agreement) => acc + agreement.totalProfit, 0), this.currencyCode);
        case 'Total Income':
          return this.formatCurrency(agreements
            .reduce((acc, agreement) => acc + agreement.total, 0), this.currencyCode);
      }
    },
  },
};
</script>
