<template>
  <div>
    <v-menu
      v-model="localOpenModal"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-on="on"
          v-model="localDate"
          data-cy="SmallDatePicker-input"
          :disabled="disabled"
          :label="label"
          :rules="rules"
          @click:append="localOpenModal = true"
          @input="offClick"
          :hint="hint"
          :hide-details="hint.length === 0"
          :filled="filled"
          :id="id"
          :dense="dense"
          v-mask="'####-##-##'"
          :append-icon="disabled ? null : mdiCalendar"
          :background-color="backgroundColor"
          clearable
          ref="dateField"
          @change="$emit('change', $event)"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="UpdatedDate"
        @input="inputDate()"
      ></v-date-picker>
    </v-menu>
  </div>
</template>
<script>
import { mdiCalendar } from '@mdi/js';
import dayjs from 'dayjs';

export default {
  name: 'DatePicker',
  props: {
    id: {
      type: String,
      default: 'date-picker',
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: 'White',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Date',
    },
    hint: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localOpenModal: this.openModal,
      localDate: this.value,
      UpdatedDate: undefined,
      mdiCalendar,
    };
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.setLocalDate();
      },
    },
    localDate() {
      if (this.localDate?.length > 0) {
        if (dayjs(this.localDate, 'YYYY-MM-DD').isValid()
          && this.localDate.length === 10) {
          this.$log.debug('Valid date', this.localDate);
          this.$emit('input', this.localDate);
        } else {
          this.$log.debug('Invalid date');
        }
      } else {
        this.$emit('input', null);
      }
    },
  },
  mounted() {
    this.setLocalDate();
  },
  methods: {
    inputDate() {
      this.localOpenModal = false;
      this.localDate = this.UpdatedDate;
      this.$emit('input', this.localDate);
    },
    setLocalDate() {
      if (this.value?.length > 10) {
        this.localDate = this.value.substring(0, 10);
      } else {
        this.localDate = this.value ?? '';
      }
    },
    offClick() {
      if (this.localDate?.length >= 10) {
        this.UpdatedDate = this.localDate;
      }
      this.localOpenModal = false;
    },
  },
};
</script>
