<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            {{ $t('common.releaseNotes.title') }}
          </v-card-title>
          <v-card-text>
            <div v-html="html" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import showdown from 'showdown';
import markdown from '../CHANGELOG.md';

showdown.setFlavor('original');

export default {
  name: 'Release',
  data() {
    return {
      markdown,
    };
  },
  computed: {
    html() {
      const converter = new showdown.Converter();
      const html = converter.makeHtml(this.markdown);
      return html;
    },
  },
};
</script>
