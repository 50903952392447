<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="localData"
      class="elevation-1"
      :loading="isLoading"
      dense
      :search="search"
      show-expand
      single-expand
      :expanded="expanded"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
            <div class="text-h5 ml-5">
              Solutions
            </div>
          </v-col>
          <v-col cols="4">
          </v-col>
          <v-col cols="4">
            <v-text-field
              clearable
              class="mr-2"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 2" style="padding: 10px">
          <div v-for="contract in item.moneyIn" :key="contract.id">
            <TableMatchContracts
              :contract="contract"
              :contracts="item.moneyOut"
              @created="refresh" />
          </div>
        </td>
      </template>
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'solutions-EditSolution', query: { id: item.id } }">
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.profit="{ item }">
        {{ formatCurrency(item.profit, item.currency) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'solutions-EditSolution',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus, mdiMagnify } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import currencies from '~src/mixins/currencies';
import TableMatchContracts from '../components/TableMatchContracts.vue';

export default {
  name: 'ReportSolutions',
  module: 'Solutions',
  components: {
    TableBasicButtons,
    TableMatchContracts,
  },
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.reports',
        disabled: true,
      },
      {
        text: 'menu.solutions',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
      mdiMagnify,
      search: '',
      /**
      * Data
      */
      expanded: [],
      localData: [],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('currencies', ['selectedCurrency']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: 'Matched Lines',
          value: 'matchedLines',
        },
        {
          text: 'Unmatched Lines',
          value: 'unmatchedLines',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('accounts', ['LOAD_accounts']),
    ...mapActions('solutions', ['DELETE_solution']),
    ...mapActions('xeroAccounts', ['LOAD_xeroAccounts']),
    ...mapActions('xeroRepeatingInvoices', ['LOAD_xeroRepeatingInvoices']),
    /** Delete Solution based on the selected currency
     */
    async refresh() {
      this.$log.debug('Refresh solutions');
      this.isLoading = true;
      await Promise.all([
        (async () => {
          const url = '/api/contracts/reports/solutions';
          const { data } = await this.$http.get(url);
          this.localData = data;
        })(),
        (async () => {
          await this.LOAD_xeroRepeatingInvoices();
        })(),
        (async () => {
          await this.LOAD_accounts();
        })(),
        (async () => {
          await this.LOAD_xeroAccounts();
        })(),
      ]);
      this.isLoading = false;
    },
    /** Delete Solution from the collection
     */
    async remove(solution) {
      this.isLoading = true;
      await this.DELETE_solution(solution.id);
      this.isLoading = false;
    },
  },
};
</script>
