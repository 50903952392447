<template>
  <v-form lazy-validation v-model="valid" @submit.prevent="submit" ref="form" v-if="localContract">
    <v-row no-gutters flex class="justify-center align-center"> </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="2" xl="2">
        <v-select
          class="d-inline-block"
          data-cy="FormContract-contractType"
          :disabled="disabled"
          :items="contractTypes"
          v-model="localContract.contractType"
          filled
          label=""
        />
      </v-col>
      <v-col cols="12" md="6" lg="4" xl="4">
        <v-autocomplete
          auto-select-first
          data-cy="FormContract-contact"
          :disabled="disabled"
          filled
          :label="$$t('contact')"
          :items="availableContacts"
          item-value="id"
          item-text="name"
          :rules="requiredFieldRules"
          @change="onContactChange"
          v-model="localContract.contactId"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="2">
        <v-select
          filled
          data-cy="FormContract-currency"
          :disabled="disabled"
          v-model="localContract.currency"
          :items="currencies"
        >
        </v-select>
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="2">
        <v-select
          class="d-inline-block"
          data-cy="FormContract-status"
          :disabled="disabled"
          :items="statuses"
          v-model="localContract.status"
          filled
          label="Status"
        />
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="2">
        <DatePicker
          filled
          data-cy="FormContract-signedDate"
          :disabled="disabled"
          :rules="requiredFieldRules"
          :label="$$t('signedDate')"
          v-model="localContract.signedDate"
        />
      </v-col>
      <v-col cols="12" md="6" lg="6" xl="6">
        <v-text-field
          filled
          data-cy="FormContract-name"
          :disabled="disabled"
          :label="$$t('name')"
          v-model="localContract.name"
          counter="100"
        />
      </v-col>
      <v-col cols="12" md="6" lg="2" xl="2">
        <v-select
          filled
          :disabled="disabled"
          :label="$$t('invoiceFrequency')"
          :items="frequencies"
          v-model="localContract.invoiceFrequency"
        ></v-select>
      </v-col>
      <!-- <v-col cols="12" md="6" lg="4" xl="4">
        <v-autocomplete
          auto-select-first
          filled
          data-cy="FormContract-repeatingInvoiceId"
          item-value="repeatingInvoiceID"
          :item-text="i => (`${i.contact.name}: ${i.reference}`)"
          :items="repeatingInvoices"
          label="Repeating Invoice"
          v-model="localContract.repeatingInvoiceId"
        >
          <template v-slot:append-outer>
            <div class="import-button-container">
            <v-btn
              data-cy="FormContract-button-import"
              color="primary"
              @click="importRepeatingInvoice"
              :disabled="disabled"
            >
              Import
            </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <Sections v-model="localContract" @onUpdatedSections="onUpdatedSections" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h3 class="primary--text">{{ $$t('notes') }}</h3>
      </v-col>
      <v-col cols="3">
        <h3 class="primary--text">{{ $$t('section') }}</h3>
      </v-col>
      <v-col cols="3">
        <h3 class="primary--text">{{ $$t('total') }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="notes">
        <!-- <v-textarea
          filled
          data-cy="FormContract-notes"
          :disabled="disabled"
          :label="$$t('notes')"
          v-model="localContract.notes"
        /> -->
        <div v-if="disabled" v-html="localContract.notes" />
        <vue-editor
          v-else
          :editorToolbar="customToolbar"
          :disabled="disabled"
          :label="$$t('notes')"
          data-cy="FormContract-notes"
          outlined
          v-model="localContract.notes"
          counter="2000"
        >
        </vue-editor>
      </v-col>
      <v-col class="overflow-y-auto section-totals pa-0" cols="6">
        <div class="section-totals-inner">
          <div class="section-total-row">
            <v-row class="ma-0" v-for="(total, index) in displayTotals" :key="index" justify="end">
              <v-col v-if="index < displayTotals.length - 1" class="h-100" cols="6">
                <v-text-field
                  filled
                  :data-cy="`FormContract-totalTitle-${index}`"
                  :disabled="disabled"
                  :hide-details="index !== displayTotals.length - 2"
                  :label="$$t('totalTitle')"
                  readonly
                  :value="total.section.title"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  filled
                  :data-cy="`FormContract-total-${index}`"
                  :disabled="disabled"
                  :hide-details="index !== displayTotals.length - 2"
                  :label="index === displayTotals.length - 1 ? $$t('grandTotal') : $$t('total')"
                  readonly
                  :value="formatCurrency(total.total, localContract.currency)"
                />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ContractAttachments v-model="localContract" />
      </v-col>
    </v-row>
    <!-- buttons -->
    <div class="d-flex justify-end mt-6">
      <!-- <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
      :loading="isSaving"
        data-cy="FormQuote-button-cancel">
        {{ $t('common.buttons.cancel') }}
      </v-btn> -->
      <v-btn
        color="primary"
        type="button"
        class="ml-4"
        :loading="isSaving"
        :disabled="disabled"
        @click="$emit('save', localContract)"
        data-cy="FormContract-button-save"
      >
        {{ $t('common.buttons.save') }}
      </v-btn>
      <v-btn
        color="primary"
        type="button"
        class="ml-4"
        :loading="isSaving"
        :disabled="disabled"
        @click="$emit('close', localContract)"
        data-cy="FormContract-button-close"
      >
        {{ $t('common.buttons.saveClose') }}
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import { mdiDelete, mdiFileDownloadOutline } from '@mdi/js';
import { sortBy } from 'lodash';
import DatePicker from '~src/components/DatePicker.vue';
import contacts from '~src/mixins/contacts';
import { VueEditor } from 'vue2-editor';
import requiredField from '~src/mixins/required-field';
import ContractAttachments from './ContractAttachments.vue';
import Sections from './Sections.vue';
import accounts from '../mixins/accounts';
import contractTypes from './contractTypes.json';
import currencies from './currencies.json';
import frequencies from './frequencies.json';
import { eventBus } from '../../../../main';

export default {
  name: 'FormContract',
  module: 'Contract',
  components: {
    ContractAttachments,
    DatePicker,
    Sections,
    VueEditor,
  },
  mixins: [accounts, contacts, requiredField],
  props: {
    /**
     * Set the text box value. Default: undefined,
     */
    Contract: {
      type: Object,
      default: undefined,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contractTypes,
      currencies,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      frequencies,
      localContract: {
        contractType: 'ACCREC',
        contractItems: [{}],
        files: [],
      },
      mdiFileDownloadOutline,
      mdiDelete,
      totals: [],
      /**
       * Hold form validation state
       */
      valid: false,
      /**
       * Local value used for property localContract
       */
    };
  },
  computed: {
    ...mapState('xeroRepeatingInvoices', ['xeroRepeatingInvoices']),
    availableContacts() {
      switch (this.localContract.contractType) {
        case 'ACCREC':
          return this.customerContacts;
        case 'ACCPAY':
        case 'OPEX':
          return this.supplierContacts;
        default:
          return [];
      }
    },
    disabled() {
      return (
        this.Contract.status === 'Accepted' ||
        this.Contract.status === 'Cancelled' ||
        this.Contract.status === 'Delivered' ||
        this.Contract.status === 'In Progress' ||
        this.Contract.status === 'Upgraded'
      );
    },
    displayTotals() {
      return this.totals.concat({
        section: undefined,
        total: this.totals.reduce((prev, curr) => prev + curr.total, 0),
      });
    },
    files() {
      return this.localContract.files?.filter((file) => !file.isDeleted) ?? [];
    },
    /**
     * Returns the address for the selected contact
     */
    contactAddress() {
      //       this.localContract.contactId = contact.id;
      // this.localContract.contactSource = contact.source;
      // this.localContract.contactName = contact.name;
      let result = '';
      const { contactId, contactSource } = this.localContract;
      if (contactId) {
        switch (contactSource) {
          case 'contact':
            result = this.$store.getters['contacts/getContactById'](contactId).address;
            break;
          case 'xero':
            result = this.$store.getters['xeroContacts/getContactById'](contactId).address;
            break;
          default:
            result = '';
        }
      }
      return result;
    },
    repeatingInvoices() {
      const { xeroRepeatingInvoices } = this;
      const { contractType } = this.localContract;
      const repeatingInvoices = xeroRepeatingInvoices.filter((i) => i.type === contractType);
      return sortBy(repeatingInvoices, (i) => i.contact.name);
    },
    statuses() {
      return [
        {
          text: 'Draft',
          value: 'Draft',
          disabled: false,
        },
        {
          text: 'Certain',
          value: 'Certain',
          disabled: false,
        },
        {
          text: 'Accepted',
          value: 'Accepted',
          disabled: true,
        },
        {
          text: 'In Progress',
          value: 'In Progress',
          disabled: true,
        },
        {
          text: 'Delivered',
          value: 'Delivered',
          disabled: true,
        },
        {
          text: 'Upgraded',
          value: 'Upgraded',
          disabled: true,
        },
      ];
    },
  },
  watch: {
    Contract: {
      immediate: true,
      deep: true,
      handler() {
        this.localContract = this.Contract;
      },
    },
    localContract: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localContract);
      },
    },
  },
  async created() {
    this.localContract = this.Contract;
  },
  methods: {
    importRepeatingInvoice() {
      const { xeroAccounts, xeroRepeatingInvoices } = this;
      const { repeatingInvoiceId } = this.localContract;
      // eslint-disable-next-line max-len
      const repeatingInvoice = xeroRepeatingInvoices.find(
        (x) => x.repeatingInvoiceID === repeatingInvoiceId,
      );
      if (repeatingInvoice) {
        repeatingInvoice.lineItems.forEach((item) => {
          const account = xeroAccounts.find((a) => a.code === item.accountCode);
          this.localContract.contractItems.push({
            description: item.description,
            quantity: item.quantity,
            amount: item.unitAmount,
            accountId: account?.accountID,
          });
        });
      }
    },
    // doSave(e) {
    //   if (!(e.keyCode === 83 && (e.ctrlKey || e.metaKey))) {
    //     return;
    //   }

    //   e.preventDefault();
    //   // this.submit();
    // },
    onUpdatedSections(totals) {
      this.totals = totals;
    },

    onContactChange() {
      const contact = this.allContacts.find((c) => c.id === this.localContract.contactId);
      this.localContract.contactSource = contact.source;
      this.localContract.contactName = contact.name;
    },

    onEventBusValidate(callback) {
      if (this.$refs.form && !this.$refs.form.validate()) {
        if (callback) {
          callback();
        }
      }
    },

    fileUploaded(file) {
      this.localContract.files.push({
        url: file.url,
        name: file.uploadedFile.fileName,
        isDeleted: false,
      });
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localContract);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
    /** Close form trigger
     * @event cancel
     */
    close() {
      this.$emit('close', this.localContract);
    },
    removeLine(index) {
      this.localContract.contractItems[index].isDeleted = true;
    },
  },
  mounted() {
    eventBus.$on('onEventBusValidate', this.onEventBusValidate);
  },
};
</script>

<style scoped>
.import-button-container {
  margin-top: -0.3125rem;
}

.notes {
  min-height: 12rem;
}

.notes-totals {
  max-height: 31.25rem;
}

.section-totals {
  position: relative;
}

.section-total-row {
  position: absolute;
  width: 100%;
}
</style>
