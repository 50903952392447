<template>
  <v-card>
    <v-card-title>
      <v-app-bar color="primary">
        <span class="title">{{ title }}</span>
        <span class="title-right text--white">
          {{ titleRight }}
        </span>
        <v-spacer></v-spacer>
        <slot name="action" />
      </v-app-bar>
    </v-card-title>
    <v-card-text class="card-text">
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'Card',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleRight: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.title-right {
  position: absolute;
  right: 0;
  margin-right: 10px;
  color: white;
}
.title {
  color: white;
}
.v-card__title {
  padding: 0 !important;
}
.card-text > div {
  max-height: 400px;
  scroll-behavior: auto;
  overflow-y: scroll;
}
.card-text {
  max-height: 400px;
  scroll-behavior: auto;
  overflow-y: scroll;
}
</style>
