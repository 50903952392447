<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-autocomplete
          auto-select-first
          data-cy="FormExchangeRate-sourceCurrency"
          filled
          :label="$$t('sourceCurrency')"
          :items="currencies"
          item-text="name"
          item-value="code"
          disabled
          v-model="localExchangeRate.sourceCurrency"
        ></v-autocomplete>
        <v-autocomplete
          auto-select-first
          data-cy="FormExchangeRate-destinationCurrency"
          filled
          :label="$$t('destinationCurrency')"
          :items="currencies"
          item-text="name"
          item-value="code"
          v-model="localExchangeRate.destinationCurrency"
        ></v-autocomplete>
        <v-text-field
          filled
          data-cy="FormExchangeRate-rate"
          :label="$$t('rate')"
          v-model="localExchangeRate.rate"
          @click:append="getRate"
          :append-icon="mdiWeb"
          :loading="loadingExchangeRate"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormExchangeRate-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormExchangeRate-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapGetters } from 'vuex';
import { mdiWeb } from '@mdi/js';

export default {
  name: 'FormExchangeRate',
  module: 'ExchangeRate',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    ExchangeRate: {
      type: Object,
      default: undefined,
    },
  },
  computed: {
    ...mapGetters('exchangeRates', ['currencies']),
  },
  data() {
    return {
      mdiWeb,
      loadingExchangeRate: false,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localExchangeRate
      */
      localExchangeRate: {},
    };
  },
  watch: {
    ExchangeRate() {
      this.localExchangeRate = this.ExchangeRate;
    },
  },
  created() {
    this.localExchangeRate = this.ExchangeRate;
  },
  methods: {
    /**
     * Executes getRate
     */
    async getRate() {
      this.loadingExchangeRate = true;
      const { sourceCurrency, destinationCurrency } = this.localExchangeRate;
      const url = `api/currency/convert/${sourceCurrency}/${destinationCurrency}`;
      const response = await this.$http.get(url);
      // eslint-disable-next-line prefer-destructuring
      this.localExchangeRate.rate = Object.values(response.data)[0];
      this.loadingExchangeRate = false;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    async submit() {
      // validate
      if (this.$refs.form.validate()) {
        const item = { ...this.ExchangeRate };
        this.$emit('save', item);
        await this.$nextTick();
        this.localExchangeRate.destinationCurrency = '';
        this.localExchangeRate.rate = undefined;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormExchangeRate example

'''js
<FormExchangeRate :ExchangeRate="ExchangeRate" />
'''
</docs>
