<template>
 <v-container fluid>
  <v-row>
    <v-col cols="12">
      <v-card>
        <ProgressLinearTimeout v-if="isLoading" />
        <v-row v-if="editSolution">
          <v-col cols="3">
            <v-card-title primary-title>
              <div class="text-h5 mr-2">
                Solution {{ editSolution.solutionNumber }}
                <v-icon
                  v-if="!editSolution.isActive"
                  small
                  class="ml-2"
                  title="Unarchive"
                  color="error"
                  @click="saveIsActive(editSolution, true)"
                >
                  {{ mdiArchive }}
                </v-icon>
              </div>
            </v-card-title>
            <v-card-subtitle v-if="summary && showTotal">
              <v-icon color="secondary" class="mr-2 mb-1" @click="showTotal = !showTotal" title="Show recurring totals">
                {{ mdiCash }}
              </v-icon>
              Total:
              {{ formatCurrency(summary.total ?? 0, selectedCurrency) }} ({{ gp }}% GP)
            </v-card-subtitle>
            <v-card-subtitle v-if="summary && !showTotal">
              <v-icon color="secondary" class="mr-2 mb-1" @click="showTotal = !showTotal" title="Show total">
                {{ mdiCash }}
              </v-icon>
              Total Recurring:
              {{ formatCurrency(summary.totalRecurring ?? 0, selectedCurrency) }} ({{ recurringGp }}% GP)
            </v-card-subtitle>
          </v-col>
          <v-col cols="3">
            <v-select
              filled
              dense
              :items="users"
              data-cy="EditSolution-ownerId"
              :label="$$t('owner')"
              item-text="displayName"
              item-value="createdById"
              v-model="editSolution.ownerId"
              @change="updateOwner"
            />
          </v-col>
          <v-col cols="5" v-if="summary" class="text-right">
            <span class="mr-2 ml-2 d-block">
              Total Recurring Profit:
              {{ formatCurrency(summary.totalRecurringProfit, selectedCurrency) }}
            </span>
            <span class="mr-2 ml-2 d-block">
              Total Non-Recurring Profit:
              {{ formatCurrency(summary.totalNonRecurringProfit, selectedCurrency) }}
            </span>
          </v-col>
          <v-col class="text-right" cols="1" v-if="summary">
            <div class="mr-5">
              <RocketMenu :items="availableActions" @click="onAction" />
            </div>
          </v-col>
        </v-row>
        <v-card-text v-if="editSolution">
          <FormSolution :Solution="editSolution" @input="debouncedSave"
            @close="close"
          />
          <ExchangeRates v-if="!isSaving" v-model="editSolution" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiArchive, mdiCash } from '@mdi/js';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import clients from '~src/mixins/clients';
import suppliers from '~src/mixins/suppliers';
import ExchangeRates from '../components/ExchangeRates.vue';
import FormSolution from '../components/FormSolution.vue';
import RocketMenu from '../components/RocketMenu.vue';

export default {
  components: {
    ExchangeRates,
    FormSolution,
    RocketMenu,
  },
  name: 'EditSolution',
  module: 'Solutions',
  mixins: [clients, loading, suppliers],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.solutions',
        to: { name: 'solutions-ListSolution' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      mdiArchive,
      mdiCash,
      /**
      * Get/Set editing Solution. Default to {}.
      */
      editSolution: undefined,
      /**
       * Query Id for Solution
       */
      solutionId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('companySettings', ['companySettings']),
    ...mapState('contracts', ['contracts']),
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('solutions', ['solutions', 'solution', 'summary']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
    ...mapState('users', ['users']),
    ...mapState('controls', ['defaultTotal']),
    availableActions() {
      return [
        // 'Print',
        // 'Cancel',
        'Archive',
      ];
    },
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.solutionId) {
        return 'edit';
      }
      return 'add';
    },
    gp() {
      return Math.round((
        parseFloat(this.summary.totalProfit ?? '0') / parseFloat(this.summary.total ?? '0')) * 100);
    },
    recurringGp() {
      const { totalRecurringProfit, totalRecurring } = this.summary;
      if (totalRecurring === 0 || totalRecurringProfit === 0) {
        return 0;
      }
      return Math.round((
        parseFloat(totalRecurringProfit ?? '0') / parseFloat(totalRecurring ?? '0')) * 100);
    },
    /**
     * Computed property for the defaultTotal vuex property
     */
    showTotal: {
      get() {
        return this.defaultTotal;
      },
      set(newValue) {
        this.SET_defaultTotal(newValue);
      },
    },
  },
  watch: {
    $route() {
      this.solutionId = this.$route?.query.id;
      this.refresh();
    },
    selectedCurrency() {
      this.refresh();
    },
  },
  async created() {
    await this.LOAD_companySettings();
    this.$log.info('Clear solution data');
    this.SET_agreements([]);
    this.SET_agreementHeaders([]);
    this.SET_incomeLines([]);
    this.SET_summary(undefined);
    this.SET_solution(undefined);
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('data:incomeLine-updated', () => {
      debounce(() => {
        if (this.editSolution?.id) {
          this.LOAD_summary(this.editSolution.id);
        }
      }, 500)();
    });
  },
  beforeDestroy() {
    this.$root.$off('data:incomeLine-updated', this.refresh);
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('agreements', ['CREATE_agreement']),
    ...mapActions('incomeLines', ['CREATE_incomeLine']),
    ...mapActions('comments', ['LOAD_solutionComments']),
    ...mapActions('clients', ['LOAD_clients']),
    ...mapActions('orgTaxRates', ['LOAD_orgTaxRates']),
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates']),
    ...mapActions('revenueLines', ['LOAD_revenueLines']),
    ...mapActions('people', ['LOAD_people']),
    ...mapActions('solutions', [
      'LOAD_solution',
      'LOAD_summary',
      'CREATE_solution',
      'UPDATE_solution',
    ]),
    ...mapActions('suppliers', ['LOAD_suppliers']),
    ...mapActions('users', ['LOAD_users']),
    ...mapActions('xeroContacts', ['LOAD_xeroContacts']),
    ...mapActions('companySettings', ['LOAD_companySettings']),
    ...mapMutations('agreements', ['SET_agreements', 'SET_agreementHeaders']),
    ...mapMutations('controls', ['SET_defaultTotal']),
    ...mapMutations('incomeLines', ['SET_incomeLines']),
    ...mapMutations('solutions', ['SET_solution', 'SET_summary']),
    /** Method used to refresh the view
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_companySettings();
      if (this.mode === 'edit') {
        this.editSolution = await this.LOAD_solution(this.$route?.query.id);
      } else {
        let solution = {};
        solution.notes = this.companySettings.find(
          (setting) => setting.name === 'defaultSolutionNotes',
        )?.value;
        solution = await this.CREATE_solution(solution);

        const newAgreement = {
          solutionId: solution.id,
          currencyCode: this.selectedCurrency,
          reference: 'New Agreement',
          status: 'Draft',
        };
        newAgreement.notes = this.companySettings.find(
          (setting) => setting.name === 'defaultAgreementNotes',
        )?.value;
        // create agreement
        const agreement = await this.CREATE_agreement(newAgreement);
        await this.CREATE_incomeLine({
          agreementId: agreement.id,
        });
        this.$router.push({ name: 'solutions-EditSolution', query: { id: solution.id } });
      }
      await Promise.all([
        (async () => {
          if (this.editSolution?.id) {
            await this.LOAD_summary(this.editSolution.id);
            await this.LOAD_solutionComments(this.editSolution.id);
          } else {
            this.$log.info('Skipping loading summary and comments as there is no solution id');
          }
        })(),
        (async () => {
          await this.LOAD_quoteTemplates();
        })(),
        (async () => {
          await this.LOAD_orgTaxRates();
        })(),
        (async () => {
          await this.LOAD_suppliers({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_revenueLines();
        })(),
        (async () => {
          await this.loadAllClients();
        })(),
        (async () => {
          await this.loadAllSuppliers();
        })(),
        (async () => {
          await this.LOAD_clients({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_people();
        })(),
        (async () => {
          await this.LOAD_users();
        })(),
        (async () => {
          await this.LOAD_xeroContacts();
        })(),
      ]);
      this.isLoading = false;
    },
    onAction(action) {
      switch (action) {
        case 'Cancel':
          this.copy();
          break;
        case 'Archive':
          this.archive();
          break;
        case 'Print':
          this.print();
          break;
        default:
          break;
      }
    },
    async print() {
      this.$refs.modalPrint.open();
    },
    async archive() {
      const res = await this.$confirm('Are you sure you want to archive this solution?');
      if (res) {
        await this.save({
          id: this.editSolution.id,
          isActive: false,
        });
        this.$router.push({ name: 'solutions-ListSolution' });
      }
    },
    /** Method used to close the view
     */
    async close(solution) {
      await this.save(solution);
      this.$router.push({ name: 'solutions-ListSolution' });
    },
    /** Method used to save the item
     */
    async save(solution) {
      this.$log.info(`Saving solution: ${solution.solutionNumber}`);
      this.isSaving = true;
      if (solution?.id) {
        await this.UPDATE_solution(solution);
      }
      this.isSaving = false;
    },
    /**
     * Archive Solution
     */
    async saveIsActive(item, isActive) {
      // eslint-disable-next-line max-len
      const result = await this.$confirm(`Are you sure you want to ${!isActive ? 'archive' : 'unarchive'} this solution?`);
      if (!result) {
        return;
      }
      console.log(item);
      await this.UPDATE_solution({
        id: item.id,
        isActive,
      });
      await this.refresh();
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (solution) {
      this.$log.info(`Debounced saving solution: ${solution.solutionNumber}`);
      if (this.isLoading) {
        this.$log.info('Skipping save as we are loading');
        return;
      }
      this.save(solution);
    }, 1000),
    updateOwner() {
      const user = this.users
        .find((u) => u.createdById === this.editSolution.ownerId);
      this.editSolution.owner = user?.displayName ?? user?.createdBy;
      this.editSolution.ownerEmail = user?.createdBy;
    },
  },
};
</script>

<docs>
# EditSolution example

'''js
<EditSolution :Solution="Solution" />
'''
</docs>
