import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('xeroRepeatingInvoice', 'xeroRepeatingInvoices');

const customActions = {
  async LOAD_xeroRepeatingInvoices({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroRepeatingInvoices')
      .query({
        $filter: 'IsDeleted eq false AND Status ne \'DELETED\'',
        $expand: 'LineItems,Contact',
      });
    commit('SET_xeroRepeatingInvoices', data);
    return data;
  },
};

export default { ...actions, ...customActions };
