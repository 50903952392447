import { mapActions, mapState } from 'vuex';
import { sortBy } from 'lodash';

export default {
  computed: {
    ...mapState('suppliers', ['suppliers']),
    ...mapState('xeroContacts', ['xeroSuppliers']),
    allSuppliers() {
      const { suppliers, xeroSuppliers } = this;
      const localSuppliers = suppliers?.map((c) => ({ id: c.id, name: c.name, source: 'local', isActive: c.isActive })) ?? [];
      const accContacts = xeroSuppliers?.map((c) => ({ id: c.contactID, name: c.name, source: 'xero' })) ?? [];
      return sortBy([
        ...localSuppliers,
        ...accContacts,
      ], (c) => c.name);
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_suppliers', 'DELETE_client']),
    ...mapActions('xeroContacts', ['LOAD_xeroSuppliers']),
    async loadAllSuppliers() {
      await Promise.all([
        (async () => {
          await this.LOAD_suppliers({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_xeroSuppliers();
        })(),
      ]);
    },
  },
};
