<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true"></v-progress-linear>
      Logging you in...
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'Init',
  async mounted() {
    this.$log.info('logged in, loading organizations');
    // const { $route } = this;
    try {
      const result = await this.LOAD_organizations();
      this.$log.debug('organizations loaded', result);
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 1500));
      if (result?.length > 0) {
        this.$router.push({ name: 'app-Home' });
        // if ($route.redirect) {
        //   this.$router.push({
        //     path: $route.query.redirect,
        //     query: $route.query.query,
        //   });
        // } else {
        //   this.$router.push({ name: 'app-Home' });
        // }
      } else {
        this.$log.debug('init: no organizations found, redirecting to onboard');
        if (this.$route.name !== 'app-Onboard') {
          this.$router.push({ name: 'app-Onboard' });
        }
      }
    } catch (error) {
      this.$log.error(error);
    }
  },
  methods: {
    ...mapActions('organizations', ['LOAD_organizations']),
  },
};
</script>
