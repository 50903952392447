import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('address', 'addresses');

const customActions = {
  async LOAD_clientAddresses({ commit, rootGetters }, clientId) {
    const data = await odata.o(rootGetters)
      .get('addresses')
      .query({ $filter: `IsDeleted eq false AND ClientId eq ${clientId}` });
    commit('SET_addresses', data);
    return data;
  },
  async LOAD_supplierAddresses({ commit, rootGetters }, supplierId) {
    const data = await odata.o(rootGetters)
      .get('addresses')
      .query({ $filter: `IsDeleted eq false AND SupplierId eq ${supplierId}` });
    commit('SET_addresses', data);
    return data;
  },
};

export default { ...actions, ...customActions };
