<template>
  <v-container>
    <v-row class="mt-6" justify="center" no-gutters>
      <v-col lg="8">
        <v-card flat class="rounded-0 ma-3 pa-4">
          <v-container>
            <v-row class="mt-3" justify="center" no-gutters>
              <v-col lg="6">
                <v-card flat>
                  <v-card-title class="text-center justify-center py-">
                    <h1 class="font-weight-bold title mb-3 text-capitalize">
                      {{ $t('common.changePasswordTitle') }}
                    </h1>
                  </v-card-title>
                  <v-card-text>
                    <PasswordField
                        v-model="oldPassword"
                        :rules="[rules.required, rules.passwordMin]"
                        :label="$t('common.oldPassword')"
                      />
                    <PasswordField
                        v-model="newPassword"
                        :rules="[rules.required, rules.passwordMin]"
                        :label="$t('common.newPassword')"
                      />
                    <v-alert type="error" v-for="error in errors" :key="error">
                      {{ error }}
                    </v-alert>
                    <v-btn
                    color="primary"
                    block
                    @click="reset"
                    :loading="isLoading"
                  >
                    Reset
                    </v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authMixins from '@codehq/aurora-app-core/src/mixins/auth';
import PasswordField from '../components/PasswordField.vue';

export default {
  name: 'ChangePassword',
  mixins: [authMixins],
  components: {
    PasswordField,
  },
  data() {
    return {
      valid: false,
      isLoading: false,
      email: '',
      oldPassword: '',
      newPassword: '',
      message: '',
      rules: {
        email: [
          (v) => !!v || 'This field is required',
          // eslint-disable-next-line max-len
          (v) => /^[A-z0-9\-.]{3}.*@[A-z0-9]*\.[A-z]{2}.*$/gi.test(v) || 'Please provide valid E-Mail',
        ],
        password: [
          (v) => !!v || 'This field is required',
          (v) => v.length > 7 || 'Password should be longer',
        ],
        required: [
          (v) => !!v || 'This field is required',
        ],
      },
    };
  },
  methods: {
    reset() {
      const self = this;
      const { oldPassword, newPassword } = this;
      this.errors = [];
      self.$root.$emit('user.changingPassword');
      this.isLoading = true;
      this.$http.post('/api/account/changePassword', {
        oldPassword,
        newPassword,
        confirmPassword: newPassword,
      }).then(() => {
        this.isLoading = false;
        this.$root.$emit('toast:notify', this.$t('common.phrases.passwordChangeSuccess'));
        this.$router.push({ name: 'app-Home' });
      })
        .catch((e) => {
          this.isLoading = false;
          self.message = e.response.data.message;
          this.errors = [e.response.data.message];
        });
    },
  },
  mounted() {
    this.username = this.$route.query.username;
    this.code = this.$route.query.code;
  },
};
</script>
