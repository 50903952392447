<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        {{ $$t('title') }}
        <v-spacer></v-spacer>
        <v-btn-toggle mandatory v-model="selectedReport">
          <v-btn small value="forecast" color="success">
            Forecast
          </v-btn>
          <v-btn small class="text--white" value="bubbles" color="secondary">
            Actual
          </v-btn>
        </v-btn-toggle>
        <v-btn icon @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="localData.length > 0 && !isLoading">
        <GraphProfit
          v-if="selectedReport === 'forecast'" :forecastData="localData"
          @click="graphProfitClicked"
          @selectedContractType="selectedContractType = $event"
          @selectedStatus="selectedStatus = $event"
        />
        <GraphOverview v-if="selectedReport === 'pie'" :forecastData="localData" />
        <GraphBubbles v-if="selectedReport === 'bubbles'" />
      </v-card-text>
      <v-card-text v-if="month">
        <TableGpPerMonth
          :month="month"
          :index="index"
          :isExpiring="isExpiring"
          :selectedContractType="selectedContractType"
          :selectedStatus="selectedStatus"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiHome, mdiRefresh } from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import GraphOverview from '~modules/contracts/src/components/GraphOverview.vue';
import GraphProfit from '~modules/contracts/src/components/GraphProfit.vue';
import GraphBubbles from '~modules/contracts/src/components/GraphBubbles.vue';
import TableGpPerMonth from '~modules/contracts/src/components/TableGpPerMonth.vue';
import currencies from '../mixins/currencies';

export default {
  name: 'Home',
  module: 'Home',
  mixins: [currencies, loading],
  components: {
    GraphBubbles,
    GraphOverview,
    GraphProfit,
    TableGpPerMonth,
  },
  data() {
    return {
      mdiHome,
      mdiRefresh,
      localData: [],
      selectedReport: 'forecast',
      month: undefined,
      index: undefined,
      isExpiring: undefined,
      selectedContractType: [],
      selectedStatus: [],
    };
  },
  computed: {
    ...mapState('currencies', ['currencies', 'selectedCurrency']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('contracts', ['LOAD_contracts']),
    async graphProfitClicked({ month, index, isExpiring }) {
      this.month = month;
      this.index = index;
      this.isExpiring = isExpiring;
    },
    async refresh() {
      this.$log.debug('Refresh home');
      this.isLoading = true;
      const url = `/api/contracts/reports/forecast?currencyCode=${this.selectedCurrency}`;
      const { data } = await this.$http.get(url);
      this.localData = data;
      this.isLoading = false;
    },
  },
};
</script>
