<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        Quote Defaults
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                filled
                :items="quoteTemplates"
                v-model="quoteDefault.templateId"
                item-text="name"
                item-value="id"
                label="Template"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="text-h6">Default Notes</div>
              <vue-editor v-model="quoteDefault.notes"></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="primary"
                @click="updateQuoteDefault"
                :loading="isSaving"
              >
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from 'vue2-editor';
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'QuoteDefaults',
  module: 'Quote',
  mixins: [loading],
  components: {
    VueEditor,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.quoteDefaults',
      },
      ],
  },
  data() {
    return {
      quoteDefault: {},
    };
  },
  computed: {
    ...mapState('quotes', ['statuses', 'stages']),
    ...mapState('quoteTemplates', ['quoteTemplates']),
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_quoteTemplates();
      const { data } = await this.$http.get('api/defaults/quotes');
      this.quoteDefault = data;
      this.isLoading = false;
    },
    async updateQuoteDefault() {
      this.isSaving = true;
      const { quoteDefault } = this;
      await this.$http.put('api/defaults/quotes/', quoteDefault);
      // this.quoteDefault = data;
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
      // Add code here to update the user's profile
    },
  },
};
</script>
