<template>
  <v-card>
    <v-card-title primary-title>
      {{ title }}
      <v-btn icon @click="refresh">
        <v-icon>{{ mdiRefresh }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-data-table
      :headers="headers"
      :items="jobsRecurring"
      :search="search"
      :loading="isLoading"
      >
        <template v-slot:top>
          <v-row>
            <v-col cols="6">
              <v-text-field
                name="Search"
                label="Search"
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.lastExecution="{ item }">
          {{ formatDateTime(item.lastExecution) }} {{ item.timeZoneId }}
        </template>
        <template v-slot:item.nextExecution="{ item }">
          {{ formatDateTime(item.nextExecution) }} {{ item.timeZoneId }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="run(item)" small color="default">Run Now</v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'HangfireListRecurringJobs',
  module: 'Hangfire',
  props: ['title'],
  mixins: [loading],
  data() {
    return {
      mdiRefresh,
      dialog: false,
      search: '',
    };
  },
  computed: {
    ...mapState('hangfireJobs', ['jobsRecurring']),
    headers() {
      return [{
        text: this.$$t('id'),
        value: 'id',
      }, {
        text: this.$$t('lastExecution'),
        value: 'lastExecution',
      }, {
        text: this.$$t('lastJobState'),
        value: 'lastJobState',
      }, {
        text: this.$$t('nextExecution'),
        value: 'nextExecution',
      }, {
        text: '',
        value: 'actions',
      }];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('hangfireJobs', ['LOAD_jobsRecurring']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_jobsRecurring();
      this.isLoading = false;
    },
    async run(job) {
      await this.$http.post(`api/hangfire/jobs/${job.id}`);
      this.$root.$emit('toast:notify', 'Job queued');
      await this.$nextTick();
      await this.refresh();
    },
  },
};
</script>
