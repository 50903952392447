<!-- eslint-disable max-len -->
<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn small color="secondary" v-on="on">Merge</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Merge {{ SectionLineItem.description }}</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="agreementId"
            @change="onAgreementChanged"
            :items="agreements"
            item-text="agreementNumber"
            item-value="id"
            label="Agreement"></v-autocomplete>
          <v-autocomplete
            v-model="form.targetItemId"
            :items="agreementItems"
            item-text="description"
            item-value="id"
            label="Target Item">
          </v-autocomplete>
          <v-checkbox v-model="form.setSupplierProductName" label="Set Supplier Product Name"></v-checkbox>
          <v-checkbox v-model="form.setUnitCostAmount" label="Set Unit Cost Amount"></v-checkbox>
          <v-checkbox v-model="form.setSupplier" label="Set Supplier"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="isSaving" color="success" text @click="migrate">
          Migrate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'MigrateSection',
  props: {
    SectionLineItem: {
      type: Object,
      required: true,
    },
  },
  mixins: [loading],
  data() {
    return {
      agreementId: undefined,
      agreementItems: undefined,
      dialog: false,
      form: {
        setSupplierProductName: true,
        setUnitCostAmount: true,
        setSupplier: true,
        sectionLineItemId: this.SectionLineItem?.id,
        targetItemId: undefined,
        sectionId: this.Section?.id,
      },
    };
  },
  computed: {
    ...mapState('agreements', ['agreements']),
  },
  methods: {
    ...mapActions('agreements', ['LOAD_agreementWithItems']),
    ...mapActions('agreementItems', ['LOAD_agreementItems']),
    async onAgreementChanged() {
      const { agreementId } = this;
      const isActive = true;
      this.agreementItems = await this.LOAD_agreementItems({
        agreementId,
        isActive,
      });
    },
    async migrate() {
      try {
        this.isSaving = true;
        await this.$http.post('/api/migrate/contract/line', this.form);
        this.dialog = false;
        this.$emit('migrated');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Failed to migrate section');
      } finally {
        this.isSaving = false;
      }
      // TODO: Implement migration logic based on checkbox values
    },
  },
};
</script>
