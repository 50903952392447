<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-name"
              :label="$$t('name')"
              v-model="localContact.name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-emailAddress"
              :label="$$t('emailAddress')"
              v-model="localContact.emailAddress"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-phone"
              :label="$$t('phone')"
              v-model="localContact.phone"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-address"
              :label="$$t('address')"
              v-model="localContact.address"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-bankAccountDetails"
              :label="$$t('bankAccountDetails')"
              v-model="localContact.bankAccountDetails"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormContact-contactPerson"
              :label="$$t('contactPerson')"
              v-model="localContact.contactPerson"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="localContact.isCustomer"
              :label="$$t('isCustomer')"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="localContact.isSupplier"
              :label="$$t('isSupplier')"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormContact-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormContact-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormContact',
  module: 'Contact',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Contact: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localContact
      */
      localContact: {},
    };
  },
  watch: {
    Contact() {
      this.localContact = this.Contact;
    },
  },
  created() {
    this.localContact = this.Contact;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localContact);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormContact example

'''js
<FormContact :Contact="Contact" />
'''
</docs>
