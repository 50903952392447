<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="4" class="text-center">
        <v-card>
          <div style="padding-top: 10px">
          <v-img
            alt="logo" :height="60" contain src="../assets/anubus_logo.png" />
          </div>
          <v-card-title primary-title>
            <v-row>
              <v-col cols="12">
                <div class="text-h4">Approval</div>
                <span class="mt-5">
                  <b>Anubus</b> would like access to:
                </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-left">
            <v-list two-line>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>{{ mdiInformation }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Solutions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>{{ mdiInformation }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Contracts</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text :href="callbackUrl" data-cy="btn-decline">
              Decline
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="approve"
              :loading="isSaving"
              color="primary" data-cy="btn-approve">
              Approve
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiInformation } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'Consent',
  mixins: [loading],
  data() {
    return {
      mdiInformation,
    };
  },
  computed: {
    callbackUrl() {
      return `${this.$route.query.callbackUrl}?auth=cancelled`;
    },
  },
  methods: {
    async approve() {
      try {
        this.isSaving = true;
        const { data } = await this.$http.post('/api/auth/consent');
        window.location = data.redirectUrl;
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
