<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title v-if="editContract">
            <div class="text-h5">Contract {{ editContract.contractNumber }}</div>
            <ContractAccept
              color="primary"
              class="ml-5"
              outlined
              :contract="editContract"
              @update:contract="loadContract()"
            >
              Approve Contract
            </ContractAccept>
            <v-spacer></v-spacer>
            <div class="text-h5 mr-2" v-if="solution">
              <router-link
                :to="{ name: 'solutions-EditSolution', query: { id: solution.id } }"
                style="text-decoration: none"
              >
                Solution {{ solution.solutionNumber }}
              </router-link>
            </div>
            <AuditLog :entityId="editContract.id" />
            <div class="ml-2" />
            <ModalPrint
              v-if="editContract.contractType === 'ACCREC'"
              :contract="editContract"
              :block="false"
              :text="false"
            />
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader :loading="isLoading" transition="expand-transition" type="article">
              <FormContract
                :Contract="editContract"
                :isSaving="isSaving"
                @input="debouncedSave"
                @cancel="$router.push({ name: from.name })"
                @close="close"
              />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import AuditLog from '~src/components/AuditLog.vue';
import ContractAccept from '../components/ContractAccept.vue';
import FormContract from '../components/FormContract.vue';
import ModalPrint from '../components/ModalPrint.vue';

export default {
  components: {
    AuditLog,
    ContractAccept,
    FormContract,
    ModalPrint,
  },
  name: 'EditContract',
  module: 'Contract',
  meta: {
    redirectOnRefresh: { name: 'contracts-ListContract' },
    breadcrumb: [
      {
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.contracts',
        to: { name: 'contracts-ListContract' },
      },
      {
        text: 'menu.edit',
      },
    ],
  },
  mixins: [loading],
  data() {
    return {
      solution: undefined,
      /**
       * Get/Set editing Contract. Default to {}.
       */
      editContract: undefined,
      /**
       * Query Id for Contract
       */
      contractId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('contracts', ['contracts', 'contract']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
    disabled() {
      return false;
    },
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.contractId) {
        return 'edit';
      }
      return 'add';
    },
  },
  watch: {
    $route() {
      this.contractId = this.$route?.query.id;
      this.loadContract();
    },
  },
  created() {
    this.$log.info('Edit Contract created');
  },
  async mounted() {
    this.$log.info('Edit Contract loaded');
    this.isLoading = true;
    await Promise.all([
      (async () => {
        await this.LOAD_clients({ includeArchived: false });
      })(),
      (async () => {
        await this.LOAD_suppliers({ includeArchived: false });
      })(),
      (async () => {
        await this.LOAD_xeroContacts();
      })(),
      (async () => {
        await this.LOAD_xeroRepeatingInvoices();
      })(),
      (async () => {
        await this.LOAD_accounts();
      })(),
      (async () => {
        await this.LOAD_xeroAccounts();
      })(),
      (async () => {
        await this.LOAD_xeroOrganisations();
      })(),
      (async () => {
        await this.LOAD_revenueLines();
      })(),
    ]);
    await this.$nextTick();
    if (this.mode === 'edit') {
      await this.loadContract();
    } else {
      const contract = {
        contractType: 'ACCREC',
        // sections: {
        //   title: 'New Section',
        //   lineItems: [
        //     {
        //       description: 'New Line Item',
        //       quantity: 1,
        //       amount: 0,
        //     },
        //   ],
        // },
      };
      if (this.xeroOrganisations.length > 0) {
        contract.currency = this.xeroOrganisations[0].baseCurrency;
      }
      const newContract = await this.CREATE_contract(contract);
      this.$router.push({ name: 'contracts-EditContract', query: { id: newContract.id } });
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('accounts', ['LOAD_accounts']),
    ...mapActions('contacts', ['LOAD_contacts']),
    ...mapActions('clients', ['LOAD_clients']),
    ...mapActions('suppliers', ['LOAD_suppliers']),
    ...mapActions('contracts', ['LOAD_contract', 'CREATE_contract', 'UPDATE_contract']),
    ...mapActions('contractItems', [
      'CREATE_contractItem',
      'UPDATE_contractItem',
      'LOAD_contractItemsByContractId',
    ]),
    ...mapActions('contractFiles', [
      'CREATE_contractFile',
      'UPDATE_contractFile',
      'LOAD_contractFilesByContractId',
    ]),
    ...mapActions('revenueLines', ['LOAD_revenueLines']),
    ...mapActions('solutions', ['LOAD_solution']),
    ...mapActions('xeroAccounts', ['LOAD_xeroAccounts']),
    ...mapActions('xeroContacts', ['LOAD_xeroContacts']),
    ...mapActions('xeroOrganisations', ['LOAD_xeroOrganisations']),
    ...mapActions('xeroRepeatingInvoices', ['LOAD_xeroRepeatingInvoices']),
    /**
     * Loads the contract from the api
     */
    async loadContract() {
      if (this.contractId) {
        this.editContract = await this.LOAD_contract(this.contractId);
        if (this.editContract.solutionId) {
          this.solution = await this.LOAD_solution(this.editContract.solutionId);
        }
      }
    },
    /** Method used to save the item
     */
    async save(contract) {
      if (contract?.id) {
        this.isSaving = true;
        await this.UPDATE_contract(contract);
        this.editContract = contract;
        this.isSaving = false;
      }
    },
    async close(contract) {
      await this.save(contract);
      this.$root.$emit('toast:notify', 'Saved successfully');
      await this.$router.push({ name: 'contracts-ListContract' });
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (contract) {
      this.save(contract);
    }, 500),
  },
};
</script>
