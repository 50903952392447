import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contractAttachment', 'contractAttachments');

const customActions = {
  async LOAD_contractAttachmentsForContract({ commit, rootGetters }, contractId) {
    const data = await odata.o(rootGetters)
      .get('contractAttachments')
      .query({ $filter: `IsDeleted eq false AND ContractId eq ${contractId}` });
    commit('SET_contractAttachments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
