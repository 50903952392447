<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        Company Targets
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="orgTargets"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                color="success"
                data-cy="OrgTarget-button-new"
                @click="$router.push({
                  name: 'quotes-EditOrgTarget'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:item.userId="{ item }">
            {{ getUserName(item.userId) }}
          </template>
          <template v-slot:item.targetJanuary="{ item }">
            {{ formatNumber(item.targetJanuary) }}
          </template>
          <template v-slot:item.targetFebruary="{ item }">
            {{ formatNumber(item.targetFebruary) }}
          </template>
          <template v-slot:item.targetMarch="{ item }">
            {{ formatNumber(item.targetMarch) }}
          </template>
          <template v-slot:item.targetApril="{ item }">
            {{ formatNumber(item.targetApril) }}
          </template>
          <template v-slot:item.targetMay="{ item }">
            {{ formatNumber(item.targetMay) }}
          </template>
          <template v-slot:item.targetJune="{ item }">
            {{ formatNumber(item.targetJune) }}
          </template>
          <template v-slot:item.targetJuly="{ item }">
            {{ formatNumber(item.targetJuly) }}
          </template>
          <template v-slot:item.targetAugust="{ item }">
            {{ formatNumber(item.targetAugust) }}
          </template>
          <template v-slot:item.targetSeptember="{ item }">
            {{ formatNumber(item.targetSeptember) }}
          </template>
          <template v-slot:item.targetOctober="{ item }">
            {{ formatNumber(item.targetOctober) }}
          </template>
          <template v-slot:item.targetNovember="{ item }">
            {{ formatNumber(item.targetNovember) }}
          </template>
          <template v-slot:item.targetDecember="{ item }">
            {{ formatNumber(item.targetDecember) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <TableBasicButtons :editLink="{
                name: 'quotes-EditOrgTarget',
                query: {id: item.id}
              }"
              @delete="remove(item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListOrgTarget',
  module: 'Quote',
  components: {
    TableBasicButtons,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.orgTargets',
      },
      ],
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('orgTargets', ['orgTargets']),
    ...mapState('users', ['users']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'January',
          value: 'targetJanuary',
        },
        {
          text: 'February',
          value: 'targetFebruary',
        },
        {
          text: 'March',
          value: 'targetMarch',
        },
        {
          text: 'April',
          value: 'targetApril',
        },
        {
          text: 'May',
          value: 'targetMay',
        },
        {
          text: 'June',
          value: 'targetJune',
        },
        {
          text: 'July',
          value: 'targetJuly',
        },
        {
          text: 'August',
          value: 'targetAugust',
        },
        {
          text: 'September',
          value: 'targetSeptember',
        },
        {
          text: 'October',
          value: 'targetOctober',
        },
        {
          text: 'November',
          value: 'targetNovember',
        },
        {
          text: 'December',
          value: 'targetDecember',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_users();
    await this.LOAD_orgTargets();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('orgTargets', ['LOAD_orgTargets', 'DELETE_orgTarget']),
    ...mapActions('users', ['LOAD_users']),
    getUserName(userId) {
      const user = this.users.find((u) => u.createdById === userId);
      return user?.displayName ?? 'Unknown user';
    },
    /** Delete OrgTarget from the collection
     */
    async remove(orgTarget) {
      this.isLoading = true;
      await this.DELETE_orgTarget(orgTarget.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# OrgTarget example

'''js
<OrgTarget />
'''
</docs>
