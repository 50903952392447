<template>
  <v-list
    nav
    dense
  >
  <div
        v-for="menuItem in items"
        :key="menuItem.name"
    >
      <v-list-item
        v-if="!menuItem.subLinks"
        class="mb-2"
        color="secondary"
        link
        :to="{ name: menuItem.pathName }"
        :data-cy="menuItem.name"
      >
        <v-list-item-icon>
          <v-icon color="secondary" v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="secondary--text">{{ $t(menuItem.name) }}</v-list-item-title>
      </v-list-item>
      <v-list-group
        v-else
        :key="menuItem.name"
        color="secondary"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon color="secondary" v-if="menuItem.icon">{{ menuItem.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title class="secondary--text">{{ $t(menuItem.name) }}</v-list-item-title>
        </template>

        <v-list-item
            color="secondary"
            v-for="subLink in menuItem.subLinks"
            :to="{ name: subLink.pathName }"
            :key="subLink.name"
            link
        >
            <v-list-item-title class="secondary--text">{{ $t(subLink.name) }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </div>
  </v-list>
</template>

<script>
// import { mdiHome } from '@mdi/js';
import menuItems from '../menuItems';

export default {
  mixins: [menuItems],
  computed: {
    items() {
      const items = this.menuItems;
      return items;
    },
  },
};
</script>

<style scoped>
.v-list-item__content > a {
  justify-content: left;
}
</style>
<style lang="scss" scoped>
  // Use the color variables in your component's styles
  .v-icon__svg {
    color: #1B99E8 !important;
  }
</style>
