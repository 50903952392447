export default {
  fromDate: (state) => state.fromDate,
  toDate: (state) => state.toDate,
  filters: (state) => {
    const {
      selectedUsernames,
      selectedVersions,
      fromDate,
      toDate,
    } = state;

    return {
      selectedUsernames,
      selectedVersions,
      fromDate,
      toDate,
    };
  },
  usernames: (state) => [...new Set(state.navigationRoutes.map((item) => item.createdBy))],

  versions: (state) => [...new Set(state.navigationRoutes.map((item) => item.version))],

  features: (state) => {
    const { selectedUsernames } = state;
    const { selectedVersions } = state;
    let result = state.features;
    if (selectedUsernames.length > 0) {
      result = result.filter((item) => selectedUsernames.includes(item.createdBy));
    }

    if (selectedVersions.length > 0) {
      result = result.filter((item) => selectedVersions.includes(item.version));
    }
    return result;
  },

  prevFeatures: (state) => {
    const { selectedUsernames } = state;
    const { selectedVersions } = state;
    let result = state.prevFeatures;
    if (selectedUsernames.length > 0) {
      result = result.filter((item) => selectedUsernames.includes(item.createdBy));
    }

    if (selectedVersions.length > 0) {
      result = result.filter((item) => selectedVersions.includes(item.version));
    }
    return result;
  },

  routes: (state) => {
    const { selectedUsernames } = state;
    const { selectedVersions } = state;
    let result = state.navigationRoutes;
    if (selectedUsernames.length > 0) {
      result = result.filter((item) => selectedUsernames.includes(item.createdBy));
    }

    if (selectedVersions.length > 0) {
      result = result.filter((item) => selectedVersions.includes(item.version));
    }
    return result;
  },
};
