import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      settings: [],
    };
  },
  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapState('companySettings', ['companySettings']),
  },
  methods: {
    ...mapActions('companySettings', [
      'LOAD_companySettings',
      'CREATE_companySetting',
      'UPDATE_companySetting',
    ]),
    async refresh() {
      await this.refreshCompanySettings();
    },
    async refreshCompanySettings() {
      this.isLoading = true;
      await this.LOAD_companySettings();
      this.setCompanySettings();
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      const { settings } = this;

      await Promise.all(settings
        .map((setting) => this.saveCompanySetting(setting.name, this[setting.name])));
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
    async setCompanySettings() {
      const { companySettings, settings } = this;
      settings.forEach((setting) => {
        const companySetting = companySettings.find((c) => c.name === setting.name);
        if (companySetting) {
          this[setting.name] = companySetting.value;
        }
      });
    },
    async saveCompanySetting(name, value) {
      const existingCompanySetting = this.companySettings.find(
        (setting) => setting.name === name,
      );
      if (existingCompanySetting) {
        await this.UPDATE_companySetting({
          id: existingCompanySetting.id,
          name,
          value,
        });
        return;
      }
      await this.CREATE_companySetting({
        name,
        value,
      });
    },
  },
};
