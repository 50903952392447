<template>
  <v-container>
    <v-row>
      <v-col cols="3" v-for="contact in contacts" :key="contact.name">
        <v-card>
          <v-card-title class="headline">
            <v-icon color="primary" class="mr-2">{{ contact.icon }}</v-icon>
            {{ $t(contact.name) }}
            </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :to="{ name: contact.pathName }">
              View
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiOfficeBuilding,
  mdiAccountGroup,
  mdiOfficeBuildingPlusOutline,
} from '@mdi/js';

export default {
  name: 'Contacts',
  data() {
    return {
      contacts: [
        {
          name: 'menu.clients',
          pathName: 'quotes-ListClient',
          icon: mdiOfficeBuilding,
          topMenu: false,
        },
        {
          name: 'menu.suppliers',
          pathName: 'quotes-ListSupplier',
          icon: mdiOfficeBuildingPlusOutline,
          topMenu: false,
        },
        {
          name: 'menu.people',
          pathName: 'quotes-ListPerson',
          icon: mdiAccountGroup,
          topMenu: false,
        },
      ],
    };
  },
};
</script>
