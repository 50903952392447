<template>
  <v-container fluid>
    <v-card :loading="isLoading">
      <v-card-title>Profile</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userProfile.displayName"
              label="Name"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="userProfile.phone"
              label="Phone"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="updateProfile"
              :loading="isSaving"
            >
              Update Profile
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UserProfile',
  mixins: [loading],
  data() {
    return {
      userProfile: {
        name: '',
        email: '',
        bio: '',
      },
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const { data } = await this.$http.get('api/user/profile');
      this.userProfile = data;
      this.isLoading = false;
    },
    async updateProfile() {
      this.isSaving = true;
      const { userProfile } = this;
      const { data } = await this.$http.put(`api/user/profile/${userProfile.id}`, userProfile);
      this.userProfile = data;
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
      // Add code here to update the user's profile
    },
  },
};
</script>
