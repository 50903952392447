<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="userTargets"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="UserTarget-button-new"
            @click="$router.push({
              name: 'quotes-EditUserTarget'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.userId="{ item }">
        {{ getUserName(item.userId) }}
      </template>
      <template v-slot:item.targetJanuary="{ item }">
        {{ formatNumber(item.targetJanuary) }}
      </template>
      <template v-slot:item.targetFebruary="{ item }">
        {{ formatNumber(item.targetFebruary) }}
      </template>
      <template v-slot:item.targetMarch="{ item }">
        {{ formatNumber(item.targetMarch) }}
      </template>
      <template v-slot:item.targetApril="{ item }">
        {{ formatNumber(item.targetApril) }}
      </template>
      <template v-slot:item.targetMay="{ item }">
        {{ formatNumber(item.targetMay) }}
      </template>
      <template v-slot:item.targetJune="{ item }">
        {{ formatNumber(item.targetJune) }}
      </template>
      <template v-slot:item.targetJuly="{ item }">
        {{ formatNumber(item.targetJuly) }}
      </template>
      <template v-slot:item.targetAugust="{ item }">
        {{ formatNumber(item.targetAugust) }}
      </template>
      <template v-slot:item.targetSeptember="{ item }">
        {{ formatNumber(item.targetSeptember) }}
      </template>
      <template v-slot:item.targetOctober="{ item }">
        {{ formatNumber(item.targetOctober) }}
      </template>
      <template v-slot:item.targetNovember="{ item }">
        {{ formatNumber(item.targetNovember) }}
      </template>
      <template v-slot:item.targetDecember="{ item }">
        {{ formatNumber(item.targetDecember) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'quotes-EditUserTarget',
            query: {id: item.id}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListUserTarget',
  module: 'Quote',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('userTargets', ['userTargets']),
    ...mapState('users', ['users']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Sales Person',
          value: 'userId',
        },
        {
          text: 'January Target',
          value: 'targetJanuary',
        },
        {
          text: 'February Target',
          value: 'targetFebruary',
        },
        {
          text: 'March Target',
          value: 'targetMarch',
        },
        {
          text: 'April Target',
          value: 'targetApril',
        },
        {
          text: 'May Target',
          value: 'targetMay',
        },
        {
          text: 'June Target',
          value: 'targetJune',
        },
        {
          text: 'July Target',
          value: 'targetJuly',
        },
        {
          text: 'August Target',
          value: 'targetAugust',
        },
        {
          text: 'September Target',
          value: 'targetSeptember',
        },
        {
          text: 'October Target',
          value: 'targetOctober',
        },
        {
          text: 'November Target',
          value: 'targetNovember',
        },
        {
          text: 'December Target',
          value: 'targetDecember',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_users();
    await this.LOAD_userTargets();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('userTargets', ['LOAD_userTargets', 'DELETE_userTarget']),
    ...mapActions('users', ['LOAD_users']),
    getUserName(userId) {
      const user = this.users.find((u) => u.createdById === userId);
      return user?.displayName ?? 'Unknown user';
    },
    /** Delete UserTarget from the collection
     */
    async remove(userTarget) {
      this.isLoading = true;
      await this.DELETE_userTarget(userTarget.id);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# UserTarget example

'''js
<UserTarget />
'''
</docs>
