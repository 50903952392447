import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('account', 'accounts');

const customActions = {
  async UPDATE_account({ commit, state, rootGetters }, data) {
    await odata.o(rootGetters)
      .patch(`accounts(${data.accountID})`, data)
      .query();
    const items = state.accounts;
    if (items?.length > 0) {
      const updatedItem = items.find((l) => l.id === data.accountID);
      if (updatedItem) {
        Object.assign(updatedItem, data);
        commit('SET_accounts', items);
      }
    }
    return data;
  },
};

export default { ...actions, ...customActions };
