<template>
  <v-container>
    <v-row v-if="supplier">
      <v-col
        cols="12">
        <v-card>
          <v-card-title>
            <div class="text-h5 ml-2">
              Supplier: {{ supplier.name }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="7" class="pl-5">
                <label class="text-h6">Head Office Address</label>
                <InlineTextArea
                  data-cy="ViewClient-headOfficeAddress"
                  v-model="supplier.address"
                  @save="UPDATE_client(supplier)"
                />
                <label class="text-h6">VAT Registration Number</label>
                <InlineTextField
                  data-cy="ViewClient-name"
                  v-model="supplier.vatNumber"
                  @save="UPDATE_supplier(supplier)"
                />
              </v-col>
              <v-col cols="5">
                <v-row>
                  <v-col cols="12">
                    <CardContracts :contracts="contracts" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiPencil } from '@mdi/js';
import CardContracts from '../components/CardContracts.vue';
import InlineTextField from '../../../../components/InlineTextField.vue';
import InlineTextArea from '../../../../components/InlineTextArea.vue';

export default {
  name: 'ViewSupplier',
  mixins: [loading],
  components: {
    CardContracts,
    InlineTextField,
    InlineTextArea,
  },
  data() {
    return {
      /** icons
       */
      mdiPencil,
      /** data
       */
      contracts: [],
      supplierId: this.$route.query.supplierId,
      supplier: undefined,
    };
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_supplier', 'UPDATE_supplier']),
  },
  async mounted() {
    this.isLoading = true;
    this.supplier = await this.LOAD_supplier(this.supplierId);
    const { data } = this.$http.get(`/api/contracts/${this.supplierId}`);
    this.contracts = data;
    this.isLoading = false;
  },
};
</script>
