<template>
  <v-card :loading="isSaving">
    <v-card-title primary-title class="justify-center text-h4">
      {{ $$t('title') }}
    </v-card-title>
    <v-card-text>
      <v-btn color="primary"
      :to="{ name: 'contracts-ListContract' }"
      >Skip</v-btn>
      <v-btn
        class="float-right"
        color="success"
        data-cy="OnboardXero-button-new"
        :href="url"
      >
        {{ $$t('connect') }}
      </v-btn>
    </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'OnboardXero',
  mixins: [loading],
  module: 'Xero',
  computed: {
    url() {
      const { organization } = this;
      return `${process.env.VUE_APP_APIURL}/Xero/connect/${organization.id}`;
    },
    /** Vuex states
     */
    ...mapState('organizations', ['organizations', 'organization']),
  },
};
</script>

<style lang="scss" scoped>

</style>
