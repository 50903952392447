<template>
  <div>
    <v-progress-linear v-if="isLoading" :indeterminate="true" color="primary" />
    <table>
      <tr
        v-for="(line, index) in lineItems"
        :key="`form-line-${index}`"
        :data-cy="`FormQuote-FormLine-${index}`"
      >
        <td>
          <FormLine
            :value="line"
            :index="index"
            :totalItems="sortedLineItems.length"
            :disabled="disabled"
            @input="debouncedSave"
            @save="saveLine"
            @remove="removeLine"
            @refresh="
              refreshSectionLineItems();
              $emit('refresh');
            "
            @update:up="moveLineUp(index)"
            @update:down="moveLineDown(index)"
            @update:section="changeSection"
          />
        </td>
      </tr>
    </table>
    <v-btn
      data-cy="FormQuote-button-addNewLine"
      color="primary"
      text
      @click="addNewLine"
      :disabled="disabled"
    >
      <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
      Add a new line
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormLine from './FormLine.vue';

export default {
  name: 'QuoteSectionLines',
  components: {
    FormLine,
  },
  mixins: [loading],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler() {
        this.refreshSectionLineItems();
      },
    },
  },
  data() {
    return {
      mdiPlus,
      lineItems: [],
    };
  },
  computed: {
    ...mapState('quotes', ['quote']),
    ...mapState('quoteSections', ['quoteSections']),
    sortedLineItems() {
      return this.lineItems.filter((item) => !item.isDeleted);
    },
  },
  async mounted() {
    await this.refreshSectionLineItems();
  },
  methods: {
    ...mapActions('sectionLineItems', [
      'UPDATE_sectionLineItem',
      'CREATE_sectionLineItem',
      'LOAD_sectionLineItemsBySection',
    ]),
    async addNewLine() {
      const newLine = {
        quoteSectionId: this.value.id,
        lineNumber: this.lineItems.length + 1,
        description: '',
        quantity: 1,
        discountRate: 0,
        unitCostCurrencyCode: this.quote.currencyCode,
        isActive: false,
      };
      this.isLoading = true;
      await this.CREATE_sectionLineItem(newLine);
      this.isLoading = false;
      this.$emit('refresh');
      await this.refreshSectionLineItems();
    },
    async removeLine(sectionLineItem) {
      const s = { ...sectionLineItem };
      s.isDeleted = true;
      this.isLoading = true;
      await this.UPDATE_sectionLineItem(s);
      this.isLoading = false;
      await this.refreshSectionLineItems();
    },
    async refreshSectionLineItems() {
      if (this.value?.id) {
        this.isLoading = true;
        this.lineItems = (await this.LOAD_sectionLineItemsBySection(this.value.id)) ?? [];
        this.isLoading = false;
      }
    },
    async saveLine(item) {
      this.$log.debug('saving line', item);
      const updatedItem = await this.UPDATE_sectionLineItem(item);
      this.$emit('updated:line', updatedItem);
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(async function (item) {
      this.$log.debug('debounced saving line', item);
      const updatedItem = await this.UPDATE_sectionLineItem(item);
      this.$emit('updated:line', updatedItem);
    }, 500),
    async moveLineUp(sourceIndex) {
      this.$log.debug('moving line up', sourceIndex);
      const targetIndex = sourceIndex - 1;
      const { lineItems } = this;
      const temp = lineItems[targetIndex];
      lineItems[targetIndex] = lineItems[sourceIndex];
      lineItems[targetIndex].lineNumber = targetIndex + 1;
      lineItems[sourceIndex] = temp;
      lineItems[sourceIndex].lineNumber = sourceIndex + 1;
      this.lineItems = lineItems;
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.UPDATE_sectionLineItem(this.lineItems[targetIndex]);
        })(),
        (async () => {
          await this.UPDATE_sectionLineItem(this.lineItems[sourceIndex]);
        })(),
      ]);
      this.isLoading = false;
      await this.refreshSectionLineItems();
      // this.lineItems = sortedLineItems.map(async (l) => {
      //   const result = { ...l };
      //   result.lineNumber = sortedLineItems.indexOf(l) + 1;
      //   await this.UPDATE_sectionLineItem(result);
      //   return result;
      // });
    },
    async moveLineDown(sourceIndex) {
      this.$log.debug('moving line down', sourceIndex);
      const targetIndex = sourceIndex + 1;
      const { lineItems } = this;
      const temp = lineItems[targetIndex];
      lineItems[targetIndex] = lineItems[sourceIndex];
      lineItems[targetIndex].lineNumber = targetIndex + 1;
      lineItems[sourceIndex] = temp;
      lineItems[sourceIndex].lineNumber = sourceIndex + 1;
      this.lineItems = lineItems;
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.UPDATE_sectionLineItem(this.lineItems[targetIndex]);
        })(),
        (async () => {
          await this.UPDATE_sectionLineItem(this.lineItems[sourceIndex]);
        })(),
      ]);
      this.isLoading = false;
      await this.refreshSectionLineItems();
    },
    async changeSection(item) {
      const { section, localLineItem } = item;
      const targetLineItem = this.lineItems.find((l) => l.id === localLineItem.id);
      this.$log.debug(`changing section from ${targetLineItem.sectionId} to ${section.id}`);
      // eslint-disable-next-line max-len
      const prevSectionLineItems = await this.LOAD_sectionLineItemsBySection(
        localLineItem.quoteSectionId,
      );
      targetLineItem.quoteSectionId = section.id;
      const targetlineItems = await this.LOAD_sectionLineItemsBySection(section.id);
      localLineItem.lineNumber = targetlineItems.length + 1;
      await this.UPDATE_sectionLineItem(targetLineItem);
      prevSectionLineItems.forEach(async (l, i) => {
        const oldLine = { ...l };
        oldLine.lineNumber = i + 1;
        await this.UPDATE_sectionLineItem(oldLine);
      });
      await this.$nextTick();
      await this.refreshSectionLineItems();
      this.$emit('refresh');
    },
    // moveLineToSection(lineIndex, sectionIndex, $event) {
    //   const newSectionIndex = this.localQuote.sections.findIndex((s) => s.id === $event.id);
    //   const section = this.localQuote.sections[sectionIndex];
    //   const { lineItems } = section;
    //   const lineItem = lineItems[lineIndex];
    //   lineItems.splice(lineIndex, 1);
    //   section.lineItems = lineItems;
    //   this.localQuote.sections[sectionIndex] = section;
    //   const newSection = this.localQuote.sections[newSectionIndex];
    //   newSection.lineItems.push(lineItem);
    //   this.localQuote.sections[newSectionIndex] = newSection;
    // },
  },
};
</script>
