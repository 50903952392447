<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-text-field
          filled
          data-cy="FormEmailQuote-recipients"
          :label="$$t('recipients')"
          v-model="localEmailQuote.recipients"
          :hint="$$t('recipientsHint')"
          required
        />
        <v-text-field
          filled
          data-cy="FormEmailQuote-subject"
          :label="$$t('subject')"
          v-model="localEmailQuote.subject"
          counter="180"
          required
        />
        <v-textarea
          filled
          data-cy="FormEmailQuote-body"
          :label="$$t('body')"
          v-model="localEmailQuote.body"
          counter="4000"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormEmailQuote-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormEmailQuote-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormEmailQuote',
  module: 'EmailQuote',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    EmailQuote: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localEmailQuote
      */
      localEmailQuote: {},
    };
  },
  watch: {
    EmailQuote() {
      this.localEmailQuote = this.EmailQuote;
    },
  },
  created() {
    this.localEmailQuote = this.EmailQuote;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    async submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localEmailQuote);
        this.isSaving = true;
        await new Promise((r) => setTimeout((r), 1000));
        this.isSaving = false;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormEmailQuote example

'''js
<FormEmailQuote :EmailQuote="EmailQuote" />
'''
</docs>
