<template>
  <div>
    <v-row>
      <v-col cols="6">
        <div class="text-h5 mb-5">Billing Information</div>
        <v-text-field v-model="billingCompanyName" label="Billing Company Name">
        </v-text-field>
        <v-text-field v-model="contactFirstName" label="Contact First Name">
        </v-text-field>
        <v-text-field v-model="contactSurname" label="Contact Surname">
        </v-text-field>
        <v-text-field v-model="contactEmail" label="Contact Email">
        </v-text-field>
        <v-text-field v-model="contactPhoneNumber" label="Contact Phone Number">
        </v-text-field>
        <v-text-field v-model="billingAddress" label="Billing Address">
        </v-text-field>
        <v-text-field v-model="billingCity" label="Billing City">
        </v-text-field>
        <v-text-field v-model="billingRegion" label="Billing Region">
        </v-text-field>
        <v-text-field v-model="billingPostalCode" label="Billing Postal Code">
        </v-text-field>
        <v-text-field v-model="vatNumber" label="VAT Number">
        </v-text-field>
        <v-btn color="success" @click="save">Save</v-btn>
      </v-col>
      <v-col cols="6">
        <div class="text-h5 mb-5">Credit Card Information</div>
        <stripe-element-card
          :pk="publishableKey"
        />
        <v-btn color="success" class="mt-5" @click="submit">Subscribe</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { StripeCheckout, StripeElementCard } from '@vue-stripe/vue-stripe';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    StripeCheckout,
    StripeElementCard,
  },
  data() {
    this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
    return {
      loading: false,
      billingCompanyName: '',
      contactFirstName: '',
      contactSurname: '',
      contactEmail: '',
      contactPhoneNumber: '',
      billingAddress: '',
      billingCity: '',
      billingRegion: '',
      billingPostalCode: '',
      vatNumber: '',
      lineItems: [
        {
          price: 'price_1HC1FmCvCX6P8QHKsvplKnwk',
          quantity: 1,
        },
        {
          price: 'price_1HC1ByCvCX6P8QHKmNRWypTr',
          quantity: 1,
        },
        {
          price: 'price_1HC0KACvCX6P8QHKdf8dcJwy',
          quantity: 1,
        },
        {
          price: 'price_1HC0JHCvCX6P8QHKnJU03XbP',
          quantity: 1,
        },
      ],
      successURL: `${baseUrl}/company/subscription/success`,
      cancelURL: `${baseUrl}/company/subscription/cancel`,
    };
  },
  computed: {
    ...mapState('companySettings', ['companySettings']),
  },
  methods: {
    ...mapActions('companySettings', [
      'LOAD_companySettings',
      'CREATE_companySetting',
      'UPDATE_companySetting',
    ]),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_companySettings();
      this.setCompanySettings(this.companySettings);
      this.isLoading = false;
    },
    async save() {
      this.isSaving = true;
      await this.saveCompanySetting('billingCompanyName', this.billingCompanyName);
      await this.saveCompanySetting('contactFirstName', this.contactFirstName);
      await this.saveCompanySetting('contactSurname', this.contactSurname);
      await this.saveCompanySetting('contactEmail', this.contactEmail);
      await this.saveCompanySetting('contactPhoneNumber', this.contactPhoneNumber);
      await this.saveCompanySetting('billingAddress', this.billingAddress);
      await this.saveCompanySetting('billingCity', this.billingCity);
      await this.saveCompanySetting('billingRegion', this.billingRegion);
      await this.saveCompanySetting('billingPostalCode', this.billingPostalCode);
      await this.saveCompanySetting('vatNumber', this.vatNumber);
      this.isSaving = false;
      this.$root.$emit('toast:notify', 'Saved successfully');
    },
    setCompanySettings(companySettings) {
      this.billingCompanyName = companySettings.find(
        (setting) => setting.name === 'billingCompanyName',
      )?.value;
      this.contactFirstName = companySettings.find(
        (setting) => setting.name === 'contactFirstName',
      )?.value;
      this.contactSurname = companySettings.find(
        (setting) => setting.name === 'contactSurname',
      )?.value;
      this.contactEmail = companySettings.find(
        (setting) => setting.name === 'contactEmail',
      )?.value;
      this.contactPhoneNumber = companySettings.find(
        (setting) => setting.name === 'contactPhoneNumber',
      )?.value;
      this.billingAddress = companySettings.find(
        (setting) => setting.name === 'billingAddress',
      )?.value;
      this.billingCity = companySettings.find(
        (setting) => setting.name === 'billingCity',
      )?.value;
      this.billingRegion = companySettings.find(
        (setting) => setting.name === 'billingRegion',
      )?.value;
      this.billingPostalCode = companySettings.find(
        (setting) => setting.name === 'billingPostalCode',
      )?.value;
      this.vatNumber = companySettings.find(
        (setting) => setting.name === 'vatNumber',
      )?.value;
    },
    async saveCompanySetting(name, value) {
      const existingCompanySetting = this.companySettings.find(
        (setting) => setting.name === name,
      );
      if (existingCompanySetting) {
        await this.UPDATE_companySetting({
          id: existingCompanySetting.id,
          name,
          value,
        });
        return;
      }
      await this.CREATE_companySetting({
        name,
        value,
      });
    },

    submit() {
    // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>
