<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <ProgressLinearTimeout v-if="isLoading" />
        <div v-else class="w-100 pr-20 float-right">
          <RocketMenu :items="availableActions" @click="onMenuClick" :small="true" class="float-right" />
        </div>
        <ModalClientPrint ref="ModalClientPrint" :Client="editClient" />
        <FormClient
          :Client="editClient"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({name: 'quotes-ListClient' })"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressLinearTimeout from '@codehq/aurora-app-core/src/components/ProgressLinearTimeout.vue';
import FormClient from '../components/FormClient.vue';
import ModalClientPrint from '../components/ModalClientPrint.vue';
import RocketMenu from '../../../solutions/src/components/RocketMenu.vue';

export default {
  components: {
    FormClient,
    ModalClientPrint,
    ProgressLinearTimeout,
    RocketMenu,
  },
  name: 'EditClient',
  module: 'Client',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.clients',
        to: { name: 'quotes-ListClient' },
        disabled: false,
      },
      {
        text: 'menu.edit',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Client. Default to {}.
      */
      editClient: {},
      /**
       * Query Id for Client
       */
      clientId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('clients', ['clients', 'client']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.clientId) {
        return 'edit';
      }
      return 'add';
    },
    availableActions() {
      return ['Print'];
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_client(this.clientId);
      this.editClient = this.client;
    } else {
      // create the client
      this.editClient = {};
      this.editClient = await this.CREATE_client(this.editClient);
      this.clientId = this.editClient.id;
    }
    await this.LOAD_quoteTemplates();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('clients', ['LOAD_client', 'CREATE_client', 'UPDATE_client']),
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates']),
    /** Method used to save the item
     */
    async save(client) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_client(client);
      } else {
        await this.UPDATE_client(client);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListClient' });
    },
    async onMenuClick(item) {
      switch (item) {
        case 'Print':
          this.$refs.ModalClientPrint.open();
          break;
        default:
          this.$root.$emit('toast:notify', 'Unknown menu item');
      }
    },
  },
};
</script>

<docs>
# EditClient example

'''js
<EditClient :Client="Client" />
'''
</docs>
