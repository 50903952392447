<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    @input="dialogChanged">
    <template v-slot:activator="{ on, attrs }">
    <v-btn
      color="secondary"
      @click="$emit('click')"
      block
      text
      data-cy="UpgradeQuote-button"
      v-bind="attrs"
      v-on="on"
    >
      Upgrade
    </v-btn>
    </template>
    <v-card :loading="isLoading">
      <v-card-title primary-title>
        Upgrading Quote {{ quote.quoteNumber }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="text-h5">
          Select any of line items that are expiring and select their expiration date
        </div>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          item-key="id"
          show-select
          :selected="selected"
          @item-selected="onSelect"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.expires="{ item }">
            <div v-if="item.expires">
              <DatePickerSmall
                :value="item.expires.expiryDate"
                @change="onDateChanged(item, $event)"
                />
            </div>
          </template>
          <template v-slot:item.implementationDate="{ item }">
            <div v-if="item.implementationDate">
              {{ formatDate(item.implementationDate) }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="default"
          text
          @click.stop="dialog = false"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="upgrade"
          :loading="isSaving"
        >
          Upgrade
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DatePickerSmall from '~src/components/DatePickerSmall.vue';
import { mapActions } from 'vuex';
import { mdiClose } from '@mdi/js';

export default {
  name: 'UpgradeQuote',
  mixins: [loading],
  components: {
    DatePickerSmall,
  },
  props: {
    quote: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /** icons
       */
      mdiClose,
      dialog: false,
      sections: [],
      selected: [],
    };
  },
  computed: {
    lineItems() {
      let lineItems = [];
      if (this.sections) {
        this.sections.forEach((s) => {
          const items = s.lineItems.map((l) => {
            const item = { ...l };
            item.section = s;
            return item;
          });
          lineItems = lineItems.concat(items);
        });
      }
      return lineItems;
    },
    headers() {
      return [
        { text: 'Section', value: 'section.title' },
        { text: 'Description', value: 'description' },
        { text: 'Implementation Date', value: 'implementationDate' },
        { text: 'Terms', value: 'terms' },
        { text: 'Quantity', value: 'quantity' },
        { text: 'Unit Price', value: 'unitAmount' },
        { text: 'Expires', value: 'expires' },
        { text: 'Total', value: 'lineAmount' },
      ];
    },
    items() {
      return this.lineItems?.map((li) => {
        const item = { ...li };
        item.expires = this.selected.find((s) => s.item.item.id === li.id) ?? null;
        return item;
      }) ?? [];
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('quoteSections', ['LOAD_quoteSectionsAndLinesForQuote', 'CREATE_quoteSection', 'UPDATE_quoteSection']),
    /**
     * Executes when the dialog opens or closes
     */
    async dialogChanged(val) {
      if (val) {
        await this.refresh();
      }
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.sections = await this.LOAD_quoteSectionsAndLinesForQuote(this.quote.id);
      this.isLoading = false;
    },
    onSelect(item) {
      if (item.value) {
        this.selected.push({
          item,
          expiryDate: this.formatDate(new Date()),
        });
      } else {
        this.selected = this.selected.filter((s) => s.item.item.id !== item.item.id);
      }
    },
    // onDateChanged(item, date) {
    //   const index = this.selected.findIndex((s) => s.item.item.id === item.id);
    //   this.selected[index].expiryDate = date;
    // },
    async upgrade() {
      const lineItems = this.selected.map((s) => ({
        quoteLineItemId: s.item.item.id,
        expiryDate: s.expiryDate,
      }));
      this.isSaving = true;
      const { data } = await this.$http.post('/api/automation/upgrade/quote', {
        quoteId: this.quote.id,
        lineItems,
      });
      this.isSaving = false;
      this.dialog = false;
      this.$root.$emit('toast:notify', 'Quote upgraded successfully');
      this.$router.push({
        name: 'quotes-EditQuote',
        query: {
          id: data.id,
        },
      });
    },
  },
};
</script>
