<!-- eslint-disable max-lines -->
<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <div v-if="localSolution">
      <v-row>
        <v-col cols="12" xl="3" lg="3" md="6">
          <v-autocomplete
            auto-select-first
            dense
            data-cy="FormQuote-contactId"
            :disabled="disabled"
            filled
            :label="$$t('contactId')"
            :items="customerContacts"
            :rules="requiredFieldRules"
            item-value="id"
            item-text="name"
            @change="onContactChange"
            v-model="localSolution.contactId"
          />
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="12">
          <SelectPerson
            v-if="localSolution.contactId"
            filled
            data-cy="FormQuote-personId"
            :disabled="disabled"
            :contact-id="localSolution.contactId"
            :label="$$t('person')"
            v-model="localSolution.personId"
            @change="personUpdated"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            filled
            dense
            data-cy="FormSolution-name"
            :label="$$t('name')"
            v-model="localSolution.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-dialog
            v-model="newAgreementDialog"
            persistent
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                color="primary"
                v-bind="attrs"
                v-on="on"
                data-cy="Section-button-add"
              >
                <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
                Add a new agreement
              </v-btn>
            </template>
            <v-card>
              <v-card-title primary-title>
                Agreement
              </v-card-title>
              <v-card-text>
                <v-text-field
                  label="Reference"
                  v-model="newAgreement.reference"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="newAgreementDialog = false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="addAgreement"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="2">
          <h4 class="mt-1">Last Updated</h4>
        </v-col>
        <v-col cols="2" class="pa-1">
          <v-select
            dense
            outlined
            class="agreement-filter"
            :items="[
              'Renewal Date',
              'Delivered Date',
              'Notice Date',
              ]"
            v-model="tableHeadingSelector"
          ></v-select>
        </v-col>
        <v-col class="text-right" cols="2" style="padding-right: 50px">
          <h4 class="mt-1">Profit</h4>
        </v-col>
        <v-col class="text-right" cols="1">
        </v-col>
        <v-col cols="1" class="text-right">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text small color="primary" v-bind="attrs" v-on="on">
                <v-icon color="secondary">{{ mdiFilter }}</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Filter</v-card-title>
              <v-card-text>
                <v-checkbox
                  v-model="includeArchived"
                  label="Include Archived"
                  @change="refresh"
                ></v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <v-icon @click="panel = undefined" color="secondary" class="mr-2">
            {{ mdiCollapseAll }}
          </v-icon>
        </v-col>
      </v-row>
      <ProgressLinearTimeout v-if="isLoading" />
      <v-expansion-panels tile v-model="panel">
        <v-expansion-panel
          v-for="(agreementHeader,i) in sortedAgreementHeaders"
          :key="i"
        >
          <v-expansion-panel-header @click="onAgreementClick">
            <template v-slot="{ open }">
              <AgreementHeader
                :Agreement="agreementHeader"
                :Open="open"
                :SelectedDate="tableHeadingSelector"
                :Solution="localSolution"
                @refresh="refresh"
                @collapse="panel = undefined"
              />
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Agreement
              v-if="panel === i" @refresh="refresh"
              :AgreementId="agreementHeader.id"
              @collapse="panel = undefined"
              :IsActive="!includeArchived"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <!-- buttons -->
      <div class="d-flex justify-end mt-5 mb-5">
        <v-btn color="primary" type="button" class="ml-4" :loading="isSaving"
          @click="$emit('close', localSolution);"
          data-cy="FormSolution-button-close">
            {{ $t('common.buttons.close') }}
        </v-btn>
      </div>
      <v-card :loading="isLoading">
        <v-row>
          <v-col cols="12">
            <v-tabs>
              <v-tab>Notes</v-tab>
              <v-tab>
                <v-badge right color="primary">
                  <span slot="badge">{{ solutionComments.length }}</span>
                  Comments
                </v-badge>
              </v-tab>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <div v-if="disabled" v-html="localSolution.notes" />
                    <vue-editor
                      v-else
                      :editorToolbar="customToolbar"
                      :disabled="disabled"
                      label="Notes"
                      outlined
                      v-model="localSolution.notes"
                      counter="2000"
                    >
                    </vue-editor>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <SolutionComments :Solution="Solution" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card>
      <SolutionAttachments :Solution="Solution" />
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiFilter, mdiPlus, mdiCollapseAll } from '@mdi/js';
import { VueEditor } from 'vue2-editor';
import clients from '~src/mixins/contacts';
import requiredField from '~src/mixins/required-field';
import Agreement from './Agreement.vue';
import AgreementHeader from './AgreementHeader.vue';
import SelectPerson from './SelectPerson.vue';
import SolutionAttachments from './SolutionAttachments.vue';
import SolutionComments from './SolutionComments.vue';
import currencies from '../../../contracts/src/components/currencies.json';

export default {
  name: 'FormSolution',
  module: 'Solutions',
  components: {
    Agreement,
    AgreementHeader,
    VueEditor,
    SelectPerson,
    SolutionAttachments,
    SolutionComments,
  },
  mixins: [clients, requiredField],
  props: {
    /**
    * Set the saving state. Default: false,
    */
    isSaving: {
      type: Boolean,
      default: false,
    },
    /**
    * Set the text box value. Default: undefined,
    */
    Solution: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      /**
       * Icons
       */
      mdiPlus,
      mdiCollapseAll,
      mdiFilter,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      isLoading: false,
      panel: undefined,
      currencies,
      newAgreementDialog: false,
      newAgreement: {
        reference: '',
        date: new Date(),
        status: 'Draft',
      },
      includeArchived: false,
      tableHeadingSelector: 'Renewal Date',
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSolution
      */
      localSolution: {},
      newContract: undefined,
    };
  },
  computed: {
    ...mapGetters('agreements', ['sortedAgreementHeaders']),
    ...mapGetters('currencies', ['currencySymbol']),
    ...mapGetters('solutions', ['solutionUsers']),
    ...mapState('comments', ['solutionComments']),
    ...mapState('companySettings', ['companySettings']),
    ...mapState('contracts', ['contracts']),
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('people', ['people']),
    availablePeople() {
      return this.people.filter((p) => p.contactId === this.localSolution.contactId) ?? [];
    },
    disabled() {
      return false;
    },
  },
  watch: {
    Solution: {
      immediate: true,
      deep: true,
      handler() {
        this.localSolution = this.Solution;
      },
    },
    localSolution: {
      immediate: true,
      deep: true,
      handler() {
        this.$log.info('localSolution changed', this.localSolution);
        this.$emit('input', this.localSolution);
        this.refresh();
      },
    },
    panel: {
      immediate: true,
      deep: true,
      handler() {
        this.$log.info('panel changed', this.panel);
        if (this.panel === undefined) {
          this.$log.info('refreshing agreements');
          this.refresh();
        }
      },
    },
  },
  created() {
    this.isLoading = true;
    this.localSolution = this.Solution;
    this.isLoading = false;
  },
  async mounted() {
    await this.refresh();
    if (this.$route.query.agreementId) {
      const agreement = this.sortedAgreementHeaders
        .find((a) => a.id === this.$route.query.agreementId);
      this.panel = this.sortedAgreementHeaders.indexOf(agreement);
    }
    this.$root.$on('refresh.agreementHeader', () => {
      this.$log.info('Agreement header refresh request');
      this.refresh();
    });
  },
  beforeDestroy() {
    this.$root.$off('refresh.agreementHeader', this.refresh);
  },
  methods: {
    ...mapActions('agreements', ['CREATE_agreement', 'LOAD_getSolutionAgreements']),
    async addAgreement() {
      this.isLoading = true;
      this.newAgreementDialog = false;
      this.panel = undefined;
      await this.$nextTick();
      await new Promise((r) => setTimeout(r, 200));
      const { newAgreement } = this;
      newAgreement.solutionId = this.localSolution.id;
      newAgreement.currencyCode = this.selectedCurrency;
      newAgreement.notes = this.companySettings.find(
        (setting) => setting.name === 'defaultAgreementNotes',
      )?.value;
      try {
        await this.CREATE_agreement(newAgreement);
        newAgreement.reference = '';
        await this.refresh();
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async refresh() {
      if (this.localSolution.id) {
        try {
          this.isLoading = true;
          const solutionId = this.localSolution.id;
          let isActive = true;
          if (this.includeArchived) {
            isActive = null;
          }
          const currencyCode = this.selectedCurrency;
          this.$log.info('Refreshing solution agreements');
          await this.LOAD_getSolutionAgreements({ currencyCode, solutionId, isActive });
          this.$root.$emit('refresh.agreement');
        } catch (error) {
          this.$log.error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSolution);
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
    personUpdated(clientId) {
      const { people } = this;
      const person = people.find((c) => c.id === clientId);
      this.localSolution.person = person?.name ?? '';
    },
    onAgreementClick(e) {
      e.preventDefault();
    },
    onContactChange(contactId) {
      const contact = this.allContacts.find((c) => c.id === contactId);
      this.localSolution.contactSource = contact.source;
      this.localSolution.contact = contact.name;
      this.localSolution.contactEmail = contact.emailAddress;
    },
    onClick($evt) {
      this.$log.info($evt);
    },
  },
};
</script>
