<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-select
          filled
          :items="['Client Contact', 'Supplier Contact']"
          data-cy="FormContact-personType"
          :label="$$t('personType')"
          v-model="localPerson.personType"
        />
        <v-autocomplete
          auto-select-first
          filled
          v-if="localPerson.personType === 'Client Contact'"
          :items="allClients"
          data-cy="FormPerson-clientId"
          :label="$$t('clientId')"
          item-value="id"
          item-text="name"
          v-model="localPerson.contactId"
        >
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
                <div v-if="item.addresses !== null">
                <div
                  v-for="(address, index) in item.addresses" :key="index"
                >
                  <div>
                    <span v-if="address.addressLine1">{{ address.addressLine1 }}, </span>
                    <span v-if="address.addressLine2">{{ address.addressLine2 }}, </span>
                    <span v-if="address.addressLine3">{{ address.addressLine3 }}, </span>
                    <span v-if="address.addressLine4">{{ address.addressLine4 }}, </span>
                    <span v-if="address.city">{{ address.city }}, </span>
                    <span v-if="address.region">{{ address.region }}, </span>
                    <span v-if="address.postalCode">{{ address.postalCode }}, </span>
                    <span v-if="address.country">{{ address.country }}</span>
                  </div>
                </div>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-autocomplete
        auto-select-first
        filled
        :items="suppliers"
        item-text="name"
        item-value="id"
        data-cy="FormPerson-supplierId"
        :label="$$t('supplierId')"
        v-model="localPerson.supplierId"
        v-if="localPerson.personType === 'Supplier Contact'"
      />
        <!-- <v-select
          filled
          multiple
          :items="contactLevels"
          data-cy="FormContact-contactLevel"
          :label="$$t('contactLevel')"
          v-model="localPerson.contactLevel"
        /> -->
        <v-text-field
          filled
          data-cy="FormPerson-firstName"
          :label="$$t('firstName')"
          v-model="localPerson.firstName"
        />
        <v-text-field
          filled
          data-cy="FormPerson-lastName"
          :label="$$t('lastName')"
          v-model="localPerson.lastName"
        />
        <v-text-field
          filled
          data-cy="FormPerson-designation"
          :label="$$t('designation')"
          v-model="localPerson.designation"
        />
        <v-text-field
          filled
          data-cy="FormPerson-emailAddress"
          :label="$$t('emailAddress')"
          v-model="localPerson.emailAddress"
        />
        <v-text-field
          filled
          data-cy="FormPerson-workNumber"
          :label="$$t('workNumber')"
          v-model="localPerson.workNumber"
        />
        <v-text-field
          filled
          data-cy="FormPerson-cellNumber"
          :label="$$t('cellNumber')"
          v-model="localPerson.cellNumber"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormPerson-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormPerson-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapActions, mapState } from 'vuex';
import clients from '~src/mixins/clients';
import SelectClients from '~src/components/SelectClients.vue';

export default {
  name: 'FormPerson',
  module: 'Person',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    SelectClients,
  },
  mixins: [clients, loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Person: {
      type: Object,
      default: undefined,
    },
    showClients: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localPerson
      */
      localPerson: {},
    };
  },
  computed: {
    ...mapState('suppliers', ['suppliers']),
    contactLevels() {
      return [
        'Authority',
        'Technical',
        'Billing',
        'Installation',
      ];
    },
  },
  watch: {
    Person() {
      this.localPerson = this.Person;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.loadAllClients();
    await this.LOAD_suppliers({ includeArchived: false });
    this.isLoading = false;
  },
  created() {
    this.localPerson = this.Person;
  },
  methods: {
    ...mapActions('suppliers', ['LOAD_suppliers']),
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localPerson);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormPerson example

'''js
<FormPerson :Person="Person" />
'''
</docs>
