<template>
  <v-container fluid>
    <v-data-table :headers="selectedHeaders" :items="expiryDatesDiffer" class="mb-4"
      :loading="isLoading"
      dense
      :search="search1"
      sort-by="solutionNumber"
      :sort-desc="true">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Expiry dates between suppliers and clients differ</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            class="mt-5"
            v-model="search1"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.solution.solutionNumber="{ item }">
        <router-link v-if="item.solution" :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }">
          {{ item.solution.solutionNumber }}
        </router-link>
      </template>
      <template v-slot:item.agreementNumber="{ item }">
        <router-link v-if="!item.solution" :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }">
          {{ item.agreementNumber }}
        </router-link>
        <span v-else>
          {{ item.agreementNumber }}
        </span>
      </template>
      <template v-slot:item.solution.contact="{ item }">
        <span v-if="item.solution">
          {{ item.solution.contact }}
        </span>
        <span v-else>
          {{ item.contact }}
        </span>
      </template>
    </v-data-table>
    <v-data-table :headers="selectedHeaders" :items="incorrectContractCount" class="mb-4"
      :loading="isLoading"
      dense
      :search="search2"
      sort-by="solutionNumber"
      :sort-desc="true">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>
            Missing supplier or client agreements
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            class="mt-5"
            v-model="search2"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.solution.solutionNumber="{ item }">
        <router-link v-if="item.solution" :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }">
          {{ item.solution.solutionNumber }}
        </router-link>
      </template>
      <template v-slot:item.agreementNumber="{ item }">
        <router-link v-if="!item.solution" :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }">
          {{ item.agreementNumber }}
        </router-link>
      </template>
      <template v-slot:item.solution.contact="{ item }">
        <span v-if="item.solution">
          {{ item.solution.contact }}
        </span>
        <span v-else>
          {{ item.contact }}
        </span>
      </template>
    </v-data-table>
    <v-data-table :headers="headers" :items="noExpiryDateSet" class="mb-4"
      :loading="isLoading"
      dense
      :search="search3"
      sort-by="agreementNumber"
      :sort-desc="true">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>No Expiry Date Set</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            class="mt-5"
            v-model="search3"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.solution.solutionNumber="{ item }">
        <router-link v-if="item.solution" :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }">
          {{ item.solution.solutionNumber }}
        </router-link>
      </template>
      <template v-slot:item.agreementNumber="{ item }">
        <router-link v-if="!item.solution" :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }">
          {{ item.agreementNumber }}
        </router-link>
        <span v-else>
          {{ item.agreementNumber }}
        </span>
      </template>
      <template v-slot:item.solution.contact="{ item }">
        <span v-if="item.solution">
          {{ item.solution.contact }}
        </span>
        <span v-else>
          {{ item.contact }}
        </span>
      </template>
    </v-data-table>
    <v-data-table :headers="headers" :items="noImplementationDate" class="mb-4"
      :loading="isLoading"
      dense
      :search="search4"
      sort-by="agreementNumber"
      :sort-desc="true">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>No Implementation Date</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            class="mt-5"
            v-model="search4"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.solution.solutionNumber="{ item }">
        <router-link v-if="item.solution" :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }">
          {{ item.solution.solutionNumber }}
        </router-link>
      </template>
      <template v-slot:item.agreementNumber="{ item }">
        <router-link v-if="!item.solution" :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }">
          {{ item.agreementNumber }}
        </router-link>
        <span v-else>
          {{ item.agreementNumber }}
        </span>
      </template>
      <template v-slot:item.solution.contact="{ item }">
        <span v-if="item.solution">
          {{ item.solution.contact }}
        </span>
        <span v-else>
          {{ item.contact }}
        </span>
      </template>
    </v-data-table>
    <v-data-table :headers="headers" :items="noTermSelected" class="mb-4"
      :loading="isLoading"
      dense
      :search="search5"
      sort-by="agreementNumber"
      :sort-desc="true">
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>No Term Selected</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            label="Search"
            class="mt-5"
            v-model="search5"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.solution.solutionNumber="{ item }">
        <router-link v-if="item.solution" :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }">
          {{ item.solution.solutionNumber }}
        </router-link>
      </template>
      <template v-slot:item.agreementNumber="{ item }">
        <router-link v-if="!item.solution" :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }">
          {{ item.agreementNumber }}
        </router-link>
        <span v-else>
          {{ item.agreementNumber }}
        </span>
      </template>
      <template v-slot:item.solution.contact="{ item }">
        <span v-if="item.solution">
          {{ item.solution.contact }}
        </span>
        <span v-else>
          {{ item.contact }}
        </span>
      </template>
      <template v-slot:item.agreementType="{ item }">
        {{ getContractType(item.agreementType) }}
      </template>
      <template v-slot:item.expires="{ item }">
        {{ formatDate(item.expires) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DataRiskReport',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.reports',
        to: { name: 'agreements-Reports' },
      },
      {
        text: 'menu.dataRiskReport',
      },
      ],
  },
  data() {
    return {
      search1: '',
      search2: '',
      search3: '',
      search4: '',
      search5: '',
      headers: [
        { text: 'Solution Number', value: 'solution.solutionNumber' },
        { text: 'Agreement Number', value: 'agreementNumber' },
        { text: 'Contact Name', value: 'solution.contact' },
        { text: 'Status', value: 'status' },
        { text: 'Reference', value: 'reference' },
        // { text: 'Total', value: 'total' },
      ],
      selectedHeaders: [
        { text: 'Solution Number', value: 'solution.solutionNumber' },
        { text: 'Agreement Number', value: 'agreementNumber' },
        { text: 'Contact Name', value: 'solution.contact' },
        { text: 'Status', value: 'status' },
        { text: 'Reference', value: 'reference' },
      ],
      data: {
        expiryDatesDiffer: [],
        noExpiryDateSet: [
        // Add the objects for the noExpiryDateSet array here
        ],
        noImplementationDate: [
        // Add the objects for the noImplementationDate array here
        ],
        noTermSelected: [
        // Add the objects for the noTermSelected array here
        ],
        incorrectContractCount: [],
      },
    };
  },
  computed: {
    expiryDatesDiffer() {
      return this.data.expiryDatesDiffer;
    },
    noExpiryDateSet() {
      return this.data.noExpiryDateSet;
    },
    noImplementationDate() {
      return this.data.noImplementationDate;
    },
    noTermSelected() {
      return this.data.noTermSelected;
    },
    incorrectContractCount() {
      return this.data.incorrectContractCount;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const { data } = await this.$http.get('/api/contracts/reports/data-integrity');
      this.data = data;
      this.isLoading = false;
    },
  },
};
</script>
