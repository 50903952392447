<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Month: {{ month }}
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="isLoading"
        dense
        :items-per-page="-1"
      ><template v-slot:top>
        <v-row>
          <v-col cols="4">
          </v-col>
          <v-col cols="3">
          </v-col>
          <v-col cols="3">
          </v-col>
          <v-col cols="2" class="text-right">
            <ExportToExcel
              small
              :name="`Monthly GP - ${month}`" class="mt-5 mr-5"
              :data="localData" />
          </v-col>
        </v-row>
      </template>
        <template v-slot:item.contractType="{ item }">
          {{ item.contractType === 'ACCREC' ? 'Money In' : 'Money Out' }}
        </template>
        <template v-slot:item.commences="{ item }">
          {{ formatDate(item.commences) }}
        </template>
        <template v-slot:item.expires="{ item }">
          {{ formatDate(item.expires) }}
        </template>
        <template v-slot:item.total="{ item }">
          <span v-if="item.total < 0" class="red--text">
            ({{ formatCurrency(item.total * -1, item.currency) }})
          </span>
          <span v-else>
            {{ formatCurrency(item.total, item.currency) }}
          </span>
        </template>
        <template slot="body.append">
            <tr class="font-weight-bold">
                <td colspan="4" />
                <td>{{ formatCurrency(sumField('total'), selectedCurrency) }}</td>
            </tr>
        </template>
      <template v-slot:item.actions="{ item }">
        <v-btn x-small
          :to="{
            name: 'contracts-EditContract',
            query: {id: item.id}
          }">
          Edit
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ReportGpPerMonth',
  mixins: [loading],
  module: 'Contract',
  data() {
    return {
      localData: [],
      month: this.$route.query.month,
      index: this.$route.query.index,
      isExpiring: this.$route.query.isExpiring,
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    headers() {
      return [
        {
          text: this.$$t('contractNumber'),
          value: 'contractNumber',
          width: '120px',
        },
        {
          text: this.$$t('solutionNumber'),
          value: 'solutionNumber',
          width: '120px',
        },
        {
          text: this.$$t('contact'),
          value: 'contactName',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('status'),
          value: 'status',
        },
        {
          text: this.$$t('contractType'),
          value: 'contractType',
          width: '120px',
        },
        {
          text: this.$$t('commences'),
          value: 'commences',
          width: '130px',
        },
        {
          text: this.$$t('expires'),
          value: 'expires',
          width: '120px',
        },
        {
          text: this.$$t('total'),
          value: 'total',
          width: '110px',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      return this.localData.map((item) => {
        const res = { ...item };
        if (res.contractType === 'ACCPAY') {
          res.total *= -1;
        }
        return res;
      });
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const { index, isExpiring, selectedCurrency } = this;
      const url = `/api/contracts/reports/byDate/${index}/${selectedCurrency}?isExpiring=${isExpiring}`;
      const { data } = await this.$http.get(url);
      this.localData = data;
      this.isLoading = false;
    },
    sumField(key) {
      // sum data in give key (property)
      return this.items.reduce((a, b) => a + (b[key] || 0), 0);
    },
  },
};
</script>
