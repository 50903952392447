<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="accounts"
      class="elevation-1"
      :loading="isLoading"
      dense
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="Account-button-new"
            @click="$router.push({
              name: 'contracts-EditAccount'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.code="{ item }">
        <router-link :to="{ name: 'contracts-EditAccount', query: { id: item.accountID } }">
          {{ item.code }}
        </router-link>
      </template>
      <template v-slot:item.actions="{ item }">
        <TableBasicButtons :editLink="{
            name: 'contracts-EditAccount',
            query: {id: item.accountID}
          }"
          @delete="remove(item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListAccount',
  module: 'Account',
  components: {
    TableBasicButtons,
  },
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.accounts',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('accounts', ['accounts']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('code'),
          value: 'code',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: this.$$t('accountType'),
          value: 'accountType',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('accounts', ['LOAD_accounts', 'DELETE_account']),
    /** Delete Account from the collection
     */
    async remove(account) {
      this.isLoading = true;
      await this.DELETE_account(account.accountID);
      await this.LOAD_accounts();
      this.isLoading = false;
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_accounts();
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Account example

'''js
<Account />
'''
</docs>
