<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="agreements"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500, -1]
      }"
      dense
      :search="search"
      sort-by="agreementNumber"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="2">
            <v-btn
              color="success"
              class="ml-2"
              data-cy="Solution-button-new"
              @click="$router.push({
                name: 'solutions-EditAgreement'
              })"
            >
              <v-icon class="mr-2">
                {{ mdiPlus }}
              </v-icon>
              {{ $$t('new') }}
            </v-btn>
          </v-col>
          <v-col class="text-right" cols="10">
            <v-text-field
              clearable
              class="mr-2 d-inline-block"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
            <v-btn small color="secondary" @click="refresh">
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
            <!-- <ExportToExcel small name="Solutions" :data="localData" /> -->
            <DownloadData entitySet="Agreements" class="ml-2" />
            <UploadData entitySet="Agreements" class="ml-2" />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="ml-2 mr-2" color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>{{ mdiFilter }}</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Filter</v-card-title>
                <v-card-text>
                  <v-checkbox
                    v-model="showArchived"
                    label="Show Archived"
                    @change="refresh"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
    <template v-slot:item.verified="{ item }">
      <v-icon
        v-if="item.verified"
        color="success"
        class="mr-2"
      >
        {{ mdiSeal }}
      </v-icon>
    </template>
    <template v-slot:item.contact="{ item }">
      <div style="display: block" v-if="item.solution">
        {{ item.solution.contact }}
      </div>
      <div style="display: block" v-else>
        {{ item.contact }}
      </div>
    </template>
    <template v-slot:item.agreementNumber="{ item }">
      <div style="display: block" v-if="item.solution">
        <router-link
          :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }"
          class="black--text"
          >
          {{ item.agreementNumber }}
        </router-link>
      </div>
      <div style="display: block" v-else>
        <router-link
          :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }"
          class="black--text"
          >
          {{ item.agreementNumber }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.solution.solutionNumber="{ item }">
      <div style="display: block" v-if="item.solution">
        <router-link
          :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }"
          class="black--text"
          >
          {{ item.solution.solutionNumber }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.acceptedDate="{ item }">
      {{ formatDate(item.acceptedDate) }}
    </template>
    <template v-slot:item.deliveredDate="{ item }">
      {{ formatDate(item.deliveredDate) }}
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiFilter, mdiSeal,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import DownloadData from '@/components/DownloadData.vue';
import UploadData from '@/components/UploadData.vue';
import agreementActions from '../mixins/agreementActions';

export default {
  name: 'ListAgreement',
  module: 'Agreements',
  components: {
    DownloadData,
    UploadData,
  },
  mixins: [agreementActions, loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiRefresh,
      mdiFilter,
      mdiSeal,
      /**
       * Data
       */
      localData: [],
      showArchived: false,
      agreements: [],
      search: '',
      headers: [
        { text: 'Verified', value: 'verified', width: '60px' },
        { text: 'Agreement Number', value: 'agreementNumber', width: '150px' },
        { text: 'Solution Number', value: 'solution.solutionNumber', width: '120px' },
        { text: 'Client', value: 'contact' },
        { text: 'Name', value: 'reference' },
        { text: 'Status', value: 'status', width: '80px' },
        { text: 'Delivered Date', value: 'deliveredDate', width: '150px' },
        { text: 'Accepted Date', value: 'acceptedDate', width: '150px' },
        { text: '', value: 'actions' },
      ],
      xeroInvoiceNumber: '',
      dialog: false,
    };
  },
  computed: {
    ...mapState('agreements', ['statuses', 'stages']),
    ...mapState('solutions', ['solutions']),
    ...mapState('xeroInvoices', ['xeroInvoices']),
    exportData() {
      return this.agreements.map((a) => ({
        'Agreement Number': a.agreementNumber,
        'Solution Number': a.solution.solutionNumber,
        'Agreement Status': a.status,
        Solution: a.solution.name,
        Deleted: a.isDeleted,
        Archived: a.isActive,
        'Project Name': a.reference,
        'Invoice Number': a.invoiceNumber,
        'Invoice Status': a.invoiceStatus,
      }));
    },
  },
  methods: {
    ...mapActions('agreements', ['LOAD_AllAgreements', 'UPDATE_agreement']),
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          this.agreements = await this.LOAD_AllAgreements();
        })(),
      ]);
      this.isLoading = false;
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>
<style scoped>
.inline-block,
.inline-block > div{
  display: inline-block;
}
</style>
