<template>
  <div>
    <v-text-field
      label="Comment"
      outlined
      v-model="comment.contents"
      v-on:keyup.enter="createComment">
      <template v-slot:append>
        <v-btn @click="createComment" small color="secondary">
          Post
        </v-btn>
      </template>
    </v-text-field>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-timeline
      dense
      clipped
      v-if="comments.length > 0"
    >
      <v-timeline-item
        v-for="comment in comments"
        :key="comment.id"
        class="mb-4"
        color="pink"
        small
      >
        <v-row justify="space-between">
          <v-col
            cols="7"
          >
            {{ comment.contents }}
          </v-col>
          <v-col
            class="text-right"
            cols="5"
          >
            {{ formatFromNow(comment.created) }} by {{ comment.createdBy }}
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'AgreementComments',
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
  },
  mixins: [loading],
  computed: {
    ...mapGetters('comments', ['comments']),
  },
  data() {
    return {
      comment: {
        agreementId: this.Agreement.id,
        contents: '',
      },
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('comments', ['CREATE_comment', 'LOAD_comments']),
    ...mapActions('users', ['LOAD_users']),
    async createComment() {
      this.isLoading = true;
      await this.CREATE_comment(this.comment);
      this.isLoading = false;
      this.comment.contents = '';
      await this.refresh();
    },
    async refresh() {
      this.isLoading = true;
      await this.LOAD_users();
      await this.LOAD_comments(this.Agreement.id);
      this.isLoading = false;
    },
  },
};
</script>
