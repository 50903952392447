import Account from './Account.json';
import Contact from './Contact.json';
import Contract from './Contract.json';
import ContractItem from './ContractItem.json';

export default {
  Account,
  Contact,
  Contract,
  ContractItem,
};
