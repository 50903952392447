<template>
  <div>
    <v-text-field
      v-for="(v, index) in localValues"
      :key="index"
      v-model="localValues[index]"
      :label="label"
      dense
      :append-icon="index === 0 ? mdiPlus : mdiMinus"
      @click:append="toggleItem(index)"
      @keyup="$emit('input', valueString)"
    />
  </div>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js';

export default {
  name: 'MultipleTextField',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiPlus,
      mdiMinus,
      localValues: [],
    };
  },
  computed: {
    valueString() {
      return this.localValues.join(';');
    },
  },
  watch: {
    value(newValues) {
      this.localValues = newValues.split(';');
    },
  },
  mounted() {
    if (this.value?.length > 0) {
      this.localValues = this.value.split(';');
    } else {
      this.localValues.push('');
    }
  },
  methods: {
    toggleItem(index) {
      if (index === 0) {
        this.localValues.push('');
      } else {
        this.localValues.splice(index, 1);
      }
      this.$emit('input', this.valueString);
    },
  },
};
</script>
