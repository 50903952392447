<template>
  <div id="d3-chart"></div>
</template>

<style>
#d3-chart {
  margin-left: 20px;
}
#d3-chart > svg {
  width: 50vw;
  height: 75vh;
}
</style>

<script>
import * as d3 from 'd3';

export default {
  name: 'D3ChartBubbles',
  props: {
    data: {
      type: Object,
      default: () => undefined,
    },
  },
  data() {
    return {
      width: 932,
      height: 932,
      format: d3.format(',d'),
      label: undefined,
      svg: undefined,
      node: undefined,
      color: d3.scaleLinear()
        .domain([0, 5])
        .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
        .interpolate(d3.interpolateHcl),
      pack: (data) => d3.pack()
        .size([this.width, this.height])
        .padding(3)(d3.hierarchy(data)
          .sum((d) => d.value)
          .sort((a, b) => b.value - a.value)),
      root: null,
      focus: null,
      view: null,
    };
  },
  mounted() {
    this.root = this.pack(this.data);
    this.focus = this.root;
    this.view = null;

    this.svg = d3
      .select('#d3-chart')
      .append('svg')
      .attr('viewBox', `-${this.width / 2} -${this.height / 2} ${this.width} ${this.height}`)
      .style('display', 'block')
      .style('margin', '0 -14px')
      .style('background', 'white')
      .style('cursor', 'pointer')
      .on('click', (event) => this.zoom(event, this.root));

    // d3.select('#d3-chart').append(this.svg);

    this.node = this.svg.append('g')
      .selectAll('circle')
      .data(this.root.descendants().slice(1))
      .join('circle')
      .attr('fill', (d) => (d.children ? this.color(d.depth) : '#9c27b0'))
      .attr('pointer-events', (d) => (!d.children ? 'none' : null))
      .on('mouseover', () => { d3.select(this).attr('stroke', '#000'); })
      .on('mouseout', () => { d3.select(this).attr('stroke', null); })
      .on('click', (event, d) => this.focus !== d && (this.zoom(event, d), event.stopPropagation()));

    this.label = this.svg.append('g')
      .style('font', '16px Work Sans')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .data(this.root.descendants())
      .join('text')
      // eslint-disable-next-line arrow-body-style
      .style('fill-opacity', (d) => {
        // console.debug(d);
        return d.parent === this.root ? 1 : 0;
      })
      .style('display', (d) => (d.parent === this.root ? 'inline' : 'none'))
      .text((d) => d.data.name);

    this.zoomTo([this.root.x, this.root.y, this.root.r * 2]);
  },
  methods: {
    zoomTo(v) {
      const k = this.width / v[2];

      this.view = v;

      this.label.attr('transform', (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      this.node.attr('transform', (d) => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      this.node.attr('r', (d) => d.r * k);
    },
    zoom(event, data) {
      // const focus0 = this.focus;

      this.focus = data;

      const transition = this.svg.transition()
        .duration(event.altKey ? 7500 : 750)
        .tween('zoom', () => {
          const i = d3.interpolateZoom(this.view, [this.focus.x, this.focus.y, this.focus.r * 2]);
          return (t) => this.zoomTo(i(t));
        });

      this.label
        .filter((d) => d.parent === this.focus || this.style.display === 'inline')
        .transition(transition)
        .style('fill-opacity', (d) => (d.parent === this.focus ? 1 : 0))
        .on('start', (d) => { if (d.parent === this.focus) this.style.display = 'inline'; })
        .on('end', (d) => { if (d.parent !== this.focus) this.style.display = 'none'; });
    },
  },
};
</script>
