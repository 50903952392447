<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12">
        <v-data-table
          v-model="selectedClients"
          :headers="headers"
          :items="items"
          item-key="id"
          class="elevation-1"
          :loading="isLoading"
          :search="search"
          :items-per-page="50"
          show-select
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                v-if="selectedClients.length > 1"
                class="mr-2"
                color="primary"
                data-cy="Client-button-merge"
                @click="merge"
              >
                Merge
              </v-btn>
              <v-btn
                color="success"
                data-cy="Client-button-new"
                @click="$router.push({
                  name: 'quotes-EditClient'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
              <div class="text-h5 ml-2">
                Clients
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                label="Search"
                v-model="search"
                dense
                clearable
                hide-details
              ></v-text-field>
              <FilterMenu>
                <v-checkbox
                  v-model="includeArchived"
                  label="Include Archived"
                  @change="refresh"
                ></v-checkbox>
              </FilterMenu>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <span v-if="item.source === 'xero'">
              {{ item.name }}
            </span>
            <router-link
              v-else
              :to="{
                name: 'quotes-EditClient',
                query: {id: item.id}
              }"
              class="black--text"
              >
              {{ item.name }}
            </router-link>
            <v-icon v-if="!item.isActive" class="ml-2" @click="unarchive(item)" title="Unarchive" color="error">
              {{ mdiArchive }}
            </v-icon>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn-toggle>
              <v-btn color="default" small :to="{
                name: 'quotes-EditClient',
                query: {id: item.id}
              }">
                {{ $t('common.buttons.edit') }}
              </v-btn>
              <v-btn color="error" small @click="remove(item)">
                {{ $t('common.buttons.delete') }}
              </v-btn>
              <v-btn v-if="item.isActive" small color="secondary" @click="archive(item)">Archive</v-btn>
            </v-btn-toggle>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mdiPlus, mdiArchive } from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import FilterMenu from '@/components/FilterMenu.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import list from '@/mixins/list';

export default {
  name: 'ListClient',
  module: 'Client',
  components: {
    FilterMenu,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.clients',
        disabled: true,
      },
      ],
  },
  mixins: [list, loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiArchive,
      mdiPlus,
      selectedClients: [],
      includeArchived: false,
    };
  },
  computed: {
    ...mapState('clients', ['clients']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('emailAddress'),
          value: 'emailAddress',
        },
        {
          text: this.$$t('telephone'),
          value: 'telephone',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      return this.clients.map((client, index) => ({
        ...client,
        index,
      }));
    },
    clientsToMerge() {
      return this.selectedClients.map((c) => c.id);
    },
    /**
     * Computed property for the name vuex property search
     */
    search: {
      get() {
        return this.$store.state.clients.search;
      },
      set(newValue) {
        this.$store.commit('clients/SET_search', newValue);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    ...mapActions('clients', [
      'DELETE_client',
      'UPDATE_client',
      'MERGE_clients',
      'LOAD_clients',
      'DELETE_client',
    ]),
    async unarchive(item) {
      const res = await this.$confirm('Are you sure you want to unarchive this client?');
      if (!res) return;
      this.isLoading = true;
      await this.UPDATE_client({
        id: item.id,
        isActive: true,
      });
      this.isLoading = false;
      await this.refresh();
    },
    async archive(item) {
      const res = await this.$confirm('Are you sure you want to archive this client?');
      if (!res) return;
      this.isLoading = true;
      await this.UPDATE_client({
        id: item.id,
        isActive: false,
      });
      this.isLoading = false;
      await this.refresh();
    },
    /** Delete Client from the collection
     */
    async remove(client) {
      const res = await this.$confirm('Are you sure you want to delete this client?');
      if (!res) return;
      this.isLoading = true;
      await this.DELETE_client(client.id);
      this.isLoading = false;
    },
    async refresh() {
      this.isLoading = true;
      const { includeArchived } = this;
      await this.LOAD_clients({ includeArchived });
      this.isLoading = false;
    },
    async merge() {
      const result = await this.$confirm('Are you sure you want to merge these clients?');
      if (!result) return;

      const { clientsToMerge } = this;
      this.isLoading = true;
      await this.MERGE_clients(clientsToMerge);
      this.isLoading = false;
      await this.refresh();
    },
  },
};
</script>

<docs>
# Client example

'''js
<Client />
'''
</docs>
