<template>
  <v-container>
    <v-row>
      <v-col
        cols="12">
          <v-data-table
            :headers="headers"
            :items="activities"
            class="elevation-1"
            :loading="isLoading"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-btn
                  color="success"
                  data-cy="Activity-button-new"
                  @click="$router.push({
                    name: 'quotes-EditActivity'
                  })"
                >
                  <v-icon class="mr-2">
                    {{ mdiPlus }}
                  </v-icon>
                  {{ $t('common.buttons.new') }}
                </v-btn>
                <div class="text-h5 ml-2">
                  Customer Activities
                </div>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  label="Search"
                  v-model="search"
                  clearable
                ></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:item.rating="{ item }">
              <v-rating
                v-model="item.rating"
                data-cy="FormActivity-rating"
                large
              ></v-rating>
            </template>
            <template v-slot:item.created="{ item }">
              {{ formatDate(item.created) }}
            </template>
            <template v-slot:item.duration="{ item }">
              {{ item.duration }} mins
            </template>
            <template v-slot:item.actions="{ item }">
              <TableBasicButtons :editLink="{
                  name: 'quotes-EditActivity',
                  query: {id: item.id}
                }"
                @delete="remove(item)"
              />
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import list from '~src/mixins/list';

export default {
  name: 'ListActivity',
  module: 'Activity',
  components: {
    TableBasicButtons,
  },
  mixins: [list, loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('activities', ['activities']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('created'),
          value: 'created',
        },
        {
          text: this.$$t('clientId'),
          value: 'client.name',
        },
        {
          text: this.$$t('activityType'),
          value: 'activityType',
        },
        {
          text: this.$$t('rating'),
          value: 'rating',
        },
        {
          text: this.$$t('duration'),
          value: 'duration',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    /**
     * Computed property for the name vuex property search
     */
    search: {
      get() {
        return this.$store.state.activities.search;
      },
      set(newValue) {
        this.$store.commit('activities/SET_search', newValue);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_activities();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('activities', ['LOAD_activities', 'DELETE_activity']),
    /** Delete Activity from the collection
     */
    async remove(Activity) {
      this.isLoading = true;
      await this.DELETE_Activity(Activity);
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Activity example

'''js
<Activity />
'''
</docs>
