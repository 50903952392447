export default {
  created() {
    this.$root.$on('currencyChanged', async () => {
      this.$log.debug('Currency changed');
      await this.refresh();
    });
  },
  destroyed() {
    this.$root.$off('currencyChanged');
  },
};
