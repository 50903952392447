<template>
  <div>
    <GChart
      :data="chartData"
      :type="'BarChart'"
      :options="chartOptions"
      :width="600"
      :height="600"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GraphTopDestinations',
  props: {
    msg: String,
  },
  components: {
    GChart,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters('telemetry', ['routes']),
    chartData() {
      const data = [['Destination', 'Popularity']];
      const destinations = {};
      const { routes } = this;

      if (routes) {
        routes.forEach((item) => {
          const destination = item.to;
          destinations[destination] = (destinations[destination] || 0) + 1;
        });
      }

      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const destination in destinations) {
        data.push([destination, destinations[destination]]);
      }

      data.sort((a, b) => b[1] - a[1]);

      if (data.length > 5) {
        return data.slice(0, 5);
      }

      return data;
    },
    chartOptions() {
      return {
        title: 'Top Destinations',
        hAxis: {
          title: 'Hits',
        },
        vAxis: {},
      };
    },
  },
};

</script>
