<template>
  <v-container>
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormContact
        :Contact="editContact"
        :isSaving="isSaving"
        @save="save"
        @cancel="$router.push({name: from.name })" />
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormContact from '../components/FormContact.vue';

export default {
  components: {
    FormContact,
  },
  name: 'EditContact',
  module: 'Contracts',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.contacts',
        to: { name: 'contracts-ListContact' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Contact. Default to {}.
      */
      editContact: {},
      /**
       * Query Id for Contact
       */
      contactId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('contacts', ['contact']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.contactId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_contact(this.contactId);
      this.editContact = this.contact;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('contacts', ['LOAD_contact', 'CREATE_contact', 'UPDATE_contact']),
    /** Method used to save the item
     */
    async save(contact) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_contact(contact);
      } else {
        await this.UPDATE_contact(contact);
      }
      this.isSaving = false;
      this.$router.push({ name: 'contracts-ListContact' });
    },
  },
};
</script>

<docs>
# EditContact example

'''js
<EditContact :Contact="Contact" />
'''
</docs>
