<template>
  <v-container fluid>
    <ProgressLinearTimeout v-if="isLoading" />
    <v-card v-if="!isLoading">
      <v-card-text>
        <v-row class="mb-10 d-flex">
          <v-col cols="12" md="3">
            <v-simple-table class="report-summary-table rounded">
              <template v-slot:default>
                <tr>
                  <td colspan="2" class="primary white--text text-h5">
                    Solutions in Progress
                  </td>
                  <td class="text-right text-h6 text-center">
                    {{ deliveredSummary.inProgress }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="primary white--text text-h6">
                    Accepted in the Last Week
                  </td>
                  <td class="text-right text-h6 text-center">
                    {{ deliveredSummary.acceptedInTheLastWeek }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="primary white--text text-h6">
                    Installed in the Last Week
                  </td>
                  <td class="text-right text-h6 text-center">
                    {{ deliveredSummary.deliveredInTheLastWeek }}
                  </td>
                </tr>
                <tr class="font-weight-bold">
                  <td>Supplier</td>
                  <td class="text-center">On Track</td>
                  <td class="text-center">Behind</td>
                </tr>
                <tr v-for="(supplierItem, index) in supplierSummary" :key="index">
                  <td>
                    {{ supplierItem.name }}
                  </td>
                  <td class="text-center">
                    {{ supplierItem.items.filter((s => s.status === 'Delivered')).length }}
                  </td>
                  <td class="text-center">
                    {{ supplierItem.items.filter((s => s.status === 'Accepted')).length }}
                  </td>
                </tr>
              </template>
            </v-simple-table>
          </v-col>
          <v-col cols="12" md="9">
            <v-row>
              <v-col cols="12" md="6">
                <TableAnnualBreakdown
                  :summary="summaryByState('Delivered')"
                  title="Solutions Delivered"
                />
              </v-col>
              <v-col cols="12" md="6">
                <TableAnnualBreakdown
                  :summary="summaryByState('Cancelled')"
                  title="Solutions Cancelled"
                />
              </v-col>
              <v-col cols="12" md="6">
                <TableAnnualBreakdown
                  :summary="profitByState('Delivered')"
                  title="GP Delivered"
                />
              </v-col>
              <v-col cols="12" md="6">
                <TableAnnualBreakdown
                  :summary="profitByState('Cancelled')"
                  title="GP Cancelled"
                />
              </v-col>
              <v-col cols="12" md="6">
                <TableAnnualBreakdown
                  :summary="summaryByState('Certain')"
                  title="Solutions in Certain"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="!isLoading" class="mt-10">
      <v-card-text class="overflow-x-auto">
        <v-data-table
          class="table-report"
          :headers="headers"
          :items="items"
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
        >
          <template v-slot:item.agreement.solution.solutionNumber="{ item }">
            <router-link
              :to="{ name: 'contracts-EditSolution', query: { id: item.agreement.solution.id } }"
            >
              {{ item.agreement.solution.solutionNumber }}
            </router-link>
          </template>
          <template v-slot:item.expectedDeliveryDelivered="{ item }">
            {{ formatDate(item.expectedDeliveryDelivered) }}
          </template>
          <template v-slot:item.cost="{ item }">
            {{ formatNumber(item.cost) }}
          </template>
          <template v-slot:item.profit="{ item }">
            {{ formatNumber(item.profit) }}
          </template>
          <template v-slot:item.percProfit="{ item }">
            <span v-if="item.percProfit">{{ item.percProfit }}%</span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import list from '@/mixins/list';
import suppliers from '@/mixins/suppliers';
import TableAnnualBreakdown from '../components/TableAnnualBreakdown.vue';

export default {
  name: 'ReportSolutionDelivery',
  mixins: [loading, list, suppliers],
  components: {
    TableAnnualBreakdown,
  },
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.reports',
      },
      {
        text: 'menu.solutionDelivery',
      },
      ],
  },
  computed: {
    ...mapState('agreementItems', ['agreementItems']),
    headers() {
      return [
        {
          text: 'Solution ID',
          value: 'agreement.solution.solutionNumber',
          width: '110px',
        },
        {
          text: 'Agreement',
          value: 'agreement.agreementNumber',
          width: '110px',
        },
        {
          text: 'Status',
          value: 'agreement.status',
          width: '100px',
        },
        {
          text: 'Client Name',
          value: 'agreement.solution.contact',
          width: '180px',
        },
        {
          text: 'Solution Description',
          value: 'agreement.solution.name',
          width: '250px',
        },
        {
          text: 'Service Type',
          value: 'revenueLine.name',
          width: '100px',
        },
        {
          text: 'Billing Cycle',
          value: 'lineType',
          width: '100px',
        },
        {
          text: 'Cost',
          value: 'cost',
          width: '80px',
          class: 'text-right',
        },
        {
          text: 'Profit',
          value: 'profit',
          width: '80px',
          class: 'text-right',
        },
        {
          text: '% Profit Margin',
          value: 'percProfit',
          width: '55px',
        },
        {
          text: 'Quote Signed Date',
          value: 'agreement.signedDate',
          width: '120px',
        },
        {
          text: 'Days Open / Days to Deliver',
          value: 'anticipatedDeliveryDate',
          width: '80px',
        },
        {
          text: 'Delivered / Open',
          value: 'deliveredOpen',
          width: '80px',
        },
        {
          text: 'Expected Delivery / Delivered',
          value: 'expectedDeliveryDelivered',
          width: '120px',
        },
        {
          text: 'Month',
          value: 'month',
          width: '80px',
        },
        {
          text: 'Supplier',
          value: 'supplier',
          width: '180px',
        },
        {
          text: 'Invoice Number',
          value: 'agreement.invoiceNumber',
          width: '120px',
        },
        {
          text: 'Invoice Status',
          value: 'agreement.invoiceStatus',
          width: '120px',
        },
        {
          text: 'Notes',
          value: 'agreement.notes',
          width: '400px',
        },
      ];
    },
    items() {
      const items = this.agreementItems.map((a) => ({
        ...a,
        cost: a.unitCostAmount * a.quantity,
      }));
      return items.map((a) => ({
        ...a,
        percProfit: a.cost > 0 && a.profit > 0 ? Math.round((a.profit / a.cost) * 100) : undefined,
        anticipatedDeliveryDate: this.formatFromNow(a.implementationDate),
        deliveredOpen: a.agreement.deliveredDate ? 'Delivered' : 'Open',
        // eslint-disable-next-line max-len
        expectedDeliveryDelivered: a.agreement.deliveredDate ? a.agreement.deliveredDate : a.implementationDate,
        // eslint-disable-next-line max-len
        month: this.formatMonth(a.agreement.deliveredDate ? a.agreement.deliveredDate : a.implementationDate),
        supplier: this.allSuppliers.find((s) => s.id === a.supplierId)?.name,
      }));
    },
    deliveredSummary() {
      return {
        inProgress: this.items
          .filter((a) => a.agreement.status === 'In Progress' ||
            a.agreement.status === 'Work In Progress')
          .length,
        acceptedInTheLastWeek: this.items
          .filter((a) => a.agreement.status === 'Accepted' &&
            a.agreement.signedDate &&
            this.isWithinLastWeek(a.agreement.signedDate))
          .length,
        deliveredInTheLastWeek: this.items
          .filter((a) => a.agreement.status === 'Delivered' &&
            a.agreement.deliveredDate &&
            this.isWithinLastWeek(a.agreement.deliveredDate))
          .length,
      };
    },
    supplierSummary() {
      return this.allSuppliers.map((s) => ({
        ...s,
        items: this.items.filter((i) => i.supplierId === s.id),
      }))
        .filter((s) => s.items.length > 0)
        .sort((a, b) => b.name - a.name);
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('agreementItems', ['LOAD_agreementItemsExpanded']),
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.loadAllSuppliers();
        })(),
        (async () => {
          await this.LOAD_agreementItemsExpanded();
        })(),
      ]);
      this.isLoading = false;
    },
    isWithinLastWeek(date) {
      const lastWeek = dayjs().subtract(7, 'day');
      return dayjs(date).isAfter(lastWeek);
    },
    summaryByState(status) {
      return {
        deliveredThisYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')))
          .length,
        deliveredFirstHalfOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .length,
        deliveredSecondHalfOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(12, 'month')))
          .length,
        deliveredFirstQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(3, 'month')))
          .length,
        deliveredSecondQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(3, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .length,
        deliveredThirdQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(9, 'month')))
          .length,
        deliveredFourthQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(9, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(12, 'month')))
          .length,
        deliveredJanuary: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(1, 'month')))
          .length,
        deliveredFebruary: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(1, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(2, 'month')))
          .length,
        deliveredMarch: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(2, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(3, 'month')))
          .length,
        deliveredApril: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(3, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(4, 'month')))
          .length,
        deliveredMay: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(4, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(5, 'month')))
          .length,
        deliveredJune: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(5, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .length,
        deliveredJuly: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(7, 'month')))
          .length,
        deliveredAugust: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(7, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(8, 'month')))
          .length,
        deliveredSeptember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(8, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(9, 'month')))
          .length,
        deliveredOctober: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(9, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(10, 'month')))
          .length,
        deliveredNovember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(10, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(11, 'month')))
          .length,
        deliveredDecember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(11, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(12, 'month')))
          .length,
      };
    },
    profitByState(status) {
      return {
        deliveredThisYear: this.formatNumber(this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')))
          .reduce((sum, a) => sum + a.profit, 0)),
        deliveredFirstHalfOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredSecondHalfOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(12, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredFirstQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(3, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredSecondQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(3, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredThirdQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(9, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredFourthQuarterOfTheYear: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(9, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(12, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredJanuary: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(1, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredFebruary: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(1, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(2, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredMarch: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(2, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(3, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredApril: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(3, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(4, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredMay: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(4, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(5, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredJune: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate).isAfter(dayjs().startOf('year').add(5, 'month')) &&
            dayjs(a.agreement.deliveredDate).isBefore(dayjs().startOf('year').add(6, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredJuly: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(6, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(7, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredAugust: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(7, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(8, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredSeptember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(8, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(9, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredOctober: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(9, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(10, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredNovember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(10, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(11, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
        deliveredDecember: this.items
          .filter((a) => a.agreement.status === status &&
            a.agreement.deliveredDate &&
            dayjs(a.agreement.deliveredDate)
              .isAfter(dayjs().startOf('year').add(11, 'month')) &&
            dayjs(a.agreement.deliveredDate)
              .isBefore(dayjs().startOf('year').add(12, 'month')))
          .reduce((sum, a) => sum + a.profit, 0),
      };
    },
  },
};
</script>
