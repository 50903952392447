export default {
  SET_selectedUsernames(state, payload) {
    state.selectedUsernames = payload;
  },
  SET_selectedVersions(state, payload) {
    state.selectedVersions = payload;
  },
  SET_fromDate(state, payload) {
    state.fromDate = payload;
  },
  SET_toDate(state, payload) {
    state.toDate = payload;
  },
  SET_navigationRoutes(state, payload) {
    state.navigationRoutes = payload;
  },
  SET_features(state, payload) {
    state.features = payload;
  },
  SET_prevFromDate(state, payload) {
    state.prevFromDate = payload;
  },
  SET_prevToDate(state, payload) {
    state.prevToDate = payload;
  },
  SET_prevFeatures(state, payload) {
    state.prevFeatures = payload;
  },
};
