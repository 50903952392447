import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('quoteSection', 'quoteSections');

const customActions = {
  async CREATE_quoteSection({ rootGetters }, quoteSection) {
    const data = await odata.o(rootGetters)
      .post('quoteSections', quoteSection)
      .query();
    return data;
  },
  async LOAD_quoteSectionsForQuote({ commit, rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('quoteSections')
      .query({
        $filter: `IsDeleted eq false AND QuoteId eq ${quoteId}`,
      });
    commit('SET_quoteSections', data);
    return data;
  },
  async LOAD_quoteSectionsAndLinesForQuote({ commit, rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('quoteSections')
      .query({
        $filter: `IsDeleted eq false AND QuoteId eq ${quoteId}`,
        $expand: 'lineItems',
      });
    commit('SET_quoteSections', data);
    return data;
  },
  async LOAD_inactiveQuoteSectionsForQuote({ rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('quoteSections')
      .query({
        $filter: `IsDeleted eq false AND QuoteId eq ${quoteId} AND IsActive eq false`,
      });
    return data;
  },
};

export default { ...actions, ...customActions };
