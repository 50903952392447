<template>
  <v-card v-if="value" :loading="isLoading" dense>
    <v-card-text>
      <v-list dense>
      <template v-for="(exchangeRate, index) in exchangeRates">
        <v-list-item
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title class="text-right">
              Converted from {{ exchangeRate.sourceCurrency }}
              using a rate of {{ exchangeRate.rate.toFixed(3) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiDelete } from '@mdi/js';
import { debounce, uniq } from 'lodash';

export default {
  name: 'ExchangeRates',
  props: ['value', 'disabled', 'allLineItems'],
  mixins: [loading],
  computed: {
    ...mapState('exchangeRates', ['exchangeRates']),
    ...mapState('quotes', ['quote']),
    quoteId() {
      return this.value?.id;
    },
    lineCurrencies() {
      return uniq(
        this.allLineItems
          .map((l) => l.unitCostCurrencyCode)
          .filter((c) => c !== this.quote?.currencyCode),
      );
    },
    missingExchangeRates() {
      return this.lineCurrencies
        .filter((c) => !this.exchangeRates
          .find((e) => e.sourceCurrency === c));
    },
  },
  data() {
    return {
      mdiDelete,
      dialog: false,
    };
  },
  watch: {
    missingExchangeRates: {
      immediate: true,
      deep: true,
      handler() {
        if (this.missingExchangeRates.length > 0) {
          this.createMissingDebounced();
        }
      },
    },
  },
  async mounted() {
    await this.loadExchangeRates();
  },
  methods: {
    ...mapActions('exchangeRates', [
      'CREATE_exchangeRate',
      'LOAD_exchangeRatesForQuote',
      'DELETE_exchangeRate',
    ]),
    async loadExchangeRates() {
      if (!this.value) {
        return;
      }
      this.isLoading = true;
      if (this.quoteId) {
        await this.LOAD_exchangeRatesForQuote(this.quoteId);
      }
      this.isLoading = false;
    },
    async remove(exchangeRate) {
      this.isLoading = true;
      await this.DELETE_exchangeRate(exchangeRate.id);
      this.isLoading = false;
      await this.loadExchangeRates();
    },
    async createExchangeRate(currency) {
      this.isLoading = true;
      try {
        this.$log.info(`Creating exchange rate: ${currency}`);
        const filter = `sourceCurrency eq '${currency}' and targetCurrency eq '${this.quote.currencyCode}'`;
        const orderBy = 'created desc';
        const { data } = await this.$http.get(`/odata/currencyItems?$filter=${filter}&$orderby=${orderBy}&$top=1`);
        this.$log.info({
          currency, data, filter, orderBy,
        });
        await this.CREATE_exchangeRate({
          quoteId: this.quoteId,
          sourceCurrency: currency,
          targetCurrency: this.quote.currencyCode,
          rate: data.value[0].rate,
        });
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error creating exchange rate');
      } finally {
        this.isLoading = false;
      }
    },
    async createMissing() {
      this.isLoading = true;
      await Promise.all(this.missingExchangeRates.map((c) => this.createExchangeRate(c)));
      this.isLoading = false;
      await this.loadExchangeRates();
    },
    // eslint-disable-next-line func-names
    createMissingDebounced: debounce(function () {
      this.createMissing();
    }, 2500),
  },
};
</script>
