var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{attrs:{"title":`Contacts (${_vm.people.length})`},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"to":{
        name: 'quotes-EditPerson',
        query: {
          clientId: _vm.clientId,
        }
      }}},[_vm._v(" Add ")])]},proxy:true}])},[_c('v-list',{staticClass:"list-height-restricted",attrs:{"three-line":""}},[_vm._l((_vm.people),function(item){return [_c('v-list-item',{key:item.id},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" "),_c('span',[_c('a',{attrs:{"href":`mailto:${item.emailAddress}`}},[_vm._v(" "+_vm._s(item.emailAddress)+" ")])]),_c('v-btn',{staticClass:"float-right",attrs:{"small":"","icon":"","to":{
                name: 'quotes-EditPerson',
                query: { id: item.id, clientId: _vm.clientId }
              }}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencil))])],1)],1),_c('v-list-item-subtitle',[_vm._v(" created "+_vm._s(_vm.formatFromNow(item.created))+" ")])],1)],1)]}),(_vm.people.length === 0)?_c('span',{staticClass:"text-h6"},[_vm._v(" No items found ")]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }