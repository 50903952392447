import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('quoteAttachment', 'quoteAttachments');

const customActions = {
  async LOAD_quoteAttachmentsForQuote({ commit, rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('quoteAttachments')
      .query({ $filter: `IsDeleted eq false AND QuoteId eq ${quoteId}` });
    commit('SET_quoteAttachments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
