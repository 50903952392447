<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormQuoteTemplate
      :QuoteTemplate="editQuoteTemplate"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormQuoteTemplate from '../components/FormQuoteTemplate.vue';

export default {
  components: {
    FormQuoteTemplate,
  },
  name: 'EditQuoteTemplate',
  module: 'QuoteTemplate',
  mixins: [loading],
  meta: {
    redirectOnRefresh: { name: 'quotes-ListPerson' },
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.quoteTemplates',
        to: { name: 'quotes-ListQuoteTemplate' },
        disabled: false,
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing QuoteTemplate. Default to {}.
      */
      editQuoteTemplate: {},
      /**
       * Query Id for QuoteTemplate
       */
      quoteTemplateId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('quoteTemplates', ['quoteTemplates', 'quoteTemplate']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.quoteTemplateId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_quoteTemplate(this.quoteTemplateId);
      this.editQuoteTemplate = this.quoteTemplate;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplate', 'CREATE_quoteTemplate', 'UPDATE_quoteTemplate']),
    /** Method used to save the item
     */
    async save(quoteTemplate) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_quoteTemplate(quoteTemplate);
      } else {
        await this.UPDATE_quoteTemplate(quoteTemplate);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListQuoteTemplate' });
    },
  },
};
</script>

<docs>
# EditQuoteTemplate example

'''js
<EditQuoteTemplate :QuoteTemplate="QuoteTemplate" />
'''
</docs>
