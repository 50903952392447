<template>
  <div>
    <MigrateSectionLines
      :value="Section"
      :index="0"
    />
  </div>
</template>

<script>
import MigrateSectionLines from './MigrateSectionLines.vue';

export default {
  name: 'MigrateSectionLineItems',
  components: {
    MigrateSectionLines,
  },
  props: {
    Section: {
      type: Object,
      required: true,
    },
  },
};
</script>
