import { mapState } from 'vuex';
import { sortBy } from 'lodash';

export default {
  computed: {
    ...mapState('clients', ['clients']),
    ...mapState('suppliers', ['suppliers']),
    ...mapState('xeroContacts', ['xeroContacts']),
    customerContacts() {
      return this.allContacts.filter((c) => !c.isSupplier || c.isSupplier === null);
    },
    supplierContacts() {
      return this.allContacts.filter((c) => !c.isCustomer);
    },
    allContacts() {
      const { clients, suppliers, xeroContacts } = this;
      const localSuppliers = suppliers?.map((c) => ({
        id: c.id,
        name: c.name,
        emailAddress: c.emailAddress,
        source: 'local',
        isSupplier: true,
        isCustomer: false,
      })) ?? [];
      const localClients = clients?.map((c) => ({
        id: c.id,
        name: c.name,
        emailAddress: c.emailAddress,
        source: 'local',
        isSupplier: false,
        isCustomer: true,
      })) ?? [];
      const accContacts = xeroContacts?.map((c) => (
        {
          id: c.contactID,
          name: c.name,
          source: 'xero',
          emailAddress: c.emailAddress,
          isSupplier: c.isSupplier,
          isCustomer: c.isCustomer })) ?? [];
      return sortBy([
        localSuppliers,
        ...localClients,
        ...accContacts,
      ], (c) => c.name);
    },
  },
};
