<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-select
              data-cy="FormPriceList-supplierId"
              filled
              :label="$$t('supplierId')"
              :items="suppliers"
              item-value="id"
              item-text="name"
              v-model="localPriceList.supplierId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPriceList-name"
              label="Item Name"
              v-model="localPriceList.name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPriceList-price"
              :label="$$t('price')"
              v-model="localPriceList.price"
              @change="calculateGrossProfit();calculateCostToCustomer();"
              @keydown="calculateGrossProfit();calculateCostToCustomer();"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPriceList-markup"
              :label="$$t('markup')"
              v-model="localPriceList.markup"
              @change="calculateGrossProfit();calculateCostToCustomer();"
              @keydown="calculateGrossProfit();calculateCostToCustomer();"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPriceList-costToCustomer"
              :label="$$t('costToCustomer')"
              v-model="localPriceList.costToCustomer"
              @change="calculateGrossProfit"
              disabled
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormPriceList-grossProfit"
              :label="$$t('grossProfit')"
              v-model="localPriceList.grossProfit"
              disabled
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormPriceList-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormPriceList-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormPriceList',
  module: 'PriceList',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    PriceList: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localPriceList
      */
      localPriceList: {},
    };
  },
  computed: {
    ...mapState('suppliers', ['suppliers']),
  },
  watch: {
    PriceList() {
      this.localPriceList = this.PriceList;
    },
  },
  created() {
    this.localPriceList = this.PriceList;
  },
  methods: {
    /**
     * Calculate Cost To Customer based on markup
     */
    calculateCostToCustomer() {
      const { price, markup } = this.localPriceList;
      this.localPriceList.costToCustomer = (price * (1 + (markup / 100))).toFixed(2);
    },
    /**
     * Calculate Gross Profit
     */
    calculateGrossProfit() {
      const { price, costToCustomer } = this.localPriceList;
      this.localPriceList.grossProfit = price ?? 0 - costToCustomer ?? 0;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localPriceList);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormPriceList example

'''js
<FormPriceList :PriceList="PriceList" />
'''
</docs>
