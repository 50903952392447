<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="revenueLines"
      class="elevation-1"
      :loading="isLoading"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-btn
            color="success"
            data-cy="RevenueLine-button-new"
            @click="$router.push({
              name: 'contracts-EditRevenueLine'
            })"
          >
            <v-icon class="mr-2">
              {{ mdiPlus }}
            </v-icon>
            {{ $t('common.buttons.new') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: 'contracts-EditRevenueLine', query: { id: item.id } }">
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.isDefault="{ item }">
        <span v-if="item.isDefault">
          Yes
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn-toggle>
          <v-btn color="default" small
            :to="{ name: 'contracts-EditRevenueLine', query: { id: item.id } }">
            {{ $t('common.buttons.edit')}}
          </v-btn>
          <v-btn :disabled="item.isDefault" color="error" small @click="remove(item)">
            {{ $t('common.buttons.delete')}}
          </v-btn>
        </v-btn-toggle>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ListRevenueLine',
  module: 'Contract',
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.revenueLines',
        disabled: true,
      },
      ],
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('revenueLines', ['revenueLines']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('revenueLines', ['LOAD_revenueLines', 'DELETE_revenueLine']),
    /** Delete RevenueLine from the collection
     */
    async remove(revenueLine) {
      this.$confirm(this.$t('common.phrases.confirmDelete')).then(async (confirm) => {
        if (confirm) {
          this.isLoading = true;
          await this.DELETE_revenueLine(revenueLine.id);
          this.isLoading = false;
        }
      });
    },
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_revenueLines();
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# RevenueLine example

'''js
<RevenueLine />
'''
</docs>
