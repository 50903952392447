<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="solutions"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="-1"
      dense
      :search="search"
      sort-by="solutionNumber"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
          </v-col>
          <v-col cols="4">
          </v-col>
          <v-col cols="3">
            <v-text-field
              clearable
              class="mr-2"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="1">
            <v-btn small color="secondary" @click="refresh" class="mt-5">
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.created="{ item }">
            {{ formatDate(item.created) }}
          </template>
      <template v-slot:item.solutionNumber="{ item }">
        <router-link :to="{ name: 'solutions-EditSolution', query: { id: item.id } }">
          {{ item.solutionNumber }}
        </router-link>
      </template>
    <template v-slot:item.isDeleted="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isDeleted"
        large
        @save="saveIsDeleted(item)"
      >
        <div class="d-flex">
          <BoolField class="inline-block" v-model="item.isDeleted" />
          <v-icon small class="ml-2">{{ mdiPencilBoxOutline }}</v-icon>
        </div>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: true }, { text: 'No', value: false}]"
            v-model="item.isDeleted"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.isActive="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isActive"
        large
        @save="saveIsActive(item)"
      >
        <div class="d-flex">
          <BoolField class="inline-block" :value="!item.isActive" />
          <v-icon small class="ml-2">{{ mdiPencilBoxOutline }}</v-icon>
        </div>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: false }, { text: 'No', value: true}]"
            v-model="item.isActive"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiPencilBoxOutline,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';
import currencies from '~src/mixins/currencies';

export default {
  name: 'ManageSolutions',
  module: 'Solutions',
  components: {
    BoolField,
  },
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.solutions',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiRefresh,
      search: '',
      mdiPencilBoxOutline,
      /**
      * Data
      */
      localData: [],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('solutions', ['solutions']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('solutionNumber'),
          value: 'solutionNumber',
        },
        {
          text: this.$$t('contactName'),
          value: 'contact',
        },
        {
          text: this.$$t('isActive'),
          value: 'isActive',
        },
        {
          text: this.$$t('isDeleted'),
          value: 'isDeleted',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('solutions', ['LOAD_manageSolutions', 'DELETE_solution', 'UPDATE_solution']),
    /** Delete Solution based on the selected currency
     */
    async refresh() {
      this.$log.debug('Refresh solutions');
      this.isLoading = true;
      await this.LOAD_manageSolutions();
      this.isLoading = false;
    },
    /** Delete Solution from the collection
     */
    async remove(solution) {
      const msg = `<b>This will delete the following Contracts:</b><br>${solution.contracts.join('<br>')}`;
      const result = await this.$confirm(msg, {
        title: 'Delete solution',
      });
      if (result) {
        this.isLoading = true;
        await this.DELETE_solution(solution.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
    async saveIsActive(item) {
      await this.UPDATE_solution({
        id: item.id,
        isActive: item.isActive,
      });
      await this.refresh();
    },
    async saveIsDeleted(item) {
      await this.UPDATE_solution({
        id: item.id,
        isDeleted: item.isDeleted,
      });
      await this.refresh();
    },
  },
};
</script>

<docs>
# Solution example

'''js
<Solution />
'''
</docs>
