<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12">
        <v-card
          class="mx-auto"
        >
          <v-card-text>
            <UserManagement ref="userManagement">
              <template v-slot:top-left>
                <v-dialog
                  v-model="dialog"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="success"
                      class="ml-10"
                      v-bind="attrs"
                      v-on="on">
                      <v-icon>{{ mdiPlus }}</v-icon>
                      {{ $t('common.userManagement.addNew') }}
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title>
                      {{ $t('common.userManagement.addNew')}}
                    </v-card-title>

                    <v-card-text>
                      <v-form
                        ref="form"
                        style="width: 100%;"
                        @keydown.enter="submit"
                        @submit.prevent="adminRegister"
                      >
                        <v-text-field
                          :label="$t('common.username')"
                          outlined
                          :prepend-inner-icon="mdiAccount"
                          v-model="form.username"
                          :rules="rules.required"
                          data-cy="login-username"
                          autocomplete="username"
                        />
                        <v-text-field
                          :label="$t('register.email')"
                          outlined
                          :prepend-inner-icon="mdiMail"
                          v-model="form.email"
                          :rules="rules.required"
                          type="email"
                          autocomplete="email"
                        />
                        <PasswordField
                          :label="$t('common.password')"
                          :rules="rules.password"
                          v-model="form.password"
                          class="mb-0"
                        />
                        <v-alert type="error" v-for="error in errors" :key="error">
                          {{ error }}
                        </v-alert>
                        <v-btn
                          class="primary mb-2 rounded-0 text-capitalize block"
                          style="width: 100%"
                          :loading="isLoading"
                          type="submit"
                          data-cy="register-adminRegister"
                        >
                          {{ $t('common.buttons.save') }}
                        </v-btn>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </template>
            </UserManagement>
            <div class="mb-10" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import {
  mdiAccount, mdiLock, mdiMail, mdiPlus,
} from '@mdi/js';
import auth from '@codehq/aurora-app-core/src/mixins/auth';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import UserManagement from '@codehq/aurora-app-core/src/components/UserManagement.vue';
import PasswordField from '../components/PasswordField.vue';

export default {
  name: 'UserManagementHome',
  module: 'Membership',
  components: {
    PasswordField,
    UserManagement,
  },
  mixins: [auth, loading],
  data() {
    return {
      mdiPlus,
      mdiAccount,
      mdiLock,
      mdiMail,
      dialog: false,
      form: {
        username: '',
        email: '',
        password: '',
      },
    };
  },
  methods: {
    ...mapActions('auth', ['REGISTER_auth']),
    async adminRegister() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await this.REGISTER_auth(this.form);
          this.dialog = false;
          await this.$refs.userManagement.refresh();
        } catch (error) {
          this.$log.error(error);
          this.errors = error.response.data.modelState[''];
          this.$log.error(error.response.data.modelState['']);
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
