<template>
  <v-row no-gutters>
    <v-col cols="3">
    </v-col>
    <v-col cols="9">
        <v-text-field
          v-model="localAmount"
          @change="change" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CurrencyField',
  props: {
    currency: {
      type: String,
      default: 'USD',
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    currency() {
      this.localCurrency = this.currency;
    },
    amount() {
      this.localAmount = this.amount;
    },
  },
  mounted() {
    this.localCurrency = this.currency;
    this.localAmount = this.amount;
  },
  computed: {
    ...mapState('currencies', ['currencies']),
  },
  data() {
    return {
      localAmount: 0,
      localCurrency: 'USD',
    };
  },
  methods: {
    /**
     * Executes change event
     */
    change() {
      const { localAmount, localCurrency } = this;
      this.$emit('change', { amount: localAmount, currencyCode: localCurrency });
    },
  },
};
</script>
