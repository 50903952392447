<template>
  <div>
   <h2 class="centered-container">
     Webhooks
   </h2>
   <v-data-table :headers="headers" :items="items">
     <template v-slot:top>
         <v-row>
           <v-col cols="4">
             <v-select
               :items="availableSettings"
               item-text="displayName"
               item-value="name"
               v-model="form.name"
               label="Setting"
             ></v-select>
           </v-col>
           <v-col cols="4">
              <v-text-field
                v-model="form.value"
                :label="valueLabel"
                hint="This is the POST url of the webhook"
              />
           </v-col>
           <v-col cols="2">
             <v-btn class="mt-2" color="success"
               :loading="isSaving"
               @click="create">Add</v-btn>
           </v-col>
           <v-col cols="2">
             <v-btn class="mt-2 float-right" color="primary"
               :loading="isLoading"
               @click="refresh">
               <v-icon>{{ mdiRefresh }}</v-icon>
             </v-btn>
           </v-col>
         </v-row>
       </template>
       <template v-slot:item.created="{ item }">
         {{ formatDate(item.created) }}
       </template>
       <template v-slot:item.actions="{ item }">
         <v-btn x-small color="error" small @click="remove(item)">
           Delete
         </v-btn>
       </template>
     </v-data-table>
  </div>
 </template>

<script>
import { mapState, mapActions } from 'vuex';
import { mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'TableIntegrationSettings',
  mixins: [loading],
  props: {
    ns: {
      type: String,
      default: '',
    },
    valueLabel: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiRefresh,
      form: {
        name: undefined,
        value: undefined,
      },
    };
  },
  computed: {
    ...mapState('integrationSettings', ['integrationSettings', 'availableSettings']),
    headers() {
      const { valueLabel } = this;
      return [
        { text: 'Name', value: 'displayName' },
        { text: valueLabel, value: 'value' },
        { text: 'Created', value: 'created' },
        { text: 'Created By', value: 'createdBy' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
    items() {
      return this.integrationSettings.map((item) => ({
        ...item,
        displayName: this.availableSettings
          .find((s) => s.name === item.name)?.displayName ?? item.name,
      }));
    },
  },
  methods: {
    ...mapActions('integrationSettings', [
      'LOAD_integrationSettings',
      'CREATE_integrationSetting',
      'DELETE_integrationSetting',
    ]),
    async create() {
      this.isSaving = true;
      const { name, value } = this.form;
      await this.CREATE_integrationSetting({
        name,
        value,
      });
      this.isSaving = false;
      this.form = {
        name: undefined,
        value: undefined,
      };
      await this.refresh();
    },
    async refresh() {
      const { ns } = this;
      this.isLoading = true;
      await this.LOAD_integrationSettings(ns);
      this.isLoading = false;
    },
    async remove(item) {
      const result = await this.$confirm('Are you sure you want to delete this item?');
      if (result) {
        this.isLoading = true;
        await this.DELETE_integrationSetting(item.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
 <style scoped>
 .centered-container {
     display: flex;
     align-items: center;
   }
 </style>
