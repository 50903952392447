<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <v-card v-if="value" :loading="isLoading">
    <v-card-title>
      <span class="headline">Exchange Rates ({{ exchangeRates.length }})</span>
      {{ lineCurrencies }}
    </v-card-title>
    <v-card-text>
      <v-list three-line>
      <template v-for="(exchangeRate, index) in exchangeRates">
        <v-list-item
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title v-html="exchangeRate.fileName"></v-list-item-title>
            <v-list-item-subtitle v-html="exchangeRate.attachmentType"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              icon
              target="_blank"
              :href="attachment.fileUrl"
            >
              <v-icon>{{ mdiDownload }}</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-btn :disabled="disabled" icon color="error" @click="remove(attachment)">
              <v-icon>{{ mdiDelete }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mdiFile, mdiDownload, mdiDelete } from '@mdi/js';
import { uniq } from 'lodash';

export default {
  name: 'ExchangeRates',
  props: ['value', 'disabled', 'allLineItems'],
  mixins: [loading],
  computed: {
    ...mapState('exchangeRates', ['exchangeRates']),
    quoteId() {
      return this.value?.id;
    },
    lineCurrencies() {
      return uniq(this.allLineItems.map((l) => l.unitCostCurrencyCode));
    },
  },
  data() {
    return {
      mdiDelete,
      mdiDownload,
      mdiFile,
      dialog: false,
    };
  },
  // watch: {
  //   allLineItems: {
  //     immediate: true,
  //     deep: true,
  //     handler() {

  //     }
  //   }
  // },
  async mounted() {
    await this.loadExchangeRates();
  },
  methods: {
    ...mapActions('exchangeRates', [
      'CREATE_exchangeRate',
      'LOAD_exchangeRatesForQuote',
      'DELETE_exchangeRate',
    ]),
    async loadExchangeRates() {
      if (!this.value) {
        return;
      }
      this.isLoading = true;
      if (this.quoteId) {
        await this.LOAD_exchangeRatesForQuote(this.quoteId);
      }
      this.isLoading = false;
    },
    async remove(exchangeRate) {
      this.isLoading = true;
      await this.DELETE_exchangeRate(exchangeRate.id);
      this.isLoading = false;
      await this.loadExchangeRates();
    },
  },
};
</script>
