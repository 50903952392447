<template>
  <v-container fluid>
    <v-card :loading="isSaving">
      <v-card-title primary-title class="justify-center text-h4">
        {{ $t('common.onboard.title') }}
      </v-card-title>
      <v-card-text>
        <v-form
          lazy-validation
          v-model="valid"
          @submit.prevent="save"
          ref="form"
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                block
                counter="100"
                data-cy="FormOrganization-name"
                :label="$t('common.onboard.name')"
                v-model="localOrganization.name"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="float-right"
                color="primary" type="submit" :loading="isSaving"
                data-cy="FormOrganization-button-save">
                {{ $t('common.buttons.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'Onboard',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Organization. Default to {}.
      */
      localOrganization: {
        name: '',
      },
      /**
      * Get/Set editing Organization. Default to {}.
      */
      valid: false,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('organizations', ['organizations', 'organization']),
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('organizations', ['CREATE_organization', 'LOAD_organizations']),
    ...mapMutations('organizations', ['SET_organization']),
    /** Method used to save the item
     */
    async save() {
      const { localOrganization } = this;
      this.isSaving = true;
      const org = await this.CREATE_organization({
        name: localOrganization.name,
      });
      this.SET_organization(org);
      await this.LOAD_organizations();
      this.isSaving = false;
      this.$router.push({ name: 'app-Init' });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
