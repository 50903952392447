<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="default"
        class="ml-2"
        v-bind="attrs"
        v-on="on"
        :title="title"
        :small="small"
      >
        🚀
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
      >
        <v-list-item-title
          style="cursor: pointer;"
          @click="onClick(item)">
          {{ item }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'RocketMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return `Available actions:\n${this.items.join('\n')}`;
    },
  },
  methods: {
    onClick(item) {
      this.$emit('click', item);
    },
  },
};
</script>
