export default {
  comments: (state, getters, rootState) => {
    const { users } = rootState.users;
    const commentsWithUsernames = state.comments.map((comment) => {
      const user = users.find((u) => u.id === comment.createdBy);
      return {
        ...comment,
        createdBy: user?.displayName ?? comment.createdBy,
      };
    });
    return commentsWithUsernames;
  },
  solutionComments: (state, getters, rootState) => {
    const { users } = rootState.users;
    const solutionCommentsWithUsernames = state.solutionComments.map((comment) => {
      const user = users.find((u) => u.id === comment.createdBy);
      return {
        ...comment,
        createdBy: user?.displayName ?? comment.createdBy,
      };
    });
    return solutionCommentsWithUsernames;
  },
};
