<template>
  <div>
    <v-btn
      text
      block
      color="secondary"
      @click="createContract"
      data-cy="button-create-contract"
    >
      Create Contract
    </v-btn>
    <v-snackbar
      absolute
      bottom
      vertical
      multi-line
      color="success"
      v-model="show"
      :timeout="timeout"
      data-cy="snackbar-create-contract"
    >
      {{ message }}
        <v-btn
          v-if="!isLoading"
          text
          class="close-button"
          @click.native="close"
          x-small
          outlined
        >
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      <template
        v-if="solutionId"
        v-slot:action="{ attrs }"
      >
        <v-btn
          :href="`https://contracts.funancial.com/solutions/edit-solution?id=${solutionId}`"
          target="_blank"
          v-bind="attrs"
          @click.native="close"
          outlined
          block
          small
        >
          <ProgressCircularTimeout :timeout="timeout" v-if="!isLoading" :size="20" />
          <div class="mr-2"/>
          View Contract
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ProgressCircularTimeout from '@codehq/aurora-app-core/src/components/ProgressCircularTimeout.vue';

export default {
  name: 'CreateContract',
  components: {
    ProgressCircularTimeout,
  },
  mixins: [loading],
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mdiClose,
      message: 'Creating contract...',
      show: false,
      solutionId: undefined,
      timeout: -1,
    };
  },
  methods: {
    /**
     * Executes close action
     */
    close() {
      this.show = false;
    },
    async createContract() {
      const { quote } = this;
      this.isLoading = true;
      this.show = true;
      const { data } = await this.$http.post('/api/automation/convert/contract', {
        quoteId: quote.id,
      });
      this.solutionId = data?.id;
      this.message = 'Contract created successfully';
      this.timeout = 30000;
      this.isLoading = false;
      this.$emit('refresh');
    },
  },
};
</script>
<style scoped>
.close-button {
  position: absolute;
  right: 10px;
  top: 14px;
}
</style>
