<template>
  <v-alert
    v-if="localIncomeLine"
    border="left"
    colored-border
    color="deep-purple accent-4"
    elevation="2"
  >
    <template v-slot:prepend>
      <span class="ml-2 mr-2">
        {{ localIncomeLine.lineNumber }}
      </span>
    </template>
    <v-row no-gutters>
      <v-col cols="11">
        <IncomeLine
          :Agreement="Agreement"
          v-model="localIncomeLine"
          :index="index"
          :disabled="isDisabled"
        />
        <v-row dense>
          <v-col cols="12"
              v-for="costLine in costLines"
              :key="costLine.id">
            <CostLine
              :value="costLine"
              :disabled="isDisabled"
              :Agreement="Agreement"
              @input="debouncedSaveCostLine"
              @remove="removeCostLine(costLine)"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              small
              title="Add a new cost line"
              @click="createCostLine(value)"
            >
              <v-icon>{{ mdiPlus }}</v-icon>
              Add Cost Line
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1" class="d-flex justify-end">
        <div class="d-flex flex-column" style="padding-left: 5px;">
        </div>
        <div class="d-flex flex-column align-start">
          <ButtonToggleIcon
            v-if="disabled"
            v-model="isLocked"
            :disabled="!isAdmin"
            :trueIcon="mdiLock"
            :falseIcon="mdiLockOpen"
          />
          <v-btn
            :disabled="disabled || index === 0"
            @click="$emit('update:up', localIncomeLine)"
            icon
          >
            <v-icon>{{ mdiArrowUpBold }}</v-icon>
          </v-btn>
          <v-btn
            :color="localIncomeLine.isActive ? '' : 'error'"
            icon
            :title="localIncomeLine.isActive ? 'Archive' : 'Unarchive'"
            @click="saveIsActive(localIncomeLine, !localIncomeLine.isActive)"
          >
            <v-icon>
              {{ localIncomeLine.isActive ? mdiArchive : mdiArchiveOff }}
            </v-icon>
          </v-btn>
          <v-btn
            color="error"
            :disabled="isDisabled"
            icon
            @click="$emit('remove', localIncomeLine)"
          >
            <v-icon>{{ mdiDelete }}</v-icon>
          </v-btn>
          <v-btn
            :disabled="disabled || index === totalItems - 1"
            @click="$emit('update:down', localIncomeLine)"
            icon
          >
            <v-icon>{{ mdiArrowDownBold }}</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import {
  mdiDelete,
  mdiDragVertical,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiVectorIntersection,
  mdiPlus,
  mdiArchive,
  mdiArchiveOff,
  mdiLock,
  mdiLockOpen,
} from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';
import { debounce } from 'lodash';
import ButtonToggleIcon from '@/components/ButtonIconToggle.vue';
import CostLine from './CostLine.vue';
import IncomeLine from './IncomeLine.vue';

export default {
  name: 'AgreementItem',
  module: 'AgreementItem',
  components: {
    ButtonToggleIcon,
    CostLine,
    IncomeLine,
  },
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    supplierDeliveredDisabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mdiArchive,
      mdiArchiveOff,
      mdiDelete,
      mdiDragVertical,
      mdiArrowUpBold,
      mdiArrowDownBold,
      mdiVectorIntersection,
      mdiPlus,
      mdiLock,
      mdiLockOpen,
      isLocked: this.disabled,
      localIncomeLine: {},
      costLines: [],
      profit: undefined,
      localDisabled: this.disabled,
    };
  },
  watch: {
    // index() {
    //   this.$log.info('index changed');
    //   this.localIncomeLine.lineNumber = this.index + 1;
    // },
    localIncomeLine: {
      immediate: true,
      deep: true,
      handler() {
        this.debouncedSave(this.localIncomeLine);
        this.$emit('input', this.localIncomeLine);
      },
    },
    disabled() {
      this.localDisabled = this.disabled;
    },
    total() {
      this.localIncomeLine.lineAmount = this.total;
    },
    taxAmount() {
      this.localIncomeLine.taxAmount = this.taxAmount;
    },
    profit() {
      if (this.profit === undefined) return;

      this.localIncomeLine.profit = this.profit;
    },
    value() {
      this.localIncomeLine = this.value;
      this.refresh();
    },
  },
  computed: {
    ...mapGetters('auth', ['isInRole']),
    ...mapState('incomeLines', ['lineTypes']),
    ...mapState('orgTaxRates', ['orgTaxRates']),
    ...mapState('quotes', ['terms', 'quote']),
    ...mapState('quoteSections', ['quoteSections']),
    isAdmin() {
      return this.isInRole('Administrators');
    },
    isDisabled() {
      if (!this.isLocked) {
        return false;
      }
      return this.disabled;
    },
    total() {
      const { discountRate, quantity } = this.localIncomeLine ?? {};
      let { unitAmount } = this.localIncomeLine ?? {};
      const { taxAmount } = this;
      if (discountRate > 0 && unitAmount) {
        const rate = (100 - discountRate) / 100;
        unitAmount *= rate;
      }
      if (quantity && unitAmount) {
        let lineAmount = parseFloat((quantity * unitAmount).toFixed(2), 10);
        if (taxAmount > 0) {
          lineAmount += taxAmount;
        }
        lineAmount = parseFloat(lineAmount.toFixed(2), 10);
        return lineAmount;
      }
      return undefined;
    },
  },
  created() {
    this.localIncomeLine = this.value;
    this.refresh();
  },
  mounted() {
    this.localDisabled = this.disabled;
    this.isLocked = this.disabled;
    if (this.localIncomeLine.lineAmount === 0) {
      this.localIncomeLine.taxAmount = 0;
      this.$emit('save', this.localIncomeLine);
    }
    if (!this.localIncomeLine.lineNumber) {
      this.localIncomeLine.lineNumber = this.index + 1;
      this.save(this.localIncomeLine);
    }
    this.refresh();
  },
  methods: {
    ...mapActions('incomeLines', ['UPDATE_incomeLine', 'CREATE_incomeLine', 'LOAD_incomeLineProfit']),
    ...mapActions('costLines', ['LOAD_costLines', 'CREATE_costLine', 'UPDATE_costLine']),
    async createCostLine(value) {
      try {
        this.isLoading = true;
        const costLine = await this.CREATE_costLine({
          incomeLineId: value.id,
          unitCostCurrencyCode: this.Agreement.currencyCode,
        });
        this.costLines.push(costLine);
      } catch (error) {
        this.$root.$emit('toast:error', 'Error adding a new line');
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async refresh() {
      try {
        this.isLoading = true;
        const { localIncomeLine } = this;
        this.costLines = await this.LOAD_costLines({ incomeLineId: localIncomeLine.id, isActive: true });
        await this.updateProfit();
      } catch (error) {
        this.$root.$emit('toast:error', 'Error loading cost lines');
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async removeChildAgreementItem(incomeLine) {
      this.$emit('remove', incomeLine);
    },
    /**
     * Archive Income Line
     */
    async saveIsActive(item, isActive) {
      // eslint-disable-next-line max-len
      const result = await this.$confirm(`Are you sure you want to ${!isActive ? 'archive' : 'unarchive'} this item?`);
      if (!result) {
        return;
      }
      await this.UPDATE_incomeLine({
        id: item.id,
        isActive,
      });
      await this.refresh();
      this.$root.$emit('data:incomeLine-updated', item);
    },
    // eslint-disable-next-line func-names
    debouncedSaveCostLine: debounce(function (costLine) {
      this.saveCostLine(costLine);
    }, 250),
    async saveCostLine(costLine) {
      console.log(this.isLocked);
      console.log(this.isDisabled);
      if (this.isDisabled) return;
      this.isSaving = true;
      try {
        await this.UPDATE_costLine(costLine);
        const costLineIdx = this.costLines.findIndex((c) => c.id === costLine.id);
        this.costLines.splice(costLineIdx, 1, costLine);
        await this.updateProfit();
      } catch (error) {
        this.$log.error(error);
      }
      this.isSaving = false;
      this.$root.$emit('data:costLine-updated', costLine);
    },
    async updateProfit() {
      const data = await this.LOAD_incomeLineProfit(this.localIncomeLine.id);
      if (data.value === 0) {
        this.localIncomeLine.profit = 0;
      } else {
        this.localIncomeLine.profit = data;
      }
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (incomeLine) {
      this.save(incomeLine);
      this.$root.$emit('data:incomeLine-updated', incomeLine);
    }, 500),
    async save(incomeLine) {
      console.log(this.isLocked);
      console.log(this.isDisabled);
      if (this.isDisabled) return;
      if (incomeLine?.id) {
        this.isLoading = true;
        try {
          await this.UPDATE_incomeLine(incomeLine);
        } catch (error) {
          this.$log.error(error);
        } finally {
          this.isLoading = false;
        }
        await this.updateProfit();
      }
    },
    async removeCostLine(costLine) {
      const res = await this.$confirm('Are you sure you want to delete this cost line?');
      if (!res) return;

      this.isLoading = true;
      await this.UPDATE_costLine({ id: costLine.id, isDeleted: true });
      this.isLoading = false;
      this.costLines = this.costLines.filter((c) => c.id !== costLine.id);
      await this.updateProfit();
    },
  },
};
</script>

<style>
.text-field-term {
  width: 150px;
  display: inline-block;
  margin-right: 5px !important;
}
</style>
