<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <v-card v-if="contract">
        <v-card-title v-if="item">
          Print {{ contract.contractNumber }} - {{ item.text }}
        </v-card-title>
        <v-card-text>
          <v-select
            :items="printItems"
            v-model="pdf"
            label="Format"
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialog = false">
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="download(url)">
            Print
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-menu v-if="items.length > 0" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :text="text"
          :block="block"
          color="secondary"
          v-bind="attrs"
          v-on="on"
          dark
          :loading="isPrinting"
        >
          Print
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" @click="printContract(item)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ModalPrint',
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    text: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPrinting: false,
      dialog: false,
      pdf: false,
      item: undefined,
      printItems: [{
        value: true,
        text: 'PDF',
      },
      {
        value: false,
        text: 'Word',
      },
      ],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('revenueLines', ['revenueLines']),
    items() {
      const { revenueLines } = this;
      if (!revenueLines) return [];
      return revenueLines.map((r) => ({
        text: r.name,
        value: r.id,
      }));
    },
    url() {
      const { contract, pdf } = this;
      return `${process.env.VUE_APP_APIURL}/print/contract?contractId=${contract.id}&pdf=${pdf}`;
    },
    filename() {
      const { contract, pdf } = this;
      return `Contract-${contract.contractNumber}-${contract.reference}.${pdf ? 'pdf' : 'docx'}`;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_revenueLines();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('revenueLines', ['LOAD_revenueLines', 'DELETE_revenueLine']),
    printContract(item) {
      this.item = item;
      this.dialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    async download() {
      this.isPrinting = true;
      // eslint-disable-next-line max-len
      // const uri = `${process.env.VUE_APP_APIURL}/print/contract?contractId=${this.contract.id}&productId=${item.value}&pdf=${this.pdf}`;

      // // Perform the file download here
      // // For example, you can use the following code to download a file from a URL:
      // const response = await fetch(uri);
      // const blob = await response.blob();
      // const url = window.URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // a.download = this.filename;
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
      // a.remove();

      // Set the loading state to false once the file has finished downloading
      this.isPrinting = false;
    },
  },
};
</script>
