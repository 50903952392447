<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on }">
      <v-btn small color="secondary" v-on="on">Migrate</v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Migrate {{ Section.title }}</span>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-autocomplete
            v-model="form.targetSolutionId"
            :items="solutions"
            :item-text="s => `${s.solutionNumber}: ${s.name}`"
            item-value="id"
            clearable
            label="Solution (Optional)"></v-autocomplete>
          <v-checkbox v-model="form.setOwner" label="Set Owner"></v-checkbox>
          <v-checkbox v-model="form.setClient" label="Set Client"></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="isSaving" color="success" text @click="migrate">
          Migrate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'MigrateSection',
  props: {
    Section: {
      type: Object,
      required: true,
    },
  },
  mixins: [loading],
  data() {
    return {
      dialog: false,
      form: {
        setStatus: false,
        setOwner: false,
        setClient: false,
        targetSolutionId: this.Section?.contract?.solutionId,
        sectionId: this.Section?.id,
      },
    };
  },
  computed: {
    ...mapState('solutions', ['solutions']),
  },
  methods: {
    async migrate() {
      try {
        this.isSaving = true;
        await this.$http.post('/api/migrate/contract/section', this.form);
        this.dialog = false;
        this.$emit('migrated');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Failed to migrate section');
      } finally {
        this.isSaving = false;
      }
      // TODO: Implement migration logic based on checkbox values
    },
  },
};
</script>
