<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :loading="isLoading"
          :search="search"
          :items-per-page="50"
          :footer-props="{
            'items-per-page-options': [50, 100, 200, 500, -1]
          }"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-btn
                color="success"
                data-cy="Person-button-new"
                @click="$router.push({
                  name: 'quotes-EditPerson'
                })"
              >
                <v-icon class="mr-2">
                  {{ mdiPlus }}
                </v-icon>
                {{ $t('common.buttons.new') }}
              </v-btn>
              <div class="text-h5 ml-2">
                People
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                label="Search"
                v-model="search"
                clearable
              ></v-text-field>
            </v-toolbar>
          </template>
          <template v-slot:item.firstName="{ item }">
            <router-link
              :to="{ name: 'quotes-EditPerson', query: { id: item.id } }"
              class="black--text"
              >
              {{ item.firstName }}
            </router-link>
          </template>
          <template v-slot:item.lastName="{ item }">
            <router-link
              :to="{ name: 'quotes-EditPerson', query: { id: item.id } }"
              class="black--text"
              >
              {{ item.lastName }}
            </router-link>
          </template>
          <template v-slot:item.emailAddress="{ item }">
            <a :href="`mailto:${item.emailAddress}`">{{ item.emailAddress }}</a>
          </template>
          <template v-slot:item.workNumber="{ item }">
            <a :href="`tel:${item.workNumber}`">{{ item.workNumber }}</a>
          </template>
          <template v-slot:item.cellNumber="{ item }">
            <a :href="`tel:${item.cellNumber}`">{{ item.cellNumber }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <TableBasicButtons :editLink="{
                name: 'quotes-EditPerson',
                query: {id: item.id}
              }"
              @delete="remove(item)"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPlus } from '@mdi/js';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import clients from '~src/mixins/clients';
import people from '~src/mixins/people';
import list from '~src/mixins/list';

export default {
  name: 'ListPerson',
  module: 'Person',
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.people',
        disabled: true,
      },
      ],
  },
  components: {
    TableBasicButtons,
  },
  mixins: [clients, list, loading, people],
  data() {
    return {
      /**
      * Icons
      */
      mdiPlus,
    };
  },
  computed: {
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('clientId'),
          value: 'client.name',
        },
        {
          text: this.$$t('firstName'),
          value: 'firstName',
        },
        {
          text: this.$$t('lastName'),
          value: 'lastName',
        },
        {
          text: this.$$t('designation'),
          value: 'designation',
        },
        {
          text: 'Type',
          value: 'personType',
        },
        {
          text: this.$$t('emailAddress'),
          value: 'emailAddress',
        },
        {
          text: this.$$t('workNumber'),
          value: 'workNumber',
        },
        {
          text: this.$$t('cellNumber'),
          value: 'cellNumber',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    items() {
      return this.allPeople.map((p) => {
        const person = { ...p };
        person.client = this.allClients.find((client) => client.id === person.contactId);
        return person;
      });
    },
    /**
     * Computed property for the name vuex property search
     */
    search: {
      get() {
        return this.$store.state.people.search;
      },
      set(newValue) {
        this.$store.commit('people/SET_search', newValue);
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('people', ['LOAD_people', 'DELETE_person']),
    /** Delete Person from the collection
     */
    async remove(person) {
      this.isLoading = true;
      await this.DELETE_person(person.id);
      this.isLoading = false;
    },
    /** Refresh the collection
     */
    async refresh() {
      this.isLoading = true;
      await this.loadAllClients();
      await this.loadAllPeople();
      this.isLoading = false;
    },
  },
};
</script>

<docs>
# Person example

'''js
<Person />
'''
</docs>
