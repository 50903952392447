<template>
<div>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      md="3"
      lg="3"
      xl="3"
    >
      <v-select
        v-model="selectedUsernames"
        data-cy="Filters-username"
        :items="usernames"
        label="Filter users"
        multiple
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="3"
      lg="3"
      xl="3"
    >
      <v-select
        v-model="selectedVersions"
        data-cy="Filters-version"
        :items="versions"
        label="Filter versions"
        multiple
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      xl="2"
    >
      <date-picker
        v-model="fromDate"
        data-cy="Filters-fromDate"
        :filled="false"
        label="From"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      xl="2"
    >
      <date-picker
        v-model="toDate"
        data-cy="Filters-toDate"
        :filled="false"
        label="To"
      />
    </v-col>
  </v-row>
  <v-row>
    <v-col
      cols="12"
      sm="12"
      offset="4"
      md="2"
      lg="2"
      xl="2"
    >
      <h4 class="text-right">
        Compared to:
      </h4>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      xl="2"
    >
      <date-picker
        v-model="prevFromDate"
        data-cy="Filters-prevFromDate"
        :filled="false"
        label="From"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      xl="2"
    >
      <date-picker
        v-model="prevToDate"
        data-cy="Filters-prevToDate"
        :filled="false"
        label="To"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="2"
      lg="2"
      xl="2"
    >
      <v-btn
        data-cy="Filters-refresh-button"
        class="mt-2"
        title="Refresh"
        color="secondary"
        @click="$emit('refresh', filters)"
      >
        <v-icon>{{ mdiRefresh }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiRefresh } from '@mdi/js';
import DatePicker from '../../../components/DatePicker.vue';

export default {
  name: 'ReportFilters',
  components: {
    DatePicker,
  },
  data() {
    return {
      mdiRefresh,
    };
  },
  computed: {
    ...mapGetters('telemetry', [
      'usernames',
      'versions',
    ]),
    selectedUsernames: {
      get() {
        return this.$store.state.telemetry.selectedUsernames;
      },
      set(value) {
        this.$store.commit('telemetry/SET_selectedUsernames', value);
      },
    },
    selectedVersions: {
      get() {
        return this.$store.state.telemetry.selectedVersions;
      },
      set(value) {
        this.$store.commit('telemetry/SET_selectedVersions', value);
      },
    },
    fromDate: {
      get() {
        return this.$store.state.telemetry.fromDate;
      },
      set(value) {
        this.$store.commit('telemetry/SET_fromDate', value);
      },
    },
    toDate: {
      get() {
        return this.$store.state.telemetry.toDate;
      },
      set(value) {
        this.$store.commit('telemetry/SET_toDate', value);
      },
    },
    prevFromDate: {
      get() {
        return this.$store.state.telemetry.prevFromDate;
      },
      set(value) {
        this.$store.commit('telemetry/SET_prevFromDate', value);
      },
    },
    prevToDate: {
      get() {
        return this.$store.state.telemetry.prevToDate;
      },
      set(value) {
        this.$store.commit('telemetry/SET_prevToDate', value);
      },
    },
    filters() {
      return this.$store.getters['telemetry/filters'];
    },
  },
};
</script>
