<template>
  <v-row data-cy="GraphOverview" class="text-center">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true" v-if="isLoading" color="primary"></v-progress-linear>
      <GChart
        type="PieChart"
        :data="chartData"
        :options="chartOptions"
        :events="chartEvents"
      />
    </v-col>
  </v-row>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'GraphOverview',
  mixins: [loading],
  module: 'Reports',
  components: {
    GChart,
  },
  props: {
    forecastData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      counterInterval: undefined,
      localData: [],
      selectedStaff: [],
      selectedStage: [],
      chartOptions: {
        animation: {
          duration: 1000,
          easing: 'out',
        },
        chart: {
          title: 'Overview',
        },
        vAxis: { format: '#,###', title: 'Amount' },
        hAxis: { minorGridlines: { minSpacing: 100 }, title: 'Date' },
        height: 400,
        colors: ['#4ABA9D', '#F11A16'],
        legend: { position: 'top' },
      },
      chartEvents: {
        select: () => {
          this.$router.push({ name: 'contracts-ListContract' });
        },
      },
    };
  },
  computed: {
    chartData() {
      const { forecastData } = this;
      const data = [['Type', 'Money']];
      data.push(['Money In', forecastData[0].moneyIn]);
      data.push(['Money Out', forecastData[0].moneyOut]);
      return data;
    },
  },
  // created() {
  //   this.counterInterval = setInterval(
  //     () => {
  //       this.refresh();
  //     }, 60000,
  //   );
  // },
  // destroyed() {
  //   clearInterval(this.counterInterval);
  // },
};
</script>
