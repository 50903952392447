<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        New Business Log
      </v-card-title>
      <v-card-text>
        <v-row v-if="!isLoading">
          <!-- <v-col>
            <v-btn :loading="isLoading" @click="download(urlQuotes)" color="primary">
              <v-icon>{{ mdiFileExcel }}</v-icon>
              Download (Quotes only)
            </v-btn>
          </v-col> -->
          <v-col>
            <v-btn :loading="isLoading" @click="download(url)" color="primary">
              <v-icon>{{ mdiFileExcel }}</v-icon>
              Download New Business Log
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else class="text-center">
          <v-col>
            <v-progress-circular indeterminate color="primary" />
            <h3>Generating report...</h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mdiFileExcel } from '@mdi/js';
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ReportNewBusinessLog',
  mixins: [loading],
  data() {
    return {
      mdiFileExcel,
    };
  },
  computed: {
    ...mapState('organizations', ['organizations', 'organization']),
    url() {
      const { organization } = this;
      return `${process.env.VUE_APP_APIURL}/print/reports/newbusinesslog/${organization.id}`;
    },
    urlQuotes() {
      const { organization } = this;
      return `${process.env.VUE_APP_APIURL}/print/reports/newbusinesslogQuotes/${organization.id}`;
    },
    filename() {
      const { organization } = this;
      return `New Business Log - ${organization.name}.xlsx`;
    },
  },
  methods: {
    async download(uri) {
      this.isLoading = true;
      try {
        // Perform the file download here
        // For example, you can use the following code to download a file from a URL:
        const response = await fetch(uri);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = this.filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error downloading report');
      } finally {
        this.isLoading = false;
      }

      // Set the loading state to false once the file has finished downloading
      this.isLoading = false;
    },
  },
};
</script>
