<template>
  <v-btn small color="secondary" title="Upload data to Excel" :loading="isLoading" @click="openFileDialog">
    <v-icon>{{ mdiUpload }}</v-icon>
  <input ref="fileInput" type="file" style="display:none" @input="upload">
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { mdiUpload, mdiDownload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'UploadData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiUpload,
      mdiDownload,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    ...mapState('auth', ['token']),
  },
  methods: {
    async openFileDialog() {
      this.$refs.fileInput.click();
    },
    async upload(event) {
      const file = event.target.files[0];
      const uploadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/upload`;
      const formData = new FormData();
      formData.append('file', file);
      try {
        this.isLoading = true;
        axios.headers['Content-Type'] = 'multipart/form-data';
        await axios.post(uploadUrl, formData);
        this.$root.$emit('toast:notify', 'Data uploaded successfully');
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Error uploading data');
      } finally {
        this.isLoading = false;
        this.$refs.fileInput.value = '';
      }
    },
    async download() {
      const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/download`;
      try {
        const response = await this.client.get(downloadUrl, { responseType: 'blob' });
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type, encoding: 'UTF-8' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.name}.xlsx`;
        link.click();
      } catch (error) {
        this.$log.error(error);
      }
    },
  },
};
</script>
