import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('xeroInvoice', 'xeroInvoices');

const customActions = {
  async LOAD_MIN_xeroInvoices({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('xeroInvoices')
      .query({
        $filter: 'Status ne \'DELETED\' AND Date gt 2022-01-01',
        $select: 'InvoiceID,InvoiceNumber,Status,Reference,Date',
      });
    commit('SET_xeroInvoices', data);
    return data;
  },
};

export default { ...actions, ...customActions };
