<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormServiceItem-serviceName"
              :label="$$t('serviceName')"
              v-model="localServiceItem.serviceName"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-select
              data-cy="FormServiceItem-priceListId"
              filled
              :label="$$t('priceListId')"
              :items="priceLists"
              item-value="id"
              :item-text="i => getPriceListName(i)"
              v-model="localServiceItem.priceListId"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormServiceItem-isp"
              :label="$$t('isp')"
              v-model="localServiceItem.isp"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormServiceItem-lastMile"
              :label="$$t('lastMile')"
              v-model="localServiceItem.lastMile"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormServiceItem-serviceAddress"
              :label="$$t('serviceAddress')"
              v-model="localServiceItem.serviceAddress"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              filled
              data-cy="FormServiceItem-officeHours"
              :label="$$t('officeHours')"
              v-model="localServiceItem.officeHours"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormServiceItem-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormServiceItem-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormServiceItem',
  module: 'ServiceItem',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    ServiceItem: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localServiceItem
      */
      localServiceItem: {},
    };
  },
  computed: {
    ...mapState('priceLists', ['priceLists']),
  },
  watch: {
    ServiceItem() {
      this.localServiceItem = this.ServiceItem;
    },
  },
  created() {
    this.localServiceItem = this.ServiceItem;
  },
  methods: {
    /**
     * Executes getPriceListName
i     */
    getPriceListName(i) {
      return `${i.supplier.name} - ${i.name} - ${i.costToCustomer} (${i.grossProfit}% GP)`;
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localServiceItem);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormServiceItem example

'''js
<FormServiceItem :ServiceItem="ServiceItem" />
'''
</docs>
