<template>
  <v-text-field v-bind="$props" v-on="$listeners" v-model="computedValue"
    :data-cy="dataCy">
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-text-field>
</template>

<script>
import { VTextField } from 'vuetify/lib';

export default {
  name: 'CurrencyTextField',
  extends: VTextField,
  props: {
    dataCy: {
      type: String,
      default: 'CurrencyTextField',
    },
  },
  data() {
    return {
      originalValue: 0,
    };
  },
  watch: {
    value() {
      this.computedValue = this.value;
    },
  },
  mounted() {
    this.computedValue = this.value;
  },
  computed: {
    computedValue: {
      get() {
        return this.originalValue;
      },
      set(newValue) {
        if (Number.isNaN(newValue)) {
          this.originalValue = 0;
        } else {
          this.originalValue = newValue;
        }
      },
    },
  },
};
</script>
