<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-select
          label="Sales Person"
          :items="users"
          item-text="displayName"
          item-value="createdById"
          data-cy="FormUserTarget-userId"
          v-model="localUserTarget.userId"
        ></v-select>
        <v-row>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetJanuary"
              label="January"
              v-model="localUserTarget.targetJanuary"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetFebruary"
              label="February"
              v-model="localUserTarget.targetFebruary"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetMarch"
              label="March"
              v-model="localUserTarget.targetMarch"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetApril"
              label="April"
              v-model="localUserTarget.targetApril"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetMay"
              label="May"
              v-model="localUserTarget.targetMay"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetJune"
              label="June"
              v-model="localUserTarget.targetJune"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetJuly"
              label="July"
              v-model="localUserTarget.targetJuly"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetAugust"
              label="August"
              v-model="localUserTarget.targetAugust"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetSeptember"
              label="September"
              v-model="localUserTarget.targetSeptember"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetOctober"
              label="October"
              v-model="localUserTarget.targetOctober"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetNovember"
              label="November"
              v-model="localUserTarget.targetNovember"
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              filled
              type="Number"
              data-cy="FormUserTarget-targetDecember"
              label="December"
              v-model="localUserTarget.targetDecember"
            />
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormUserTarget-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormUserTarget-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormUserTarget',
  module: 'Quote',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    UserTarget: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localUserTarget
      */
      localUserTarget: {},
    };
  },
  computed: {
    ...mapState('users', ['users']),
  },
  watch: {
    UserTarget() {
      this.localUserTarget = this.UserTarget;
    },
  },
  created() {
    this.localUserTarget = this.UserTarget;
  },
  async mounted() {
    await this.LOAD_users();
  },
  methods: {
    ...mapActions('users', ['LOAD_users']),
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localUserTarget);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormUserTarget example

'''js
<FormUserTarget :UserTarget="UserTarget" />
'''
</docs>
