/* eslint-disable max-len */
import {
  mdiAccountSupervisor,
  mdiEmoticonHappyOutline,
  mdiAccountHardHat,
  mdiFile,
  mdiFileSign,
  mdiCashMultiple,
  mdiStoreCheckOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isInRole']),
    menuItems() {
      const result = [
        { name: 'menu.manageAgreements', pathName: 'solutions-ManageAgreements', icon: mdiFileSign },
      ];
      if (this.isInRole('Administrators')) {
        result.push(
          { name: 'menu.manage', pathName: 'admin-Manage', icon: mdiAccountHardHat },
        );
        result.push(
          { name: 'menu.manageSolutions', pathName: 'solutions-ManageSolutions', icon: mdiStoreCheckOutline },
        );
        result.push(
          { name: 'menu.migrateContracts', pathName: 'contracts-MigrateContracts', icon: mdiFile },
        );
        // result.push(
        //   { name: 'menu.manageContracts', pathName: 'contracts-ManageContracts', icon: mdiFileSign },
        // );
        result.push(
          { name: 'menu.orgTaxRates', pathName: 'quotes-ListOrgTaxRate', icon: mdiCashMultiple },
        );
        result.push({
          name: 'menu.users', pathName: 'admin-Users', icon: mdiAccountSupervisor, topMenu: false,
        });
        result.push({
          name: 'menu.feedbackItems', pathName: 'admin-FeedbackItems', icon: mdiEmoticonHappyOutline, topMenu: false,
        });
      }
      return result;
    },
  },
};
