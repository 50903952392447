<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :text="text"
        :block="block"
        color="secondary"
        v-bind="attrs"
        v-on="on"
        dark
        :loading="isPrinting"
      >
        Print
      </v-btn>
    </template>
    <v-card v-if="quote">
      <v-card-title>Print {{ quote.quoteNumber }}</v-card-title>
      <v-card-text>
        <!-- <v-checkbox label="Include VAT" v-model="includeVat"></v-checkbox> -->
        <!-- <v-checkbox label="Include first and last page" v-model="includeFirstPage">
          </v-checkbox> -->
        <v-select
          :items="items"
          v-model="pdf"
          label="Format"
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="download(url)">
          Print
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ModalPrint',
  props: {
    quote: {
      type: Object,
      default: () => {},
    },
    text: {
      type: Boolean,
      default: true,
    },
    block: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isPrinting: false,
      includeVat: false,
      dialog: false,
      pdf: false,
      includeFirstPage: true,
      items: [{
        value: true,
        text: 'PDF',
      },
      {
        value: false,
        text: 'Word',
      },
      ],
    };
  },
  computed: {
    url() {
      const {
        includeVat, quote, pdf, includeFirstPage,
      } = this;
      // eslint-disable-next-line max-len
      return `${process.env.VUE_APP_APIURL}/print/quote?quoteId=${quote.id}&includeVat=${includeVat}&pdf=${pdf}&includeFirstPage=${includeFirstPage}`;
    },
    filename() {
      const { quote, pdf } = this;
      return `Quote-${quote.quoteNumber}-${quote.reference}.${pdf ? 'pdf' : 'docx'}`;
    },
  },
  methods: {
    async download(uri) {
      this.dialog = false;
      this.isPrinting = true;

      // Perform the file download here
      // For example, you can use the following code to download a file from a URL:
      const response = await fetch(uri);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = this.filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();

      // Set the loading state to false once the file has finished downloading
      this.isPrinting = false;
    },
  },
};
</script>
