var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Month: "+_vm._s(_vm.month)+" ")]),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"dense":"","items-per-page":-1},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('ExportToExcel',{staticClass:"mt-5 mr-5",attrs:{"small":"","name":`Monthly GP - ${_vm.month}`,"data":_vm.excelData}})],1)],1)]},proxy:true},{key:"item.section.contract.contractNumber",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'contracts-EditContract', query: { id: item.section.contract.id }
        }}},[_vm._v(" "+_vm._s(item.section.contract.contractNumber)+" ")])]}},{key:"item.section.contract.contractType",fn:function({ item }){return [_vm._v(" "+_vm._s(item.section.contract.contractType === 'ACCREC' ? 'Money In' : 'Money Out')+" ")]}},{key:"item.section.contract.commences",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.section.contract.commences))+" ")]}},{key:"item.section.contract.expires",fn:function({ item }){return [(item.section.contract.expires
          && item.section.contract.expires !== '0001-01-01T00:00:00+00:00')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate(item.section.contract.expires))+" ")]):_c('span',[_vm._v(" No Date ")])]}},{key:"item.monthlyTotal",fn:function({ item }){return [(item.monthlyTotal < 0)?_c('span',{staticClass:"red--text"},[_vm._v(" ("+_vm._s(_vm.formatCurrency(item.monthlyTotalConverted * -1, _vm.selectedCurrency))+") ")]):_c('span',[_vm._v(" "+_vm._s(_vm.formatCurrency(item.monthlyTotalConverted, _vm.selectedCurrency))+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","to":{
          name: 'contracts-EditContract',
          query: {id: item.section.contract.id}
        }}},[_vm._v(" Edit ")])]}}])},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"font-weight-bold"},[_c('td',{attrs:{"colspan":"4"}}),_c('td',[_vm._v(_vm._s(_vm.formatCurrency(_vm.sumField('monthlyTotalConverted'), _vm.selectedCurrency)))])])])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }