<template>
  <v-container fluid>
   <v-row>
     <v-col cols="12">
       <v-card>
         <ProgressLinearTimeout v-if="isLoading" />
         <v-row v-if="editAgreement">
          <v-col cols="12" xl="3" lg="3" md="12">
             <v-card-title primary-title>
               <div class="text-h5 mr-2">
                 Agreement {{ editAgreement.agreementNumber }}
               </div>
             </v-card-title>
           </v-col>
           <v-col cols="12" xl="3" lg="3" md="12">
             <v-select
               filled
               dense
               :items="users"
               data-cy="EditAgreement-ownerId"
               :label="$$t('owner')"
               item-text="displayName"
               item-value="createdById"
               v-model="editAgreement.ownerId"
               @change="onOwnerChange"
             />
           </v-col>
           <v-col cols="12" xl="3" lg="3" md="12">
            <v-autocomplete
              auto-select-first
              dense
              data-cy="EditAgreement-contactId"
              filled
              :label="$$t('contactId')"
              :items="customerContacts"
              item-value="id"
              item-text="name"
              @change="onContactChange"
              v-model="editAgreement.contactId"
            />
           </v-col>
            <v-col cols="12" xl="3" lg="3" md="12">
              <SelectPerson
                class="mr-5"
                data-cy="FormQuote-personId"
                :contact-id="editAgreement.contactId"
                :label="$$t('person')"
                :item-text="(c) => `${c.firstName} ${c.lastName} (${c.emailAddress})`"
                item-value="id"
                v-model="editAgreement.personId"
                @change="onPersonChange"
              />
            </v-col>
        </v-row>
        <v-card-text v-if="editAgreement">
          <v-row>
            <v-col offset="4" cols="2">
              <h4 class="mt-1">Last Updated</h4>
            </v-col>
            <v-col cols="2">
              <v-select
                dense
                :items="[
                  'Renewal Date',
                  'Delivered Date',
                  'Notice Date',
                  ]"
                v-model="tableHeadingSelector"
              ></v-select>
            </v-col>
            <v-col class="text-right" cols="2" style="padding-right: 50px">
              <h4 class="mt-1">Profit</h4>
            </v-col>
          </v-row>
          <AgreementHeader :Agreement="editAgreement" :Open="true" @refresh="refreshAgreement" />
          <Agreement ref="Agreement" :AgreementId="editAgreement.id" @input="debouncedSave"
            @close="close"
          />
         </v-card-text>
       </v-card>
     </v-col>
   </v-row>
 </v-container>
 </template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { debounce } from 'lodash';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import contacts from '~src/mixins/contacts';
import clients from '~src/mixins/clients';
import suppliers from '~src/mixins/suppliers';
import Agreement from '../components/Agreement.vue';
import AgreementHeader from '../components/AgreementHeader.vue';
import SelectPerson from '../components/SelectPerson.vue';

export default {
  components: {
    AgreementHeader,
    Agreement,
    SelectPerson,
  },
  name: 'EditAgreement',
  module: 'Agreements',
  mixins: [clients, loading, suppliers, contacts],
  meta: {
    breadcrumb:
       [{
         text: 'menu.home',
         to: { name: 'app-Home' },
       },
       {
         text: 'menu.agreements',
         to: { name: 'solutions-ListAgreement' },
       },
       {
         text: 'menu.edit',
       },
       ],
  },
  data() {
    return {
      /**
       * Get/Set editing Agreement. Default to {}.
       */
      editAgreement: undefined,
      /**
        * Query Id for Agreement
        */
      agreementId: this.$route?.query.id,
      tableHeadingSelector: 'Renewal Date',
    };
  },
  computed: {
    /** Vuex states
      */
    ...mapState('companySettings', ['companySettings']),
    ...mapState('contracts', ['contracts']),
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('agreements', ['agreements', 'agreement', 'summary']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
    ...mapState('users', ['users']),
    ...mapState('people', ['people']),
    availablePeople() {
      return this.people.filter((p) => p.contactId === this.contactId) ?? [];
    },
    availableActions() {
      return [
        // 'Print',
        // 'Cancel',
        'Archive',
      ];
    },
    /** Get the mode for this view, create or update
      */
    mode() {
      if (this.agreementId) {
        return 'edit';
      }
      return 'add';
    },
    gp() {
      return Math.round((this.summary.totalProfit ?? 0 / this.summary.total ?? 0) * 100);
    },
  },
  watch: {
    $route() {
      this.agreementId = this.$route?.query.id;
      this.refresh();
    },
    selectedCurrency() {
      this.refresh();
    },
  },
  async created() {
    await this.LOAD_companySettings();
    this.$log.info('Clear agreement data');
    this.SET_agreements([]);
    this.SET_agreementHeaders([]);
    this.SET_incomeLines([]);
    this.SET_summary(undefined);
    this.SET_agreement(undefined);
  },
  async mounted() {
    await this.refresh();
    this.$root.$on('data:incomeLine-updated', () => {
      debounce(() => {
        this.LOAD_summary(this.editAgreement.id);
      }, 500)();
    });
  },
  beforeDestroy() {
    this.$root.$off('data:incomeLine-updated', this.refresh);
  },
  methods: {
    /** Vuex methods
      */
    ...mapActions('agreements', ['CREATE_agreement']),
    ...mapActions('incomeLines', ['CREATE_incomeLine']),
    ...mapActions('comments', ['LOAD_comments']),
    ...mapActions('clients', ['LOAD_clients']),
    ...mapActions('orgTaxRates', ['LOAD_orgTaxRates']),
    ...mapActions('quoteTemplates', ['LOAD_quoteTemplates']),
    ...mapActions('revenueLines', ['LOAD_revenueLines']),
    ...mapActions('people', ['LOAD_people']),
    ...mapActions('agreements', [
      'LOAD_agreement',
      'LOAD_summary',
      'CREATE_agreement',
      'UPDATE_agreement',
    ]),
    ...mapActions('suppliers', ['LOAD_suppliers']),
    ...mapActions('users', ['LOAD_users']),
    ...mapActions('xeroContacts', ['LOAD_xeroContacts']),
    ...mapActions('companySettings', ['LOAD_companySettings']),
    ...mapMutations('agreements', ['SET_agreements', 'SET_agreementHeaders']),
    ...mapMutations('incomeLines', ['SET_incomeLines']),
    ...mapMutations('agreements', ['SET_agreement', 'SET_summary']),
    /** Method used to refresh the view
      */
    async refresh() {
      this.isLoading = true;
      await this.LOAD_companySettings();
      if (this.mode === 'edit') {
        this.editAgreement = await this.LOAD_agreement(this.$route?.query.id);
      } else {
        const newAgreement = {
          currencyCode: this.selectedCurrency,
          reference: 'New Agreement',
          status: 'Draft',
        };
        newAgreement.notes = this.companySettings.find(
          (setting) => setting.name === 'defaultAgreementNotes',
        )?.value;
        // create agreement
        const agreement = await this.CREATE_agreement(newAgreement);
        await this.CREATE_incomeLine({
          agreementId: agreement.id,
        });
        this.$router.push({ name: 'solutions-EditAgreement', query: { id: agreement.id } });
      }
      await Promise.all([
        (async () => {
          if (this.editAgreement?.id) {
            await this.LOAD_summary(this.editAgreement.id);
            await this.LOAD_comments(this.editAgreement.id);
          } else {
            this.$log.info('Skipping loading summary and comments as there is no agreement id');
          }
        })(),
        (async () => {
          await this.LOAD_quoteTemplates();
        })(),
        (async () => {
          await this.LOAD_orgTaxRates();
        })(),
        (async () => {
          await this.LOAD_suppliers({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_revenueLines();
        })(),
        (async () => {
          await this.loadAllClients();
        })(),
        (async () => {
          await this.loadAllSuppliers();
        })(),
        (async () => {
          await this.LOAD_clients({ includeArchived: false });
        })(),
        (async () => {
          await this.LOAD_people();
        })(),
        (async () => {
          await this.LOAD_users();
        })(),
        (async () => {
          await this.LOAD_xeroContacts();
        })(),
      ]);
      this.isLoading = false;
    },
    onAction(action) {
      switch (action) {
        case 'Cancel':
          this.copy();
          break;
        case 'Archive':
          this.archive();
          break;
        case 'Print':
          this.print();
          break;
        default:
          break;
      }
    },
    async print() {
      this.$refs.modalPrint.open();
    },
    async archive() {
      const res = this.$confirm('Are you sure you want to archive this agreement?');
      if (res) {
        this.editAgreement.status = 'Archived';
        this.editAgreement.isActive = false;
        await this.save(this.editAgreement);
        this.$router.push({ name: 'solutions-ListAgreement' });
      }
    },
    /** Method used to close the view
      */
    async close(agreement) {
      await this.save(agreement);
      this.$router.push({ name: 'solutions-ListAgreement' });
    },
    /** Method used to save the item
      */
    async save(agreement) {
      this.$log.info(`Saving agreement: ${agreement.agreementNumber}`);
      this.isSaving = true;
      if (agreement?.id) {
        await this.UPDATE_agreement(agreement);
      }
      this.isSaving = false;
    },
    // eslint-disable-next-line func-names
    debouncedSave: debounce(function (agreement) {
      this.$log.info(`Debounced saving agreement: ${agreement.agreementNumber}`);
      if (this.isLoading) {
        this.$log.info('Skipping save as we are loading');
        return;
      }
      this.save(agreement);
    }, 1000),
    async onContactChange(contactId) {
      const contact = this.allContacts.find((c) => c.id === contactId);
      this.editAgreement.contact = contact.name;
      this.editAgreement.contactEmail = contact.emailAddress;
      await this.UPDATE_agreement({
        id: this.editAgreement.id,
        contact: this.editAgreement.contact,
        contactEmail: this.editAgreement.contactEmail,
        contactId: this.editAgreement.contactId,
      });
    },
    async onOwnerChange() {
      const user = this.users.find((u) => u.createdById === this.editAgreement.ownerId);
      this.editAgreement.owner = user?.displayName ?? user?.createdBy;
      this.editAgreement.ownerEmail = user?.createdBy;
      await this.UPDATE_agreement({
        id: this.editAgreement.id,
        owner: this.editAgreement.owner,
        ownerEmail: this.editAgreement.ownerEmail,
        ownerId: this.editAgreement.ownerId,
      });
    },
    async onPersonChange(personId) {
      const person = this.people.find((p) => p.id === personId);
      if (person && person?.firstName) {
        this.editAgreement.person = `${person?.firstName ?? ''} ${person?.lastName ?? ''}`.trim();
      } else {
        this.editAgreement.person = person?.emailAddress ?? '';
      }
      await this.UPDATE_agreement({
        id: this.editAgreement.id,
        person: this.editAgreement.person,
        personId: this.editAgreement.personId,
      });
    },
    refreshAgreement() {
      this.$refs.Agreement.refresh();
    },
  },
};
</script>

 <docs>
 # EditAgreement example

 '''js
 <EditAgreement :Agreement="Agreement" />
 '''
 </docs>
