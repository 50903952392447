<template>
  <div>
      <v-progress-circular
        :rotate="360"
        :size="50"
        :width="5"
        :value="complete"
        :color="complete > 99 ? 'success' : 'error'"
      >
        <span class="text-subtitle-2">
          {{ complete }}%
        </span>
      </v-progress-circular>
      <div class="d-flex align-center justify-center">
        <v-icon :color="complete > 99 ? 'success' : 'error'">{{ mdiBullseyeArrow }}</v-icon>
        {{ target ? `${currencySymbol} ${formatNumberRounded(target)}` : '' }}
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiBullseyeArrow } from '@mdi/js';

export default {
  name: 'Target',
  props: {
    target: {
      type: Number,
      default: 0,
    },
    actual: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      mdiBullseyeArrow,
    };
  },
  computed: {
    ...mapGetters('currencies', ['currencySymbol']),
    complete() {
      if (this.target === 0) {
        return 0;
      }
      return Math.round((this.actual / this.target) * 100);
    },
  },
};
</script>
