<template>
  <v-row dense>
    <v-col cols="12" lg="3">
      <v-textarea
        dense
        data-cy="FormLine-description"
        :disabled="disabled"
        auto-grow
        outlined
        :title="localIncomeLine.description"
        :label="$$t('description')"
        v-model="localIncomeLine.description"
        rows="1"
      />
    </v-col>
    <v-col cols="12" lg="9">
    <v-row dense>
      <v-col cols="12" xl="3" lg="3" md="6">
        <v-select
          clearable
          dense
          data-cy="FormLine-lineType"
          :disabled="disabled"
          :label="$$t('serviceType')"
          v-model="localIncomeLine.lineType"
          :content-class="`data-cy-lineType-${index}`"
          :items="lineTypes"
          :rules="requiredFieldRules"
        />
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="6">
        <v-autocomplete
          auto-select-first
          clearable
          dense
          data-cy="FormLine-revenueLineId"
          :disabled="disabled"
          :label="$$t('revenueLine')"
          v-model="localIncomeLine.revenueLineId"
          :items="revenueLines"
          item-text="name"
          item-value="id"
          :rules="requiredFieldRules"
        />
      </v-col>
      <!-- <v-col v-if="localIncomeLine.implementationDate" cols="12" xl="2" lg="2" md="6">
        <DatePicker
          :dense="true"
          :filled="false"
          data-cy="FormLine-implementationDate"
          :label="$$t('deliveredDate')"
          v-model="localIncomeLine.implementationDate"
        />
      </v-col> -->
      <v-col cols="12" xl="1" lg="1" md="6">
        <NullableTextField
          dense
          data-cy="FormLine-quantity"
          :disabled="disabled"
          :label="$$t('quantity')"
          v-model.number="localIncomeLine.quantity"
          type="Number"
        />
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="6">
        <NullableNumberField
          dense
          data-cy="FormLine-unitAmount"
          :disabled="disabled"
          :label="$$t('unitAmount')"
          v-model.number="localIncomeLine.unitAmount"
          :rules="requiredFieldRules"
        />
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="6">
        <v-text-field
          dense
          data-cy="FormLine-discountRate"
          :disabled="disabled"
          label="% Discount"
          v-model="localIncomeLine.discountRate"
        />
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6">
        <NullableNumberField
          dense
          readonly
          :disabled="disabled"
          data-cy="lineAmount"
          :label="$$t('lineAmount')"
          v-model="localIncomeLine.lineAmount"
        />
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="6">
        <NullableNumberField
          dense
          readonly
          :disabled="disabled"
          data-cy="profit"
          label="Profit"
          v-model="localIncomeLine.profit"
        />
      </v-col>
      <v-col
        v-if="localIncomeLine.lineType !== 'Non-Recurring Cost'"
        cols="12"
        lg="2"
      >
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="text-field-term"
          :disabled="disabled"
          label="Term"
          hint="Supplier Term in months"
          v-model="localIncomeLine.term"
          type="number"
        />
      </v-col>
      <v-col
        v-if="localIncomeLine.lineType !== 'Non-Recurring Cost'"
        cols="12"
        lg="2"
      >
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="text-field-noticeTerm"
          :disabled="disabled"
          label="Notice Term"
          hint="Supplier Notice Term in months"
          v-model="localIncomeLine.noticeTerm"
          type="number"
        />
      </v-col>
      <v-col
        v-if="localIncomeLine.lineType !== 'Non-Recurring Cost'"
        cols="12"
        lg="2"
      >
        <NullableTextField
          dense
          class="text-field-term"
          data-cy="text-field-renewalTerm"
          :disabled="disabled"
          label="Renewal Term"
          hint="Supplier Renewal Term in months"
          v-model="localIncomeLine.renewalTerm"
          type="number"
        />
      </v-col>
      <v-col
        v-if="localIncomeLine.lineType !== 'Non-Recurring Cost'"
        cols="12"
        lg="3"
      >
      <v-hover v-slot="{ hover }">
        <DatePicker
          v-model="localIncomeLine.cancellationDate"
          :filled="false"
          :dense="true"
          data-cy="FormLine-date"
          label="Cancellation Date"
          type="date"
          class="mt-2"
          :disabled="disableEditCancellationDate(localIncomeLine, hover)"
        />
      </v-hover>
      </v-col>
    </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { mdiCalculator } from '@mdi/js';
import dayjs from 'dayjs';
import requiredField from '~src/mixins/required-field';

export default {
  name: 'IncomeLine',
  module: 'AgreementItem',
  mixins: [requiredField],
  props: {
    Agreement: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mdiCalculator,
      localIncomeLine: this.value || {},
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'isInRole']),
    ...mapState('incomeLines', ['lineTypes']),
    ...mapState('revenueLines', ['revenueLines']),
    isAdmin() {
      return this.isInRole('Administrators');
    },
  },
  watch: {
    value() {
      this.localIncomeLine = this.value;
    },
  },
  created() {
    this.localIncomeLine = this.value;
  },
  methods: {
    disableEditCancellationDate(incomeLine, hover) {
      if (!incomeLine.cancellationDate) {
        return false;
      }
      if (this.isAdmin && hover) {
        return false;
      }
      const result = this.isDateWithThreeDays(incomeLine.cancellationDate);
      return result;
    },
    isDateWithThreeDays(date) {
      if (!date) {
        return false;
      }

      const today = dayjs();
      const threeDays = today.add(3, 'day');
      return dayjs(date).isBefore(threeDays);
    },
  },
};
</script>
