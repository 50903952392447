<template>
  <v-card>
    <v-card-title primary-title>
      {{ title }}
      <v-btn icon @click="refresh">
        <v-icon>{{ mdiRefresh }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-dialog
        v-model="dialog"
        scrollable fullscreen
        :overlay="false"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title primary-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="dialog = false">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <pre v-html="itemData"></pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :search="search"
        sort-by="utcDateTime"
        :sort-desc="true"
        item-key="traceId"
      >
        <template v-slot:item.utcDateTime="{ item }">
          {{ formatDateTime(item.utcDateTime) }}
        </template>
        <template v-slot:item.data="{ item }">
          <v-btn
            v-if="item.data !== null && item.data !== undefined"
            small
            color="primary" @click="view(item)">View</v-btn>
        </template>
        <template v-slot:top>
          <v-toolbar
            flat
          >
            <v-row>
              <v-col cols="3" offset="6">
                <v-text-field
                  v-model="search"
                  :append-icon="mdiMagnify"
                  label="Search"
                  clearable
                ></v-text-field>
              </v-col><v-col cols="3">
              <v-select
                data-cy="ListFeedbackItems-modules"
                label="Source"
                :items="sources"
                v-model="selectedSource"
                multiple
                clearable
              />
            </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClose, mdiMagnify, mdiRefresh } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'EventLog',
  mixins: [loading],
  props: {
    title: {
      type: String,
      default: 'Event Log',
    },
  },
  data() {
    return {
      mdiClose,
      mdiMagnify,
      mdiRefresh,
      eventLogs: [],
      sources: [],
      dialog: false,
      itemData: undefined,
      selectedSource: undefined,
      headers: [
        { text: 'Date and Time', value: 'utcDateTime' },
        { text: 'Source', value: 'source' },
        { text: 'Type', value: 'eventType' },
        { text: 'Message', value: 'message' },
        { text: 'Data', value: 'data' },
      ],
      search: '',
    };
  },
  computed: {
    items() {
      if (this.selectedSource) {
        return this.eventLogs.filter((eventLog) => this.selectedSource.includes(eventLog.source));
      }
      return this.eventLogs;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          const { data } = await this.$http.get('api/admin/eventlogs');
          this.eventLogs = data;
        })(),
        (async () => {
          const { data } = await this.$http.get('api/admin/eventlogs/sources');
          this.sources = data;
        })(),
      ]);
      this.isLoading = false;
    },
    view(item) {
      this.itemData = item.data;
      this.dialog = true;
    },
  },
};
</script>
