import { mapState } from 'vuex';
import { sortBy, uniqBy } from 'lodash';

export default {
  computed: {
    ...mapState('accounts', ['accounts']),
    ...mapState('xeroAccounts', ['xeroAccounts']),
    accountTypes() {
      return uniqBy(this.allAccounts, (a) => a.type);
    },
    allAccounts() {
      const { accounts, xeroAccounts } = this;
      const localContacts = accounts?.map((c) => ({
        id: c.accountID, type: c.type, name: c.name, source: 'local',
      })) ?? [];
      const accAccounts = xeroAccounts?.map((c) => ({
        id: c.accountID, type: c.type, code: c.code, name: c.name, source: 'xero',
      })) ?? [];
      return sortBy([
        ...localContacts,
        ...accAccounts,
      ], (c) => c.name);
    },
    bankAccounts() {
      const { allAccounts } = this;
      return allAccounts.filter((c) => c.type === 'BANK');
    },
    incomeAccounts() {
      const { allAccounts } = this;
      return sortBy(allAccounts.filter((c) => c.type === 'REVENUE'
        || c.type === 'OTHERINCOME'
        || c.type === 'CURRLIAB'), (c) => c.name);
    },
    expenseAccounts() {
      const { allAccounts } = this;
      return sortBy(allAccounts.filter((c) => c.type === 'DIRECTCOSTS'
        || c.type === 'CURRENT'
        || c.type === 'EXPENSE'), (c) => c.name);
    },
  },
};
