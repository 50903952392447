import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contractSection', 'contractSections');

const customActions = {
  async LOAD_contractSectionsByContract({ commit, rootGetters }, contractId) {
    const data = await odata.o(rootGetters)
      .get('contractSections')
      .query({
        $filter: `IsDeleted eq false AND ContractId eq ${contractId}`,
        $orderby: 'Order',
      });
    commit('SET_contractSections', data);
    return data;
  },
};

export default { ...actions, ...customActions };
