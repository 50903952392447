<template>
  <v-menu offset-y v-if="currencies.length > 0">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="secondary"
        :loading="isLoading"
      >
        {{ title }}
        <v-icon>{{ mdiMenuDown }}</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="currency in currencies"
        :key="`menu-currency-${currency}`"
        @click="currencyChanged(currency)"
      >
        <v-list-item-title>{{ currency }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { mdiMenuDown } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'CurrencyMenu',
  mixins: [loading],
  data() {
    return {
      /** icons */
      mdiMenuDown,
    };
  },
  computed: {
    ...mapState('currencies', ['currencies', 'selectedCurrency']),
    ...mapState('xeroOrganisations', ['xeroOrganisations']),
    title() {
      return this.localSelectedCurrency ?? this.$t('App.Currencies.select');
    },
    /**
     * Computed property for the selectedCurrency vuex property
     */
    localSelectedCurrency: {
      get() {
        return this.selectedCurrency;
      },
      set(newValue) {
        this.SET_selectedCurrency(newValue);
      },
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapMutations('currencies', ['SET_selectedCurrency']),
    ...mapActions('xeroOrganisations', ['LOAD_xeroOrganisations']),
    /**
     * Executes when the user selects a currency
     */
    currencyChanged(currency) {
      this.localSelectedCurrency = currency;
      this.$root.$emit('currencyChanged', currency);
    },
    /**
     * Executes refresh
     */
    async refresh() {
      await this.LOAD_xeroOrganisations();
      await this.$nextTick();
      if (this.xeroOrganisations?.length > 0) {
        this.localSelectedCurrency = this.xeroOrganisations[0].baseCurrency;
      }
    },
  },
};
</script>
