<template>
  <Card
    :title="`Contracts (${contracts.length})`"
  >
    <v-list three-line>
      <template v-for="(item) in contracts">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}: {{ item.contractType }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <div style="width: 100px" v-if="item.contractType === 'ACCREC'">
                Money In
              </div>
              <div v-else>
                Money Out
              </div>
              <div class="float-right">
                {{ formatCurrency(item.total, item.currency) }}
              </div>
              <!-- <span class="text-right d-block">
                {{
                  formatNumber(
                    lineSum(item, "Monthly Recurring Cost"),
                  )
                }} MRC
              </span> -->
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <span class="text-h6" v-if="contracts.length === 0">
        No items found
      </span>
    </v-list>
  </Card>
</template>

<script>
import Card from './Card.vue';

export default {
  name: 'CardQuotes',
  components: {
    Card,
  },
  props: {
    contracts: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
