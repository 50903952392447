<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="address.addressLine1"
      label="Address Line 1"
      maxlength="500"
      counter
      required
    ></v-text-field>
    <v-text-field
      v-model="address.addressLine2"
      label="Address Line 2"
      maxlength="500"
      counter
    ></v-text-field>
    <v-text-field
      v-model="address.addressLine3"
      label="Address Line 3"
      maxlength="500"
      counter
    ></v-text-field>
    <v-text-field
      v-model="address.addressLine4"
      label="Address Line 4"
      maxlength="500"
      counter
    ></v-text-field>
    <v-select
      v-model="address.addressType"
      :items="addressTypes"
      label="Address Type"
      required
    ></v-select>
    <v-text-field
      v-model="address.city"
      label="City"
      required
    ></v-text-field>
    <v-text-field
      v-model="address.country"
      label="Country"
      required
    ></v-text-field>
    <v-text-field
      v-model="address.postalCode"
      label="Postal Code"
    ></v-text-field>
    <v-text-field
      v-model="address.region"
      label="Region"
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    address: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  data() {
    return {

      valid: true,
      address: this.value ?? {
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        addressType: '',
        city: '',
        country: '',
        postalCode: '',
        region: '',
      },
      addressTypes: [
        'Registered',
        'Billing',
        'Delivery Address',
        'Other',
      ],
    };
  },
};
</script>
