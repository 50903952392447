<template>
  <v-dialog
    v-model="dialog"
    fullscreen
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="margin-top: -3px">
        <v-btn
          color="secondary"
          small
          v-bind="attrs"
          v-on="on"
          data-cy="ModalContact-button-newContact"
        >
          <!-- <v-icon>{{ mdiPlus }}</v-icon> -->
          Add
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title>New Person</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <FormPerson
          :Person="editPerson"
          :isSaving="isSaving"
          @save="save"
          @cancel="dialog = false"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormPerson from './FormPerson.vue';

export default {
  name: 'ModalPerson',
  props: {
    contactId: {
      type: String,
      default: undefined,
    },
  },
  watch: {
    contactId() {
      this.editPerson.contactId = this.contactId;
    },
  },
  components: {
    FormPerson,
  },
  mixins: [loading],
  data() {
    return {
      mdiPlus,
      dialog: false,
      /**
      * Get/Set editing Person. Default to {}.
      */
      editPerson: {
        contactId: this.contactId,
        personType: 'Client Contact',
      },
    };
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_person(this.personId);
      this.editPerson = this.person;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('people', ['LOAD_people', 'CREATE_person']),
    /** Method used to save the item
     */
    async save(person) {
      this.isSaving = true;
      const response = await this.CREATE_person(person);
      this.$log.info(response);
      this.isSaving = false;
      this.dialog = false;
      this.editPerson = {
        contactId: this.contactId,
        personType: 'Client Contact',
      };
      this.$emit('refresh');
    },
  },
};
</script>
