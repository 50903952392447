<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn small class="ml-2 mr-2" color="secondary" v-bind="attrs" v-on="on">
        <v-icon>{{ mdiFilter }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text>
        <slot />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mdiFilter } from '@mdi/js';

export default {
  name: 'FilterMenu',
  data() {
    return {
      mdiFilter,
      showArchived: false,
    };
  },
};
</script>
