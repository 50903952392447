export default {
  selectedHeaders: [],
  allAgreementHeaders: [
    {
      value: 'solution.solutionNumber', text: 'Solution Number', filterable: false, width: '150px',
    },
    {
      value: 'solution.name', filterable: true, text: 'Solution Name',
    },
    {
      value: 'solution.owner', text: 'Solution Owner', filterable: true,
    },
    {
      value: 'agreementNumber', text: 'Agreement Number', filterable: true,
    },
    {
      value: 'acceptedDate', text: 'Accepted Date', filterable: true,
    },
    {
      value: 'expectedDeliveryDate', text: 'Expected Date', filterable: true,
    },
    {
      value: 'cancellationDate', text: 'Cancellation Date', filterable: true,
    },
    {
      value: 'responsiblePerson', text: 'Responsible Person', filterable: true,
    },
    {
      value: 'deliveredDate', text: 'Delivered Date', filterable: true,
    },
    {
      value: 'endDate', text: 'End Date', filterable: true,
    },
    {
      value: 'originalEndDate', text: 'Original End Date', filterable: true,
    },
    {
      value: 'verified', text: 'Verified', filterable: true,
    },
    {
      value: 'verifiedBy', text: 'Verified By', filterable: true,
    },
    {
      value: 'cancellationDate', text: 'Cancellation Date', filterable: true,
    },
    {
      value: 'responsiblePerson', text: 'Responsible Person', filterable: true,
    },
    {
      value: 'deliveredDate', text: 'Delivered Date', filterable: true,
    },
    {
      value: 'responsiblePerson', text: 'Responsible Person', filterable: true,
    },
    {
      value: 'deliveredDate', text: 'Delivered Date', filterable: true,
    },
    {
      value: 'status', text: 'Agreement Status', filterable: true,
    },
    {
      value: 'agreementTotal', text: 'Total', filterable: true,
    },
    {
      value: 'agreementProfit', text: 'Profit', filterable: true,
    },
    {
      value: 'agreementNrcTotal', text: 'NRC Total', filterable: true,
    },
    {
      value: 'agreementNrcProfit', text: 'NRC Profit', filterable: true,
    },
    {
      value: 'agreementMrcTotal', text: 'MRC Total', filterable: true,
    },
    {
      value: 'agreementMrcProfit', text: 'MRC Profit', filterable: true,
    },
    {
      value: 'isActive', text: 'Agreement Active', filterable: true,
    },
  ],
  allIncomeLineHeaders: [
    {
      value: 'agreement.solution.solutionNumber', text: 'Solution Number', filterable: false, width: '150px',
    },
    {
      value: 'agreement.solution.name', filterable: true, text: 'Solution Name',
    },
    {
      value: 'agreement.solution.owner', text: 'Solution Owner', filterable: true,
    },
    {
      value: 'agreement.agreementNumber', text: 'Agreement Number', filterable: true,
    },
    {
      value: 'agreement.acceptedDate', text: 'Accepted Date', filterable: true,
    },
    {
      value: 'agreement.expectedDeliveryDate', text: 'Expected Date', filterable: true,
    },
    {
      value: 'created', text: 'Created', filterable: true,
    },
    {
      value: 'agreement.cancellationDate', text: 'Cancellation Date', filterable: true,
    },
    {
      value: 'agreement.responsiblePerson', text: 'Responsible Person', filterable: true,
    },
    {
      value: 'agreement.deliveredDate', text: 'Delivered Date', filterable: true,
    },
    {
      value: 'lineAmount', text: 'Line Amount', filterable: true,
    },
    {
      value: 'lineNumber', text: 'Line Number', filterable: true,
    },
    {
      value: 'lineType', text: 'Type', filterable: true,
    },
    {
      value: 'noticeTerm', text: 'Notice Term', filterable: true,
    },
    {
      value: 'profit', text: 'Profit', filterable: true,
    },
    {
      value: 'agreement.status', text: 'Agreement Status', filterable: true,
    },
    {
      value: 'agreement.isActive', text: 'Agreement Active', filterable: true,
    },
    {
      value: 'term', text: 'Term', filterable: true,
    },
    {
      value: 'unitCostAmount', text: 'Unit Cost Amount', filterable: true,
    },
    {
      value: 'unitCostCurrencyCode', text: 'Unit Cost Currency Code', filterable: true,
    },
  ],
  agreementMetadata: [
    {
      name: 'acceptedDate', displayName: 'Accepted Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'cancellationDate', displayName: 'Cancellation Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'expectedDeliveryDate', displayName: 'Expected Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'status', displayName: 'Status', type: 'System.String',
    },
  ],
  solutionHeaders: [
    {
      value: 'solutionNumber', text: 'Solution Number', filterable: false, width: '150px',
    },
    {
      value: 'owner', text: 'Owner', filterable: true,
    },
    {
      value: 'name', text: 'Solution Name', filterable: true,
    },
  ],
  solutionMetadata: [
    {
      name: 'solutionNumber', displayName: 'Solution Number', type: 'System.String',
    },
    {
      name: 'name', displayName: 'Solution Name', type: 'System.String',
    },
    {
      name: 'owner', displayName: 'Owner', type: 'System.String',
    },
    {
      name: 'created', displayName: 'Created', type: 'System.DateTimeOffset',
    },
  ],
  incomeLineMetadata: [
    {
      name: 'acceptedDate', displayName: 'Accepted Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'cancellationDate', displayName: 'Cancellation Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'expectedDeliveryDate', displayName: 'Expected Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'deliveredDate', displayName: 'Delivered Date', type: 'System.DateTimeOffset',
    },
    {
      name: 'responsiblePerson', displayName: 'Responsible Person', type: 'System.DateTimeOffset',
    },
    {
      name: 'lineAmount', displayName: 'Line Amount', type: 'System.Double',
    },
    {
      name: 'lineNumber', displayName: 'Line Number', type: 'System.Double',
    },
    {
      name: 'lineType', displayName: 'Type', type: 'System.String',
    },
    {
      name: 'noticeTerm', displayName: 'Notice Term', type: 'System.Double',
    },
    {
      name: 'profit', displayName: 'Profit', type: 'System.Double',
    },
    {
      name: 'term', displayName: 'Term', type: 'System.Double',
    },
    {
      name: 'agreement/status', displayName: 'Status', type: 'System.String',
    },
    {
      name: 'unitCostAmount', displayName: 'Unit Cost Amount', type: 'System.Double',
    },
    {
      name: 'unitCostCurrencyCode', displayName: 'Unit Cost Currency Code', type: 'System.Double',
    },
  ],
};
