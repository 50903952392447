<template>
  <v-alert
    v-if="localContractItem"
    border="left"
    dense
    colored-border
    color="deep-purple accent-4"
    elevation="2"
  >
    <template v-slot:prepend>
      <span class="ml-2 mr-2">
        {{ localContractItem.lineNumber }}
      </span>
    </template>
    <v-row style="padding-top: 10px">
      <v-col cols="11">
        <v-row>
          <v-col cols="12" xl="3" lg="3" md="6">
            <v-text-field
              :label="$$t('description')"
              dense
              :data-cy="`FormContractItem-description-${index}`"
              :disabled="disabled"
              v-model="localContractItem.description"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <v-select
              block
              clearable
              dense
              data-cy="FormContractItem-lineType"
              :disabled="disabled"
              :label="$$t('serviceType')"
              :rules="requiredFieldRules"
              v-model="localContractItem.lineType"
              :content-class="`data-cy-lineType-${index}`"
              :items="lineTypes"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <v-autocomplete
              auto-select-first
              :label="$$t('revenueLine')"
              dense
              :data-cy="`FormContractItem-revenueLineId-${index}`"
              :disabled="disabled"
              v-model="localContractItem.revenueLineId"
              :items="revenueLines"
              item-text="name"
              :rules="requiredFieldRules"
              item-value="id"
              @input="revenueLineChanged(item, index)"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              v-if="localContractItem.lineType !== 'Non-Recurring Cost'"
              :data-cy="`FormContractItem-terms-${index}`"
              dense
              :disabled="disabled"
              v-model="localContractItem.terms"
              :label="$$t('term')"
              :rules="requiredFieldRules"
              @input="matchTermAndRenewalTerm"
              hint="months"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <NullableTextField
              v-if="localContractItem.lineType !== 'Non-Recurring Cost'"
              :data-cy="`FormContractItem-terms-${index}`"
              dense
              v-model="localContractItem.renewalTerm"
              :label="$$t('renewalTerm')"
              :rules="requiredFieldRules"
              hint="months"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <NullableTextField
              v-if="localContractItem.lineType !== 'Non-Recurring Cost'"
              :data-cy="`FormContractItem-terms-${index}`"
              dense
              v-model="localContractItem.noticePeriod"
              :label="$$t('noticePeriod')"
              :rules="requiredFieldRules"
              hint="months"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              :label="$$t('quantity')"
              dense
              :data-cy="`FormContractItem-quantity-${index}`"
              :disabled="disabled"
              v-model="localContractItem.quantity"
              type="number"
              :rules="requiredFieldRules"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <NullableTextField
              :label="$$t('amount')"
              dense
              :data-cy="`FormContractItem-amount-${index}`"
              :disabled="disabled"
              v-model="localContractItem.amount"
              type="number"
              :rules="requiredFieldRules"
            />
          </v-col>
          <v-col cols="12" xl="1" lg="1" md="6">
            <CurrencyTextField
              :data-cy="`FormContractItem-total-${index}`"
              dense
              disabled
              :label="$$t('total')"
              :value="total"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <DatePicker
              :data-cy="`FormContractItem-start-${index}`"
              :dense="true"
              :filled="false"
              v-model="localContractItem.start"
              :label="$$t('start')"
              :id="`FormContractItem-start-${index}-datepicker`"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <DatePicker
              v-if="localContractItem.lineType !== 'Non-Recurring Cost'"
              :data-cy="`FormContractItem-end-${index}`"
              :dense="true"
              :filled="false"
              v-model="localContractItem.end"
              :label="$$t('cancellationDate')"
              :id="`FormContractItem-end-${index}-datepicker`"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <v-text-field
              dense
              disabled
              label="Migration ID"
              :value="localContractItem.migratedItemId"
            />
          </v-col>
          <v-col cols="12" xl="2" lg="2" md="6">
            <MergeSectionLineItem
              :SectionLineItem="localContractItem"
              @migrated="$emit('migrated')"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row no-gutters>
          <v-col cols="9">
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn
                  :disabled="disabled"
                  @click="$emit('update:up', localContractItem)"
                  icon
                  v-if="index > 0"
                >
                  <v-icon>{{ mdiArrowUpBold }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-btn
                  :disabled="disabled"
                  @click="$emit('update:down')"
                  v-if="index < totalItems - 1"
                  icon
                >
                  <v-icon>{{ mdiArrowDownBold }}</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6">
                <v-menu offset-y v-if="sections.length > 0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      :disabled="disabled"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      dark
                      slot="activator"
                    >
                      <v-icon class="ml-2">{{ mdiVectorIntersection }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in sections"
                      :key="item.id"
                      @click="$emit('update:section', { sectionId: item.id, localContractItem })"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <!-- <v-btn
              :disabled="disabled"
              @click="$emit('remove', localContractItem)"
              large
              color="error"
              icon
            >
              <v-icon>{{ mdiDelete }}</v-icon>
            </v-btn> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12"> </v-col>
    </v-row>
  </v-alert>
</template>

<script>
import { mapState } from 'vuex';
import {
  mdiDelete,
  mdiDragVertical,
  mdiArrowUpBold,
  mdiArrowDownBold,
  mdiVectorIntersection,
  mdiNotebook,
} from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import RequiredField from '~src/mixins/required-field';
import MergeSectionLineItem from './MergeSectionLineItem.vue';

export default {
  name: 'FormContractItem',
  module: 'ContractItem',
  components: {
    MergeSectionLineItem,
  },
  mixins: [loading, RequiredField],
  props: {
    value: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      /**
       * Icons
       */
      mdiArrowDownBold,
      mdiArrowUpBold,
      mdiDragVertical,
      mdiDelete,
      mdiNotebook,
      mdiVectorIntersection,
      dialog: false,
      /**
       * Hold form validation state
       */
      valid: false,
      /**
       * Local value used for property localContractItem
       */
      localContractItem: undefined,
    };
  },
  computed: {
    ...mapState('contractSections', ['contractSections']),
    ...mapState('revenueLines', ['revenueLines']),
    ...mapState('quotes', ['terms']),
    lineTypes() {
      return [
        { value: 'Annual Recurring Cost', text: 'Annual Recurring' },
        { value: 'Bi-Annual Recurring Cost', text: 'Bi-Annual Recurring' },
        { value: 'Quarterly Recurring Cost', text: 'Quarterly Recurring' },
        { value: 'Monthly Recurring Cost', text: 'Monthly Recurring' },
        { value: 'Non-Recurring Cost', text: 'Non-Recurring' },
      ];
    },
    sections() {
      const { contractSections } = this;
      return contractSections.filter(
        (section) => section.id !== this.localContractItem.contractSectionId,
      );
    },
    total() {
      return this.localContractItem.quantity * this.localContractItem.amount;
    },
  },
  watch: {
    value() {
      this.localContractItem = this.value;
    },
    localContractItem: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localContractItem);
      },
    },
  },
  mounted() {
    this.localContractItem = this.value;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localContractItem);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },

    matchTermAndRenewalTerm(value) {
      this.localContractItem.renewalTerm = value;
    },
  },
};
</script>

<docs>
# FormContractItem example

'''js
<FormContractItem :ContractItem="ContractItem" />
'''
</docs>
