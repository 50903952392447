<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-row>
          <v-col cols="12" xl="4" lg="4">
            <v-select
              filled
              :items="clients"
              item-text="name"
              item-value="id"
              data-cy="FormActivity-clientId"
              :label="$$t('clientId')"
              v-model="localActivity.clientId"
            />
            <v-select
              data-cy="FormActivity-activityType"
              filled
              :label="$$t('activityType')"
              :items="['Phone Call', 'Meeting', 'Visit']"
              v-model="localActivity.activityType"
            ></v-select>
            <v-datetime-picker
              filled
              :textFieldProps="{ filled: true }"
              label="Date and Time" v-model="localActivity.activityDate">
                <template v-slot:dateIcon>
                  Date
                </template>
                <template v-slot:timeIcon>
                  Time
                </template>
            </v-datetime-picker>
            <v-text-field
              filled
              data-cy="FormActivity-duration"
              :label="$$t('duration')"
              v-model="localActivity.duration"
              type="Number"
            >
            <template v-slot:append="">
              minutes
            </template>
            </v-text-field>
            <div class="text-h6">Rating</div>
            <v-rating
              v-model="localActivity.rating"
              data-cy="FormActivity-rating"
              :label="$$t('rating')"
              large
            ></v-rating>
            <v-textarea
              filled
              data-cy="FormActivity-notes"
              :label="$$t('notes')"
              v-model="localActivity.notes">
            </v-textarea>
            <div class="d-flex justify-end mb-6">
              <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
                data-cy="FormActivity-button-cancel">
                {{ $t('common.buttons.cancel') }}
              </v-btn>
              <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
                data-cy="FormActivity-button-save">
                  {{ $t('common.buttons.save') }}
                </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import { mapState } from 'vuex';

export default {
  name: 'FormActivity',
  module: 'Activity',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Activity: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      contacts: [],
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localActivity
      */
      localActivity: {},
    };
  },
  computed: {
    ...mapState('clients', ['clients']),
  },
  watch: {
    Activity() {
      this.localActivity = this.Activity;
    },
  },
  async created() {
    this.localActivity = this.Activity;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localActivity);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>
<style>
.v-picker__title {
    height: 84px;
    padding-top: 10px;
}
</style>
<docs>
# FormActivity example

'''js
<FormActivity :Activity="Activity" />
'''
</docs>
