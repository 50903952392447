<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="500px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          data-cy="ModalExchangeRate-Add"
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon class="mr-2">{{ mdiPlus }}</v-icon>
          Add Exchange Rate
        </v-btn>
      </template>
      <v-card>
        <v-card-title>New Exchange Rate</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px;">
          <FormExchangeRate
            :ExchangeRate="editExchangeRate"
            @save="save"
            @cancel="dialog = false"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormExchangeRate from './FormExchangeRate.vue';

export default {
  name: 'ModalExchangeRate',
  components: {
    FormExchangeRate,
  },
  mixins: [loading],
  props: ['exchangeRates', 'currencyCode'],
  data() {
    return {
      mdiPlus,
      dialog: false,
      /**
      * Get/Set editing ExchangeRate. Default to {}.
      */
      editExchangeRate: {},
    };
  },
  watch: {
    currencyCode() {
      this.editExchangeRate.sourceCurrency = this.currencyCode;
    },
  },
  mounted() {
    this.editExchangeRate.sourceCurrency = this.currencyCode;
  },
  methods: {
    /**
     * Executes save
     */
    save(item) {
      this.$emit('save', item);
      this.dialog = false;
    },
  },
};
</script>
