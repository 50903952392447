<template>
  <Card :title="`Activities (${activities.length})`">
    <template v-slot:action>
      <v-btn
        :to="{
          name: 'quotes-EditActivity',
          query: {
            clientId,
          }
        }">
        Add
      </v-btn>
    </template>
    <v-list three-line>
      <template v-for="(item) in activities">
        <v-list-item
          :key="item.id"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ item.activityType }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <span v-if="item.activityDate"> at {{ formatDateTime(item.activityDate) }}</span>
              <span class="float-right">
                created {{ formatFromNow(item.created) }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </Card>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import Card from './Card.vue';

export default {
  name: 'CardActivities',
  components: {
    Card,
  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    clientId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mdiPencil,
    };
  },
};
</script>
