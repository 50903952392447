<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="quotes"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="50"
      :footer-props="{
        'items-per-page-options': [50, 100, 200, 500, -1]
      }"
      dense
      :search="search"
      sort-by="quoteNumber"
      :sort-desc="true"
    >
    <template v-slot:top>
      <v-row>
        <v-col cols="4">
          <div class="text-h5 ml-2">Manage Quotes</div>
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="2">
        </v-col>
        <v-col cols="3">
          <v-text-field
            clearable
            class="mr-2"
            v-model="search"
            :append-icon="mdiMagnify"
            :label="$t('common.search')"
            single-line
            hide-details
          />
        </v-col>
        <v-col cols="1">
          <v-btn small color="secondary" @click="refresh" class="mt-5">
            <v-icon>{{ mdiRefresh }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.isDeleted="{ item }">
      <v-edit-dialog
        :return-value.sync="item.isDeleted"
        large
        @save="saveIsDeleted(item)"
      >
        <BoolField class="inline-block" v-model="item.isDeleted" />
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="[{ text: 'Yes', value: true }, { text: 'No', value: false}]"
            v-model="item.isDeleted"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.isActive="{ item }">
      <BoolField v-model="item.isActive" />
    </template>
    <template v-slot:item.status="{ item }">
      <v-edit-dialog
        :return-value.sync="item.status"
        large
        @save="saveStatus(item)"
      >
        {{ item.status }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-select
            :items="statuses"
            v-model="item.status"
          ></v-select>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.quoteNumber="{ item }">
      <div style="display: block">
        <router-link
          :to="{ name: 'quotes-EditQuote', query: { id: item.id } }"
          class="black--text"
          >
          {{ item.quoteNumber }}
        </router-link>
      </div>
    </template>
    <template v-slot:item.contractSolutionId="{ item }">
      <v-edit-dialog
        :return-value.sync="item.contractSolutionId"
        large
        @save="saveSolution(item)"
      >
        {{ getSolutionName(item.contractSolutionId) }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-autocomplete
            clearable
            :items="solutions"
            v-model="item.contractSolutionId"
            :item-text="i => `${formatDate(i.contactName)}: ${i.name}`"
            item-value="id"
            label="Solution" />
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.invoiceID="{ item }">
      <v-edit-dialog
        :return-value.sync="item.invoiceID"
        large
        @save="save(item)"
      >
        {{ item.xeroInvoiceNumber }}
        <v-icon small>{{ mdiPencilBoxOutline }}</v-icon>
        <template v-slot:input>
          <v-autocomplete
            clearable
            :items="sortedInvoices"
            v-model="item.invoiceID"
            :item-text="i =>
              `${formatDate(i.date)}: ${i.invoiceNumber} - ${i.reference}`
              "
            item-value="invoiceID"
            label="Invoice Number">
          </v-autocomplete>
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="!item.contractSolutionId"
        color="primary"
        :disabled="isLoading"
        x-small
        @click="createSolution(item)"
      >
        Generate Solution
      </v-btn>
    </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiPencilBoxOutline,
} from '@mdi/js';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ManageQuotes',
  mixins: [loading],
  components: {
    BoolField,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiPencilBoxOutline,
      mdiRefresh,
      search: '',
      headers: [
        { text: 'Quote Number', value: 'quoteNumber', width: '100px' },
        { text: 'Quote Status', value: 'status' },
        { text: 'Deleted', value: 'isDeleted', width: '120px' },
        { text: 'Archived', value: 'isActive' },
        { text: 'Project Name', value: 'reference', width: '30%' },
        { text: 'Xero Invoice', value: 'invoiceID' },
        { text: 'Xero Status', value: 'xeroStatus' },
        { text: 'Solution', value: 'contractSolutionId', width: '30%' },
        { text: '', value: 'actions' },
      ],
      xeroInvoiceNumber: '',
      dialog: false,
    };
  },
  computed: {
    ...mapState('quotes', ['quotes', 'statuses']),
    ...mapState('solutions', ['solutions']),
    ...mapState('xeroInvoices', ['xeroInvoices']),
    sortedInvoices() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.xeroInvoices
        .sort((a, b) => new Date(a.date) - new Date(b.date))
        .reverse();
    },
  },
  methods: {
    ...mapActions('quotes', ['LOAD_manageQuotes', 'UPDATE_quote']),
    ...mapActions('solutions', ['LOAD_solutions', 'DELETE_solution', 'UPDATE_solution']),
    ...mapActions('xeroInvoices', ['LOAD_MIN_xeroInvoices']),
    async createSolution(item) {
      this.isLoading = true;
      await this.$http.post(`api/quotes/create-solution/${item.id}`);
      this.isLoading = false;
      this.$root.$emit('toast:notify', 'Solution created successfully');
    },
    getSolutionName(id) {
      const solution = this.solutions.find((x) => x.id === id);
      return solution ? solution.name : '';
    },
    async updateXeroInvoice(item) {
      await this.UPDATE_xeroInvoice({ xeroInvoice: item });
      this.xeroInvoiceNumber = '';
    },
    async refresh() {
      this.isLoading = true;
      await Promise.all([
        (async () => {
          await this.LOAD_manageQuotes();
        })(),
        (async () => {
          await this.LOAD_solutions();
        })(),
        (async () => {
          await this.LOAD_MIN_xeroInvoices();
        })(),
      ]);
      this.isLoading = false;
    },
    async save(item) {
      const invoice = this.xeroInvoices
        .find((x) => x.invoiceID === item.invoiceID);
      await this.UPDATE_quote({
        id: item.id,
        invoiceID: item.invoiceID ?? null,
        xeroInvoiceNumber: invoice?.invoiceNumber ?? null,
        xeroStatus: invoice?.status ?? null,
      });
      await this.refresh();
    },
    async saveSolution(item) {
      await this.UPDATE_quote({
        id: item.id,
        contractSolutionId: item.contractSolutionId ?? null,
      });
      await this.UPDATE_solution({
        id: item.contractSolutionId,
        solutionNumber: item.quoteNumber.replace('Q', 'S'),
      });
      await this.refresh();
    },
    async saveStatus(item) {
      await this.UPDATE_quote({
        id: item.id,
        status: item.status,
      });
      await this.refresh();
    },
    async saveIsDeleted(item) {
      await this.UPDATE_quote({
        id: item.id,
        isDeleted: item.isDeleted,
      });
      await this.refresh();
    },
  },
  async created() {
    await this.refresh();
  },
};
</script>
<style scoped>
.inline-block,
.inline-block > div{
  display: inline-block;
}
</style>
