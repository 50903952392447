/* eslint-disable no-console */
export default {
  async LOAD_navigationRoutes({ commit, state }) {
    const { fromDate, toDate } = state;
    try {
      const { data } = await this.$http.get('/api/telemetry/navigation', {
        params: {
          from: fromDate,
          to: toDate,
        },
      });
      commit('SET_navigationRoutes', data);
    } catch (error) {
      console.error('Error fetching navigation routes:', error);
    }
  },
  async LOAD_features({ commit, state }) {
    const { fromDate, toDate, prevFromDate, prevToDate } = state;
    try {
      const { data } = await this.$http.get('/api/telemetry/feature', {
        params: {
          from: fromDate,
          to: toDate,
        },
      });
      commit('SET_features', data);
    } catch (error) {
      console.error('Error fetching features:', error);
    }
    try {
      const { data } = await this.$http.get('/api/telemetry/feature', {
        params: {
          from: prevFromDate,
          to: prevToDate,
        },
      });
      commit('SET_prevFeatures', data);
    } catch (error) {
      console.error('Error fetching features:', error);
    }
  },
};
