<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
      <v-container class="text-center">
        <v-select
          :loading="isLoading"
          data-cy="FormOrgTaxRate-taxType"
          filled
          :label="$$t('taxType')"
          :items="taxRates"
          :item-text="t => `${t.name} (${t.taxType})`"
          item-value="taxType"
          v-model="localOrgTaxRate.taxType"
        ></v-select>
        <v-text-field
          filled
          data-cy="FormOrgTaxRate-taxName"
          :label="$$t('taxName')"
          v-model="localOrgTaxRate.taxName"
          :hint="$$t('taxNameHint')"
        />
        <v-text-field
          filled
          data-cy="FormOrgTaxRate-rate"
          :label="$$t('rate')"
          v-model="localOrgTaxRate.rate"
        />
        <div class="d-flex justify-end mb-6">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel"
            data-cy="FormOrgTaxRate-button-cancel">
            {{ $t('common.buttons.cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving"
            data-cy="FormOrgTaxRate-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
        </div>
      </v-container>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'FormOrgTaxRate',
  module: 'OrgTaxRate',
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    OrgTaxRate: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Holds the tax rates from Xero
      */
      taxRates: [],
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localOrgTaxRate
      */
      localOrgTaxRate: {},
    };
  },
  watch: {
    OrgTaxRate() {
      this.localOrgTaxRate = this.OrgTaxRate;
    },
  },
  created() {
    this.localOrgTaxRate = this.OrgTaxRate;
  },
  async mounted() {
    this.isLoading = true;
    const response = await this.$http.get('/api/xero/taxRates');
    this.taxRates = response.data;
    this.isLoading = false;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localOrgTaxRate);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormOrgTaxRate example

'''js
<FormOrgTaxRate :OrgTaxRate="OrgTaxRate" />
'''
</docs>
