<template>
<v-container fluid>
  <v-card>
    <v-card-text>
      <v-skeleton-loader
        :loading="isLoading"
        transition="expand-transition"
        type="article"
      >
        <FormRevenueLine
          :RevenueLine="editRevenueLine"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({ name: 'contracts-ListRevenueLine' })" />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormRevenueLine from '../components/FormRevenueLine.vue';

export default {
  components: {
    FormRevenueLine,
  },
  name: 'EditRevenueLine',
  module: 'Contract',
  meta: {
    redirectOnRefresh: { name: 'contracts-ListRevenueLine' },
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.revenueLines',
        to: { name: 'contracts-ListRevenueLine' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing RevenueLine. Default to {}.
      */
      editRevenueLine: {},
      /**
       * Query Id for RevenueLine
       */
      revenueLineId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('revenueLines', ['revenueLines', 'revenueLine']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.revenueLineId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('accounts', ['LOAD_accounts']),
    ...mapActions('revenueLines', ['LOAD_revenueLine', 'CREATE_revenueLine', 'UPDATE_revenueLine']),
    ...mapActions('xeroAccounts', ['LOAD_xeroAccounts']),
    /**
     * Executes refresh of data
     */
    async refresh() {
      this.isLoading = true;
      if (this.mode === 'edit') {
        await this.LOAD_revenueLine(this.revenueLineId);
        this.editRevenueLine = this.revenueLine;
      }
      await Promise.all([
        (async () => {
          await this.LOAD_accounts();
        })(),
        (async () => {
          await this.LOAD_xeroAccounts();
        })(),
      ]);
      this.isLoading = false;
    },
    /** Method used to save the item
     */
    async save(revenueLine) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_revenueLine(revenueLine);
      } else {
        await this.UPDATE_revenueLine(revenueLine);
      }
      this.isSaving = false;
      this.$router.push({ name: 'contracts-ListRevenueLine' });
    },
  },
};
</script>

<docs>
# EditRevenueLine example

'''js
<EditRevenueLine :RevenueLine="RevenueLine" />
'''
</docs>
