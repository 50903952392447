/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import {
  mdiHome,
  mdiFileSign,
  mdiFile,
  mdiOfficeBuilding,
  mdiAccountGroup,
  mdiWalletOutline,
  mdiStoreCheckOutline,
  mdiEmoticonHappyOutline,
  mdiChartAreaspline,
  mdiCash,
  mdiOfficeBuildingPlusOutline,
  mdiCogOutline,
  mdiContacts,
  mdiBullseyeArrow,
  mdiTextBoxMultipleOutline,
  // mdiBullseye,
} from '@mdi/js';

export default {
  computed: {
    menuItems() {
      return [
        {
          name: 'menu.home', pathName: 'app-Home', icon: mdiHome, topMenu: false,
        },
        {
          name: 'menu.solutions', pathName: 'solutions-ListSolution', icon: mdiStoreCheckOutline, topMenu: false,
        },
        {
          name: 'menu.agreements', pathName: 'solutions-ListAgreement', icon: mdiFileSign, topMenu: false,
        },
        // {
        //   name: 'menu.contracts', pathName: 'contracts-ListContract', icon: mdiFileSign, topMenu: false,
        // },
        {
          name: 'menu.contacts',
          pathName: 'contracts-ListContract',
          icon: mdiContacts,
          topMenu: false,

          subLinks: [
            {
              name: 'menu.clients', pathName: 'quotes-ListClient', icon: mdiOfficeBuilding, topMenu: false,
            },
            {
              name: 'menu.suppliers', pathName: 'quotes-ListSupplier', icon: mdiOfficeBuildingPlusOutline, topMenu: false,
            },
            {
              name: 'menu.people', pathName: 'quotes-ListPerson', icon: mdiAccountGroup, topMenu: false,
            }],
        },
        // {
        //   name: 'menu.settings', pathName: 'app-CompanySettings', icon: mdiCogOutline, topMenu: false,
        // },
        {
          name: 'menu.settings',
          // pathName: 'app-Settings',
          icon: mdiCogOutline,
          topMenu: false,
          subLinks: [
            {
              name: 'menu.accounts', pathName: 'contracts-ListAccount', icon: mdiWalletOutline, topMenu: false,
            },
            {
              name: 'menu.companyDefaults', pathName: 'app-CompanyDefaults', icon: mdiCash, topMenu: false,
            },
            {
              name: 'menu.companySettings', pathName: 'app-CompanySettings', icon: mdiWalletOutline, topMenu: false,
            },
            {
              name: 'menu.orgTargets', pathName: 'solutions-CompanyTargets', icon: mdiBullseyeArrow, topMenu: false,
            },
            {
              name: 'menu.integrationSettings', pathName: 'app-IntegrationSettings', icon: mdiBullseyeArrow, topMenu: false,
            },
            {
              name: 'menu.quoteTemplates', pathName: 'quotes-ListQuoteTemplate', icon: mdiTextBoxMultipleOutline, topMenu: false,
            },
            {
              name: 'menu.revenueLines', pathName: 'contracts-ListRevenueLine', icon: mdiCash, topMenu: false,
            },
            // {
            //   name: 'menu.userTargets', pathName: 'quotes-ListUserTarget',
            //   icon: mdiBullseye, topMenu: false,
            // },
          ],
        },
        {
          name: 'menu.reports',
          pathName: 'contracts-Reports',
          icon: mdiChartAreaspline,
          topMenu: false,
          subLinks: [
            {
              name: 'menu.reportBuilder', pathName: 'reports-ReportBuilder', icon: mdiChartAreaspline, topMenu: false,
            },
            {
              name: 'menu.dataRiskReport', pathName: 'contracts-DataRiskReport', topMenu: true,
            },
            // {
            //   name: 'menu.solutionDelivery', pathName: 'contracts-ReportSolutionDelivery', icon: mdiChartAreaspline, topMenu: false,
            // },
            {
              name: 'menu.reportExceptions', pathName: 'solutions-ReportExceptions', icon: mdiChartAreaspline, topMenu: false,
            },
            // {
            //   name: 'menu.agreementsByStatusReport', pathName: 'contracts-ReportAgreementsByStatus', icon: mdiChartAreaspline, topMenu: false,
            // },
            {
              name: 'menu.newBusinessLog', pathName: 'reports-ListReports', topMenu: true,
            },
          ],
        },
        {
          name: 'menu.myFeedbackItems', pathName: 'app-MyFeedbackItems', icon: mdiEmoticonHappyOutline, topMenu: false,
        },
      ];
    },
  },
};
