<template>
  <v-card>
    <v-card-title v-if="item.solution">
      <router-link
        :to="{ name: 'solutions-EditSolution', query: { id: item.solutionId } }"
        class="black--text"
      >
        {{ item.agreementNumber }}: {{ item.reference }} ({{ item.status }})
      </router-link>
    </v-card-title>
    <v-card-title v-else>
      <router-link
        :to="{ name: 'solutions-EditAgreement', query: { id: item.id } }"
        class="black--text"
      >
        {{ item.agreementNumber }}: {{ item.reference }} ({{ item.status }})
      </router-link>
    </v-card-title>
    <v-card-subtitle class="text-left">
      Created: {{ formatDate(item.created) }}
    </v-card-subtitle>
    <v-card-text class="text-left">
      <v-row dense v-if="summary">
        <v-col cols="12">
          <div class="text-h5">
            Recurring Profit:
            <b class="float-right">
              {{ formatCurrency(summary.totalRecurringIncome, item.currencyCode) }} -
              {{ formatCurrency(summary.totalRecurringCost, item.currencyCode) }} =
              {{ formatCurrency(summary.totalRecurringProfit, item.currencyCode) }}
            </b>
          </div>
          <div class="text-h5">
            Non-Recurring Profit:
            <b class="float-right">
              {{ formatCurrency(summary.totalNonRecurringIncome, item.currencyCode) }} -
              {{ formatCurrency(summary.totalNonRecurringCost, item.currencyCode) }} =
              {{ formatCurrency(summary.totalNonRecurringProfit, item.currencyCode) }}
            </b>
          </div>
          <v-divider />
          <div class="text-h6">
            Renewal Date:
            <b class="float-right">{{ formatDate(summary.renewalDate) }}</b>
          </div>
          <div class="text-h6">
            Delivered Date:
            <b class="float-right">{{ formatDate(item.deliveredDate) }}</b>
          </div>
          <div class="text-h6">
            Accepted Date:
            <b class="float-right">{{ formatDate(item.acceptedDate) }}</b>
          </div>
          <div class="text-h6">
            Notice Date:
            <b class="float-right">{{ formatDate(item.noticeDate) }}</b>
          </div>
          <div class="text-h6">
            Expiry Date:
            <b class="float-right">{{ formatDate(item.expiryDate) }}</b>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AgreementSummary',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      summary: undefined,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('agreements', ['LOAD_summary']),
    async refresh() {
      this.summary = await this.LOAD_summary(this.item.id);
    },
  },

};
</script>
