<template>
  <v-data-table
    hide-default-header
    class="server-table"
    :headers="localColumns"
    :items="items"
    :search="search"
    :loading="isLoading"
    :options.sync="pagination"
    :footer-props="{
      itemsPerPageOptions: [10, 50, 100, 250, 500, 1000, 2000, 5000],
    }"
    :server-items-length="pagination.totalItems"
    dense
    @click:row="rowClick"
  >
    <template #top>
      <div class="d-flex align-center justify-end">
        <slot name="top"></slot>
        <div class="mr-5" />
        <!-- <v-text-field v-model="search" class="mt-4 ml-5" :append-icon="mdiMagnify" label="Search" dense /> -->
        <v-btn class="mt-2" icon title="Refresh data" @click="refresh">
          <v-icon>{{ mdiRefresh }}</v-icon>
        </v-btn>
        <ColumnDialog :headers.sync="localColumns" :all-headers="allHeaders" />
        <FiltersDialog
          :headers.sync="localColumns"
          :all-headers="allHeaders"
          :metadata="metadata"
          v-model="filters"
        />
        <ExportExcel :data="exportItems" :name="entitySet" />
        <v-spacer></v-spacer>
        <div class="d-flex align-center justify-center justify-space-between mb-2">
          <v-radio-group v-model="reportType" class="mr-2" row dense>
            <v-radio label="Private" value="private"></v-radio>
            <v-radio label="Shared" value="shared"></v-radio>
          </v-radio-group>
          <v-select
            class="mr-5"
            clearable
            dense
            :items="availableReports"
            v-model="selectedReport"
            item-value="id"
            item-text="name"
            hide-details
            label="Select a report"
          />
          <v-btn
            v-if="selectedReport"
            class="mr-5"
            small
            color="secondary"
            @click="load"
          >
            Load
          </v-btn>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                Create Report Definition
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="reportDefinition.name" label="Report Name"></v-text-field>
                <v-checkbox label="Shared Report" v-model="reportDefinition.isShared" />
              </v-card-text>
              <v-card-actions>
                <v-btn text @click="dialog = false">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="create">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-btn
            v-if="selectedReport"
            class="mr-5"
            small
            color="secondary"
            @click="update"
          >
            Update
          </v-btn>
          <v-btn v-else class="mr-5" small color="secondary" @click="dialog = true">
            Save
          </v-btn>
        </div>
      </div>
    </template>
    <template #header="{ props }">
      <thead class="v-data-table-header mt-1">
        <tr>
          <th v-for="column in props.headers" :key="column.value" class="text-start sortable" :width="column.width">
            {{ column.text }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-for="slot in slots" #[`item.${slot.name}`]="{ item, index }">
      <slot :name="slot.name" :item="item" :index="index"></slot>
    </template>
  </v-data-table>
</template>

<script>
import {
  mdiMagnify, mdiMenu, mdiClose, mdiRefresh,
} from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import ODataQueryBuilder from './ODataQueryBuilder';
import ColumnDialog from './ColumnDialog.vue';
import ExportExcel from './ExportExcel.vue';
import FiltersDialog from './FiltersDialog.vue';

const baseUrl = process.env.VUE_APP_APIURL;

export default {
  name: 'ServerDataTable',
  components: {
    ColumnDialog,
    ExportExcel,
    FiltersDialog,
  },
  mixins: [loading],
  props: {
    allHeaders: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    expand: {
      type: String,
      default: undefined,
    },
    metadata: {
      type: Array,
      default: () => [],
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    hideTemplate: {
      type: Boolean,
      default: false,
    },
    entitySet: {
      type: String,
      required: true,
    },
    slots: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiClose,
      mdiMagnify,
      mdiMenu,
      mdiRefresh,
      items: [],
      isLoading: false,
      filters: [],
      search: '',
      advSearch: {
        filter: 'IsDeleted eq false',
      },
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: false,
        totalItems: 0,
      },
      queryBuilder: new ODataQueryBuilder(`${baseUrl}/odata`, this.entitySet, this.localColumns),
      localColumns: this.headers ?? [],
      dialog: false,
      reportDefinition: {
        name: '',
        isShared: false,
      },
      reportType: 'private',
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
    ...mapState('reportDefinitions', ['reportDefinitions']),
    availableReports() {
      if (this.reportDefinitions?.length === 0) {
        return [];
      }
      switch (this.reportType) {
        case 'private':
          return this.reportDefinitions
            .filter((r) => r.isShared === false && r.createdById === this.$store.state.auth.userId);
        case 'shared':
          return this.reportDefinitions.filter((r) => r.isShared === true);
        default:
          return [];
      }
    },
    client() {
      return this.$http;
    },
    exportItems() {
      const { localColumns, items } = this;
      const result = [];
      items.forEach((item) => {
        const itemArr = {};
        localColumns?.forEach((column) => {
          const { value, text } = column;
          let res = item[value];
          if (value.includes('agreement.solution.')) {
            res = item.agreement.solution[value.replace('agreement.solution.', '')];
          } else if (value.includes('agreement.')) {
            res = item.agreement[value.replace('agreement.', '')];
          }
          itemArr[text] = res;
        });
        result.push(itemArr);
      });
      return result;
    },
    selectedReport: {
      get() {
        return this.$store.state.app.selectedReport;
      },
      set(value) {
        this.$store.commit('app/SET_selectedReport', value);
      },
    },
  },
  watch: {
    entitySet: {
      immediate: true,
      handler(newValue) {
        this.queryBuilder.entitySet = newValue;
        this.refresh();
      },
    },
    headers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localColumns = newValue;
      },
    },
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
    filters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.advSearch.filter = newValue.map((f) => `${f.query}`).join(' and ');
        this.refresh();
      },
    },
    pagination: {
      immediate: true,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  // async created() {
  //   const url = this.queryBuilder.getMetadataUrl();
  //   const { data } = await this.client.get(url);
  //   this.metadata = data.value;
  // },
  async mounted() {
    await this.refresh();
    if (this.selectedReport) {
      await this.load();
    }
  },
  methods: {
    ...mapActions('reportDefinitions',
      ['LOAD_reportDefinitions',
        'CREATE_reportDefinition',
        'UPDATE_reportDefinition',
      ]),
    async create() {
      const { localColumns, filters } = this;
      const { name, isShared } = this.reportDefinition;
      await this.CREATE_reportDefinition({
        name,
        isShared,
        data: JSON.stringify({
          localColumns,
          filters,
        }),
      });
      this.dialog = false;
      this.reportName = '';
      this.$root.$emit('toast:notify', 'Report saved successfully');
      await this.refresh();
    },
    async update() {
      const { localColumns, filters, selectedReport } = this;
      await this.UPDATE_reportDefinition({
        id: selectedReport,
        data: JSON.stringify({
          localColumns,
          filters,
        }),
      });
      this.dialog = false;
      this.reportName = '';
      this.$root.$emit('toast:notify', 'Report saved successfully');
      await this.refresh();
    },
    async load() {
      const { selectedReport } = this;
      const reportDefinition = this.reportDefinitions.find((r) => r.id === selectedReport);
      if (!reportDefinition) {
        return;
      }
      const data = JSON.parse(reportDefinition.data);
      this.localColumns = data.localColumns;
      this.filters = data.filters;
      await this.refresh();
      this.$emit('update:headers', data.localColumns);
    },
    async refresh() {
      await this.LOAD_reportDefinitions();
      const { expand, queryBuilder } = this;
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const { filter } = this.advSearch;
      const query = {
        filter,
        expand,
        page,
        itemsPerPage,
        sortBy,
        sortDesc,
      };
      const url = queryBuilder.buildUrl(query);
      this.isLoading = true;
      const { data } = await this.client.get(url);
      this.pagination.totalItems = data['@odata.count'];
      this.items = data.value;
      this.isLoading = false;
    },
    rowClick(item) {
      this.$emit('row:click', item);
    },
  },
};
</script>
