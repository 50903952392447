<template>
  <div>
  </div>
</template>

<script>
import $ from 'jquery';
import { mapState } from 'vuex';

window.jQuery = $;

require('signalr');

export default {
  name: 'LiveUpdates.vue',
  data() {
    return {
      hub: undefined,
      connection: undefined,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
  },
  watch: {
    async organization() {
      await this.hub.stop();
      this.$log.info('updated org, starting hub');
      this.initHub();
    },
  },
  methods: {
    async initHub() {
      this.$log.debug('starting signalr');
      this.hub = $.hubConnection(`${process.env.VUE_APP_APIURL}`);
      this.connection = this.hub.createHubProxy('dataUpdates');

      this.connection.on('SendUpdate', (method, name) => {
        this.$log.info(`Received $${method} for ${name}`);
        this.$root.$emit(`${name}:updated`, { method, name });
        switch (name) {
          case 'Contact':
            this.$log.debug(`Received $${method} for ${name} and updating contacts`);
            this.$store.dispatch('contacts/LOAD_contacts');
            break;
          case 'Contract':
            this.$log.debug(`Received $${method} for ${name} and updating contracts`);
            // this.$store.dispatch('contracts/LOAD_contracts');
            break;
          default:
            break;
        }
      });
      const result = await this.hub.start();
      this.$log.info('signalr started', result);
      this.connection.invoke('registerConnection', this.organization?.id);
      // this.$log.debug('invoke GetAllStocks');
      // this.connection.invoke('GetAllStocks');
    },
  },
  async mounted() {
    this.$log.info('starting hub');
    await this.initHub();
  },
  async destroyed() {
    this.$log.info('stopping hub');
    await this.hub.stop();
  },
  // async created() {
  //   await this.$socket.start({
  //     log: process.env.NODE_ENV === 'development',
  //   });
  // },
  // destroyed() {
  //   this.$log.debug('destroyed');
  //   this.$socket.stop();
  // },
};
</script>
