<template>
  <v-container>
  <v-data-table
    :headers="headers"
    :items="xeroTenants"
    class="elevation-1"
    :loading="isLoading"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-btn
          color="success"
          data-cy="XeroTenants-button-new"
          :href="url"
        >
          Connect to Xero
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.isDefault="{ item }">
      <BoolField v-model="item.isDefault" />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        v-if="!item.isDefault"
        outlined
        color="secondary" @click="setAsDefault(item)">
        Set as default
      </v-btn>
    </template>
  </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import BoolField from '@codehq/aurora-app-core/src/components/BoolField.vue';

export default {
  components: {
    BoolField,
  },
  mixins: [loading],
  name: 'XeroTenants',
  computed: {
    /** Vuex states
     */
    ...mapState('organizations', ['organizations', 'organization']),
    ...mapState('xeroTenants', ['xeroTenants']),
    headers() {
      return [
        {
          text: 'Name',
          value: 'tenantName',
        },
        {
          text: 'Is Default',
          value: 'isDefault',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    url() {
      const { organization } = this;
      return `${process.env.VUE_APP_APIURL}/Xero/connect/${organization.id}`;
    },
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('xeroTenants', ['LOAD_xeroTenants', 'UPDATE_xeroTenant']),
    async refresh() {
      this.isLoading = true;
      await this.LOAD_xeroTenants();
      this.isLoading = false;
    },
    async setAsDefault(item) {
      this.isLoading = true;
      const xeroTenant = { ...item };
      xeroTenant.isDefault = true;
      await this.UPDATE_xeroTenant(xeroTenant);
      this.isLoading = false;
      await this.refresh();
    },
  },
};
</script>
