import dayjs from 'dayjs';

export default {
  selectedUsernames: [],
  selectedVersions: [],
  navigationRoutes: [],
  features: [],
  fromDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
  toDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
  prevFromDate: dayjs().subtract(2, 'week').format('YYYY-MM-DD'),
  prevToDate: dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
  prevFeatures: [],
};
