<template>
  <v-form lazy-validation v-model="valid" @submit.prevent="submit" ref="form">
    <v-row dense class="text-center">
      <v-col cols="7">
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              color="primary"
              slider-color="primary"
            >
              <v-tab>
                Information
              </v-tab>
              <v-tab>
                Additional Information
              </v-tab>
              <v-tab>
                Addresses
              </v-tab>
              <v-tab>
                Attachments
              </v-tab>
              <v-tab-item>
                <v-row class="mt-5 ml-2 mr-2">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="localClient.name"
                      filled
                      data-cy="FormClient-name"
                      :label="$$t('name')"
                      @keyup="nameChanged"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled data-cy="FormClient-legalName" :label="$$t('legalName')" v-model="localClient.legalName" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field filled data-cy="FormClient-registrationNumber" :label="$$t('registrationNumber')"
                      v-model="localClient.registrationNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field filled data-cy="FormClient-vatNumber" :label="$$t('vatNumber')" v-model="localClient.vatNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field filled data-cy="FormClient-emailAddress" :label="$$t('emailAddress')"
                      v-model="localClient.emailAddress" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-row class="mt-5 ml-2 mr-2">
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-adminTechnicalContactName"
                      filled
                      :label="$$t('adminTechnicalContactName')"
                      v-model="localClient.adminTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-adminTechnicalContactJobTitle"
                      filled
                      :label="$$t('adminTechnicalContactJobTitle')"
                      v-model="localClient.adminTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-adminTechnicalContactEmailAddress"
                      filled
                      :label="$$t('adminTechnicalContactEmailAddress')"
                      v-model="localClient.adminTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-adminTechnicalContactMobileNumber"
                      filled
                      :label="$$t('adminTechnicalContactMobileNumber')"
                      v-model="localClient.adminTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-adminTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('adminTechnicalContactPhoneNumber')"
                      v-model="localClient.adminTechnicalContactPhoneNumber" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-firstTechnicalContactName"
                      filled
                      :label="$$t('firstTechnicalContactName')"
                      v-model="localClient.firstTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-firstTechnicalContactJobTitle"
                      filled
                      :label="$$t('firstTechnicalContactJobTitle')"
                      v-model="localClient.firstTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-firstTechnicalContactEmailAddress"
                      filled
                      :label="$$t('firstTechnicalContactEmailAddress')"
                      v-model="localClient.firstTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-firstTechnicalContactMobileNumber"
                      filled
                      :label="$$t('firstTechnicalContactMobileNumber')"
                      v-model="localClient.firstTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-firstTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('firstTechnicalContactPhoneNumber')"
                      v-model="localClient.firstTechnicalContactPhoneNumber" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-secondTechnicalContactName"
                      filled
                      :label="$$t('secondTechnicalContactName')"
                      v-model="localClient.secondTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormClient-secondTechnicalContactJobTitle"
                      filled
                      :label="$$t('secondTechnicalContactJobTitle')"
                      v-model="localClient.secondTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-secondTechnicalContactEmailAddress"
                      filled
                      :label="$$t('secondTechnicalContactEmailAddress')"
                      v-model="localClient.secondTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-secondTechnicalContactMobileNumber"
                      filled
                      :label="$$t('secondTechnicalContactMobileNumber')"
                      v-model="localClient.secondTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormClient-secondTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('secondTechnicalContactPhoneNumber')"
                      v-model="localClient.secondTechnicalContactPhoneNumber" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <Addresses v-if="localClient.id" :client-id="localClient.id" />
              </v-tab-item>
              <v-tab-item v-if="localClient">
                <ClientAttachments :Client="localClient" />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mb-6 mt-5">
          <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel" data-cy="FormClient-button-cancel">
            {{ $t('common.buttons.back') }}
          </v-btn>
          <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving" data-cy="FormClient-button-save">
            {{ $t('common.buttons.save') }}
          </v-btn>
        </div>
    </v-col>
    <v-col cols="5" v-if="localClient.id">
      <ClientAgreements :client-id="localClient.id" />
    </v-col>
    </v-row>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
// import countries from '@/lib/countries.json';
import Addresses from './Addresses.vue';
import ClientAgreements from '../../../contracts/src/components/ClientAgreements.vue';
import ClientAttachments from './ClientAttachments.vue';

export default {
  name: 'FormClient',
  module: 'Client',
  components: {
    Addresses,
    ClientAgreements,
    ClientAttachments,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Client: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
       * List of countries
       */
      // countries,
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localClient
      */
      localClient: {},

      /**
       * Tab index
       */
      tab: 0,
    };
  },
  watch: {
    Client() {
      this.localClient = this.Client;
    },
    watch: {
      localClient: {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
          console.debug(newValue);
          if (newValue.name !== oldValue.name) {
            this.nameChanged();
          }
        },
      },
    },
  },
  created() {
    this.localClient = this.Client;
  },
  methods: {
    nameChanged() {
      if (this.localClient.name.startsWith(this.localClient.legalName)
       || this.localClient.legalName === null
      ) {
        this.localClient.legalName = this.localClient.name;
      }
    },
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localClient);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormClient example

'''js
<FormClient :Client="Client" />
'''
</docs>
