<template>
  <v-btn
    small
    color="secondary"
    title="Download data to Excel"
    :loading="isLoading"
    @click="download"
  >
    <v-icon>{{ mdiDownload }}</v-icon>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import { mdiDownload } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'DownloadData',
  mixins: [loading],
  props: {
    entitySet: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      mdiDownload,
    };
  },
  computed: {
    ...mapState('organizations', ['organization']),
  },
  methods: {
    async download() {
      const downloadUrl = `${process.env.VUE_APP_APIURL}/odata/${this.entitySet}/download`;
      try {
        this.isLoading = true;
        const response = await axios.get(downloadUrl, { responseType: 'blob' });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${this.entitySet}_download.xlsx`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', 'Failed to download data');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
