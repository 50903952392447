export default {
  module: 'Dialog',
  methods: {
    async confirmDialog(message, trueMessage, falseMessage) {
      return this.$confirm(message, {
        buttonTrueText: trueMessage ?? '',
        buttonFalseText: falseMessage ?? this.$t('ok'),
        color: 'warning',
        icon: 'warning',
      });
    },

    async optionalConfirmDialog(message, trueMessage, falseMessage) {
      return this.confirmDialog(
        message,
        trueMessage ?? this.$t('yes'),
        falseMessage ?? this.$t('no'),
      );
    },
  },
};
