export default {
  quotes: [],
  quote: undefined,
  statuses: [
    'Draft',
    'Prospecting',
    'Negotiating',
    'Certain',
    'Accepted',
    'Declined',
    'Delivered',
    'In Progress',
    'In Xero',
    'Invoiced',
    'Paid',
  ],
  stages: ['Prospecting', 'Negotiating', 'Possibility', 'Certain', 'Invoiced'],
  activeQuotes: [],
  selectedStatus: [],
  selectedUser: [],
  selectedStage: [],
  search: '',
  terms: [
    { text: '1 Month', value: 1 },
    { text: '6 Months', value: 6 },
    { text: '12 Months', value: 12 },
    { text: '24 Months', value: 24 },
    { text: '36 Months', value: 36 },
    { text: '5 Years', value: 60 },
  ],
};
