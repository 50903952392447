var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isLoading,"search":_vm.search,"items-per-page":50,"footer-props":{
          'items-per-page-options': [50, 100, 200, 500, -1]
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"success","data-cy":"Person-button-new"},on:{"click":function($event){return _vm.$router.push({
                name: 'quotes-EditPerson'
              })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.new'))+" ")],1),_c('div',{staticClass:"text-h5 ml-2"},[_vm._v(" People ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.firstName",fn:function({ item }){return [_c('router-link',{staticClass:"black--text",attrs:{"to":{ name: 'quotes-EditPerson', query: { id: item.id } }}},[_vm._v(" "+_vm._s(item.firstName)+" ")])]}},{key:"item.lastName",fn:function({ item }){return [_c('router-link',{staticClass:"black--text",attrs:{"to":{ name: 'quotes-EditPerson', query: { id: item.id } }}},[_vm._v(" "+_vm._s(item.lastName)+" ")])]}},{key:"item.emailAddress",fn:function({ item }){return [_c('a',{attrs:{"href":`mailto:${item.emailAddress}`}},[_vm._v(_vm._s(item.emailAddress))])]}},{key:"item.workNumber",fn:function({ item }){return [_c('a',{attrs:{"href":`tel:${item.workNumber}`}},[_vm._v(_vm._s(item.workNumber))])]}},{key:"item.cellNumber",fn:function({ item }){return [_c('a',{attrs:{"href":`tel:${item.cellNumber}`}},[_vm._v(_vm._s(item.cellNumber))])]}},{key:"item.actions",fn:function({ item }){return [_c('TableBasicButtons',{attrs:{"editLink":{
              name: 'quotes-EditPerson',
              query: {id: item.id}
            }},on:{"delete":function($event){return _vm.remove(item)}}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }