<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <FormSupplier
          :Supplier="editSupplier"
          :isSaving="isSaving"
          @save="save"
          @cancel="$router.push({name: 'quotes-ListSupplier' })" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormSupplier from '../components/FormSupplier.vue';

export default {
  components: {
    FormSupplier,
  },
  name: 'EditSupplier',
  module: 'Supplier',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.suppliers',
        to: { name: 'quotes-ListSupplier' },
        disabled: false,
      },
      {
        text: 'menu.edit',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Supplier. Default to {}.
      */
      editSupplier: {},
      /**
       * Query Id for Supplier
       */
      supplierId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('suppliers', ['suppliers', 'supplier']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.supplierId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_supplier(this.supplierId);
      this.editSupplier = this.supplier;
    } else {
      // create the supplier
      this.editSupplier = {};
      this.editSupplier = await this.CREATE_supplier(this.editSupplier);
      this.supplierId = this.editSupplier.id;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('suppliers', ['LOAD_supplier', 'CREATE_supplier', 'UPDATE_supplier']),
    /** Method used to save the item
     */
    async save(supplier) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_supplier(supplier);
      } else {
        await this.UPDATE_supplier(supplier);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListSupplier' });
    },
  },
};
</script>

<docs>
# EditSupplier example

'''js
<EditSupplier :Supplier="Supplier" />
'''
</docs>
