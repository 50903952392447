<template>
  <v-container>
    <v-row>
      <v-col
        cols="12">
          <ReportNewBusinessLog />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportNewBusinessLog from '../components/ReportNewBusinessLog.vue';

export default {
  name: 'ListReports',
  components: {
    ReportNewBusinessLog,
  },
  data() {
    return {
      report: 'New Business Log',
    };
  },
};
</script>
