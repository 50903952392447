import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('costLine', 'costLines');

const customActions = {
  async CREATE_costLine({ rootGetters }, costLine) {
    const data = await odata.o(rootGetters)
      .post('costLines', costLine)
      .query();
    return data;
  },
  async LOAD_costLines({ rootGetters }, { incomeLineId, isActive }) {
    const data = await odata.o(rootGetters)
      .get('costLines')
      .query({
        $filter: `IsDeleted eq false AND IncomeLineId eq ${incomeLineId} AND IsActive eq ${isActive}`,
        $orderby: 'Created',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
