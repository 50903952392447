<template>
  <v-row class="text-center">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true" v-if="isLoading" color="primary"></v-progress-linear>
      <v-row class="ml-2 mr-2">
        <v-col cols="6">
          <v-select
            v-model="selectedContractType"
            multiple
            :items="contractTypes"
            label="Contract Type" class="mr-4"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="selectedStatus"
            :items="statuses"
            label="Status"
            multiple
          />
        </v-col>
      </v-row>
      <GChart
        type="ColumnChart"
        :data="chartData"
        :options="chartOptions"
        :events="chartEvents"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';
import { GChart } from 'vue-google-charts/legacy';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'GraphProfit',
  mixins: [loading],
  module: 'Reports',
  components: {
    GChart,
  },
  props: {
    forecastData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedContractType: [],
      selectedStatus: [],
      chartEvents: {
        click: (e) => {
          if (e.targetID.startsWith('bar')) {
            // eslint-disable-next-line no-unused-vars
            const [name, stack, col] = e.targetID.split('#');
            const index = parseInt(col, 10);
            const monthIndex = index + 1;
            const [month] = this.chartData[monthIndex];
            const isExpiring = stack === '1';
            // this.$router.push({ name: 'contracts-ReportGpPerMonth',
            // query: { month, index, isExpiring } });
            this.$emit('click', { month, index, isExpiring });
          }
        },
      },
    };
  },
  watch: {
    selectedContractType() {
      this.$emit('selectedContractType', this.selectedContractType);
    },
    selectedStatus() {
      this.$emit('selectedStatus', this.selectedStatus);
    },
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    ...mapGetters('currencies', ['currencySymbol']),
    filteredData() {
      const filteredData = this.forecastData.map(({ month, expiringTotals, totals }) => {
      // Filter by selected contract types
        let expiring = expiringTotals;
        let total = totals;
        if (this.selectedContractType.length > 0) {
          expiring = expiring
            .filter((l) => this.selectedContractType.includes(l.lineType));
          total = total
            .filter((l) => this.selectedContractType.includes(l.lineType));
        }
        // Filter by selected statuses
        if (this.selectedStatus.length > 0) {
          expiring = expiring
            .filter((l) => this.selectedStatus.includes(l.status));
          total = total
            .filter((l) => this.selectedStatus.includes(l.status));
        }
        return {
          expiringTotals: expiring,
          totals: total,
          month,
        };
      });

      return filteredData;
    },
    chartData() {
      const { filteredData } = this;
      const rows = filteredData.map(({ month, expiringTotals, totals }) => [
        dayjs(month).format('MMM YY'),
        Math.round(totals.reduce((sum, { total }) => sum + total, 0)),
        Math.round(expiringTotals.reduce((sum, { total }) => sum + total, 0)),
      ]);
      const data = [['Month', 'Profit', 'Expiring Profit'], ...rows];
      return data;
    },
    contractTypes() {
      const { forecastData } = this;
      const lineTypes = [...new Set(forecastData.flatMap(({ expiringTotals, totals }) => [
        ...expiringTotals.map(({ lineType }) => lineType),
        ...totals.map(({ lineType }) => lineType),
      ]))];
      return lineTypes;
    },
    statuses() {
      const { forecastData } = this;
      const statuses = [...new Set(forecastData
        .flatMap(({ expiringTotals, totals }) => [
          ...expiringTotals.map(({ status }) => status),
          ...totals.map(({ status }) => status),
        ]).filter(Boolean))];
      return statuses;
    },
    maxValue() {
      const { chartData } = this;
      const profits = chartData
        .slice(1)
        .map((row) => row.slice(1).reduce((sum, value) => sum + value, 0));
      return Math.max(...profits) * 1.1;
    },
    chartOptions() {
      const { maxValue } = this;
      return {
        animation: {
          duration: 1000,
          easing: 'out',
        },
        chart: {
          title: 'Overview',
        },
        vAxis: {
          format: `${this.currencySymbol} #,###`, title: '', minValue: 0, maxValue,
        },
        hAxis: {
          minorGridlines:
        { minSpacing: 100 },
          title: '',
        },
        height: 400,
        colors: ['#1B99E8', '#F11A16'],
        legend: { position: 'top' },
        // isStacked: true,
        annotations: {
          alwaysOutside: true,
          stem: {
            length: 5,
          },
          textStyle: {
            // The color of the text.
            color: '#000000',
          },
        },
      };
    },
  },
  mounted() {
    this.selectedStatus = this.statuses;
    this.selectedContractType = this.contractTypes;
  },
};
</script>
