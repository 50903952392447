import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('contractItem', 'contractItems');

const customActions = {
  async LOAD_contractItemsByContractId({ commit, rootGetters }, contractId) {
    const data = await odata.o(rootGetters)
      .get('contractItems')
      .query({ $filter: `IsDeleted eq false AND ContractId eq ${contractId}` });
    commit('SET_contractItems', data);
    return data;
  },
};

export default { ...actions, ...customActions };
