<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title primary-title>
            Sales Target
          </v-card-title>
          <v-card-text>
            <v-skeleton-loader
              :loading="isLoading"
              transition="expand-transition"
              type="article"
            >
              <FormUserTarget
                :UserTarget="editUserTarget"
                :isSaving="isSaving"
                @save="save"
                @cancel="$router.push({name: from.name })" />
            </v-skeleton-loader>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormUserTarget from '../components/FormUserTarget.vue';

export default {
  components: {
    FormUserTarget,
  },
  name: 'EditUserTarget',
  module: 'Quote',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.targets',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing UserTarget. Default to {}.
      */
      editUserTarget: {},
      /**
       * Query Id for UserTarget
       */
      userTargetId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('userTargets', ['userTargets', 'userTarget']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.userTargetId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_userTarget(this.userTargetId);
      this.editUserTarget = this.userTarget;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('userTargets', ['LOAD_userTarget', 'CREATE_userTarget', 'UPDATE_userTarget']),
    /** Method used to save the item
     */
    async save(userTarget) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_userTarget(userTarget);
      } else {
        await this.UPDATE_userTarget(userTarget);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListUserTarget' });
    },
  },
};
</script>

<docs>
# EditUserTarget example

'''js
<EditUserTarget :UserTarget="UserTarget" />
'''
</docs>
