<template>
  <v-container fluid>
    <v-card>
      <v-card-title primary-title>
        Report Builder
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-select
            :items="dataSources"
            v-model="dataSource"
            item-text="name"
            item-value="value"
            label="Source"
            @change="() => { headers = []; }"
          ></v-select>
      </v-card-title>
      <v-card-text>
        <ServerDataTable
          :all-headers="allHeaders"
          :headers="headers"
          @update:headers="headers = $event"
          :expand="expand"
          :metadata="metadata"
          :entity-set="entitySet"
          class="elevation-1"
          :slots="[
            { name: 'created' },
            { name: 'actions' },
            { name: 'agreement.acceptedDate' },
            { name: 'agreement.cancellationDate' },
            { name: 'agreement.deliveredDate' },
            { name: 'agreement.expectedDeliveryDate' },
            { name: 'agreement.lastRenewalDate' },
            { name: 'agreement.endDate' },
            { name: 'agreement.originalEndDate' },
            { name: 'agreement.verified' },
            { name: 'endDate' },
            { name: 'originalEndDate' },
            { name: 'verified' },
            { name: 'acceptedDate' },
            { name: 'cancellationDate' },
            { name: 'deliveredDate' },
            { name: 'expectedDeliveryDate' },
            { name: 'lastRenewalDate' },
            { name: 'agreementTotal' },
            { name: 'agreementProfit' },
            { name: 'agreementNrcTotal' },
            { name: 'agreementNrcProfit' },
            { name: 'agreementMrcTotal' },
            { name: 'agreementMrcProfit' },
          ]"
        >
          <template v-slot:agreement.acceptedDate="{ item }">
            <span>{{ formatDate(item.agreement.acceptedDate) }}</span>
          </template>
          <template v-slot:agreement.cancellationDate="{ item }">
            <span>{{ formatDate(item.agreement.cancellationDate) }}</span>
          </template>
          <template v-slot:agreement.deliveredDate="{ item }">
            <span>{{ formatDate(item.agreement.deliveredDate) }}</span>
          </template>
          <template v-slot:agreement.expectedDeliveryDate="{ item }">
            <span>{{ formatDate(item.agreement.expectedDeliveryDate) }}</span>
          </template>
          <template v-slot:agreement.lastRenewalDate="{ item }">
            <span>{{ formatDate(item.agreement.lastRenewalDate) }}</span>
          </template>
          <template v-slot:acceptedDate="{ item }">
            <span>{{ formatDate(item.acceptedDate) }}</span>
          </template>
          <template v-slot:cancellationDate="{ item }">
            <span>{{ formatDate(item.cancellationDate) }}</span>
          </template>
          <template v-slot:deliveredDate="{ item }">
            <span>{{ formatDate(item.deliveredDate) }}</span>
          </template>
          <template v-slot:expectedDeliveryDate="{ item }">
            <span>{{ formatDate(item.expectedDeliveryDate) }}</span>
          </template>
          <template v-slot:lastRenewalDate="{ item }">
            <span>{{ formatDate(item.lastRenewalDate) }}</span>
          </template>
          <template v-slot:agreement.endDate="{ item }">
            <span>{{ formatDate(item.agreement.endDate) }}</span>
          </template>
          <template v-slot:agreement.originalEndDate="{ item }">
            <span>{{ formatDate(item.agreement.originalEndDate) }}</span>
          </template>
          <template v-slot:agreement.verified="{ item }">
            <span>{{ formatDate(item.agreement.verified) }}</span>
          </template>
          <template v-slot:endDate="{ item }">
            <span>{{ formatDate(item.endDate) }}</span>
          </template>
          <template v-slot:originalEndDate="{ item }">
            <span>{{ formatDate(item.originalEndDate) }}</span>
          </template>
          <template v-slot:verified="{ item }">
            <span>{{ formatDate(item.verified) }}</span>
          </template>
          <template v-slot:agreementTotal="{ item }">
            <span>{{ formatCurrency(getTotal(item.lines)) }}</span>
          </template>
          <template v-slot:agreementProfit="{ item }">
            <span>{{ formatCurrency(getProfit(item.lines)) }}</span>
          </template>
          <template v-slot:agreementNrcTotal="{ item }">
            <span>{{ formatCurrency(getNrcTotal(item.lines)) }}</span>
          </template>
          <template v-slot:agreementNrcProfit="{ item }">
            <span>{{ formatCurrency(getNrcProfit(item.lines)) }}</span>
          </template>
          <template v-slot:agreementMrcTotal="{ item }">
            <span>{{ formatCurrency(getMrcTotal(item.lines)) }}</span>
          </template>
          <template v-slot:agreementMrcProfit="{ item }">
            <span>{{ formatCurrency(getMrcProfit(item.lines)) }}</span>
          </template>
          <template v-slot:agreementNrcCost="{ item }">
            <span>{{ formatCurrency(getNrcCost(item.lines)) }}</span>
          </template>
      </ServerDataTable>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import ServerDataTable from '@/components/ServerDataTable.vue';

export default {
  name: 'ReportBuilder',
  components: {
    ServerDataTable,
  },
  data() {
    return {
      dataSource: 'agreements',
      dataSources: [
        {
          name: 'Agreements',
          value: 'agreements',
        },
        {
          name: 'Solutions',
          value: 'solutions',
        },
      ],
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('tables', [
      'allIncomeLineHeaders',
      'incomeLineMetadata',
      'allAgreementHeaders',
      'agreementMetadata',
      'solutionHeaders',
      'selectedHeaders',
      'solutionMetadata',
    ]),
    allHeaders() {
      if (this.dataSource === 'incomeLines') {
        return this.allIncomeLineHeaders;
      }
      if (this.dataSource === 'agreements') {
        return this.allAgreementHeaders;
      }
      if (this.dataSource === 'solutions') {
        return this.solutionHeaders;
      }
      return [];
    },
    entitySet() {
      if (this.dataSource === 'incomeLines') {
        return 'IncomeLines';
      }
      if (this.dataSource === 'agreements') {
        return 'Agreements';
      }
      if (this.dataSource === 'solutions') {
        return 'Solutions';
      }
      return undefined;
    },
    expand() {
      if (this.dataSource === 'incomeLines') {
        return 'Agreement($expand=Solution)';
      }
      if (this.dataSource === 'agreements') {
        return 'Solution,Lines($filter=IsDeleted eq false AND isActive eq true;$select=lineAmount,profit,lineType)';
      }
      return undefined;
    },

    metadata() {
      if (this.dataSource === 'incomeLines') {
        return this.incomeLineMetadata;
      }
      if (this.dataSource === 'agreements') {
        return this.agreementMetadata;
      }
      if (this.dataSource === 'solution') {
        return this.solutionMetadata;
      }
      return [];
    },

    /**
     * Computed property for the name vuex property
     */
    headers: {
      get() {
        return this.selectedHeaders;
      },
      set(newValue) {
        this.SET_selectedHeaders(newValue);
      },
    },
  },
  methods: {
    /** Vuex methods
     */
    ...mapMutations('tables', ['SET_selectedHeaders']),
    getProfit(lines) {
      return lines.reduce((acc, line) => acc + line.profit, 0);
    },
    getTotal(lines) {
      return lines.reduce((acc, line) => acc + line.lineAmount, 0);
    },
    getNrcTotal(lines) {
      return lines
        .filter((line) => line.lineType === 'Non-Recurring Cost')
        .reduce((acc, line) => acc + line.lineAmount, 0);
    },
    getNrcProfit(lines) {
      return lines
        .filter((line) => line.lineType === 'Non-Recurring Cost')
        .reduce((acc, line) => acc + line.profit, 0);
    },
    getMrcTotal(lines) {
      return lines
        .filter((line) => line.lineType === 'Monthly Recurring Cost')
        .reduce((acc, line) => acc + line.lineAmount, 0);
    },
    getMrcProfit(lines) {
      return lines
        .filter((line) => line.lineType === 'Monthly Recurring Cost')
        .reduce((acc, line) => acc + line.profit, 0);
    },
  },
};
</script>
