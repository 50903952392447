import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('sectionLineItem', 'sectionLineItems');

const customActions = {
  async CREATE_sectionLineItem({ rootGetters }, sectionLineItem) {
    const data = await odata.o(rootGetters)
      .post('sectionLineItems', sectionLineItem)
      .query();
    return data;
  },
  async LOAD_sectionLineItemsBySection({ commit, rootGetters }, sectionId) {
    const data = await odata.o(rootGetters)
      .get('sectionLineItems')
      .query({
        $filter: `IsDeleted eq false AND QuoteSectionId eq ${sectionId}`,
        $orderby: 'LineNumber',
      });
    commit('SET_sectionLineItems', data);
    return data;
  },
  async LOAD_allQuoteSectionLines({ rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('sectionLineItems')
      .query({
        $filter: `IsDeleted eq false AND QuoteSection/QuoteId eq ${quoteId} AND QuoteSection/IsDeleted eq false`,
        $orderby: 'LineNumber',
      });
    return data;
  },
  async LOAD_allInActiveQuoteSectionLines({ rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('sectionLineItems')
      .query({
        $filter: `IsDeleted eq false AND QuoteSection/QuoteId eq ${quoteId} AND IsActive eq false`,
        $orderby: 'LineNumber',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
