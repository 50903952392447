import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('comment', 'comments');

const customActions = {
  async LOAD_comments({ commit, rootGetters }, agreementId) {
    commit('SET_comments', []);
    const data = await odata.o(rootGetters)
      .get('comments')
      .query({
        $filter: `IsDeleted eq false AND AgreementId eq ${agreementId}`,
        $orderby: 'Created desc',
      });
    commit('SET_comments', data);
    return data;
  },
  async LOAD_solutionComments({ commit, rootGetters }, solutionId) {
    commit('SET_solutionComments', []);
    const data = await odata.o(rootGetters)
      .get('comments')
      .query({
        $filter: `IsDeleted eq false AND SolutionId eq ${solutionId}`,
        $orderby: 'Created desc',
      });
    commit('SET_solutionComments', data);
    return data;
  },
};

export default { ...actions, ...customActions };
