var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.suppliers,"loading":_vm.isLoading,"search":_vm.search,"items-per-page":50,"footer-props":{
          'items-per-page-options': [50, 100, 200, 500, -1]
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"color":"success","data-cy":"Supplier-button-new"},on:{"click":function($event){return _vm.$router.push({
                name: 'quotes-EditSupplier'
              })}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.buttons.new'))+" ")],1),_c('div',{staticClass:"text-h5 ml-2"},[_vm._v(" Suppliers ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('FilterMenu',[_c('v-checkbox',{attrs:{"label":"Include Archived"},on:{"change":_vm.refresh},model:{value:(_vm.includeArchived),callback:function ($$v) {_vm.includeArchived=$$v},expression:"includeArchived"}})],1)],1)]},proxy:true},{key:"item.name",fn:function({ item }){return [_c('router-link',{staticClass:"black--text",attrs:{"to":{
              name: 'quotes-EditSupplier',
              query: {
                id: item.id
              }
            }}},[_vm._v(" "+_vm._s(item.name)+" ")]),(!item.isActive)?_c('v-icon',{staticClass:"ml-2",attrs:{"title":"Unarchive","color":"error"},on:{"click":function($event){return _vm.unarchive(item)}}},[_vm._v(" "+_vm._s(_vm.mdiArchive)+" ")]):_vm._e()]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn-toggle',[_c('v-btn',{attrs:{"color":"default","small":"","to":{
              name: 'quotes-EditSupplier',
              query: {id: item.id}
            }}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.edit'))+" ")]),_c('v-btn',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.buttons.delete'))+" ")]),(item.isActive)?_c('v-btn',{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.archive(item)}}},[_vm._v("Archive")]):_vm._e()],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }