<template>
  <v-form
    lazy-validation
    v-model="valid"
    @submit.prevent="submit"
    ref="form"
  >
    <v-row dense class="text-center">
      <v-col cols="7">
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              color="primary"
              slider-color="primary"
            >
              <v-tab>
                Information
              </v-tab>
              <v-tab>
                Additional Information
              </v-tab>
              <v-tab>
                Addresses
              </v-tab>
              <v-tab-item>
                <v-row class="mt-5 ml-2 mr-2 mb-5">
                  <v-col cols="12" md="6">
                    <v-text-field
                      filled
                      data-cy="FormSupplier-name"
                      :label="$$t('name')"
                      v-model="localSupplier.name"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field filled data-cy="FormSupplier-legalName" :label="$$t('legalName')" v-model="localSupplier.legalName" />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      filled
                      data-cy="FormSupplier-vatNumber"
                      :label="$$t('vatNumber')"
                      v-model="localSupplier.vatNumber"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      filled
                      data-cy="FormSupplier-telephone"
                      :label="$$t('telephone')"
                      v-model="localSupplier.telephone"
                    />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      filled
                      data-cy="FormSupplier-email"
                      :label="$$t('email')"
                      v-model="localSupplier.email"
                    />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
              <v-row class="mt-5 ml-2 mr-2">
                <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-adminTechnicalContactName"
                      filled
                      :label="$$t('adminTechnicalContactName')"
                      v-model="localSupplier.adminTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-adminTechnicalContactJobTitle"
                      filled
                      :label="$$t('adminTechnicalContactJobTitle')"
                      v-model="localSupplier.adminTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-adminTechnicalContactEmailAddress"
                      filled
                      :label="$$t('adminTechnicalContactEmailAddress')"
                      v-model="localSupplier.adminTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-adminTechnicalContactMobileNumber"
                      filled
                      :label="$$t('adminTechnicalContactMobileNumber')"
                      v-model="localSupplier.adminTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-adminTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('adminTechnicalContactPhoneNumber')"
                      v-model="localSupplier.adminTechnicalContactPhoneNumber" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-firstTechnicalContactName"
                      filled
                      :label="$$t('firstTechnicalContactName')"
                      v-model="localSupplier.firstTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-firstTechnicalContactJobTitle"
                      filled
                      :label="$$t('firstTechnicalContactJobTitle')"
                      v-model="localSupplier.firstTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-firstTechnicalContactEmailAddress"
                      filled
                      :label="$$t('firstTechnicalContactEmailAddress')"
                      v-model="localSupplier.firstTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-firstTechnicalContactMobileNumber"
                      filled
                      :label="$$t('firstTechnicalContactMobileNumber')"
                      v-model="localSupplier.firstTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-firstTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('firstTechnicalContactPhoneNumber')"
                      v-model="localSupplier.firstTechnicalContactPhoneNumber" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-secondTechnicalContactName"
                      filled
                      :label="$$t('secondTechnicalContactName')"
                      v-model="localSupplier.secondTechnicalContactName" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      data-cy="FormSupplier-secondTechnicalContactJobTitle"
                      filled
                      :label="$$t('secondTechnicalContactJobTitle')"
                      v-model="localSupplier.secondTechnicalContactJobTitle" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-secondTechnicalContactEmailAddress"
                      filled
                      :label="$$t('secondTechnicalContactEmailAddress')"
                      v-model="localSupplier.secondTechnicalContactEmailAddress" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-secondTechnicalContactMobileNumber"
                      filled
                      :label="$$t('secondTechnicalContactMobileNumber')"
                      v-model="localSupplier.secondTechnicalContactMobileNumber" />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      data-cy="FormSupplier-secondTechnicalContactPhoneNumber"
                      filled
                      :label="$$t('secondTechnicalContactPhoneNumber')"
                      v-model="localSupplier.secondTechnicalContactPhoneNumber" />
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <Addresses v-if="localSupplier.id" :supplier-id="localSupplier.id" />
              </v-tab-item>
            </v-tabs>
          </v-col>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn color="default" outlined class="ml-4 btn-cancel" @click="cancel" data-cy="FormSupplier-button-cancel">
              {{ $t('common.buttons.back') }}
            </v-btn>
            <v-btn color="primary" type="submit" class="ml-4" :loading="isSaving" data-cy="FormSupplier-button-save">
              {{ $t('common.buttons.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5" v-if="localSupplier.id">
        <SupplierAgreements :supplier-id="localSupplier.id" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import Addresses from './Addresses.vue';
import SupplierAgreements from '../../../contracts/src/components/SupplierAgreements.vue';

export default {
  name: 'FormSupplier',
  module: 'Supplier',
  components: {
    Addresses,
    SupplierAgreements,
  },
  mixins: [loading],
  props: {
    /**
    * Set the text box value. Default: undefined,
    */
    Supplier: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      /**
      * Hold form validation state
      */
      valid: false,
      /**
      * Local value used for property localSupplier
      */
      localSupplier: {},
      tab: 0,
    };
  },
  watch: {
    Supplier() {
      this.localSupplier = this.Supplier;
    },
  },
  created() {
    this.localSupplier = this.Supplier;
  },
  methods: {
    /** Submit Form data trigger
     * @event save
     * @type {object}
     */
    submit() {
      // validate
      if (this.$refs.form.validate()) {
        this.$emit('save', this.localSupplier);
        this.isSaving = true;
      }
    },
    /** Cancel form trigger
     * @event cancel
     */
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<docs>
# FormSupplier example

'''js
<FormSupplier :Supplier="Supplier" />
'''
</docs>
