<template>
  <v-dialog :value="dialog" fullscreen>
    <v-card>
      <v-card-title>
        Audit Logs
        <v-spacer></v-spacer>
        <v-checkbox v-model="showVerbose" label="Verbose" />
        <v-spacer></v-spacer>
        <v-btn color="primary" icon @click="$emit('close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text style="height: 100%;">
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="auditLogs"
          sort-by="updated"
          :sort-desc="true"
        >
          <template v-slot:item.updated="{ item }">
            {{ formatDateTime(item.updated) }}
          </template>
      </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="$emit('close')">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiClipboardTextClockOutline } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'AuditDialog',
  mixins: [loading],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    entityType: {
      type: String,
    },
    entityId: {
      type: String,
      required: true, // Entity ID is required for filtering the logs
    },
  },
  data: () => ({
    /** Icons
     */
    mdiClose,
    mdiClipboardTextClockOutline,
    loading: false, // Flag to track if logs are currently being fetched
    auditLogs: [], // Array to store the fetched logs
    showVerbose: false, // Flag to show verbose logs
    /** Headers for data table
     */
    headers: [
      { text: 'Name', value: 'propertyName' },
      { text: 'Original Value', value: 'originalValue' },
      { text: 'Current Value', value: 'currentValue' },
      { text: 'Updated', value: 'updated' },
      { text: 'Updated By', value: 'updatedBy' },
    ],
  }),
  watch: {
    dialog: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.getAuditLogs();
        }
      },
    },
    showVerbose: {
      immediate: true,
      handler() {
        this.getAuditLogs();
      },
    },
  },
  methods: {
    async getAuditLogs() {
      this.isLoading = true;
      try {
        let url = `/odata/auditLogs?$filter=EntityId eq '${this.entityId}'`;
        if (this.entityType) {
          url += ` AND EntityType eq '${this.entityType}'`;
        }
        if (!this.showVerbose) {
          url += '  AND originalValue ne currentValue';
        }
        url += '&$orderby=Updated desc';
        const { data } = await this.$http.get(url);
        this.auditLogs = data.value;
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.isLoading = false;
      }
    },

  },

};

</script>
