import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('person', 'people');

const customActions = {
  async LOAD_people({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('people')
      .query({ $filter: 'IsDeleted eq false' });
    commit('SET_people', data);
    return data;
  },
  async LOAD_clientPeople({ rootGetters }, clientId) {
    const data = await odata.o(rootGetters)
      .get('people')
      .query({
        $filter: `IsDeleted eq false AND ClientId eq ${clientId}`,
        $orderby: 'Created desc',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
