<!-- eslint-disable max-lines -->
<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="filteredItems"
      class="elevation-1"
      :loading="isLoading"
      :items-per-page="-1"
      dense
      :search="search"
      sort-by="solutionNumber"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-row>
          <v-col cols="4">
            <v-btn
              color="success"
              class="ml-2"
              data-cy="Solution-button-new"
              @click="$router.push({
                name: 'solutions-EditSolution'
              })"
            >
              <v-icon class="mr-2">
                {{ mdiPlus }}
              </v-icon>
              {{ $$t('new') }}
            </v-btn>
          </v-col>
          <v-col class="text-right" cols="8">
            <v-autocomplete
              class="d-inline-block mr-2"
              item-text="displayName"
              item-value="createdById"
              :items="users"
              v-model="selectedUser"
              label="Filter by Owner"
              auto-select-first
              clearable
            />
            <v-text-field
              clearable
              class="mr-2 d-inline-block"
              v-model="search"
              :append-icon="mdiMagnify"
              :label="$t('common.search')"
              single-line
              hide-details
            />
            <v-btn small color="secondary" @click="refresh">
              <v-icon>{{ mdiRefresh }}</v-icon>
            </v-btn>
            <ExportToExcel small name="Solutions" :data="localData" />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn small class="ml-2 mr-2" color="secondary" v-bind="attrs" v-on="on">
                  <v-icon>{{ mdiFilter }}</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Filter</v-card-title>
                <v-card-text>
                  <v-checkbox
                    v-model="showArchived"
                    label="Show Archived"
                    @change="refresh"
                  ></v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td colspan="3"></td>
          <td class="text-right" colspan="2"></td>
          <td class="text-right">
            <div class="d-block"><strong>Once Off Profit</strong></div>
            {{ formatCurrency(totalNonRecurringProfit, selectedCurrency) }}
          </td>
          <td class="text-right">
            <div class="d-block"><strong>Monthly Profit</strong></div>
            {{ formatCurrency(totalProfit, selectedCurrency) }}
          </td>
          <td />
        </tr>
      </template>
      <template v-slot:body.append>
        <tr>
          <td colspan="5"></td>
          <td class="text-right">
            {{ formatCurrency(totalNonRecurringProfit, selectedCurrency) }}
            <div class="d-block"><strong>Once Off Profit</strong></div>
          </td>
          <td class="text-right">
            {{ formatCurrency(totalProfit, selectedCurrency) }}
            <div class="d-block"><strong>Monthly Profit</strong></div>
          </td>
          <td />
        </tr>
      </template>
      <template v-slot:item.created="{ item }">
        {{ formatDate(item.created) }}
      </template>
      <template v-slot:item.solutionNumber="{ item }">
        <div class="d-flex">
          <router-link :to="{ name: 'solutions-EditSolution', query: { id: item.id } }">
            {{ item.solutionNumber }}
          </router-link>
          <v-icon small v-if="!item.isActive" class="ml-2" @click="saveIsActive(item, true)" title="Unarchive"
            color="error"
          >
            {{ mdiArchive }}
          </v-icon>
        </div>
      </template>
      <template v-slot:item.totalRecurringProfit="{ item }">
        <span class="d-block text-right">
          {{ formatCurrency(item.totalRecurringProfit, selectedCurrency) }}
        </span>
      </template>
      <template v-slot:item.totalNonRecurringProfit="{ item }">
        <span class="d-block text-right">
          {{ formatCurrency(item.totalNonRecurringProfit, selectedCurrency) }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" text v-on="on">
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list class="text-left">
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  text
                  block
                  color="secondary"
                  dark
                  :to="{
                    name: 'solutions-EditSolution',
                    query: {
                      id: item.id,
                    },
                  }"
                >
                  Edit
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!item.readOnly">
              <v-list-item-content>
                <v-btn
                  text
                  block
                  color="secondary"
                  dark
                  @click="remove(item)"
                >
                  Delete
                </v-btn>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  text
                  block
                  color="secondary"
                  dark
                  @click="saveIsActive(item, !item.isActive)"
                >
                  {{ item.isActive ? 'Archive' : 'Unarchive' }}
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import {
  mdiDotsVertical, mdiPlus, mdiMagnify, mdiRefresh, mdiFilter, mdiArchive,
} from '@mdi/js';
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import currencies from '~src/mixins/currencies';

export default {
  name: 'ListSolution',
  module: 'Solutions',
  mixins: [currencies, loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
        disabled: false,
      },
      {
        text: 'menu.solutions',
        disabled: true,
      },
      ],
  },
  data() {
    return {
      /**
      * Icons
      */
      mdiArchive,
      mdiDotsVertical,
      mdiPlus,
      mdiMagnify,
      mdiRefresh,
      mdiFilter,
      /**
       * Data
       */
      localData: [],
      showArchived: false,
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('currencies', ['selectedCurrency']),
    ...mapState('users', ['users']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: this.$$t('solutionNumber'),
          value: 'solutionNumber',
          width: this.showArchived ? '150px' : '120px',
        },
        {
          text: this.$$t('contactName'),
          value: 'contact',
        },
        {
          text: this.$$t('name'),
          value: 'name',
        },
        {
          text: this.$$t('owner'),
          value: 'owner',
          width: '150px',
        },
        {
          text: this.$$t('created'),
          value: 'created',
          width: '120px',
        },
        {
          text: '',
          value: 'totalNonRecurringProfit',
          width: '140px',
        },
        {
          text: '',
          value: 'totalRecurringProfit',
          width: '140px',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '100px',
        },
      ];
    },
    filteredItems() {
      if (this.selectedUser) {
        return this.localData.filter((item) => item.ownerId === this.selectedUser);
      }
      return this.localData;
    },
    totalProfit() {
      return this.localData.reduce((total, item) => total + item.totalRecurringProfit, 0);
    },
    totalNonRecurringProfit() {
      return this.localData.reduce((total, item) => total + item.totalNonRecurringProfit, 0);
    },
    selectedUser: {
      get() {
        return this.$store.state.app.selectedUser;
      },
      set(value) {
        this.$store.commit('app/SET_selectedUser', value);
      },
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.refresh();
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('solutions', ['DELETE_solution', 'LOAD_solutionExpanded', 'UPDATE_solution']),
    ...mapActions('users', ['LOAD_users']),
    /** Delete Solution based on the selected currency
     */
    async refresh() {
      this.$log.debug('Refresh solutions');
      this.isLoading = true;
      await this.LOAD_users();
      // eslint-disable-next-line max-len
      let url = `odata/solutions/getActiveSolutions(currencyCode='${this.selectedCurrency}',IsActive=null)`;
      if (!this.showArchived) {
        url = `odata/solutions/getActiveSolutions(currencyCode='${this.selectedCurrency}',IsActive=true)`;
      }
      const { data } = await this.$http.get(url);
      this.localData = data.value;
      this.isLoading = false;
    },
    /**
     * Archive Solution
     */
    async saveIsActive(item, isActive) {
      // eslint-disable-next-line max-len
      const result = await this.$confirm(`Are you sure you want to ${!isActive ? 'archive' : 'unarchive'} this solution?`);
      if (!result) {
        return;
      }
      await this.UPDATE_solution({
        id: item.id,
        isActive,
      });
      await this.refresh();
    },
    /** Delete Solution from the collection
     */
    async remove(solution) {
      this.isLoading = true;
      const expandedSolution = await this.LOAD_solutionExpanded(solution.id);
      this.isLoading = true;
      const agreements = expandedSolution.agreements.map((a) => a.reference);
      let msg = 'Are you sure you want to delete this solution? It has no agreements attached.';
      if (agreements.length > 0) {
        // eslint-disable-next-line max-len
        msg = `<b>This will delete the following Agreements:</b><br>${agreements.join('<br>')}`;
      }
      const result = await this.$confirm(msg, {
        title: 'Delete solution',
      });
      if (result) {
        this.isLoading = true;
        await this.DELETE_solution(solution.id);
        this.isLoading = false;
        await this.refresh();
      }
    },
  },
};
</script>

<docs>
# Solution example

'''js
<Solution />
'''
</docs>
