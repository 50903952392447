// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1C1C65',
        secondary: '#1B99E8',
        success: '#1daa89',
        warning: '#ff9900',
        error: '#F11A16',
      },
      dark: {
        primary: '#1C1C65',
        secondary: '#1B99E8',
        success: '#1daa89',
        warning: '#ff9900',
        error: '#F11A16',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
