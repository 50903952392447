<template>
  <div>
    <GChart
      :data="chartData"
      :type="'BarChart'"
      :options="chartOptions"
      :events="chartEvents"
    />
    <GChart
      v-if="detail"
      :data="chartDetailData"
      :type="'BarChart'"
      :options="chartDetailOptions"
      :events="chartEvents"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';

export default {
  name: 'GraphTopFeatures',
  components: {
    GChart,
  },
  data() {
    return {
      chartEvents: {
        click: (e) => {
          switch (this.getIdFromGraph(e.targetID)) {
            default:
            case 0:
              this.detail = {
                name: 'Contracts',
                period: 'current',
              };
              break;
            case 1:
              this.detail = {
                name: 'Contracts',
                period: 'previous',
              };
              break;
            case 2:
              this.detail = {
                name: 'Quotes',
                period: 'current',
              };
              break;
            case 3:
              this.detail = {
                name: 'Quotes',
                period: 'previous',
              };
              break;
            case 4:
              this.detail = {
                name: 'Solutions',
                period: 'current',
              };
              break;
            case 5:
              this.detail = {
                name: 'Solutions',
                period: 'previous',
              };
              break;
          }
        },
      },
      chartOptions: {
        title: 'Top Features',
        chartArea: { width: '65%' },
        hAxis: {
          title: 'Interactions',
          minValue: 0,
        },
        vAxis: {
          title: 'Feature',
        },
        width: '100%',
        height: 600,
        isStacked: true,
      },
      detail: undefined,
    };
  },
  computed: {
    ...mapGetters('telemetry', ['features', 'prevFeatures']),
    chartData() {
      const { features, prevFeatures } = this;
      const heading = [['Feature', 'Mounted', 'Input', 'Click']];
      const currentData = this.getFeatureData(features);
      let prevData = this.getFeatureData(prevFeatures);
      prevData = prevData.map((r) => [`${r[0]} - Previous`, r[1], r[2], r[3]]) ?? [];

      const data = [...currentData, ...prevData];

      data.sort((a, b) => a[0].localeCompare(b[0]));

      return [...heading, ...data];
    },
    chartDetailData() {
      const { features, prevFeatures } = this;
      const heading = [['Component', 'Mounted', 'Input', 'Click']];
      let data = [];
      if (this.detail.period === 'current') {
        data = this.getFeatureDetailData(features.filter((f) => f.name === this.detail.name));
      } else {
        data = this.getFeatureDetailData(prevFeatures.filter((f) => f.name === this.detail.name));
      }

      data.sort((a, b) => a[0]?.localeCompare(b[0]));

      return [...heading, ...data];
    },
    chartDetailOptions() {
      return {
        title: 'Top Components',
        chartArea: { width: '65%' },
        hAxis: {
          title: 'Interactions',
          minValue: 0,
        },
        vAxis: {
          title: `${this.detail.name} - ${this.detail.period}`,
        },
        width: '100%',
        height: 600,
        isStacked: true,
      };
    },
  },
  methods: {
    getFeatureData(features) {
      const featureNames = [...new Set(features.map((f) => f.name))];

      const data = featureNames.map((f) => {
        const mounted = features.filter((item) => item.name === f && item.trigger === 'mounted').length;
        const input = features.filter((item) => item.name === f && item.trigger === 'input').length;
        const click = features.filter((item) => item.name === f && item.trigger === 'click').length;
        return [f, mounted, input, click];
      });
      return data;
    },
    getFeatureDetailData(features) {
      const componentNames = [...new Set(features.map((f) => f.element))];

      const data = componentNames.map((f) => {
        const mounted = features.filter((item) => item.element === f && item.trigger === 'mounted').length;
        const input = features.filter((item) => item.element === f && item.trigger === 'input').length;
        const click = features.filter((item) => item.element === f && item.trigger === 'click').length;
        return [f, mounted, input, click];
      });
      return data;
    },
    getIdFromGraph(str) {
      const regex = /\d+$/; // Matches one or more digits at the end of the string
      const match = str.match(regex);

      if (match) {
        return parseInt(match[0].slice(-1), 10);
      }

      return null; // Return null if no digit is found
    },
  },
};
</script>
