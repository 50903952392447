<template>
  <div>
    <v-text-field
      filled
      dense
      class="mt-5"
      label="Invoice"
      v-if="invoice"
      :value="invoice.invoiceNumber"
    ></v-text-field>
    <v-btn
      color="primary"
      @click="createInvoice"
      :loading="isSaving"
      :disabled="disabled"
      :title="title"
      v-else
    >
      {{ $$t(buttonText) }}
    </v-btn>
  </div>
</template>

<script>
import { mdiPencil } from '@mdi/js';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'ContractSectionInvoice',
  module: 'Contract',
  mixins: [loading],
  props: {
    contract: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invoiceID: {
      type: String,
      default: null,
    },
    sectionId: {
      type: String,
      default: null,
    },
    contractType: {
      type: String,
      default: 'ACCREC',
    },
    lineItems: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'single',
    },
  },
  data() {
    return {
      /**
       * Icons
       */
      mdiPencil,
      invoice: null,
      createInvoiceMenuOpen: false,
    };
  },
  computed: {
    buttonText() {
      return this.contractType === 'ACCPAY' || this.contractType === 'OPEX'
        ? 'createBill'
        : 'createInvoice';
    },
    title() {
      return this.disabled ? this.$$t('contractNotAccepted') : '';
    },
    url() {
      if (this.type === 'repeating') {
        return `/odata/xeroRepeatingInvoices(${this.invoiceID})`;
      }
      return `/odata/xeroInvoices/${this.invoiceID}`;
    },
  },
  watch: {
    async invoiceID() {
      await this.loadInvoice();
    },
  },
  async mounted() {
    await this.loadInvoice();
  },
  methods: {
    async loadInvoice() {
      try {
        if (this.invoiceID) {
          const { data } = await this.$http.get(this.url);
          this.invoice = data;
        }
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', this.$$t('errorLoadingInvoice'));
      }
    },
    async createInvoice() {
      try {
        const missingSuppliers = this.lineItems.filter((item) => !item.supplierId);
        if (missingSuppliers.length) {
          const proceed = await this.$confirm();
          if (!proceed) {
            return;
          }
        }
        // Check if the contract's status is set to "Accepted"
        if (this.contract.status === 'Accepted'
          || this.contract.status === 'Delivered'
          || this.contract.status === 'In Progress') {
          this.isSaving = true;
          const { data } = await this.$http.post('/api/contracts/invoice/section', {
            sectionId: this.sectionId,
          });
          this.invoice = data;
        } else {
          await this.$confirm(this.$$t('acceptContractMessage'), {
            buttonTrueText: '',
            buttonFalseText: 'Ok',
            color: 'warning',
            icon: 'warning',
          });
        }
      } catch (error) {
        this.$log.error(error);
        this.$root.$emit('toast:error', this.$$t('errorCreatingInvoice'));
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>
