<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormServiceItem
      :ServiceItem="editServiceItem"
      :isSaving="isSaving"
      @save="save"
      @cancel="$router.push({name: from.name })" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormServiceItem from '../components/FormServiceItem.vue';

export default {
  components: {
    FormServiceItem,
  },
  name: 'EditServiceItem',
  module: 'ServiceItem',
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing ServiceItem. Default to {}.
      */
      editServiceItem: {},
      /**
       * Query Id for ServiceItem
       */
      serviceItemId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('serviceItems', ['serviceItems', 'serviceItem']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.serviceItemId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_priceLists();
    if (this.mode === 'edit') {
      await this.LOAD_serviceItem(this.serviceItemId);
      this.editServiceItem = this.serviceItem;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('serviceItems', ['LOAD_serviceItem', 'CREATE_serviceItem', 'UPDATE_serviceItem']),
    ...mapActions('priceLists', ['LOAD_priceLists']),
    /** Method used to save the item
     */
    async save(serviceItem) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_serviceItem(serviceItem);
      } else {
        await this.UPDATE_serviceItem(serviceItem);
      }
      this.isSaving = false;
      this.$router.push({ name: 'quotes-ListServiceItem' });
    },
  },
};
</script>

<docs>
# EditServiceItem example

'''js
<EditServiceItem :ServiceItem="ServiceItem" />
'''
</docs>
