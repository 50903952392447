import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('exchangeRate', 'exchangeRates');

const customActions = {
  async LOAD_exchangeRatesForQuote({ commit, rootGetters }, quoteId) {
    const data = await odata.o(rootGetters)
      .get('exchangeRates')
      .query({ $filter: `IsDeleted eq false AND QuoteId eq ${quoteId}` });
    commit('SET_exchangeRates', data);
    return data;
  },
  async LOAD_exchangeRatesForAgreement({ commit, rootGetters }, agreementId) {
    commit('SET_exchangeRates', []);
    const data = await odata.o(rootGetters)
      .get('exchangeRates')
      .query({ $filter: `IsDeleted eq false AND AgreementId eq ${agreementId}` });
    commit('SET_exchangeRates', data);
    return data;
  },
};

export default { ...actions, ...customActions };
