<template>
  <v-skeleton-loader
    :loading="isLoading"
    transition="expand-transition"
    type="article"
  >
    <FormPerson
      :Person="editPerson"
      :isSaving="isSaving"
      @save="save"
      @cancel="cancel" />
  </v-skeleton-loader>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormPerson from '../components/FormPerson.vue';

export default {
  components: {
    FormPerson,
  },
  name: 'EditPerson',
  module: 'Person',
  meta: {
    redirectOnRefresh: { name: 'quotes-ListPerson' },
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.contacts',
        to: { name: 'app-Contacts' },
        disabled: false,
      },
      {
        text: 'menu.people',
        to: { name: 'quotes-ListPerson' },
        disabled: true,
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  mixins: [loading],
  data() {
    return {
      /**
      * Get/Set editing Person. Default to {}.
      */
      editPerson: {
        personType: 'Client Contact',
      },
      /**
       * Query Id for Person
       */
      personId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('people', ['people', 'person']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.personId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.LOAD_clients({ includeArchived: false });
    if (this.$route.query.clientId) {
      this.editPerson.clientId = this.$route.query.clientId;
    }
    if (this.mode === 'edit') {
      await this.LOAD_person(this.personId);
      this.editPerson = this.person;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('people', ['LOAD_person', 'CREATE_person', 'UPDATE_person']),
    ...mapActions('clients', ['LOAD_clients']),
    /** Method used to save the item
     */
    async save(person) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_person(person);
      } else {
        await this.UPDATE_person(person);
      }
      this.isSaving = false;
      if (this.$route.query.clientId) {
        this.$router.push({ name: 'quotes-ViewClient', query: { clientId: this.$route.query.clientId } });
      } else {
        this.$router.push({ name: 'quotes-ListPerson' });
      }
    },
    cancel() {
      if (this.$route.query.clientId) {
        this.$router.push({ name: 'quotes-ViewClient', query: { clientId: this.$route.query.clientId } });
      } else {
        this.$router.push({ name: 'quotes-ListPerson' });
      }
    },
  },
};
</script>

<docs>
# EditPerson example

'''js
<EditPerson :Person="Person" />
'''
</docs>
