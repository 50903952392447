import { sortBy, uniq } from 'lodash';

export default {
  getQuote: (state) => state.quote,
  activeQuotes: (state) => state.activeQuotes,
  quoteUsers: (state) => sortBy(uniq(state.quotes.map((q) => ({
    text: q.displayName ?? q.createdBy,
    value: q.createdBy,
  }))), 'text'),
};
