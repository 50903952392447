<template>
  <v-dialog v-model="columnDialog" max-width="400">
    <template #activator="{ on }">
      <v-btn class="mt-2" icon title="Select your Columns" v-on="on">
        <v-icon>{{ mdiViewColumn }}</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title color="accent">
        <span class="grey--text">Columns</span>
      </v-card-title>
      <v-card-text>
        <draggable v-model="localColumns" :options="{ handle: '.drag-handle' }" @end="saveColumnOrder">
          <template slot="header" class="d-flex">
            <v-autocomplete
              v-model="localColumns"
              label="Add column"
              :items="allHeaders"
              multiple
              return-object
            ></v-autocomplete>
          </template>
          <div v-for="column in localColumns" :id="column.value" :key="column.text" class="d-flex border mb-2">
            <v-icon class="drag-handle">
              {{ mdiMenu }}
            </v-icon>
            <div class="text-h6 mt-1 ml-1">
              {{ column.text }}
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="localColumns.splice(localColumns.indexOf(column), 1)">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </div>
        </draggable>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="columnDialog = false">
          Done
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiViewColumn, mdiClose, mdiMenu } from '@mdi/js';
import draggable from 'vuedraggable';

export default {
  name: 'ColumnDialog',
  components: {
    draggable,
  },
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      mdiClose,
      mdiMenu,
      mdiViewColumn,
      columnDialog: false,
      localColumns: this.headers,
    };
  },
  watch: {
    localColumns: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.$emit('update:headers', newValue);
      },
    },
    headers: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.localColumns = newValue;
      },
    },
  },
  methods: {
    saveColumnOrder() {
      this.$emit('update:headers', this.localColumns);
    },
  },
};
</script>
