<template>
  <v-data-table
    :headers="headers"
    :items="localItems.filter(l => !l.isDeleted)"
    class="elevation-1"
    :items-per-page="-1"
    hide-default-footer
  >
    <template v-slot:item.description="{ item, index }">
      <v-text-field
        :data-cy="`FormContractItem-description-${index}`"
        v-model="item.description"
      />
    </template>
    <template v-slot:item.start="{ item, index }">
      <DatePickerSmall
        :data-cy="`FormContractItem-start-${index}`"
        v-model="item.start"
        label=""
        :id="`FormContractItem-start-${index}-datepicker`"
      />
    </template>
    <template v-slot:item.end="{ item, index }">
      <DatePickerSmall
        :data-cy="`FormContractItem-end-${index}`"
        v-model="item.end"
        label=""
        :id="`FormContractItem-end-${index}-datepicker`"
      />
    </template>
    <template v-slot:item.quantity="{ item, index }">
      <v-text-field
        :data-cy="`FormContractItem-quantity-${index}`"
        v-model="item.quantity"
        type="number"
      />
    </template>
    <template v-slot:item.total="{ item, index }">
      <span :data-cy="`FormContractItem-total-${index}`">
        {{ formatCurrency(getTotal(item), currency) }}
      </span>
    </template>
    <template v-slot:item.amount="{ item, index }">
      <v-text-field
        :data-cy="`FormContractItem-amount-${index}`"
        v-model="item.amount"
        type="number"
      >
      </v-text-field>
    </template>
    <template v-slot:item.revenueLineId="{ item, index }">
      <v-autocomplete
        auto-select-first
        :data-cy="`FormContractItem-revenueLineId-${index}`"
        v-model="item.revenueLineId"
        :items="revenueLines"
        item-text="name"
        item-value="id"
        @input="revenueLineChanged(item, index)"
      >
        <template v-slot:append-outer>
          <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                small
              >
                <v-icon>{{ mdiNotebook }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card>
                <v-toolbar
                  color="primary"
                  dark
                >Accounts</v-toolbar>
                <v-card-text>
                  <v-row class="mt-2">
                    <v-col cols="6">
                      <v-autocomplete
                        auto-select-first
                        :data-cy="`FormContractItem-accountId-${index}`"
                        v-model="item.accountId"
                        :items="contractAccounts"
                        item-text="name"
                        item-value="id"
                        label="Account"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        auto-select-first
                        :data-cy="`FormContractItem-bankAccountId-${index}`"
                        v-model="item.bankAccountId"
                        :items="bankAccounts"
                        item-text="name"
                        item-value="id"
                        label="Bank account"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    text
                    @click="dialog.value = false"
                  >Ok</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template>
      </v-autocomplete>
    </template>
    <template v-slot:item.bankAccountId="{ item, index }">
      <v-autocomplete
        auto-select-first
        :data-cy="`FormContractItem-bankAccountId-${index}`"
        v-model="item.bankAccountId"
        :items="bankAccounts"
        item-text="name"
        item-value="id"
      />
    </template>
    <template v-slot:item.accountId="{ item, index }">
      <v-autocomplete
        auto-select-first
        :data-cy="`FormContractItem-accountId-${index}`"
        v-model="item.accountId"
        :items="contractAccounts"
        item-text="name"
        item-value="id"
      />
    </template>
    <template v-slot:item.actions="{ index }">
      <v-btn small color="error" @click="$emit('remove', index)">
        <v-icon>
          {{ mdiDelete }}
        </v-icon>
      </v-btn>
    </template>
    <template v-slot:body.append>
      <tr>
        <td colspan="4"></td>
        <td>Total:</td>
        <td>{{ formatCurrency(totals.total, currency) }}</td>
        <td colspan="2"></td>
      </tr>
    </template>
    <template v-slot:footer>
      <v-row>
        <v-col cols="12">
          <v-btn
            data-cy="FormContractItem-button-add"
            class="float-right mr-2"
            color="primary"
            @click="$emit('add')"
            small
          >
            {{ $$t('add') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script src="http://localhost:8098"></script>
<script>
import { mdiDelete, mdiNotebook } from '@mdi/js';
import DatePickerSmall from '~src/components/DatePickerSmall.vue';
import accounts from '../mixins/accounts';
import { mapState } from 'vuex';

export default {
  name: 'TableContractItems',
  module: 'ContractItem',
  mixins: [accounts],
  components: {
    DatePickerSmall,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    contractType: {
      type: String,
      default: 'ACCREC',
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },
  data() {
    return {
      localItems: [],
      mdiDelete,
      mdiNotebook,
    };
  },
  computed: {
    ...mapState('revenueLines', ['revenueLines']),
    contractAccounts() {
      const { contractType } = this;
      let type = 'EXPENSE';
      if (contractType === 'ACCREC') {
        type = 'REVENUE';
      }
      return this.allAccounts.filter(
        (account) => account.type === type,
      );
    },
    headers() {
      return [
        {
          text: this.$$t('description'),
          value: 'description',
        },
        {
          text: this.$$t('quantity'),
          value: 'quantity',
          width: '110px',
        },
        {
          text: this.$$t('amount'),
          value: 'amount',
          width: '110px',
        },
        {
          text: this.$$t('start'),
          value: 'start',
          width: '155px',
        },
        {
          text: this.$$t('end'),
          value: 'end',
          width: '155px',
        },
        {
          text: this.$$t('total'),
          value: 'total',
          width: '110px',
        },
        {
          text: this.$$t('revenueLine'),
          value: 'revenueLineId',
          width: '300px',
        },
        // {
        //   text: this.$$t('bankAccount'),
        //   value: 'bankAccountId',
        //   width: '200px',
        // },
        // {
        //   text: this.$$t('account'),
        //   value: 'accountId',
        //   width: '200px',
        // },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
    totals() {
      // Calculate the totals for each column
      return this.localItems.reduce((totals, item) => {
        Object.keys(item).forEach(key => {
          totals[key] = (totals[key] || 0) + parseInt(item[key]);
        });
        totals.total = (totals.total || 0) + this.getTotal(item);
        return totals;
      }, {});
    },
  },
  watch: {
    localItems: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.localItems);
      },
    },
    value() {
      this.localItems = this.value;
    },
  },
  methods: {
    getTotal(item) {
      return (item.quantity ?? 0) * (item.amount ?? 0);
    },
    revenueLineChanged(item, index) {
      const revenueLine = this.revenueLines.find(
        (line) => line.id === item.revenueLineId,
      );
      if (revenueLine) {
        switch(this.contractType) {
          case 'ACCREC':
            this.localItems[index].accountId = revenueLine.incomeAccountId;
            this.localItems[index].bankAccountId =
              revenueLine.incomeBankAccountId;
            break;
          case 'ACCPAY':
            this.localItems[index].accountId = revenueLine.expenseAccountId;
            this.localItems[index].bankAccountId =
              revenueLine.expenseBankAccountId;
            break;
        }
      }
    },
  },
  mounted() {
    this.localItems = this.value;
  },
  created() {
    this.localItems = this.value;
  },
};
</script>
