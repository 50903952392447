<template>
  <v-row class="text-center" v-if="chartData.length > 1">
    <v-col cols="12">
      <v-progress-linear :indeterminate="true" v-if="isLoading" color="primary"></v-progress-linear>
      <GChart
        v-else
        type="BarChart"
        :data="chartData"
        :options="chartOptions"
        width="100%"
      />
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { GChart } from 'vue-google-charts/legacy';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'GraphClientPipeline',
  mixins: [loading],
  module: 'Reports',
  components: {
    GChart,
  },
  props: {
    lineType: {
      type: String,
      default: 'Monthly Recurring Cost',
    },
    clientId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    ...mapState('currencies', ['selectedCurrency']),
    ...mapGetters('currencies', ['currencySymbol']),
    chartData() {
      const { items, statuses } = this;
      const data = [['Status', 'Non-Recurring Profit', 'Recurring Profit']];
      statuses.forEach((status) => {
        const incomeLines = [...items
          .filter((item) => item.status === status)
          .map((item) => item.lines)]
          .flat();
        const total = incomeLines
          .reduce((sum, item) => {
            const totalProfit = sum + (item.profit ?? 0);
            return totalProfit;
          }, 0);
        const totalRecurring = incomeLines
          .filter((l) => l.lineType !== 'Non-Recurring Cost')
          .reduce((sum, item) => {
            const totalProfit = sum + (item.profit ?? 0);
            return totalProfit;
          }, 0);

        const totalNonRecurring = total - totalRecurring;
        data.push([status, totalRecurring, totalNonRecurring]);
      });
      return data;
    },
    maxValue() {
      const { chartData } = this;
      const profits = chartData
        .slice(1)
        .map((row) => row.slice(1).reduce((sum, value) => sum + value, 0));
      return Math.max(...profits) * 1.1;
    },
    statuses() {
      return [...new Set(this.items.map((item) => item.status))];
    },
    chartOptions() {
      const { maxValue } = this;
      return {
        animation: {
          duration: 1000,
          easing: 'out',
        },
        chart: {
          title: 'Overview',
        },
        vAxis: {
          format: `${this.currencySymbol} #,###`, title: '', minValue: 0, maxValue,
        },
        hAxis: {
          minorGridlines:
        { minSpacing: 100 },
          title: '',
        },
        height: 280,
        colors: ['#1B99E8', '#F11A16'],
        legend: { position: 'top' },
        // isStacked: true,
        annotations: {
          alwaysOutside: true,
          stem: {
            length: 5,
          },
          textStyle: {
            // The color of the text.
            color: '#000000',
          },
        },
      };
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    /**
     * Executes refresh
     */
    async refresh() {
      this.isLoading = true;
      this.items = [];
      let url = 'odata/agreements?';
      url += '$filter=isDeleted eq false AND isActive eq true';
      // url += ` AND lineType eq '${this.lineType}' AND isDeleted eq false AND isActive eq true`;
      url += ` AND (contactId eq ${this.clientId} OR solution/ContactId eq ${this.clientId})`;
      url += '&$expand=Lines($filter=isDeleted eq false and isActive eq true),solution($select=contactId)';
      url += '&$select=Lines,solution,status';
      const { data } = await this.$http.get(url);
      this.items = data.value;
      this.isLoading = false;
    },
  },
};
</script>
