<template>
  <v-select
    filled
    dense
    :items="availablePeople"
    :data-cy="dataCy"
    :label="label"
    :item-text="(c) => `${c.firstName} ${c.lastName} (${c.emailAddress})`"
    item-value="id"
    v-model="localValue"
    @change="$emit('change', localValue)"
    @input="$emit('input', localValue)"
  >
    <template v-slot:prepend-inner>
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on">{{ mdiPlus }}</v-icon>
        </template>
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            New Person
          </v-card-title>

          <v-card-text>
            <v-text-field
              v-model="person.firstName"
              label="First Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="person.lastName"
              label="Last Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="person.emailAddress"
              label="Email Address"
              required
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn text @click="dialog = false">
              Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { mdiPlus } from '@mdi/js';

export default {
  name: 'SelectPerson',
  props: {
    contactId: {
      type: String,
      default: null,
    },
    dataCy: {
      type: String,
      default: 'SelectPerson',
    },
    label: {
      type: String,
      default: 'Select Person',
    },
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localValue: this.value,
      mdiPlus,
      dialog: false,
      person: {
        firstName: '',
        lastName: '',
        emailAddress: '',
        personType: 'Client Contact',
        contactId: this.contactId,
      },
    };
  },
  computed: {
    ...mapState('people', ['people']),
    availablePeople() {
      return this.people.filter((p) => p.contactId === this.contactId) ?? [];
    },
  },
  watch: {
    value: {
      handler() {
        this.localValue = this.value;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('people', ['LOAD_people', 'CREATE_person']),
    async save() {
      const person = await this.CREATE_person(this.person);
      await this.LOAD_people();
      this.localValue = person.id;
      this.dialog = false;
      this.person = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        personType: 'Client Contact',
        contactId: this.contactId,
      };
      this.$emit('input', this.localValue);
      this.$emit('change', this.localValue);
    },
  },
};
</script>
