import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('supplier', 'suppliers');

const customActions = {
  async LOAD_suppliers({ commit, rootGetters }, { includeArchived }) {
    const $filter = includeArchived ? 'IsDeleted eq false' : 'IsDeleted eq false AND IsActive eq true';
    const data = await odata.o(rootGetters)
      .get('suppliers')
      .query({
        $filter,
        $orderby: 'Name',
      });
    commit('SET_suppliers', data);
    return data;
  },
};

export default { ...actions, ...customActions };
