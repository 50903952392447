import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('client', 'clients');

const customActions = {
  async LOAD_clients({ commit, rootGetters }, { includeArchived }) {
    const $filter = includeArchived ? 'IsDeleted eq false' : 'IsDeleted eq false AND IsActive eq true';
    const data = await odata.o(rootGetters)
      .get('clients')
      .query({
        $filter,
        $orderby: 'Name',
      });
    commit('SET_clients', data);
    return data;
  },
  async MERGE_clients({ rootGetters }, clients) {
    const data = await odata.o(rootGetters)
      .post('clients/merge', {
        request: {
          keys: clients,
        },
      })
      .query();
    return data;
  },
};

export default { ...actions, ...customActions };
