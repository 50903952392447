<template>
  <div class="text-center">
    <v-progress-linear :indeterminate="true" v-if="isLoading" color="primary"></v-progress-linear>
    <GChart
      v-if="localData.length > 0"
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import graph from '../mixins/graph';

export default {
  name: 'GraphSalesPipeline',
  mixins: [loading, graph],
  data() {
    return {
      counterInterval: undefined,
      localData: [],
      chartOptions: {
        animation: {
          duration: 1000,
          easing: 'out',
        },
        chart: {
          title: 'Sales Pipeline',
        },
        vAxis: { format: '#,###', title: 'Amount' },
        hAxis: { minorGridlines: { minSpacing: 100 }, title: 'Date' },
        height: 400,
        // colors: ['#39ff14', '#00daff', '#7570b3'],
        legend: { position: 'top' },
        isStacked: true,
        // chartArea: {
        //   backgroundColor: {
        //     fill: '#000',
        //     fillOpacity: 0.1,
        //   },
        //   color: '#FFF',
        // },
        // backgroundColor: {
        //   fill: '#000',
        //   fillOpacity: 0.8,
        // },
      },
    };
  },
  watch: {
    selectedFilters: {
      immediate: false,
      deep: true,
      handler() {
        this.refresh();
      },
    },
  },
  computed: {
    chartData() {
      const result = [['Month', 'Prospecting', 'Negotiating', 'Possibility', 'Certain']];
      const items = this.localData.map((f) => {
        const item = [];
        item.push(f.month);
        item.push(f.prospectingAmount);
        item.push(f.negotiatingAmount);
        item.push(f.possibilityAmount);
        item.push(f.certainAmount);
        return item;
      });
      const chartData = [...result, ...items];
      return chartData;
    },
  },
  // created() {
  //   this.counterInterval = setInterval(
  //     () => {
  //       this.refresh();
  //     }, 60000,
  //   );
  // },
  // destroyed() {
  //   clearInterval(this.counterInterval);
  // },
  async mounted() {
    await this.refresh();
  },
  methods: {
    async refresh() {
      this.isLoading = true;
      const result = await this.$http.get('api/quotes/reports/sales-pipeline');
      this.localData = result.data;
      this.isLoading = false;
    },
  },
};
</script>
