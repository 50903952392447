<template>
  <v-btn :disabled="disabled" icon @click="toggle">
    <v-icon>{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'BooleanIconButton',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    trueIcon: {
      type: String,
      required: true,
    },
    falseIcon: {
      type: String,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.value ? this.trueIcon : this.falseIcon;
    },
  },
  methods: {
    toggle() {
      this.$emit('input', !this.value);
    },
  },
};
</script>
