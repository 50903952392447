import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('solution', 'solutions');

const customActions = {
  async LOAD_solution({ commit, rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`solutions(${id})`)
      .query();
    commit('SET_solution', data);
    return data;
  },
  async LOAD_MIN_solutions({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('solutions')
      .query({
        $select: 'id,name',
        $filter: 'IsDeleted eq false',
      });
    commit('SET_solutions', data);
    return data;
  },
  async LOAD_manageSolutions({ commit, rootGetters }) {
    const data = await odata.o(rootGetters)
      .get('solutions')
      .query();
    commit('SET_solutions', data);
    return data;
  },
  async LOAD_summary({ commit, rootGetters, rootState }, id) {
    const currencyCode = rootState.currencies.selectedCurrency;
    if (!id) return;
    const data = await odata.o(rootGetters)
      .get(`solutions(${id})/GetSummary(currencyCode='${currencyCode}')`)
      .query();
    commit('SET_summary', data);
  },
  async LOAD_solutionExpanded({ rootGetters }, id) {
    const data = await odata.o(rootGetters)
      .get(`solutions(${id})`)
      .query({
        $expand: 'Agreements',
      });
    return data;
  },
};

export default { ...actions, ...customActions };
