<template>
  <v-autocomplete v-bind="$props" v-on="$listeners"
    ref="autocomplete">
    <template
      v-for="slot in Object.keys($scopedSlots)"
      :slot="slot"
      slot-scope="scope"
    >
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-autocomplete>
</template>

<script>
import { VAutocomplete } from 'vuetify/lib';

export default {
  name: 'AutoComplete',
  extends: VAutocomplete,
  methods: {
    selectFirstOption() {
      this.$refs.autocomplete.selectItem(this.items[0]);
    },
  },
};
</script>
