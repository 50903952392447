<template>
  <v-container>
    <v-skeleton-loader
      :loading="isLoading"
      transition="expand-transition"
      type="article"
    >
      <FormAccount
        :Account="editAccount"
        :isSaving="isSaving"
        @save="save"
        @cancel="$router.push({name: from.name })" />
    </v-skeleton-loader>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import FormAccount from '../components/FormAccount.vue';

export default {
  components: {
    FormAccount,
  },
  name: 'EditAccount',
  module: 'Account',
  mixins: [loading],
  meta: {
    breadcrumb:
      [{
        text: 'menu.home',
        to: { name: 'app-Home' },
      },
      {
        text: 'menu.accounts',
        to: { name: 'contracts-ListAccount' },
      },
      {
        text: 'menu.edit',
      },
      ],
  },
  data() {
    return {
      /**
      * Get/Set editing Account. Default to {}.
      */
      editAccount: {},
      /**
       * Query Id for Account
       */
      accountId: this.$route?.query.id,
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('accounts', ['accounts', 'account']),
    /** Get the mode for this view, create or update
     */
    mode() {
      if (this.accountId) {
        return 'edit';
      }
      return 'add';
    },
  },
  async mounted() {
    this.isLoading = true;
    if (this.mode === 'edit') {
      await this.LOAD_account(this.accountId);
      this.editAccount = this.account;
    }
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('accounts', ['LOAD_account', 'CREATE_account', 'UPDATE_account']),
    /** Method used to save the item
     */
    async save(account) {
      this.isSaving = true;
      if (this.mode === 'add') {
        await this.CREATE_account(account);
      } else {
        await this.UPDATE_account(account);
      }
      this.isSaving = false;
      this.$router.push({ name: 'contracts-ListAccount' });
    },
  },
};
</script>

<docs>
# EditAccount example

'''js
<EditAccount :Account="Account" />
'''
</docs>
